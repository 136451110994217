import React from 'react'
import { ConnectedRouter } from 'connected-react-router'
import ScrollToTop from 'react-router-scroll-top'
import routes from './routes'
import './assets/css/style.scss'
import Modal from 'react-modal'
import queryString from 'query-string'
import ls from 'localstorage-ttl'
import ReactGA from 'react-ga'
import CommonFooter from './components/footer/CommonFooter'
import LogHelper from './helpers/LogHelper'

Modal.setAppElement('#root')

export default class App extends React.Component {
  componentDidMount() {
    const { pathname } = this.props.history.location
    ReactGA.set({ page: pathname })
    ReactGA.pageview(pathname)

    let hostCode =
      queryString.parse(this.props.history.location.search).host ||
      ls.get('hostCode')

    if (hostCode) {
      ls.set('hostCode', hostCode, 1800000)
    }

    LogHelper.postPageAccessLog(this.props.history)
  }

  render() {
    return (
      <>
        <ConnectedRouter history={this.props.history}>
          <ScrollToTop>{routes}</ScrollToTop>
        </ConnectedRouter>
        <CommonFooter />
      </>
    )
  }
}
