function getApiKey() {
  switch (process.env.REACT_APP_ENV) {
    case 'production': {
      return 'AIzaSyDZeF2IXUcsXS4l8edr4cub79VgAyxesR0'
    }
    case 'development': {
      return 'AIzaSyCyeDKcxnxya_m0h2u9VwbGlaXKUIWC6qo'
    }
    default: {
      return 'AIzaSyCyeDKcxnxya_m0h2u9VwbGlaXKUIWC6qo'
    }
  }
}

const ApiKey = getApiKey()
export { ApiKey }
