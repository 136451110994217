const initialState = {
  data: {
    reviews: [],
  },
}
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'GOOGLE_PLACE_REQUEST':
      return {
        ...state,
        loadingFlag: true,
      }
    case 'GOOGLE_PLACE_REQUEST_OK':
      return {
        ...state,
        data: action.payload.data,
        loadingFlag: false,
      }
    case 'GOOGLE_PLACE_REQUEST_NG':
      return {
        ...state,
        loadingFlag: false,
      }
    case 'CLEAR_GOOGLE_PLACE':
      return initialState
    default:
      return state
  }
}
