import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  updatePasswordForm,
  postUpdatePassword,
} from '../../../actions/updatePassword'
import AuthComponent, {
  authMapStateToProps,
  authMapDispatchToProps,
} from '../../../components/AuthComponent'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import cleanDeep from 'clean-deep'
import { Formik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { withRouter } from 'react-router'
import { PcHeader, SpHeader } from '../../../components/top/Header'

const styles = theme => ({
  textField: {
    width: '100%',
    fontSize: '16px',
  },
  formControl: {
    minWidth: 100,
  },
})

const Text = ({
  label,
  disabled,
  handleChange,
  costomChange,
  values,
  errors,
  name,
  classes,
  touched,
  setFieldTouched,
  rows,
  parentErrors,
}) => (
  <div style={{ position: 'relative', fontSize: '40px' }}>
    <TextField
      className={classes.textField}
      name={name}
      label={label}
      disabled={disabled}
      inputProps={{ style: { fontSize: 16 } }}
      error={
        (!!parentErrors && !!parentErrors[name]) ||
        (!!touched[name] && !!errors[name])
      }
      value={values[name]}
      rows={rows}
      onFocus={() => setFieldTouched(name, true, false)}
      onChange={e => {
        handleChange(e)
        costomChange(e, name)
      }}
    />
    {!!parentErrors && !!parentErrors[name] && (
      <div style={{ position: 'absolute', color: 'red', fontSize: '0.8rem' }}>
        {parentErrors[name]}
      </div>
    )}
    {!!touched[name] && errors[name] && (
      <div style={{ position: 'absolute', color: 'red', fontSize: '0.8rem' }}>
        {errors[name]}
      </div>
    )}
  </div>
)

function mapStateToProps(state) {
  return Object.assign(authMapStateToProps(state), {
    updatePassword: state.updatePassword,
  })
}

function mapDispatchToProps(dispatch) {
  return Object.assign(authMapDispatchToProps(dispatch), {
    updatePasswordForm: bindActionCreators(updatePasswordForm, dispatch),
    postUpdatePassword: bindActionCreators(postUpdatePassword, dispatch),
  })
}
class PasswordUpdateContainer extends AuthComponent {
  constructor(props) {
    super(props)
    this.state = {
      btnDidabled: true,
    }
  }
  componentDidMount() {
    super.componentDidMount()
    this.props.updatePassword.user_no = this.props.authUser.authInfo.user_no
    this.props.updatePassword.token = this.props.authUser.authInfo.token
  }

  componentDidUpdate() {
    this.checkPassword()
  }

  handleChange = (event, name, formikProps) => {
    this.onUpdate({
      ...this.props.signUpForm,
      [name]: event.target.value,
    })
  }
  onUpdate = data => {
    const updatePassword = this.props.updatePassword
    this.props.updatePasswordForm({ ...updatePassword, ...data })
  }

  normalizeData = () => {
    const { updatePassword } = this.props
    return cleanDeep(updatePassword)
  }
  submit = () => {
    const data = this.normalizeData()
    console.log(data)
    this.props.postUpdatePassword({ data })
  }

  checkPassword() {
    if (this.state.btnDidabled === true) {
      if (
        this.props.updatePassword.pass ===
        this.props.updatePassword.pass_confirm &&
        this.props.updatePassword.pass !== ''
      ) {
        this.setState({ btnDidabled: false })
        this.props.updatePassword.errors = ''
      }
    }

    if (this.state.btnDidabled === false) {
      if (
        !(
          this.props.updatePassword.pass ===
          this.props.updatePassword.pass_confirm &&
          this.props.updatePassword.pass !== ''
        )
      ) {
        this.setState({ btnDidabled: true })
      }
    }
  }

  render() {
    const { classes, authUser, updatePassword } = this.props
    return (
      <>
        <div className="account-setting top-page">
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
          <PcHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
          />
          <SpHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
          />
          <div className="account-setting-contents">
            <section className="setting-menu">
              <ul>
                <li>
                  <Link to="/setting/reservation">ご予約一覧</Link>
                </li>
                <li>
                  <Link to="/setting/creditcard">お支払い情報</Link>
                </li>
                <li>
                  <Link to="/setting/user_info">アカウント設定</Link>
                </li>
                <li Style="color: #ff5800;">
                  <Link to="/setting/password">パスワード変更</Link>
                </li>
                <li>
                  <Link to="/setting/delete">退会</Link>
                </li>
              </ul>
            </section>
            <section className="setting-form">
              <Formik
                // validationSchema={validationSchema}
                enableReinitialize={true}
              >
                {props => (
                  <>
                    <div className="reserve-form-guest-ditails reserve-form-container">
                      <h3 className="block-title">パスワード変更</h3>
                      <p
                        style={{
                          fontSize: '0.8rem',
                          paddingLeft: '20px',
                          fontWeight: '500',
                          lineHeight: '19px',
                          color: '#5c5c5c',
                        }}
                      >
                        *半角大文字、半角小文字、数字をそれぞれ１つ以上組み合わせた８文字以上で設定ください。
                      </p>
                      <p
                        style={{
                          position: 'absolute',
                          color: 'red',
                          fontSize: '0.8rem',
                          paddingLeft: '20px',
                        }}
                      >
                        {updatePassword.errorMessage}
                      </p>
                      <ul className="reserve-form-input-area">
                        <Text
                          {...props}
                          name="pass"
                          label="新しいパスワード"
                          type="password"
                          values={updatePassword.pass}
                          classes={classes}
                          costomChange={(e, name) =>
                            this.handleChange(e, name, props)
                          }
                        />
                        <Text
                          {...props}
                          name="pass_confirm"
                          label="パスワードの確認"
                          values={updatePassword.pass_confirm}
                          classes={classes}
                          costomChange={(e, name) =>
                            this.handleChange(e, name, props)
                          }
                        />
                        {!!updatePassword.errors && (
                          <div
                            style={{
                              position: 'absolute',
                              color: 'red',
                              fontSize: '0.8rem',
                            }}
                          >
                            {updatePassword.errors}
                          </div>
                        )}
                      </ul>
                    </div>
                    <p className="reserve-confirm-btn">
                      <input
                        style={{ cursor: 'pointer' }}
                        type="button"
                        value="パスワードを変更する"
                        disabled={this.state.btnDidabled}
                        onClick={() => this.submit()}
                      />
                    </p>
                  </>
                )}
              </Formik>
            </section>
          </div>
        </div>
      </>
    )
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(PasswordUpdateContainer))
)
