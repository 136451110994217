const initialState = {
  data: [],
}
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'AREAS_REQUEST':
      return {
        ...state,
        loadingFlag: true,
      }
    case 'AREAS_OK':
      return {
        ...state,
        data: action.payload.data,
        loadingFlag: false,
      }
    case 'AREAS_NG':
      return {
        ...state,
        loadingFlag: false,
      }
    default:
      return state
  }
}
