// import React, { Component } from 'react'
// import FsLightbox from 'fslightbox-react'

// export default class RestaurantImage extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       isOpen: false,
//     }
//   }

//   createSlideImages() {
//     const createdSlideImages = this.props.images.map(images => {
//       return images
//     })
//     if (this.props.video) {
//       createdSlideImages.unshift(this.props.video)
//     }
//     return createdSlideImages
//   }

//   createSlideImagesType() {
//     const slideImagesType = []
//     if (!(this.props.video == null)) {
//       slideImagesType.push('video')
//     }
//     for (var i = 0; i < this.props.images.length; i++) {
//       slideImagesType.push('image')
//     }
//     return slideImagesType
//   }

//   render() {
//     const data = this.props

//     return (
//       <>
//         <div
//           className="restaurant-image-box sp"
//           onClick={() => this.setState({ isOpen: !this.state.isOpen })}
//         >
//           <div className="restaurant-image-box-inner">
//             <div className="restaurant-image">
//               {data.images && <img src={data.images[0]} alt="レストラン" />}
//             </div>
//             {data.video && (
//               <img
//                 src="/assets/img/play_video_icon.svg"
//                 alt=""
//                 className="play-video-icon"
//               />
//             )}
//           </div>

//           {data.chefImage && (
//             <img
//               src={data.chefImage}
//               className="restaurant-img-icon"
//               alt="シェフ"
//             />
//           )}
//           <img
//             src="/assets/img/box_icon_01.svg"
//             className="restaurant-box-icon"
//             alt=""
//             onClick={() => this.setState({ isOpen: !this.state.isOpen })}
//             style={{ cursor: 'pointer' }}
//           />
//         </div>
//         <div className="kv_restaurant_menu">
//           <div onClick={() => this.setState({ isOpen: !this.state.isOpen })}>
//             {data.images && <img src={data.images[0]} alt="レストラン" />}
//             {data.video && (
//               <img
//                 src="/assets/img/play_video_icon.svg"
//                 alt=""
//                 className="play-video-icon"
//               />
//             )}
//           </div>
//         </div>
//         {data.images && (
//           <FsLightbox
//             toggler={this.state.isOpen}
//             sources={this.createSlideImages()}
//             key={this.createSlideImages()}
//             types={this.createSlideImagesType()}
//           />
//         )}
//       </>
//     )
//   }
// }

import React from 'react'
import Slider from 'react-slick'
import { isMobile } from 'react-device-detect'

const RestaurantImage = ({ data, className }) => {
  const settings1 = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
  }

  const settings2 = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  }

  const settings = isMobile ? settings2 : settings1

  return (
    <div className="restaurant">
      <div className="restaurant__slide1">
        <Slider {...settings}>
          {data.images.map(
            (image, index) =>
              index < 8 && (
                <div>
                  <img src={image} alt="" />
                </div>
              )
          )}
        </Slider>
      </div>
      {isMobile && data.chefImage && (
        <img src={data.chefImage} className={className} alt="chef" />
      )}
    </div>
  )
}

export default RestaurantImage
