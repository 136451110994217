import { RESTAURANT_OK } from '../actions'
import { fetchMenus } from '../actions/restaurant'

const hooks = {
  [RESTAURANT_OK](store, action) {
    if (action.payload && action.payload.data.reservationType === 'chefle') {
      const payload = {
        restaurantId: action.payload.data.id,
      }
      store.dispatch(fetchMenus(payload))
    }
    if (action.payload && action.payload.data.reservationType === 'pickup') {
      const payload = {
        restaurantId: action.payload.data.id,
      }
      store.dispatch(fetchMenus(payload))
    }
    if (
      action.payload &&
      action.payload.data.reservationType === 'chefle-menu'
    ) {
      const payload = {
        restaurantId: action.payload.data.id,
      }
      store.dispatch(fetchMenus(payload))
    }
  },
}

export default store => next => action => {
  const hook = hooks[action.type]
  hook && hook(store, action)
  return next(action)
}
