import queryString from 'query-string'
import axios from 'axios'
import ApiOrigin from '../constants/endpoint'
import ls from 'localstorage-ttl'
import uuidv1 from 'uuid/v1'

const API_ROOT = ApiOrigin

const LogHelper = {
  postPageAccessLog: history => {
    if (!localStorage.getItem('clientTerminalId')) {
      localStorage.setItem('clientTerminalId', uuidv1())
    }

    const url = API_ROOT + '/log/page'
    axios({
      url,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Client-Terminal-Id': localStorage.getItem('clientTerminalId'),
      },
      data: {
        clientTerminalId: localStorage.getItem('clientTerminalId'),
        url: window.location.protocol + '//' + window.location.hostname,
        path: history.location.pathname,
        query: queryString.parse(history.location.search),
        hostCode: ls.get('hostCode'),
        referer: document.referrer,
        postedAt: Date.now(),
      },
      timeout: 20000,
    }).then(response => {
      return response
    })
  },
}

export default LogHelper
