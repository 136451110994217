import { CALL_API } from '../middlewares/api'
import { AREAS_REQUEST, AREAS_OK, AREAS_NG } from './index'

export const fetchAreas = (payload = {}) => ({
  [CALL_API]: {
    types: [AREAS_REQUEST, AREAS_OK, AREAS_NG],
    endpoint: `/chefle/areas`,
    payload,
  },
})
