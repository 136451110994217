import { CALL_API } from '../middlewares/api'
import {
  RESTAURANT_REQUEST,
  RESTAURANT_OK,
  RESTAURANT_NG,
  MENUS_REQUEST,
  MENUS_OK,
  MENUS_NG,
  RESERVABLE_DATE_REQUEST,
  RESERVABLE_DATE_OK,
  RESERVABLE_DATE_NG,
  RESERVABLE_TIME_REQUEST,
  RESERVABLE_TIME_OK,
  RESERVABLE_TIME_NG,
  LOCAL_SET_RESEVABLE_TIME,
} from './index'

export const fetchRestaurant = payload => ({
  [CALL_API]: {
    types: [RESTAURANT_REQUEST, RESTAURANT_OK, RESTAURANT_NG],
    endpoint: `/chefle/restaurants/${payload.restaurantId}`,
    payload,
  },
})

export const fetchPickupRestaurant = payload => ({
  [CALL_API]: {
    types: [RESTAURANT_REQUEST, RESTAURANT_OK, RESTAURANT_NG],
    endpoint: `/chefle/restaurants/${payload.restaurantId}?type=pickup`,
    payload,
  },
})

export const fetchChefleMenuRestaurant = payload => ({
  [CALL_API]: {
    types: [RESTAURANT_REQUEST, RESTAURANT_OK, RESTAURANT_NG],
    endpoint: `/chefle/restaurants/${payload.restaurantId}?type=chefle-menu`,
    payload,
  },
})

export const fetchMenus = payload => ({
  [CALL_API]: {
    types: [MENUS_REQUEST, MENUS_OK, MENUS_NG],
    endpoint: `/restaurants/${payload.restaurantId}/plans`,
    payload,
  },
})

export const fetchResevableDate = payload => ({
  [CALL_API]: {
    types: [RESERVABLE_DATE_REQUEST, RESERVABLE_DATE_OK, RESERVABLE_DATE_NG],
    endpoint: `/restaurants/${payload.restaurantId}/reservable-dates`,
    payload,
  },
})

export const fetchResevableTime = payload => ({
  [CALL_API]: {
    types: [RESERVABLE_TIME_REQUEST, RESERVABLE_TIME_OK, RESERVABLE_TIME_NG],
    endpoint: `/restaurants/${payload.restaurantId}/reservable-times?date=${
      payload.query
    }`,
    payload,
  },
})

export const setResevableTime = (payload = [], meta = {}) => ({
  type: LOCAL_SET_RESEVABLE_TIME,
  payload,
  meta,
})
