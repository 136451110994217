const initialState = {
  ID: '', //　メールアドレス
  sei: '',
  mei: '',
  seikana: '',
  meikana: '',
  birthday: '',
  allergy: '',
  tel: '',
  prefectures: '',
  mailmagazine: false,
  errors: {},
  isCompleteSignUp: false,
  errorMessage: '',
}
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_SIGN_UP_FORM':
      return {
        ...state,
        ...action.payload,
      }
    case 'CONFIRM_SIGN_UP_FORM':
      return {
        ...state,
        ...action.payload,
      }
    case 'SIGN_UP_POST_REQUEST':
      return {
        ...state,
        postingFlag: true,
      }
    case 'SIGN_UP_POST_OK':
      if (action.payload.data.error === 0) {
        // 正常時
        return {
          ...state,
          postingFlag: false,
          isCompleteSignUp: true,
        }
      } else {
        // エラー時
        return {
          ...state,
          postingFlag: false,
          errorMessage: action.payload.data.body.message,
          isCompleteSignUp: false,
        }
      }
    case 'SIGN_UP_POST_NG':
      return {
        ...state,
        postingFlag: false,
        errorMessage:
          'システムエラーが発生しております。しばらくお待ちください。',
        isCompleteSignUp: false,
      }
    default:
      return state
  }
}
