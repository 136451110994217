import React from 'react'
import StringHelper from '../../helpers/StringHelper'

const MenuReserveHeader = props => (
  <header>
    <h1 className="menu-reserve-store-title">
      <span>Chefle 予約</span>
    </h1>
  </header>
)

const StrictNotice = ({ isVacancyManagement }) => (
  <div className="notice" Style="padding-top: 20px;">
    <div className="header-inner" id="modal">
      <p className="notification-title" Style="margin-top: 0px;">
        注意事項
      </p>
      {isVacancyManagement && (
        <p
          className="notification-txt"
          Style="color: #ff5800; font-weight:bold;"
        >
          このご予約は"即時予約"です。予約情報を入れ、カード決済を完了した時点で予約が確定します。※キャンセルポリシーの対象となります。
        </p>
      )}
      {isVacancyManagement ? (
        <p className="notification-txt">
          ご予約送信前に、必ずキャンセルポリシーのご確認をお願いいたします。
          ご予約送信後、クレジットカード情報の入力画面に切り替わります。ご予約確定時に決済が発生します。
        </p>
      ) : (
        <p className="notification-txt">
          ご予約リクエスト送信前に、必ずキャンセルポリシーのご確認をお願いいたします。
          ご予約リクエスト送信後、クレジットカード情報の入力画面に切り替わります。ご予約確定時に決済が発生します。ご予約確定に至らなかった場合、決済されることはございません。
          稀に、与信審査のため、ご請求が発生したように明細表示されることがございます。30日以上同じ状態が続く場合は、クレジットカード会社にお問い合わせください。
        </p>
      )}
    </div>
  </div>
)

const BraedCrumb = props => (
  <ul className="breadcrumb">
    <li>Top</li>
    <li>Tokyo, Ginza</li>
    <li>restaurant name</li>
  </ul>
)
const RestaurantName = props => (
  <div className="menu-reserve-name-container">
    <p className="menu-reserve-name-en">{props.en}</p>
    <p className="menu-reserve-name-ja">{props.ja}</p>
  </div>
)

const SelectedData = props => (
  <div className="reservation-fee-02 reserve-form-container">
    <h3 className="reservation-fee-title">ご予約金額</h3>
    <p className="reservation-fee-menu">
      <img src="/assets/img/menu_icon_02.svg" alt="" />
    </p>
    <ul className="reserve-form-contents-menu">
      {props.menuForm.restaurant.plans.map((obj, i) => (
        <li key={'selectedMenu' + i}>
          <div className="reserve-form-contents-box">
            <p className="reserve-form-contents-box-menu">{obj.name}</p>
            <p className="reserve-form-contents-box-course">
              {StringHelper.getCourse(obj.description)}
            </p>
            <p className="reserve-form-contents-box-price">
              ¥&nbsp;
              <span className="reserve-form-contents-box-price-num">
                {StringHelper.numberWithCommas(
                  props.isHostPrice && obj.hostPrice ? obj.hostPrice : obj.price
                )}
              </span>
              <span className="reserve-form-contents-box-price-txt">
                /&nbsp;person&nbsp;
              </span>
              <span className="reserve-form-contents-box-price-txt2">
                x&nbsp;{obj.quantity}
              </span>
              <p className="reserve-form-contents-fee">
                シェフルサービス料 {obj.jpneazyFeeRate}%含む
              </p>
            </p>
          </div>
        </li>
      ))}
    </ul>
    <div className="reservation-fee-line-box">
      <hr className="reservation-fee-line" />
    </div>
    <div className="reserve-form-contents-point">
      <ul>
        {props.restaurant.data.reservationType === 'chefle' && (
          <li Style="color: #ff5800">
            *本サービスは、ポイント獲得・ポイント
            <br />
            利用はできません。
          </li>
        )}
        {props.restaurant.data.reservationType === 'pickup' && (
          <li Style="color: #ff5800">
            *本サービスは、ポイント獲得はできません。
          </li>
        )}
        {props.restaurant.data.reservationType === 'chefle-menu' && (
          <li>
            獲得予定ポイント: <span>{calcTotlePoint(props.menuForm)}P</span>
          </li>
        )}
        {(props.menuForm.usePoint === 0 ||
          props.menuForm.usePoint === null) && <li>ポイントを利用しない。</li>}
        {props.effectivePoint === props.usePoints &&
          props.effectivePoint >= 100 && <li>すべてのポイントを利用する。</li>}
        {props.menuForm.usePoint !== 0 &&
          props.effectivePoint !== props.menuForm.usePoint &&
          props.menuForm.usePoint !== null && (
            <li>
              利用するポイント: <span> {props.menuForm.usePoint}P</span>
            </li>
          )}
      </ul>
    </div>
    <div className="reserve-form-contents-last-box">
      <p className="reserve-form-contents-last">
        合計：
        <span className="reserve-form-contents-total-yen">¥</span>
        <span className="reserve-form-contents-total-number">
          {calcTotle(
            props.menuForm.restaurant.plans,
            props.isHostPrice,
            props.menuForm
          )}
        </span>
      </p>
      {props.menuForm.usePoint !== 0 &&
        (props.restaurant.data.reservationType === 'pickup' ||
          props.restaurant.data.reservationType === 'chefle-menu') && (
          <p Style="font-size: 12px; color: #5c5c5c;">
            ＊ポイント利用を反映した金額です
          </p>
        )}
    </div>
  </div>
)
const calcTotle = (menus, isHostPrice, menuForm) => {
  let sum = menus.reduce((value, menu) => {
    let price = isHostPrice && menu.hostPrice ? menu.hostPrice : menu.price
    return value + price * menu.quantity
  }, 0)

  // stateにtotalPriceを保存（ポイントでマイナスする前の合計金額）
  menuForm.totalPrice = sum

  // pickup, chefle-menuでポイントを利用する場合
  if (
    menuForm.usePoint !== 0 &&
    sum !== 0 &&
    menuForm.usePoint !== undefined &&
    menuForm.usePoint !== null
  ) {
    sum = sum - parseInt(menuForm.usePoint, 10)

    if (sum <= 0) {
      sum = 0
    }
  }

  return StringHelper.numberWithCommas(sum)
}

// 獲得予定ポイントを計算する
const calcTotlePoint = menuForm => {
  let people = menuForm.adult + menuForm.child
  let times = menuForm.time.split(':')
  let date_object = new Date()
  let sum = 0

  // 10人までポイント付与可能
  if (10 <= people) {
    people = 10
  }

  //  夜 15:00 ~ 23:59
  if (
    date_object.setHours(15, 0, 0) <=
    date_object.setHours(times[0], times[1], 0)
  ) {
    sum = people * 1000
    return sum
  }
  // 昼　0:00 ~ 14:59
  sum = people * 500

  return sum
}

const MenuCancellationPolicy = props => (
  <div className="reserve-form-cancelpolicy">
    <p className="reserve-form-cancelpolicy-title">キャンセルポリシー</p>
    <p className="reserve-form-cancelpolicy-txt">
      {StringHelper.nl2br(props.cancellationPolicy)}
    </p>
  </div>
)
export {
  MenuReserveHeader,
  StrictNotice,
  RestaurantName,
  BraedCrumb,
  SelectedData,
  MenuCancellationPolicy,
}
