import { format, utcToZonedTime } from 'date-fns-tz'
import { ja } from 'date-fns/locale'

const DateHelper = {
  formatDateTimeForDisplay: iso8601 => {
    //  ISO8601形式の日付をフォーマットする
    const timeZone = 'Asia/Tokyo';
    const date = utcToZonedTime(new Date(iso8601), timeZone)
    return format(date, 'yyyy年M月d日(E) H時m分', {
      timeZone,
      locale: ja,
    })
  },

  alternativeDateForUser: (date, diff) => {
    let alternativeDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + diff
    )

    let Year = alternativeDate.getFullYear()
    let Month = ('0' + (alternativeDate.getMonth() + 1)).slice(-2)
    let Day = ('0' + alternativeDate.getDate()).slice(-2)

    switch (Month) {
      case '01':
        Month = 'Jan'
        break
      case '02':
        Month = 'Feb'
        break
      case '03':
        Month = 'Mar'
        break
      case '04':
        Month = 'Apr'
        break
      case '05':
        Month = 'May'
        break
      case '06':
        Month = 'Jun'
        break
      case '07':
        Month = 'Jul'
        break
      case '08':
        Month = 'Aug'
        break
      case '09':
        Month = 'Sep'
        break
      case '10':
        Month = 'Oct'
        break
      case '11':
        Month = 'Nov'
        break
      case '12':
        Month = 'Dec'
        break
      default:
    }

    switch (Day) {
      case '01':
        Day = '1st'
        break
      case '02':
        Day = '2nd'
        break
      case '03':
        Day = '3rd'
        break
      case '04':
        Day = '4th'
        break
      case '05':
        Day = '5th'
        break
      case '06':
        Day = '6th'
        break
      case '07':
        Day = '7th'
        break
      case '08':
        Day = '8th'
        break
      case '09':
        Day = '9th'
        break
      case '10':
        Day = '10th'
        break
      case '11':
        Day = '11th'
        break
      case '12':
        Day = '12th'
        break
      case '13':
        Day = '13th'
        break
      case '14':
        Day = '14th'
        break
      case '15':
        Day = '15th'
        break
      case '16':
        Day = '16th'
        break
      case '17':
        Day = '17th'
        break
      case '18':
        Day = '18th'
        break
      case '19':
        Day = '19th'
        break
      case '20':
        Day = '20th'
        break
      case '21':
        Day = '21st'
        break
      case '22':
        Day = '22nd'
        break
      case '23':
        Day = '23rd'
        break
      case '24':
        Day = '24th'
        break
      case '25':
        Day = '25th'
        break
      case '26':
        Day = '26th'
        break
      case '27':
        Day = '27th'
        break
      case '28':
        Day = '28th'
        break
      case '29':
        Day = '29th'
        break
      case '30':
        Day = '30th'
        break
      case '31':
        Day = '31st'
        break
      default:
    }
    let alternativeDateForUser = [Month + '.\n' + Day + '\n' + Year]
    return alternativeDateForUser
  },
}

export default DateHelper
