const initialState = {
  guest: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    country: '',
    phone: '',
  },
  host: '',
  comment: '',
  allergy: '',
  date: null,
  time: '',
  alternativeBeforeDate: false,
  alternativeAfterDate: false,
  adult: 0,
  child: 0,
  baby: 0,
  selectedMenuId: 0,
  confirmedCancellationPolicy: false,
  restaurant: {
    id: 0,
    plans: [], // {id: 0, quantity: 0}
  },
  errors: {},
  postingFlag: false,
}
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_MENU_FORM':
      return {
        ...state,
        ...action.payload,
      }
    case 'CONFIRM_MENU_FORM':
      return {
        ...state,
        ...action.payload,
      }
    case 'MENU_POST_REQUEST':
      return {
        ...state,
        postingFlag: true,
      }
    case 'MENU_POST_OK':
      return {
        ...state,
        postingFlag: false,
      }
    case 'MENU_POST_NG':
      return {
        ...state,
        postingFlag: false,
      }
    default:
      return state
  }
}
