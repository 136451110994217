import React from 'react'
import GoogleMap from '../common/GoogleMap'
import StringHelper from '../../helpers/StringHelper'

const RestaurantInfo = props => (
  <div className="restaurant-info">
    <h2 className="section-title sp">レストラン情報</h2>
    <InfoItem
      className="restaurant-note"
      children={StringHelper.nl2br(props.restaurant.notice)}
    />
    <InfoItem
      className="restaurant-holiday"
      children={StringHelper.nl2br(props.restaurant.regularHoliday)}
    />
    <InfoItem
      className="restaurant-time"
      children={StringHelper.nl2br(props.restaurant.businessHour)}
    />
    <InfoItem className="restaurant-tel" children={props.restaurant.tel} />
    <InfoItem
      className="restaurant-seats"
      children={props.restaurant.numberOfSeat}
    />
    <InfoItem className="restaurant-address" noLine={true}>
      {props.restaurant.address}
    </InfoItem>
    <InfoItem
      className="restaurant-map"
      children={
        props.googlePlace.data.geometry && (
          <GoogleMap
            geometry={props.googlePlace.data.geometry}
            googlePlaceId={props.restaurant.googlePlaceId}
          />
        )
      }
    />
    {props.restaurant.cancellationPolicy && (
      <div className="restaurant-cancelpolicy">
        <p className="restaurant-cancelpolicy-head">キャンセルポリシー</p>
        {props.restaurant.reservationType === 'chefle' ? (
          <p className="restaurant-cancelpolicy-txt">
            *キャンセル料発生起算日は、各シェフ所属の営業稼働日に基づき計算
          </p>
        ) : (
          <p className="restaurant-cancelpolicy-txt">
            *キャンセル料発生起算日は、店舗営業日に基づき計算
          </p>
        )}
        <p className="restaurant-cancelpolicy-txt">
          {StringHelper.nl2br(props.restaurant.cancellationPolicy)}
        </p>
      </div>
    )}
  </div>
)

const InfoItem = props => (
  <>
    {!!props.children && (
      <>
        <div className={props.className}>{props.children}</div>
        {!props.noLine && <hr className="restaurant-line" />}
      </>
    )}
  </>
)

export default RestaurantInfo
