import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'

class SideMenu extends PureComponent {
  sideMenuClose() {
    var menuCont = document.getElementById('menu-container')
    var hTag = document.getElementsByTagName('html')
    hTag[0].classList.remove('html-fix')
    menuCont.classList.remove('menu-move-open')
    menuCont.classList.add('menu-move-close')
  }

  render() {
    const { authUser, logout } = this.props
    let isLogin = false
    if (authUser && authUser.authInfo.isLogin) {
      isLogin = true
    }
    return (
      <div id="menu-container" className="menu-container">
        <div className="menu-container-inner">
          <a
            href={() => false}
            id="menu-close"
            className="menu-close-btn"
            onClick={() => this.sideMenuClose()}
          >
            <img
              className={this.props.className}
              src="/assets/img/close_icon_01.svg"
              alt=""
            />
          </a>
          <ul className="menu-top-block">
            {isLogin && (
              <div>
                <Link
                  to="/setting/reservation"
                  Style="height:100%; border: solid 1px; display: flex; justify-content: center; align-items: center; color: white; text-decoration: none;"
                  onClick={() => this.sideMenuClose()}
                >
                  <div Style="padding: 16px;">ご予約一覧</div>
                </Link>
              </div>
            )}
            <div
              className="menu-under-block"
              Style="color: white; display: flex; height: 50px; align-items: center; justify-content: space-around;"
            >
              {isLogin ? (
                <>
                  <Link
                    to="/setting/user_info"
                    Style="width: 100%; height:100%; border: solid 1px; display: flex; justify-content: center; align-items: center; color: white; text-decoration: none;"
                    onClick={() => this.sideMenuClose()}
                  >
                    <div>アカウント設定</div>
                  </Link>
                  <Link
                    to="/setting/user_info"
                    Style="width: 100%; height:100%; border: solid 1px; display: flex; justify-content: center; align-items: center; color: white; text-decoration: none;"
                    onClick={() => logout()}
                  >
                    <div>ログアウト</div>
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to="/sign_in"
                    Style="width: 100%; height:100%; border: solid 1px; display: flex; justify-content: center; align-items: center; color: white; text-decoration: none;"
                    onClick={() => this.sideMenuClose()}
                  >
                    <div>ログイン</div>
                  </Link>
                  <Link
                    to="/sign_up"
                    Style="width: 100%; height:100%; border: solid 1px; display: flex; justify-content: center; align-items: center; color: white; text-decoration: none;"
                    onClick={() => this.sideMenuClose()}
                  >
                    <div>新規会員登録</div>
                  </Link>
                </>
              )}
            </div>

            <a href="/">
              <li Style="position: relative">
                <span className="chefle-kitchen__banner-header-side">
                  来店予約
                </span>
                <span className="chefle-kitchen__banner-text-side">
                  Reservation
                </span>
                <img
                  Style="height: 133px; object-fit:cover;"
                  src="/assets/img/chefle/top-small.jpg"
                  alt="chefleメニュー"
                />
              </li>
            </a>
            <a href="/catering">
              <li>
                <img
                  src="/assets/img/chefle/menu_restaurants.png"
                  alt="レストランメニュー"
                />
              </li>
            </a>
            <a href="/pickup">
              <li>
                <img src="/assets/img/chefle/menu_pickup.png" alt="店頭受取" />
              </li>
            </a>
            {/* <a href="/kitchen">
              <li Style="position: relative">
                <span className="chefle-kitchen__banner-header-side">
                  シェフルキッチン
                </span>
                <span className="chefle-kitchen__banner-text-side">
                  Chefle Kitchen
                </span>
                <img
                  src="/assets/img/chefle/chefle-kitchen-banner.png"
                  alt="キッチンバナー"
                />
              </li>
            </a> */}
          </ul>
        </div>
      </div>
    )
  }
}

class MenuIcon extends PureComponent {
  sideMenuOpen() {
    var menuCont = document.getElementById('menu-container')
    var hTag = document.getElementsByTagName('html')
    hTag[0].classList.add('html-fix')
    menuCont.classList.remove('menu-move-close')
    menuCont.classList.add('menu-move-open')
  }

  render() {
    // メニューアイコンの色を判断
    let src = this.props.isIconWite
      ? '/assets/img/menu_button_icon2.svg'
      : '/assets/img/menu_button_icon.svg'
    return (
      <a
        href={() => false}
        id="menu-open"
        style={{ cursor: 'pointer' }}
        className={['menu-icon', this.props.className].join(' ')}
        onClick={() => this.sideMenuOpen()}
      >
        <img src={src} alt="menu" />
      </a>
    )
  }
}
export { SideMenu, MenuIcon }
