import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AuthComponent, {
  authMapStateToProps,
  authMapDispatchToProps,
} from '../../../components/AuthComponent'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Formik } from 'formik'
import { withRouter } from 'react-router'
import { PcHeader, SpHeader } from '../../../components/top/Header'
import {
  fetchCreditcard,
  postCreditcard,
  updateCreditcard,
  deleteCreditcard,
} from '../../../actions/creditcard'
import images from 'react-payment-inputs/images'
import { PaymentInputsContainer } from 'react-payment-inputs'
import { PaymentInputsWrapper } from 'react-payment-inputs'
import ReactLoading from 'react-loading'
import Modal from 'react-modal'

function mapStateToProps(state) {
  return Object.assign(authMapStateToProps(state), {
    creditcard: state.creditcard,
  })
}

function mapDispatchToProps(dispatch) {
  return Object.assign(authMapDispatchToProps(dispatch), {
    fetchCreditcard: bindActionCreators(fetchCreditcard, dispatch),
    postCreditcard: bindActionCreators(postCreditcard, dispatch),
    updateCreditcard: bindActionCreators(updateCreditcard, dispatch),
    deleteCreditcard: bindActionCreators(deleteCreditcard, dispatch),
  })
}

class CreditCard extends AuthComponent {
  constructor(props) {
    super(props)
    this.state = {
      modalChatIsOpen: false,
      modalType: '',
      cardNumber: '',
      expiryDate: '',
      cvc: '',
    }
  }
  componentDidMount() {
    super.componentDidMount()
    this.props.fetchCreditcard({
      userNo: this.props.authUser.authInfo.user_no,
      token: this.props.authUser.authInfo.token,
    })
  }
  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
  }

  render() {
    const { authUser, creditcard } = this.props

    const getText = () => {
      const text = hasCreditcard() ? '変更' : '追加'

      return 'クレジットカードを' + text
    }

    const hasCreditcard = () => {
      return Object.keys(creditcard.data).length >= 4
    }

    const handleSubmit = () => {
      // トークン生成ロジック呼び出し

      const ccNumber = this.state.cardNumber.replace(/\s+/g, '')
      const expiryDate = this.state.expiryDate.replace(/\s+/g, '').split('/')
      const ccExpiration = '20' + expiryDate[1] + expiryDate[0]
      const securityCode = this.state.cvc

      // eslint-disable-next-line no-undef
      com_sbps_system.generateToken(
        {
          merchantId: '73342',
          serviceId: '001',
          ccNumber,
          ccExpiration,
          securityCode,
        },
        afterGenerateToken
      )
    }

    const handleDelete = () => {
      const data = {
        user_no: this.props.authUser.authInfo.user_no,
        token: this.props.authUser.authInfo.token,
      }
      this.props.deleteCreditcard({ data })
    }

    const afterGenerateToken = response => {
      if (response.result == 'OK') {
        const data = {
          user_no: this.props.authUser.authInfo.user_no,
          token: this.props.authUser.authInfo.token,
          sbpToken: response.tokenResponse.token,
          sbpTokenKey: response.tokenResponse.tokenKey,
        }

        if (hasCreditcard()) {
          this.props.updateCreditcard({ data })
        } else {
          this.props.postCreditcard({ data })
        }
      } else {
        alert('トークン取得に失敗しました。')
      }
    }

    const handleChange = e => {
      const target = e.target
      if (target.name === 'cardNumber') {
        this.setState({ cardNumber: target.value })
      } else if (target.name === 'expiryDate') {
        this.setState({ expiryDate: target.value })
      } else if (target.name === 'cvc') {
        this.setState({ cvc: target.value })
      }
    }

    const getBrandImage = brandCode => {
      switch (brandCode) {
        case 'JCB':
          return '/assets/img/chefle/jcb.png'
        case 'Visa':
          return '/assets/img/chefle/visa.png'
        case 'Master':
          return '/assets/img/chefle/master.png'
        case 'AMEX':
          return '/assets/img/chefle/amex.png'
        case 'Diners':
          return '/assets/img/chefle/diners.png'
        default:
          return ''
      }
    }

    const splitByChunk = (str, size) => {
      const numChunks = Math.ceil(str.length / size)
      const chunks = new Array(numChunks)
      for (let i = 0, x = 0; i < numChunks; ++i, x += size) {
        chunks[i] = str.substr(x, size).replace(/\s+/g, '')
      }
      return chunks
    }

    const getCardNumber = cardNumber => {
      const numbers = splitByChunk(cardNumber, 4)
      return numbers[0] + ' ' + numbers[1] + ' ' + numbers[2] + ' ' + numbers[3]
    }

    const getExpiration = cardExpiration => {
      const expirations = splitByChunk(cardExpiration, 4)

      return expirations[0] + '年' + ' ' + expirations[1] + '月'
    }

    const modalSettingsPC = {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        zIndex: '1000',
      },
      content: {
        margin: 'auto',
        maxWidth: '400px',
        maxHeight: '120px',
        borderRadius: '18px',
        zIndex: '1000',
      },
    }

    const CreditcardComponent = () => {
      return (
        <section className="setting-form">
          <Helmet>
            <script
              type="text/javascript"
              src={process.env.REACT_APP_SBPS_TOKEN}
            />
          </Helmet>
          <Formik enableReinitialize={true}>
            <>
              {!hasCreditcard() ? (
                <div
                  className="reserve-form-container chefle-reservation chefle-creditcard__wrapper"
                  Style="background: #FFF4F6; padding: 10px;"
                >
                  <div Style="padding: 10px;">
                    <p className="chefle-creditcard__warning-head">
                      ご予約にはクレジットカードのご登録が必要です。
                    </p>
                    <p className="chefle-creditcard__warning-text">
                      ・現在、VISA、Master、AMEX、JCB、ダイナーズがご利用いただけます。
                    </p>
                    <p className="chefle-creditcard__warning-text">
                      ・クレジットカードは一枚のみ登録可能です。
                    </p>
                  </div>
                </div>
              ) : (
                <div
                  className="reserve-form-container chefle-reservation chefle-creditcard__wrapper"
                  Style="padding: 10px;"
                >
                  <div Style="padding: 10px;">
                    <p className="chefle-creditcard__head">
                      登録済みクレジットカード
                    </p>
                  </div>
                  <div className="chefle-creditcard__infos">
                    <div className="chefle-creditcard__info">
                      <div>
                        <p className="chefle-creditcard__number">
                          {getCardNumber(creditcard.data.ccNumber)}
                        </p>
                        <p className="chefle-creditcard__expiration">
                          有効期限 {getExpiration(creditcard.data.ccExpiration)}
                        </p>
                      </div>
                      {creditcard.data.brandCode !== 'その他' && (
                        <img
                          src={getBrandImage(creditcard.data.brandCode)}
                          alt="creditcard"
                          className="chefle-creditcard__image"
                        />
                      )}
                    </div>
                    <button
                      className="chefle-creditcard__deleteButton"
                      onClick={() => {
                        this.setState({
                          modalChatIsOpen: true,
                        })
                        this.setState({
                          modalType: 'delete',
                        })
                      }}
                    >
                      削除する
                    </button>
                  </div>
                </div>
              )}
              <div
                className="reserve-form-container chefle-reservation chefle-creditcard__wrapper"
                Style={
                  isMobile
                    ? 'margin: 10px;'
                    : 'padding: 10px; margin: 20px 0 20px 0;'
                }
              >
                <div Style="padding: 10px;">
                  <p className="chefle-creditcard__head">
                    クレジットカードを
                    {hasCreditcard() ? '変更' : '新規登録'}
                  </p>
                  <img
                    src="/assets/img/chefle/card_5brand.png"
                    alt="creditcard"
                    className="chefle-creditcard__images"
                  />
                  <div className="chefle-creditcard__warnings">
                    <p className="chefle-creditcard__warning-text2">
                      ・デビットカードはお使いいただけません。
                    </p>
                    <p className="chefle-creditcard__warning-text2">
                      ・必ずお客様ご本人のクレジットカードをご登録ください。ご本人のものでないものをご登録された場合、お客様のアカウントを停止させて頂くことがございます。
                    </p>
                  </div>
                  {hasCreditcard() && (
                    <p className="chefle-creditcard__warning-text3">
                      ※カードを変更する場合、現在登録されているカードは削除されます。
                    </p>
                  )}
                  <PaymentInputsContainer>
                    {({
                      wrapperProps,
                      getCardImageProps,
                      getCardNumberProps,
                      getExpiryDateProps,
                      getCVCProps,
                    }) => (
                      <>
                        <PaymentInputsWrapper
                          {...wrapperProps}
                          Style="margin: 10px;"
                        >
                          <svg {...getCardImageProps({ images })} />
                          <input
                            {...getCardNumberProps({
                              onChange: handleChange,
                            })}
                          />
                          <input
                            {...getExpiryDateProps({
                              onChange: handleChange,
                            })}
                          />
                          <input {...getCVCProps({ onChange: handleChange })} />
                        </PaymentInputsWrapper>
                        <div>
                          {creditcard.data.error && (
                            <p className="sc-dkPtRN">
                              ※クレジットカードの処理に失敗しました。
                            </p>
                          )}
                          <button
                            type=""
                            onClick={() => handleSubmit()}
                            className="chefle-creditcard__registerButton"
                            disabled={wrapperProps.error}
                          >
                            {getText()}
                          </button>
                        </div>
                      </>
                    )}
                  </PaymentInputsContainer>
                </div>
              </div>
            </>
          </Formik>
          <Modal
            closeTimeoutMS={200}
            isOpen={this.state.modalChatIsOpen}
            onRequestClose={() =>
              this.setState({
                modalChatIsOpen: false,
              })
            }
            style={modalSettingsPC}
            contentLabel="CancelationPolicy Modal"
          >
            <div class="chat-modal">
              {this.state.modalType == 'delete' && (
                <>
                  <p className="chefle-creditcard__modalText">
                    登録済みのクレジットカードを削除します。
                    <br />
                    本当によろしいですか？
                  </p>
                  <div className="chefle-creditcard__modalButtons">
                    <button
                      type=""
                      onClick={() =>
                        this.setState({
                          modalChatIsOpen: false,
                        })
                      }
                      className="chefle-creditcard__modalCancelButton"
                    >
                      キャンセル
                    </button>
                    <button
                      type=""
                      onClick={() => {
                        handleDelete()
                        this.setState({
                          modalChatIsOpen: false,
                        })
                      }}
                      className="chefle-creditcard__modalDeleteButton"
                    >
                      削除する
                    </button>
                  </div>
                </>
              )}
            </div>
          </Modal>
        </section>
      )
    }

    const LoadingComponent = () => {
      const spinnerProperties = isMobile
        ? { height: '30%', width: '30%' }
        : { height: '12%', width: '12%' }
      return (
        <section className="setting-form">
          <Formik enableReinitialize={true}>
            <>
              <div
                className="reserve-form-guest-ditails reserve-form-container"
                Style="margin-bottom: 50px; position: relative;"
              >
                <React.Fragment>
                  <div className="loading-spinner-text">Now Loading</div>
                  <div className="loading-spinner">
                    <ReactLoading
                      type="spin"
                      color="#ff5800"
                      {...spinnerProperties}
                    />
                  </div>
                </React.Fragment>
              </div>
            </>
          </Formik>
        </section>
      )
    }

    return (
      <>
        <div className="account-setting top-page">
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
          <PcHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
          />
          <SpHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
          />
          <div className="account-setting-contents">
            <section className="setting-menu">
              <ul>
                <li>
                  <Link to="/setting/reservation">ご予約一覧</Link>
                </li>
                <li Style="color: #ff5800;">
                  <Link to="/setting/creditcard">お支払い情報</Link>
                </li>
                <li>
                  <Link to="/setting/user_info">アカウント設定</Link>
                </li>
                <li>
                  <Link to="/setting/password">パスワード変更</Link>
                </li>
                <li>
                  <Link to="/setting/delete">退会</Link>
                </li>
              </ul>
            </section>
            {/* {CreditcardComponent()} */}
            {creditcard.loadingFlag
              ? LoadingComponent()
              : CreditcardComponent()}
          </div>
        </div>
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreditCard))
