import React from 'react'
import { Link } from 'react-router-dom'
import StringHelper from '../../helpers/StringHelper'
import ReminderToSignIn from '../common/ReminderToSignIn'

const Price = props => (
  <p className="evaluation-detail-txt">
    {props.restaurant.reservationType === 'chefle' ||
    props.restaurant.reservationType === 'pickup' ||
    props.restaurant.reservationType === 'chefle-menu' ? (
      <>
        Price: ¥&nbsp;
        <span className="evaluation-detail-price">
          {StringHelper.numberWithCommas(Number(props.restaurant.minPrice))}
        </span>
      </>
    ) : (
      <>
        <ul className="evaluation-detail-yen evaluation-detail-yen-02">
          <li>¥</li>
          <li>¥</li>
          <li>¥</li>
        </ul>
      </>
    )}
    &nbsp;-&nbsp;/&nbsp;
    <span className="evaluation-detail-category">
      {props.restaurant.category.name}
    </span>
  </p>
)

const ReservationButtonArea = props => (
  <div
    style={{
      padding: '40px 14px 19px',
      backgroundColor: '#fff',
    }}
  >
    <ReservationButton
      isMenu={props.isMenu}
      restaurantId={props.restaurantId}
      style={props.style}
      reservationType={props.reservationType}
      isLogin={props.isLogin}
    />
  </div>
)

const ReservationButton = props => {
  let to = ''
  if (props.reservationType === 'pickup') {
    to = `/pickup/restaurants/${props.restaurantId}/menu-reservation/form`
  } else if (props.reservationType === 'chefle-menu') {
    to = `/menu/restaurants/${props.restaurantId}/menu-reservation/form`
  } else {
    to = `/restaurants/${props.restaurantId}/menu-reservation/form`
  }

  let className = 'restaurant-menu-reservation-btn'

  if (!props.isLogin) {
    return (
      <ReminderToSignIn
        className={className}
        style={props.style}
        text={'予約申込'}
      />
    )
  }

  return (
    <Link to={to} className={className} style={props.style}>
      予約申込
    </Link>
  )
}

export { Price, ReservationButtonArea, ReservationButton }
