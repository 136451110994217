import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AuthComponent, {
  authMapStateToProps,
  authMapDispatchToProps,
} from '../../../components/AuthComponent'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { Formik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { withRouter } from 'react-router'
import { PcHeader, SpHeader } from '../../../components/top/Header'
import {
  updateDeleteAccountForm,
  confirmDeleteAccount,
} from '../../../actions/deleteAccount'

const styles = theme => ({
  textField: {
    width: '100%',
    fontSize: '16px',
  },
  formControl: {
    minWidth: 100,
  },
})

const Text = ({
  label,
  disabled,
  handleChange,
  costomChange,
  values,
  errors,
  name,
  classes,
  touched,
  setFieldTouched,
  rows,
  multiline,
  parentErrors,
}) => (
  <div>
    <TextField
      className={classes.textField}
      name={name}
      label={label}
      multiline
      disabled={disabled}
      inputProps={{ style: { fontSize: 16 } }}
      error={
        (!!parentErrors && !!parentErrors[name]) ||
        (!!touched[name] && !!errors[name])
      }
      value={values[name]}
      rows={rows}
      onFocus={() => setFieldTouched(name, true, false)}
      onChange={e => {
        handleChange(e)
        costomChange(e, name)
      }}
    />
    {!!parentErrors && !!parentErrors[name] && (
      <div style={{ position: 'absolute', color: 'red', fontSize: '0.8rem' }}>
        {parentErrors[name]}
      </div>
    )}
    {!!touched[name] && errors[name] && (
      <div style={{ position: 'absolute', color: 'red', fontSize: '0.8rem' }}>
        {errors[name]}
      </div>
    )}
  </div>
)

function mapStateToProps(state) {
  return Object.assign(authMapStateToProps(state), {
    deleteAccount: state.deleteAccount,
  })
}

function mapDispatchToProps(dispatch) {
  return Object.assign(authMapDispatchToProps(dispatch), {
    updateDeleteAccountForm: bindActionCreators(
      updateDeleteAccountForm,
      dispatch
    ),
    confirmDeleteAccount: bindActionCreators(confirmDeleteAccount, dispatch),
  })
}
class AccountDeleteContainer extends AuthComponent {
  // constructor(props) {
  //   super(props)
  // }
  componentDidMount() {
    super.componentDidMount()
    this.props.deleteAccount.user_no = this.props.authUser.authInfo.user_no
    this.props.deleteAccount.token = this.props.authUser.authInfo.token
    this.props.deleteAccount.reason1 = ''
    this.props.deleteAccount.reason2 = ''
    this.props.deleteAccount.reason3 = ''
    this.props.deleteAccount.reason4 = ''
    this.props.deleteAccount.reason5 = ''
    this.props.deleteAccount.withdrawal = ''
  }

  normalizeData = () => {
    const { deleteAccount } = this.props

    deleteAccount.withdrawal =
      (deleteAccount.reason1
        ? 'サービスを利用する機会がなくなった。'
        : '' + deleteAccount.reason1) +
      (deleteAccount.reason1
        ? 'サービスが使いづらかった。'
        : '' + deleteAccount.reason2) +
      (deleteAccount.reason1
        ? '気になるお店がなかった。'
        : '' + deleteAccount.reason3) +
      (deleteAccount.reason1
        ? 'サービスの対応が悪かった。'
        : '' + deleteAccount.reason4) +
      deleteAccount.withdrawal
  }

  submit = () => {
    // const data = this.normalizeData()
    this.props.confirmDeleteAccount(this.props.deleteAccount, {})
  }

  handleChange = (event, name, formikProps) => {
    this.onUpdate({
      ...this.props.deleteAccount,
      [name]: event.target.value,
    })
  }

  handleCheckboxChange = (name, formikProps, event) => {
    this.onUpdate({
      ...this.props.deleteAccount,
      [name]: event.target.checked,
    })
  }

  onUpdate = data => {
    const deleteAccount = this.props.deleteAccount
    this.props.updateDeleteAccountForm({ ...deleteAccount, ...data })
  }

  render() {
    const { classes, authUser } = this.props
    return (
      <>
        <div className="account-setting top-page">
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
          <PcHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
          />
          <SpHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
          />
          <div className="account-setting-contents">
            <section className="setting-menu">
              <ul>
                <li>
                  <Link to="/setting/reservation">ご予約一覧</Link>
                </li>
                <li>
                  <Link to="/setting/creditcard">お支払い情報</Link>
                </li>
                <li>
                  <Link to="/setting/user_info">アカウント設定</Link>
                </li>
                <li>
                  <Link to="/setting/password">パスワード変更</Link>
                </li>
                <li Style="color: #ff5800;">
                  <Link to="/setting/delete">退会</Link>
                </li>
              </ul>
            </section>
            <section className="setting-form">
              <Formik
                // validationSchema={validationSchema}
                enableReinitialize={true}
              >
                {props => (
                  <>
                    <div className="reserve-form-guest-ditails reserve-form-container">
                      <h3 className="block-title">退会</h3>
                      <div className="account-setting-description">
                        シェフル(chefle)を退会します。
                        <br />
                        <br />
                        退会手続き完了後: <br />
                        -
                        全ての個人情報はシェフル(chefle)サービス上から削除されます。また、
                        一度削除された個人情報は復元することはできません。
                        <br />
                        -
                        退会手続きの完了後に、改めて退会前にご登録のメールアドレスを再度会
                        員登録に利用いただくことは可能です。
                        <br />
                        -
                        シェフル(chefle)サービスからのメールマガジン配信は停止されます。
                        <br />
                        <br />
                        退会の理由をお聞かせください。
                        <br />
                        <br />
                        (必須){' '}
                        {!!this.props.deleteAccount.errors['withdrawal'] && (
                          <span
                            style={{
                              position: 'absolute',
                              color: 'red',
                              fontSize: '0.8rem',
                              marginLeft: '5px',
                            }}
                          >
                            {this.props.deleteAccount.errors['withdrawal']}
                          </span>
                        )}
                      </div>
                      <ul className="reserve-form-input-area">
                        <div className="checkbox-label">
                          <FormControlLabel
                            className="mail-magazine"
                            control={
                              <Checkbox
                                name="reason1"
                                onChange={this.handleCheckboxChange.bind(
                                  this,
                                  'reason1',
                                  props
                                )}
                                checked={this.props.deleteAccount.reason1}
                                value={this.props.deleteAccount.reason1}
                              />
                            }
                            label="サービスを利用する機会がなくなった。"
                          />
                        </div>
                        <div className="checkbox-label">
                          <FormControlLabel
                            className="mail-magazine"
                            control={
                              <Checkbox
                                name="reason2"
                                onChange={this.handleCheckboxChange.bind(
                                  this,
                                  'reason2',
                                  props
                                )}
                                checked={this.props.deleteAccount.reason2}
                                value={this.props.deleteAccount.reason2}
                              />
                            }
                            label="サービスが使いづらかった。"
                          />
                        </div>
                        <div className="checkbox-label">
                          <FormControlLabel
                            className="mail-magazine"
                            control={
                              <Checkbox
                                name="reason3"
                                onChange={this.handleCheckboxChange.bind(
                                  this,
                                  'reason3',
                                  props
                                )}
                                checked={this.props.deleteAccount.reason3}
                                value={this.props.deleteAccount.reason3}
                              />
                            }
                            label="気になるお店がなかった。"
                          />
                        </div>
                        <div className="checkbox-label">
                          <FormControlLabel
                            className="mail-magazine"
                            control={
                              <Checkbox
                                name="reason4"
                                onChange={this.handleCheckboxChange.bind(
                                  this,
                                  'reason4',
                                  props
                                )}
                                checked={this.props.deleteAccount.reason4}
                                value={this.props.deleteAccount.reason4}
                              />
                            }
                            label="サービスの対応が悪かった。"
                          />
                        </div>
                        <div className="checkbox-label">
                          <FormControlLabel
                            className="mail-magazine"
                            control={
                              <Checkbox
                                name="reason5"
                                onChange={this.handleCheckboxChange.bind(
                                  this,
                                  'reason5',
                                  props
                                )}
                                checked={this.props.deleteAccount.reason5}
                                value={this.props.deleteAccount.reason5}
                              />
                            }
                            label="その他"
                          />
                        </div>
                        <div className="withdrawal">
                          <Text
                            {...props}
                            name="withdrawal"
                            values={this.props.deleteAccount}
                            classes={classes}
                            disabled={!this.props.deleteAccount.reason5}
                            costomChange={(e, name) =>
                              this.handleChange(e, name, props)
                            }
                          />
                        </div>
                      </ul>
                    </div>
                    <p className="reserve-confirm-btn">
                      <input
                        style={{ cursor: 'pointer' }}
                        type="button"
                        value="確認する"
                        onClick={() => this.submit()}
                      />
                    </p>
                  </>
                )}
              </Formik>
            </section>
          </div>
        </div>
      </>
    )
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(AccountDeleteContainer))
)
