import { CALL_API } from '../middlewares/api'
import queryString from 'query-string'
import {
  IMMEDIATE_RESTAURANT_LIST_REQUEST,
  IMMEDIATE_RESTAURANT_LIST_OK,
  IMMEDIATE_RESTAURANT_LIST_NG,
} from './index'

export const fetchImmediateRestaurantList = (payload = {}, meta = {}) => ({
  [CALL_API]: {
    types: [
      IMMEDIATE_RESTAURANT_LIST_REQUEST,
      IMMEDIATE_RESTAURANT_LIST_OK,
      IMMEDIATE_RESTAURANT_LIST_NG,
    ],
    endpoint: `/chefle/restaurants?${queryString.stringify(
      payload
    )}&isImmediateReservable=true`,
    payload,
    meta,
  },
})

export const fetchImmediatePickupRestaurantList = (
  payload = {},
  meta = {}
) => ({
  [CALL_API]: {
    types: [
      IMMEDIATE_RESTAURANT_LIST_REQUEST,
      IMMEDIATE_RESTAURANT_LIST_OK,
      IMMEDIATE_RESTAURANT_LIST_NG,
    ],
    endpoint: `/chefle/restaurants?type=pickup&${queryString.stringify(
      payload
    )}&isImmediateReservable=true`,
    payload,
    meta,
  },
})

export const fetchImmediateChefleMenuRestaurantList = (
  payload = {},
  meta = {}
) => ({
  [CALL_API]: {
    types: [
      IMMEDIATE_RESTAURANT_LIST_REQUEST,
      IMMEDIATE_RESTAURANT_LIST_OK,
      IMMEDIATE_RESTAURANT_LIST_NG,
    ],
    endpoint: `/chefle/restaurants?type=chefle-menu&${queryString.stringify(
      payload
    )}&isImmediateReservable=true`,
    payload,
    meta,
  },
})
