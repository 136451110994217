import React, { Component } from 'react'
import Modal from 'react-modal'

class AboutChefle extends Component {
  state = {
    modalIsOpen: false,
  }
  openModal() {
    this.setState({ modalIsOpen: true })
  }
  closeModal() {
    this.setState({ modalIsOpen: false })
  }
  changeModalState() {
    this.setState({ modalIsOpen: !this.state.modalIsOpen })
  }
  render() {
    return (
      <>
        <a
          href={() => false}
          onClick={this.openModal.bind(this)}
          className={this.props.btnclassname}
        >
          {this.props.text}
        </a>
        <Modal
          closeTimeoutMS={200}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal.bind(this)}
          style={customStyles}
          contentLabel="CancelationPolicy Modal"
        >
          <div className="modal">
            <h3 className="modal-title">シェフルとは</h3>
            <div className="modal-content">
              <div className="sentence">
                私たちは、これまで訪日外国人向けにレストラン予約サービス
                <b>”JPNEAZY（ジャパニージー）”</b>
                を2017年より展開し、外国人に人気の高い有名高級店を中心に全国の飲食店とお取引をさせていただいております。
                <br />
                <br />
                現在、新型コロナウイルスの影響により、多くの飲食店様にとって厳しい環境ではありますが、工夫しながらこれまでに培った技による食の提供を目指していらっしゃいます。
                我々も今できることとして、コロナウイルスに対応した新しい”安全基準”に則り、在宅サービス（出張シェフ）、テイクアウトにて、外国人にも人気の高い有名高級店を皆様のご自宅でご堪能いただけたらと考え、新しく
                <b>”シェフル”</b>をスタートしました。
                <br />
                <br />
                <b>“食は人を幸せにする”</b>
                <br />
                お子様の入園入学祝いや、ご両親の誕生日祝い、あるいは自分自身のご褒美に。食を通して、みなさんの笑顔が戻ってくることを信じて、選びぬかれた飲食店との出会いを創造できればと存じます。
                <br />
                <br />
                株式会社Fesbase 代表取締役　金田　雅人
              </div>
            </div>

            <h3 className="modal-title">運営会社：株式会社Fesbaseについて</h3>
            <div className="modal-content">
              <div className="sentence">
                会社名：株式会社Fesbase <br />
                本社　：東京都品川区上大崎3-5-3 <br />
                設立　：2015年9月
                <br />
                代表者：代表取締役　金田　雅人
                <br />
                <br />
                URL ：
                <a href="https://www.fesbase.co.jp" target="blank">
                  https://www.fesbase.co.jp
                </a>
              </div>
            </div>

            <h3 className="modal-title">関連サービス</h3>
            <div className="modal-content">
              <div className="sentence">
                <b>JPNEAZY ジャパニージー</b>　：
                <a href="https://jpneazy.com" target="blank">
                  https://jpneazy.com
                </a>
                <br />
                訪日旅行者のための、レストラン予約代行サービス。外国人に人気の高い有名高級店がバイリンガルのコンシェルジュを通じて予約あるいは、リコメンドを受けることができます。
                <br />
                <br />
                <b>JPNEAZY チャンネル</b> ：{' '}
                <a href="https://www.youtube.com/jpneazy" target="blank">
                  https://www.youtube.com/jpneazy
                </a>
                <br />
                レストランを始め、ローカルな観光名所、日本の文化、作法、日本旅行で役立つhow
                toなど、JPNEAZYが提供する訪日旅行者のためのYoutubeチャンネル。
              </div>
            </div>
          </div>
        </Modal>
      </>
    )
  }
}

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    'z-index': '1000',
  },
  content: {
    margin: 'auto',
    'max-width': '900px',
    'border-radius': '18px',
    'z-index': '1000',
  },
}

export default AboutChefle
