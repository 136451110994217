import { CALL_API } from '../middlewares/api'
import queryString from 'query-string'
import {
  RESTAURANT_LIST_REQUEST,
  RESTAURANT_LIST_OK,
  RESTAURANT_LIST_NG,
} from './index'

export const fetchRestaurantList = (payload = {}, meta = {}) => ({
  [CALL_API]: {
    types: [RESTAURANT_LIST_REQUEST, RESTAURANT_LIST_OK, RESTAURANT_LIST_NG],
    endpoint: `/chefle/restaurants?${queryString.stringify(payload)}`,
    payload,
    meta,
  },
})

export const fetchPickupRestaurantList = (payload = {}, meta = {}) => ({
  [CALL_API]: {
    types: [RESTAURANT_LIST_REQUEST, RESTAURANT_LIST_OK, RESTAURANT_LIST_NG],
    endpoint: `/chefle/restaurants?type=pickup&${queryString.stringify(
      payload
    )}`,
    payload,
    meta,
  },
})

export const fetchChefleMenuRestaurantList = (payload = {}, meta = {}) => ({
  [CALL_API]: {
    types: [RESTAURANT_LIST_REQUEST, RESTAURANT_LIST_OK, RESTAURANT_LIST_NG],
    endpoint: `/chefle/restaurants?type=chefle-menu&${queryString.stringify(
      payload
    )}`,
    payload,
    meta,
  },
})
