const initialState = {
  ID: '',
  birthday: '',
  errors: {},
  isCompleteResendEmail: false,
  errorMessage: '',
}
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_RESEND_EMAIL_FORM':
      return {
        ...state,
        ...action.payload,
      }
    case 'RESEND_EMAIL_POST_REQUEST':
      return {
        ...state,
        loadingFlag: true,
      }
    case 'RESEND_EMAIL_POST_OK':
      if (action.payload.data.error === 0) {
        // 正常時
        return {
          ...state,
          postingFlag: false,
          isCompleteResendEmail: true,
        }
      } else {
        // エラー時
        return {
          ...state,
          postingFlag: false,
          isCompleteResendEmail: false,
          errorMessage:
            '処理に失敗しました。メールアドレスまたは誕生日が間違っています。',
        }
      }
    case 'RESEND_EMAIL_POST_NG':
      return {
        ...state,
        loadingFlag: false,
        isCompleteResendEmail: false,
        errorMessage: 'システムエラーが発生しております。しばらくお待ちください。',
      }
    default:
      return state
  }
}
