import { CALL_API } from '../middlewares/api'
import {
  UPDATE_CHEFLE_MENU_FORM,
  CONFIRM_CHEFLE_MENU_FORM,
  MENUS_REQUEST,
  MENUS_OK,
  MENUS_NG,
  CHEFLE_MENU_POST_REQUEST,
  CHEFLE_MENU_POST_OK,
  CHEFLE_MENU_POST_NG,
} from './index'

export const fetchMenus = payload => ({
  [CALL_API]: {
    types: [MENUS_REQUEST, MENUS_OK, MENUS_NG],
    endpoint: `/restaurants/${payload.restaurantId}/plans`,
    payload,
  },
})

export const updateMenuForm = (payload, meta) => ({
  type: UPDATE_CHEFLE_MENU_FORM,
  payload,
  meta,
})

export const confirmMenuForm = (payload, meta) => ({
  type: CONFIRM_CHEFLE_MENU_FORM,
  payload,
  meta,
})

export const postMenu = (payload = {}, meta = {}) => ({
  [CALL_API]: {
    types: [CHEFLE_MENU_POST_REQUEST, CHEFLE_MENU_POST_OK, CHEFLE_MENU_POST_NG],
    endpoint: `/chefle/reservations?type=chefle-menu`,
    payload: {
      ...payload,
      method: 'POST',
    },
  },
})
