import React from 'react'
import { Formik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { PcHeader, SpHeader } from '../../components/top/Header'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { updateSignUpForm, confirmSignUpForm } from '../../actions/signUpForm'
import TermsOfService from '../../components/common/TermsOfService'
import AuthComponent, {
  authMapStateToProps,
  authMapDispatchToProps,
} from '../../components/AuthComponent'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import PREFECTURES from '../../constants/prefectures'

const styles = theme => ({
  textField: {
    width: '100%',
    fontSize: '16px',
  },
  formControl: {
    minWidth: 100,
  },
})

const Text = ({
  label,
  handleChange,
  costomChange,
  values,
  errors,
  name,
  classes,
  touched,
  setFieldTouched,
  rows,
  parentErrors,
}) => (
  <div style={{ position: 'relative', fontSize: '40px' }}>
    <TextField
      style={{ marginTop: '16px' }}
      className={classes.textField}
      name={name}
      label={label}
      inputProps={{ style: { fontSize: 16 } }}
      error={
        (!!parentErrors && !!parentErrors[name]) ||
        (!!touched[name] && !!errors[name])
      }
      value={values[name]}
      rows={rows}
      onFocus={() => setFieldTouched(name, true, false)}
      onChange={e => {
        handleChange(e)
        costomChange(e, name)
      }}
    />
    {!!parentErrors && !!parentErrors[name] && (
      <div style={{ position: 'absolute', color: 'red', fontSize: '0.8rem' }}>
        {parentErrors[name]}
      </div>
    )}
    {!!touched[name] && errors[name] && (
      <div style={{ position: 'absolute', color: 'red', fontSize: '0.8rem' }}>
        {errors[name]}
      </div>
    )}
  </div>
)

function mapStateToProps(state) {
  return Object.assign(authMapStateToProps(state), {
    signUpForm: state.signUpForm,
  })
}

function mapDispatchToProps(dispatch) {
  return Object.assign(authMapDispatchToProps(dispatch), {
    updateSignUpForm: bindActionCreators(updateSignUpForm, dispatch),
    confirmSignUpForm: bindActionCreators(confirmSignUpForm, dispatch),
  })
}

class SignUpContainer extends AuthComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    super.componentDidMount()

    this.props.updateSignUpForm({
      ...this.props.SignUpForm,
    })
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
  }

  submit = () => {
    this.props.confirmSignUpForm(this.props.signUpForm, {})
  }

  handleChange = (event, name, formikProps) => {
    this.onUpdate({
      ...this.props.signUpForm,
      [name]: event.target.value,
    })
  }

  handleSelectChange = (name, formikProps, event) => {
    this.handleChange(event, name, formikProps)
  }

  handleCheckboxChange = (name, formikProps, event) => {
    this.onUpdate({
      ...this.props.signUpForm,
      [name]: event.target.checked,
    })
  }

  onUpdate = data => {
    const signUpForm = this.props.signUpForm
    this.props.updateSignUpForm({ ...signUpForm, ...data })
  }

  render() {
    const { classes, authUser } = this.props
    return (
      <div className="sign-up">
        <PcHeader
          authUser={authUser}
          logout={() =>
            this.props.postSignOut({ data: this.props.authUser.authInfo })
          }
        />
        <SpHeader
          authUser={authUser}
          logout={() =>
            this.props.postSignOut({ data: this.props.authUser.authInfo })
          }
        />
        <Formik
          // validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {props => (
            <>
              <div className="reserve-form-guest-ditails reserve-form-container">
                <div className="block-title">
                  新規会員登録
                  <div className="register-merit">
                    ・Chefle独自ポイント付与のため、ご予約リクエスト時には会員登録が必須となっております
                    <br />
                    ・会員登録者限定のメルマガでは、新規掲載レストラン情報提供、予約困難店の空席情報やChefleキッチン（お取り寄せ）の先行案内を配信いたします
                  </div>
                </div>
                <ul className="reserve-form-input-area">
                  <Text
                    {...props}
                    name="ID"
                    label="*メールアドレス"
                    values={this.props.signUpForm}
                    classes={classes}
                    costomChange={(e, name) =>
                      this.handleChange(e, name, props)
                    }
                  />
                  {!!this.props.signUpForm.errors['ID'] && (
                    <div
                      style={{
                        position: 'absolute',
                        color: 'red',
                        fontSize: '0.8rem',
                      }}
                    >
                      {this.props.signUpForm.errors['ID']}
                    </div>
                  )}
                  <Text
                    {...props}
                    name="sei"
                    label="*姓 (漢字)"
                    values={this.props.signUpForm}
                    classes={classes}
                    costomChange={(e, name) =>
                      this.handleChange(e, name, props)
                    }
                  />
                  {!!this.props.signUpForm.errors['sei'] && (
                    <div
                      style={{
                        position: 'absolute',
                        color: 'red',
                        fontSize: '0.8rem',
                      }}
                    >
                      {this.props.signUpForm.errors['sei']}
                    </div>
                  )}
                  <Text
                    {...props}
                    name="mei"
                    label="*名 (漢字)"
                    values={this.props.signUpForm}
                    classes={classes}
                    costomChange={(e, name) =>
                      this.handleChange(e, name, props)
                    }
                  />
                  {!!this.props.signUpForm.errors['mei'] && (
                    <div
                      style={{
                        position: 'absolute',
                        color: 'red',
                        fontSize: '0.8rem',
                      }}
                    >
                      {this.props.signUpForm.errors['mei']}
                    </div>
                  )}
                  <Text
                    {...props}
                    name="seikana"
                    label="*セイ（フリガナ）"
                    values={this.props.signUpForm}
                    classes={classes}
                    costomChange={(e, name) =>
                      this.handleChange(e, name, props)
                    }
                  />
                  {!!this.props.signUpForm.errors['seikana'] && (
                    <div
                      style={{
                        position: 'absolute',
                        color: 'red',
                        fontSize: '0.8rem',
                      }}
                    >
                      {this.props.signUpForm.errors['seikana']}
                    </div>
                  )}
                  <Text
                    {...props}
                    name="meikana"
                    label="*メイ（フリガナ）"
                    values={this.props.signUpForm}
                    classes={classes}
                    costomChange={(e, name) =>
                      this.handleChange(e, name, props)
                    }
                  />
                  {!!this.props.signUpForm.errors['meikana'] && (
                    <div
                      style={{
                        position: 'absolute',
                        color: 'red',
                        fontSize: '0.8rem',
                      }}
                    >
                      {this.props.signUpForm.errors['meikana']}
                    </div>
                  )}
                  <Text
                    {...props}
                    name="birthday"
                    label="*生年月日 YYYYMMDD 例) 1985年1月1日の場合は19850101"
                    values={this.props.signUpForm}
                    classes={classes}
                    costomChange={(e, name) =>
                      this.handleChange(e, name, props)
                    }
                  />
                  {!!this.props.signUpForm.errors['birthday'] && (
                    <div
                      style={{
                        position: 'absolute',
                        color: 'red',
                        fontSize: '0.8rem',
                      }}
                    >
                      {this.props.signUpForm.errors['birthday']}
                    </div>
                  )}
                  <Text
                    {...props}
                    name="tel"
                    label="*電話番号 ハイフンあり 例) XXX-XXXX-XXXX"
                    values={this.props.signUpForm}
                    classes={classes}
                    costomChange={(e, name) =>
                      this.handleChange(e, name, props)
                    }
                  />
                  {!!this.props.signUpForm.errors['tel'] && (
                    <div
                      style={{
                        position: 'absolute',
                        color: 'red',
                        fontSize: '0.8rem',
                      }}
                    >
                      {this.props.signUpForm.errors['tel']}
                    </div>
                  )}
                  <FormControl style={{ marginTop: '16px', width: ' 100%' }}>
                    <InputLabel>*お住まいの都道府県</InputLabel>
                    <Select
                      {...props}
                      name="prefectures"
                      label="お住まいの都道府県"
                      classes={classes}
                      value={this.props.signUpForm.prefectures}
                      inputProps={{ style: { fontSize: 16 } }}
                      SelectDisplayProps={{ style: { fontSize: 16 } }}
                      onChange={this.handleSelectChange.bind(
                        this,
                        'prefectures',
                        props
                      )}
                    >
                      {PREFECTURES.map(list => (
                        <MenuItem key={list} value={list}>
                          {list}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {!!this.props.signUpForm.errors['prefectures'] && (
                    <div
                      style={{
                        position: 'absolute',
                        color: 'red',
                        fontSize: '0.8rem',
                      }}
                    >
                      {this.props.signUpForm.errors['prefectures']}
                    </div>
                  )}
                  <Text
                    {...props}
                    name="allergy"
                    label="アレルギー"
                    values={this.props.signUpForm}
                    classes={classes}
                    costomChange={(e, name) =>
                      this.handleChange(e, name, props)
                    }
                  />
                  {!!this.props.signUpForm.errors['allergy'] && (
                    <div
                      style={{
                        position: 'absolute',
                        color: 'red',
                        fontSize: '0.8rem',
                      }}
                    >
                      {this.props.signUpForm.errors['allergy']}
                    </div>
                  )}
                  <div className="checkbox-label">
                    <FormControlLabel
                      className="terms-of-service"
                      control={
                        <>
                          <Checkbox
                            name="signUpTermsOfService"
                            onChange={this.handleCheckboxChange.bind(
                              this,
                              'signUpTermsOfService',
                              props
                            )}
                            checked={this.props.signUpForm.signUpTermsOfService}
                            value={this.props.signUpForm.signUpTermsOfService}
                          />
                          <p Style="font-size: 14px; line-height: 22px; color: rgba(0, 0, 0, 0.87);">
                            Chefle
                            <TermsOfService
                              text={'利用規約'}
                              btnclassname={'policy-link'}
                            />
                            に同意する
                          </p>
                        </>
                      }
                    />
                    <FormControlLabel
                      className="mail-magazine"
                      control={
                        <Checkbox
                          name="mailmagazine"
                          onChange={this.handleCheckboxChange.bind(
                            this,
                            'mailmagazine',
                            props
                          )}
                          checked={this.props.signUpForm.mailmagazine}
                          value={this.props.signUpForm.mailmagazine}
                        />
                      }
                      label="Chefleからのメルマガを受け取る"
                    />
                  </div>
                  {!!this.props.signUpForm.errors['signUpTermsOfService'] && (
                    <div
                      style={{
                        position: 'absolute',
                        color: 'red',
                        fontSize: '0.8rem',
                      }}
                    >
                      {this.props.signUpForm.errors['signUpTermsOfService']}
                    </div>
                  )}
                </ul>
              </div>
              <p className="reserve-confirm-btn">
                <input
                  style={{ cursor: 'pointer' }}
                  type="button"
                  value="入力情報を確認する"
                  // disabled={!props.isValid}
                  onClick={() => this.submit()}
                />
              </p>
            </>
          )}
        </Formik>
      </div>
    )
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(SignUpContainer))
)
