import React from 'react'
import { connect } from 'react-redux'
import AuthComponent, {
  authMapStateToProps,
  authMapDispatchToProps,
} from '../../../components/AuthComponent'
import { Helmet } from 'react-helmet'
import CompleteIcon from '../../../components/common/CompleteIcon'

function mapStateToProps(state) {
  return Object.assign(authMapStateToProps(state), {})
}

function mapDispatchToProps(dispatch) {
  return Object.assign(authMapDispatchToProps(dispatch), {})
}
class AccountDeleteCompleteContainer extends AuthComponent {
  render() {
    return (
      <>
        <div className="account-setting">
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
          <div className="account-setting-contents">
            <div className="reserve-complete">
              <div className="main-inner">
                <CompleteIcon />
                <p className="reserve-complete-txt" />
                <p className="reserve-complete-txt2">
                  シェフルを退会しました。
                  <br />
                  シェフルをご利用いただきまして、誠にありがとうございました。
                </p>
                <a href="/" className="reserve-complete-return-btn">
                  トップページへ戻る
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountDeleteCompleteContainer)
