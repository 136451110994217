import { CALL_API } from '../middlewares/api'
import { PAYMENT_FORM_REQUEST, PAYMENT_FORM_OK, PAYMENT_FORM_NG } from './index'

export const fetchPaymentForm = payload => ({
  [CALL_API]: {
    types: [PAYMENT_FORM_REQUEST, PAYMENT_FORM_OK, PAYMENT_FORM_NG],
    endpoint: `/reservations/${payload.reservationId}/payment-form?method=${
      payload.paymentMethod
    }`,
    payload,
  },
})
