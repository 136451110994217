const initialState = {
  total: 0,
  perPage: 0,
  currentPage: 0,
  lastPage: 0,
  from: 0,
  to: 0,
  items: [],
  loadingFlag: false,
}
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'IMMEDIATE_RESTAURANT_LIST_REQUEST':
      return {
        ...state,
        loadingFlag: true,
      }
    case 'IMMEDIATE_RESTAURANT_LIST_OK':
      return {
        ...state,
        ...action.payload.data,
        loadingFlag: false,
      }
    case 'IMMEDIATE_RESTAURANT_LIST_NG':
      return {
        ...state,
        loadingFlag: false,
      }
    default:
      return state
  }
}
