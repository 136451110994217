import '@babel/polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import configureStore, { history } from './configureStore'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-M83TFC9',
}

const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#ff5800',
      light: '#ff5800',
      dark: '#ff5800',
    },
    secondary: {
      main: '#ff5800',
      light: '#ff5800',
    },
  },
  typography: {
    fontFamily: '"Poppins"',
  },
  overrides: {
    MuiInputLabel: {
      root: {
        color: '#888',
        fontSize: 12,
      },
    },
    MuiPickersDay: {
      day: {
        fontSize: 20,
      },
    },
    MuiDialogActions: {
      root: {
        display: 'none',
      },
    },
  },
})

const store = configureStore()
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={customTheme}>
      <App history={history} />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
