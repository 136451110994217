import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload'
import StringHelper from '../../helpers/StringHelper'

const RestaurantCard = props => {
  let whichReservationTypeUrl = `/restaurants/${props.restaurant.id}`

  if (props.restaurant.reservationType === 'pickup') {
    whichReservationTypeUrl = `/pickup/restaurants/${props.restaurant.id}`
  }
  if (props.restaurant.reservationType === 'chefle-menu') {
    whichReservationTypeUrl = `/menu/restaurants/${props.restaurant.id}`
  }

  return (
    <li
      className={
        props.restaurant.reservationType === 'menu'
          ? 'store-card-box store-card-menu'
          : 'store-card-box store-card-seat'
      }
    >
      <Link
        to={whichReservationTypeUrl}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <LazyLoad height="100%" offset={100} Style="position: relative;">
          {props.restaurant.images ? (
            <img
              src={props.restaurant.images[0]}
              alt={props.restaurant.name}
              className="store-card-img"
            />
          ) : (
            <img
              src="/assets/img/NoPhoto.png"
              alt="店舗のサンプル写真"
              className="store-card-img"
            />
          )}
          {props.restaurant.video && (
            <img
              src="/assets/img/video_icon.svg"
              alt=""
              className="card-video-icon"
            />
          )}
        </LazyLoad>
        <div className="store-card-title-box">
          <LazyLoad height="100%" offset={50}>
            {props.restaurant.chefImage && (
              <img
                src={props.restaurant.chefImage}
                alt={props.restaurant.chefName}
                className="store-card-person"
              />
            )}
            {!props.restaurant.chefImage && (
              <img
                src="/assets/img/chef_sample.jpg"
                alt="シェフのサンプル写真"
                className="store-card-person"
              />
            )}
          </LazyLoad>
          <h3 className="store-card-title-en">{props.restaurant.name}</h3>
          <p className="store-card-title-ja">{props.restaurant.nameJa}</p>
        </div>
        <div className="evaluation-detail">
          <div className="evaluation-detail-container">
            <p className="evaluation-detail-txt">
              {props.restaurant.reservationType === 'chefle' ||
              props.restaurant.reservationType === 'pickup' ||
              props.restaurant.reservationType === 'chefle-menu' ? (
                <>
                  ¥&nbsp;
                  <span className="evaluation-detail-price">
                    {StringHelper.numberWithCommas(
                      Number(props.restaurant.minPrice)
                    )}
                  </span>
                  -
                </>
              ) : (
                <ul className="evaluation-detail-yen evaluation-detail-yen-02">
                  <li>¥</li>
                  <li>¥</li>
                  <li>¥</li>
                </ul>
              )}
              /&nbsp;
              <span className="evaluation-detail-category">
                {props.restaurant.category.name}
              </span>
            </p>
            <ul
              className={
                'evaluation-detail-star evaluation-detail-star-0' +
                props.restaurant.popularity
              }
            >
              <li>★</li>
              <li>★</li>
              <li>★</li>
              <li>★</li>
              <li>★</li>
            </ul>
          </div>
          <hr className="store-card-line" />
          <p className="store-card-place">
            {props.restaurant.area.subArea &&
              props.restaurant.area.subArea + ', '}
            {props.restaurant.area.name}
          </p>
          <p className="store-card-description">
            {StringHelper.omission(
              StringHelper.htmlToText(props.restaurant.description),
              120
            )}
          </p>
        </div>
      </Link>
    </li>
  )
}

export { RestaurantCard }
