import React from 'react'
import Video from '../../assets/img/chefle/bg.mp4'

// Reactではiphoneでautoplayがうまく作動しない
// 対策としてdangerouslySetInnerHTMLを使用する
const BackgroundVideo = () => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `
      <video
        class="background-video"
        autoPlay
        muted
        playsinline
        loop
        style="width: 100%; object-fit: cover; height: 100%;"
      >
        <source src=${Video} type="video/mp4" />
      </video>
     `,
      }}
    />
  )
}

export default BackgroundVideo
