import { UPDATE_PASSWORD_FORM } from '../actions'

const validateUpdatePasswordForm = updatePasswordForm => {
  let errors = {}

  updatePasswordForm.pass !== updatePasswordForm.pass_confirm &&
    (errors = '新しいパスワードとパスワードの確認が一致していません。')

  return errors
}

// // const update = action => {
// //   const signUpForm = action.payload
// //   if (signUpForm.errors) {
// //     let errors = validateSignUpForm(signUpForm)
// //     return { ...action, payload: { ...action.payload, errors: errors } }
// //   }
// //   return action
// // }

const confirm = action => {
  const updatePasswordForm = action.payload
  let errors = validateUpdatePasswordForm(updatePasswordForm)
  console.log('confirm', updatePasswordForm)
  if (!!Object.keys(errors).length) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    return { ...action, payload: { ...action.payload, errors: errors } }
  }
  return action
}

export default store => next => action => {
  if (action.type === UPDATE_PASSWORD_FORM) {
    console.log('UPDATE_PASSWORD_POST_REQUEST2')
    return next(confirm(action))
  }
  // console.log('confirm', 2)
  return next(action)
}
