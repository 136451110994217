export const validateGuest = guests => {
  let errors = {}
  !guests.firstName && (errors.firstName = '名が必要です')
  !guests.lastName && (errors.lastName = '姓が必要です')
  !guests.email
    ? (errors.email = 'メールアドレスが必要です')
    : RegExp(
        "/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/"
      ).test(guests.email) &&
      (errors.email = '有効なメールアドレスを入力してください')
  return errors
}

export const validateSeatRestaurant = restaurant => {
  let errors = {}
  !restaurant.name && (errors.name = 'restaurant name is required')
  !restaurant.url && (errors.url = 'restaurant url is required')
  return errors
}

export const validateMenuRestaurant = (restaurant, adult) => {
  let totalMenuQuantity = restaurant.plans.reduce(
    (result, menu) => (result += menu.quantity),
    0
  )

  let errors = {}
  !(
    restaurant.plans &&
    restaurant.plans.length &&
    restaurant.plans.some(menu => menu.quantity && adult <= totalMenuQuantity)
  ) && (errors.menus = '「大人」の人数以上のコースメニュー数を記入してください')
  return errors
}

export const validatePickupRestaurant = restaurant => {
  let errors = {}
  !(restaurant.plans && restaurant.plans.length) &&
    (errors.menus = 'メニュー数を記入してください')
  return errors
}

export const validateUsePoint = (
  effectivePoint,
  usePoint,
  isUsePoint,
  totalPrice
) => {
  let errors = ''
  if (isUsePoint === 'unuse') {
    return errors
  }

  if (usePoint == null) {
    usePoint = 0
  }

  if (typeof usePoint === 'string') {
    usePoint = parseInt(usePoint, 10)
  }
  if (typeof effectivePoint === 'string') {
    effectivePoint = parseInt(effectivePoint, 10)
  }

  !(usePoint <= effectivePoint) && (errors = 'ポイントが足りません。')

  !!(usePoint % 100 !== 0 && usePoint !== 0) &&
    (errors = '100P単位で入力してください。')

  !(0 <= usePoint) && (errors = '0ポイント未満は指定できません。')

  !(usePoint < totalPrice) &&
    (errors = '利用ポイントはご予約金額内で指定してください。')

  return errors
}
