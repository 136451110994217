import React, { Component } from 'react'
import { Formik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import * as Yup from 'yup'
import { PcHeader, SpHeader } from '../../components/top/Header'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { updateSignInForm, postSignIn } from '../../actions/signInForm'
import { updateAuthUser } from '../../actions/authUser'
import cleanDeep from 'clean-deep'
import {
  authMapStateToProps,
  authMapDispatchToProps,
} from '../../components/AuthComponent'

const styles = theme => ({
  textField: {
    width: '100%',
    fontSize: '12px',
  },
  formControl: {
    minWidth: 100,
  },
})

const validationSchema = Yup.object({
  ID: Yup.string().required('メールアドレスが必要です'),
  pass: Yup.string().required('パスワードが必要です'),
})

const CustomTextField = ({
  label,
  handleChange,
  costomChange,
  values,
  errors,
  name,
  classes,
  touched,
  setFieldTouched,
  multiline,
  rows,
  parentErrors,
  type,
}) => (
  <div style={{ position: 'relative' }}>
    <TextField
      className={classes.textField}
      name={name}
      label={label}
      type={type}
      margin="dense"
      error={
        (!!parentErrors && !!parentErrors[name]) ||
        (!!touched[name] && !!errors[name])
      }
      value={values[name]}
      multiline={multiline}
      rows={rows}
      onFocus={() => setFieldTouched(name, true, false)}
      onChange={e => {
        handleChange(e)
        costomChange(e, name)
      }}
    />
    {!!parentErrors && !!parentErrors[name] && (
      <div style={{ position: 'absolute', color: 'red', fontSize: '0.8rem' }}>
        {parentErrors[name]}
      </div>
    )}
    {!!touched[name] && errors[name] && (
      <div style={{ position: 'absolute', color: 'red', fontSize: '0.8rem' }}>
        {errors[name]}
      </div>
    )}
  </div>
)

function mapStateToProps(state) {
  return Object.assign(authMapStateToProps(state), {
    signInForm: state.signInForm,
  })
}

function mapDispatchToProps(dispatch) {
  return Object.assign(authMapDispatchToProps(dispatch), {
    updateAuthUser: bindActionCreators(updateAuthUser, dispatch),
    updateSignInForm: bindActionCreators(updateSignInForm, dispatch),
    postSignIn: bindActionCreators(postSignIn, dispatch),
  })
}

class SignInContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.updateSignInForm({
      ...this.props.signInForm,
    })
  }

  componentDidUpdate(prevProps) {
    // ユーザのトークン情報など取得できたら、ユーザ情報を取得するためfetchする
    if (this.props.authUser.tempAuthInfo !== prevProps.authUser.tempAuthInfo) {
      const data = this.props.authUser.tempAuthInfo
      this.props.getAuthUser({ data })
    }

    // ユーザがログイン/ログアウト状態になったら、リダイレクトする
    if (
      this.props.authUser.userInfo !== prevProps.authUser.userInfo &&
      this.props.authUser.redirectPath !== ''
    ) {
      window.location.href = this.props.authUser.redirectPath
    }
  }

  normalizeData = () => {
    const { signInForm } = this.props
    return cleanDeep(signInForm)
  }

  handleSubmit = e => {
    e.preventDefault()

    // ログイン後のリダイレクトパスをセット
    if (!this.props.authUser.redirectPath) {
      this.props.authUser.redirectPath = '/'
    }
    this.props.updateAuthUser({
      ...this.props.authUser,
    })

    const data = this.normalizeData()
    this.props.postSignIn({ data })
  }

  handleChange = (event, name, formikProps) => {
    this.onUpdate({
      ...this.props.signInForm,
      [name]: event.target.value,
    })
  }

  handleCheckboxChange = (name, formikProps, event) => {
    this.onUpdate({
      ...this.props.signInForm,
      [name]: event.target.checked,
    })
  }

  onUpdate = data => {
    const signInForm = this.props.signInForm
    this.props.updateSignInForm({ ...signInForm, ...data })
  }

  render() {
    const { classes, authUser, signInForm } = this.props
    return (
      <div className="sign-in">
        <PcHeader
          authUser={authUser}
          logout={() =>
            this.props.postSignOut({ data: this.props.authUser.authInfo })
          }
        />
        <SpHeader
          authUser={authUser}
          logout={() =>
            this.props.postSignOut({ data: this.props.authUser.authInfo })
          }
        />
        <Formik validationSchema={validationSchema} enableReinitialize={true}>
          {props => (
            <>
              <div className="reserve-form-guest-ditails reserve-form-container">
                <h3 className="block-title">ログイン</h3>
                <div
                  style={{
                    position: 'absolute',
                    color: 'red',
                    fontSize: '0.8rem',
                  }}
                >
                  {signInForm.errorMessage}
                  {authUser.errorMessage}
                </div>
                <ul className="reserve-form-input-area">
                  <CustomTextField
                    {...props}
                    name="ID"
                    label="メールアドレス"
                    classes={classes}
                    costomChange={(e, name) =>
                      this.handleChange(e, name, props)
                    }
                  />
                  <CustomTextField
                    {...props}
                    name="pass"
                    label="パスワード"
                    type="password"
                    classes={classes}
                    costomChange={(e, name) =>
                      this.handleChange(e, name, props)
                    }
                  />
                  <div
                    Style="font-size:10px; margin: 20px 0 0; color: rgba(0, 0, 0, 0.87);"
                    className="terms-of-service"
                  >
                    パスワードを忘れた方は
                    <Link to="/sign_up/resend_email">こちら</Link>
                  </div>
                </ul>
              </div>
              <p className="reserve-submit-btn reserve-confirm-btn">
                <form onSubmit={this.handleSubmit}>
                  <input
                    type="submit"
                    value="ログインする"
                    disabled={!props.isValid}
                    onSubmit={this.handleSubmit}
                  />
                </form>
              </p>
            </>
          )}
        </Formik>
      </div>
    )
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(SignInContainer))
)
