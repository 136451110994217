import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

const styles = theme => ({
  root: {
    zIndex: 20,
  },
})

class PcMenu extends React.Component {
  state = {
    open: false,
  }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }))
  }

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return
    }

    this.setState({ open: false })
  }

  render() {
    const { classes } = this.props
    const { open } = this.state

    return (
      <div className={classes.root}>
        <div>
          <Button
            buttonRef={node => {
              this.anchorEl = node
            }}
            aria-owns={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.93)',
            }}
            onClick={this.handleToggle}
          >
            {this.props.userName} ▼
          </Button>
          <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList>
                      <MenuItem>
                        <Link
                          to="/setting/reservation"
                          Style="text-decoration: none; color: black;"
                        >
                          ご予約一覧
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link
                          to="/setting/user_info"
                          Style="text-decoration: none; color: black;"
                        >
                          アカウント設定
                        </Link>
                      </MenuItem>
                      <MenuItem onClick={this.props.logout}>
                        ログアウト
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    )
  }
}

PcMenu.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(PcMenu)
