import { RESTAURANT_OK } from '../actions'
import { fetchGooglePlace } from '../actions/googlePlace'

const hooks = {
  [RESTAURANT_OK](store, action) {
    const payload = {
      restaurantId: action.payload.data.id,
    }
    store.dispatch(fetchGooglePlace(payload))
  },
}

export default store => next => action => {
  const hook = hooks[action.type]
  hook && hook(store, action)
  return next(action)
}
