import { CALL_API } from '../middlewares/api'
import {
  UPDATE_SIGN_UP_FORM,
  CONFIRM_SIGN_UP_FORM,
  SIGN_UP_POST_REQUEST,
  SIGN_UP_POST_OK,
  SIGN_UP_POST_NG,
} from './index'

export const updateSignUpForm = (payload, meta) => ({
  type: UPDATE_SIGN_UP_FORM,
  payload,
  meta,
})

export const confirmSignUpForm = (payload, meta) => ({
  type: CONFIRM_SIGN_UP_FORM,
  payload,
  meta,
})

export const postSignUp = (payload = {}, meta = {}) => ({
  [CALL_API]: {
    types: [SIGN_UP_POST_REQUEST, SIGN_UP_POST_OK, SIGN_UP_POST_NG],
    endpoint: `/chefle/user/register`,
    payload: {
      ...payload,
      method: 'POST',
    },
  },
})
