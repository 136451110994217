import { UPDATE_MENU_FORM, CONFIRM_MENU_FORM } from '../actions'
import { history } from '../configureStore'
import { validateGuest, validateMenuRestaurant } from './logic/validator'
import { save as saveGuest, remove as removeGuest } from './logic/guest'

const validateMenuForm = menuForm => {
  let errors = {}

  !menuForm.date && (errors.date = '日付が必要です')
  !menuForm.time && (errors.time = '時間が必要です')
  !menuForm.adult && (errors.adult = '大人の人数が必要です')

  !menuForm.confirmedCancellationPolicy &&
    (errors.confirmedCancellationPolicy =
      'キャンセルポリシーとユーザーガイドのチェックが必要です')

  let guestErrors = validateGuest(menuForm.guest)
  Object.keys(guestErrors).length && (errors.guest = guestErrors)

  let restaurantErroes = validateMenuRestaurant(
    menuForm.restaurant,
    menuForm.adult
  )
  Object.keys(restaurantErroes).length && (errors.restaurant = restaurantErroes)

  return errors
}

const update = action => {
  const menuForm = action.payload

  if (Object.keys(menuForm.errors).length) {
    let errors = validateMenuForm(menuForm)
    return { ...action, payload: { ...action.payload, errors: errors } }
  }
  return action
}

const confirm = action => {
  const menuForm = action.payload
  let errors = validateMenuForm(menuForm)

  if (!!Object.keys(errors).length) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    return { ...action, payload: { ...action.payload, errors: errors } }
  }

  action.meta && action.meta.isSaveUser
    ? saveGuest(menuForm.guest)
    : removeGuest()

  history.push('/restaurants/menu-reservation/confirm/')
  return action
}

export default store => next => action => {
  if (action.type === UPDATE_MENU_FORM) {
    return next(update(action))
  }

  if (action.type === CONFIRM_MENU_FORM) {
    return next(confirm(action))
  }
  return next(action)
}
