import { CALL_API } from '../middlewares/api'
import {
  UPDATE_DELETE_ACCOUNT_FORM,
  CONFIRM_DELETE_ACCOUNT_FORM,
  DELETE_ACCOUNT_POST_REQUEST,
  DELETE_ACCOUNT_POST_OK,
  DELETE_ACCOUNT_POST_NG,
} from './index'

export const updateDeleteAccountForm = (payload, meta) => ({
  type: UPDATE_DELETE_ACCOUNT_FORM,
  payload,
  meta,
})

export const confirmDeleteAccount = (payload, meta) => ({
  type: CONFIRM_DELETE_ACCOUNT_FORM,
  payload,
  meta,
})

export const postDeleteAccount = (payload = {}, meta = {}) => ({
  [CALL_API]: {
    types: [
      DELETE_ACCOUNT_POST_REQUEST,
      DELETE_ACCOUNT_POST_OK,
      DELETE_ACCOUNT_POST_NG,
    ],
    endpoint: `/chefle/user/delete`,
    payload: {
      ...payload,
      method: 'POST',
    },
  },
})
