export const CREDITCARD_PAYMENT_POST_REQUEST = 'CREDITCARD_PAYMENT_POST_REQUEST'
export const CREDITCARD_PAYMENT_POST_OK = 'CREDITCARD_PAYMENT_POST_OK'
export const CREDITCARD_PAYMENT_POST_NG = 'CREDITCARD_PAYMENT_POST_NG'

export const FETCH_CREDITCARD_REQUEST = 'FETCH_CREDITCARD_REQUEST'
export const FETCH_CREDITCARD_OK = 'FETCH_CREDITCARD_OK'
export const FETCH_CREDITCARD_NG = 'FETCH_CREDITCARD_NG'

export const CREDITCARD_POST_REQUEST = 'CREDITCARD_POST_REQUEST'
export const CREDITCARD_POST_OK = 'CREDITCARD_POST_OK'
export const CREDITCARD_POST_NG = 'CREDITCARD_POST_NG'

export const CREDITCARD_UPDATE_REQUEST = 'CREDITCARD_UPDATE_REQUEST'
export const CREDITCARD_UPDATE_OK = 'CREDITCARD_UPDATE_OK'
export const CREDITCARD_UPDATE_NG = 'CREDITCARD_UPDATE_NG'

export const CREDITCARD_DELETE_REQUEST = 'CREDITCARD_DELETE_REQUEST'
export const CREDITCARD_DELETE_OK = 'CREDITCARD_DELETE_OK'
export const CREDITCARD_DELETE_NG = 'CREDITCARD_DELETE_NG'

export const FETCH_RESERVATION_REQUEST = 'FETCH_RESERVATION_REQUEST'
export const FETCH_RESERVATION_OK = 'FETCH_RESERVATION_OK'
export const FETCH_RESERVATION_NG = 'FETCH_RESERVATION_NG'

export const UPDATE_SIGN_UP_FORM = 'UPDATE_SIGN_UP_FORM'
export const CONFIRM_SIGN_UP_FORM = 'CONFIRM_SIGN_UP_FORM'
export const SIGN_UP_POST_REQUEST = 'SIGN_UP_POST_REQUEST'
export const SIGN_UP_POST_OK = 'SIGN_UP_POST_OK'
export const SIGN_UP_POST_NG = 'SIGN_UP_POST_NG'

export const UPDATE_SIGN_IN_FORM = 'UPDATE_SIGN_IN_FORM'
export const SIGN_IN_POST_REQUEST = 'SIGN_IN_POST_REQUEST'
export const SIGN_IN_POST_OK = 'SIGN_IN_POST_OK'
export const SIGN_IN_POST_NG = 'SIGN_IN_POST_NG'
export const SIGN_OUT_POST_REQUEST = 'SIGN_OUT_POST_REQUEST'
export const SIGN_OUT_POST_OK = 'SIGN_OUT_POST_OK'
export const SIGN_OUT_POST_NG = 'SIGN_OUT_POST_NG'

export const UPDATE_RESEND_EMAIL_FORM = 'UPDATE_RESEND_EMAIL_FORM'
export const RESEND_EMAIL_POST_REQUEST = 'RESEND_EMAIL_POST_REQUEST'
export const RESEND_EMAIL_POST_OK = 'RESEND_EMAIL_POST_OK'
export const RESEND_EMAIL_POST_NG = 'RESEND_EMAIL_POST_NG'

export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER'
export const AUTH_USER_POST_REQUEST = 'AUTH_USER_POST_REQUEST'
export const AUTH_USER_POST_OK = 'AUTH_USER_POST_OK'
export const AUTH_USER_POST_NG = 'AUTH_USER_POST_NG'

export const UPDATE_PASSWORD_FORM = 'UPDATE_PASSWORD_FORM'
export const UPDATE_PASSWORD_POST_REQUEST = 'UPDATE_PASSWORD_POST_REQUEST'
export const UPDATE_PASSWORD_OK = 'UPDATE_PASSWORD_OK'
export const UPDATE_PASSWORD_NG = 'UPDATE_PASSWORD_NG'

export const UPDATE_ACCOUNT_FORM = 'UPDATE_ACCOUNT_FORM'
export const UPDATE_ACCOUNT_POST_REQUEST = 'UPDATE_ACCOUNT_POST_REQUEST'
export const UPDATE_ACCOUNT_OK = 'UPDATE_ACCOUNT_OK'
export const UPDATE_ACCOUNT_NG = 'UPDATE_ACCOUNT_NG'

export const UPDATE_DELETE_ACCOUNT_FORM = 'UPDATE_DELETE_ACCOUNT_FORM'
export const CONFIRM_DELETE_ACCOUNT_FORM = 'CONFIRM_DELETE_ACCOUNT_FORM'
export const DELETE_ACCOUNT_POST_REQUEST = 'DELETE_ACCOUNT_POST_REQUEST'
export const DELETE_ACCOUNT_POST_OK = 'DELETE_ACCOUNT_POST_OK'
export const DELETE_ACCOUNT_POST_NG = 'DELETE_ACCOUNT_POST_NG'

export const RESTAURANTS_REQUEST = 'RESTAURANTS_REQUEST'
export const RESTAURANTS_OK = 'RESTAURANTS_OK'
export const RESTAURANTS_NG = 'RESTAURANTS_NG'

export const RESTAURANT_LIST_REQUEST = 'RESTAURANT_LIST_REQUEST'
export const RESTAURANT_LIST_OK = 'RESTAURANT_LIST_OK'
export const RESTAURANT_LIST_NG = 'RESTAURANT_LIST_NG'

export const IMMEDIATE_RESTAURANT_LIST_REQUEST =
  'IMMEDIATE_RESTAURANT_LIST_REQUEST'
export const IMMEDIATE_RESTAURANT_LIST_OK = 'IMMEDIATE_RESTAURANT_LIST_OK'
export const IMMEDIATE_RESTAURANT_LIST_NG = 'IMMEDIATE_RESTAURANT_LIST_NG'

export const RESTAURANT_REQUEST = 'RESTAURANT_REQUEST'
export const RESTAURANT_OK = 'RESTAURANT_OK'
export const RESTAURANT_NG = 'RESTAURANT_NG'

export const RESERVABLE_DATE_REQUEST = 'RESERVABLE_DATE_REQUEST'
export const RESERVABLE_DATE_OK = 'RESERVABLE_DATE_OK'
export const RESERVABLE_DATE_NG = 'RESERVABLE_DATE_NG'

export const RESERVABLE_TIME_REQUEST = 'RESERVABLE_TIME_REQUEST'
export const RESERVABLE_TIME_OK = 'RESERVABLE_TIME_OK'
export const RESERVABLE_TIME_NG = 'RESERVABLE_TIME_NG'

export const MENUS_REQUEST = 'MENUS_REQUEST'
export const MENUS_OK = 'MENUS_OK'
export const MENUS_NG = 'MENUS_NG'

export const MENU_POST_REQUEST = 'MENU_POST_REQUEST'
export const MENU_POST_OK = 'MENU_POST_OK'
export const MENU_POST_NG = 'MENUS_POST_NG'

export const UPDATE_MENU_FORM = 'UPDATE_MENU_FORM'
export const CONFIRM_MENU_FORM = 'CONFIRM_MENU_FORM'

export const UPDATE_PICKUP_FORM = 'UPDATE_PICKUP_FORM'
export const CONFIRM_PICKUP_FORM = 'CONFIRM_PICKUP_FORM'

export const PICKUP_POST_REQUEST = 'PICKUP_POST_REQUEST'
export const PICKUP_POST_OK = 'PICKUP_POST_OK'
export const PICKUP_POST_NG = 'PICKUP_POST_NG'

export const UPDATE_CHEFLE_MENU_FORM = 'UPDATE_CHEFLE_MENU_FORM'
export const CONFIRM_CHEFLE_MENU_FORM = 'CONFIRM_CHEFLE_MENU_FORM'

export const CHEFLE_MENU_POST_REQUEST = 'CHEFLE_MENU_POST_REQUEST'
export const CHEFLE_MENU_POST_OK = 'CHEFLE_MENU_POST_OK'
export const CHEFLE_MENU_POST_NG = 'CHEFLE_MENU_POST_NG'

export const UPDATE_SEAT_FORM = 'UPDATE_SEAT_FORM' //　未使用 TODO: 影響を調査して削除する
export const CONFIRM_SEAT_FORM = 'CONFIRM_SEAT_FORM' //　未使用
export const SEAT_POST_REQUEST = 'SEAT_POST_REQUEST' //　未使用
export const SEAT_POST_OK = 'SEAT_POST_OK' //　未使用
export const SEAT_POST_NG = 'SEAT_POST_NG' //　未使用

export const LOCAL_SET_RESEVABLE_TIME = 'LOCAL_SET_RESEVABLE_TIME' // seat 予約時はRestaurantIdがない場合があるため

export const SUGGESTIONS_REQUEST = 'SUGGESTIONS_REQUEST'
export const SUGGESTIONS_OK = 'SUGGESTIONS_OK'
export const SUGGESTIONS_NG = 'SUGGESTIONS_NG'
export const RESET_SUGGESTIONS = 'RESET_SUGGESTIONS'

export const AREAS_REQUEST = 'AREAS_REQUEST'
export const AREAS_OK = 'AREAS_OK'
export const AREAS_NG = 'AREAS_NG'

export const CATEGORIES_REQUEST = 'CATEGORIES_REQUEST'
export const CATEGORIES_OK = 'CATEGORIES_OK'
export const CATEGORIES_NG = 'CATEGORIES_NG'

export const GOOGLE_PLACE_REQUEST = 'GOOGLE_PLACE_REQUEST'
export const GOOGLE_PLACE_REQUEST_OK = 'GOOGLE_PLACE_REQUEST_OK'
export const GOOGLE_PLACE_REQUEST_NG = 'GOOGLE_PLACE_REQUEST_NG'
export const CLEAR_GOOGLE_PLACE = 'CLEAR_GOOGLE_PLACE'

export const RESERVATION_REQUEST = 'RESERVATION_REQUEST'
export const RESERVATION_OK = 'RESERVATION_OK'
export const RESERVATION_NG = 'RESERVATION_NG'

export const PAYMENT_FORM_REQUEST = 'PAYMENT_FORM_REQUEST'
export const PAYMENT_FORM_OK = 'PAYMENT_FORM_OK'
export const PAYMENT_FORM_NG = 'PAYMENT_FORM_NG'

export const CAMPAIGN_REQUEST = 'CAMPAIGN_REQUEST'
export const CAMPAIGN_OK = 'CAMPAIGN_OK'
export const CAMPAIGN_NG = 'CAMPAIGN_NG'

export const CALENDAR_REQUEST = 'CALENDAR_REQUEST'
export const CALENDAR_OK = 'CALENDAR_OK'
export const CALENDAR_NG = 'CALENDAR_NG'
