import { CALL_API } from '../middlewares/api'

import {
  UPDATE_PASSWORD_FORM,
  UPDATE_PASSWORD_POST_REQUEST,
  UPDATE_PASSWORD_OK,
  UPDATE_PASSWORD_NG,
} from './index'

export const updatePasswordForm = (payload, meta) => ({
  type: UPDATE_PASSWORD_FORM,
  payload,
  meta,
})

export const postUpdatePassword = (payload = {}, meta = {}) => ({
  [CALL_API]: {
    types: [
      UPDATE_PASSWORD_POST_REQUEST,
      UPDATE_PASSWORD_OK,
      UPDATE_PASSWORD_NG,
    ],
    endpoint: `/chefle/user/update`,
    payload: {
      ...payload,
      method: 'POST',
    },
  },
})
