import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {
  MenuReserveHeader,
  StrictNotice,
  RestaurantName,
  SelectedData,
  MenuCancellationPolicy,
} from '../../components/MenuReserveForm/MenuReserveParts'
import CancelationPolicy from '../../components/common/CancelationPolicy'
import { CustomDatePicker } from '../../components/MenuReserveForm/DatePicker'
import MenuSelector from '../../components/MenuReserveForm/MenuSelector'
import UserInfoInput from '../../components/MenuReserveForm/UserInfoInput'
import MemberSelector from '../../components/MenuReserveForm/MemberSelector'
import {
  updateMenuForm,
  fetchMenus,
  confirmMenuForm,
} from '../../actions/menuForm'
import { fetchRestaurant } from '../../actions/restaurant'
import { fetchCalendar } from '../../actions/calendar'
import { BackButton, ChatButton } from '../../components/common/Buttons'
import ls from 'localstorage-ttl'
import { Helmet } from 'react-helmet'
import Checkbox from '@material-ui/core/Checkbox'
import PersonalInformation from '../../components/common/PersonalInformation'

function mapStateToProps(state) {
  return {
    menuForm: state.menuForm,
    menu: state.menu,
    restaurant: state.restaurant,
    calendar: state.calendar,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchMenus: bindActionCreators(fetchMenus, dispatch),
    fetchRestaurant: bindActionCreators(fetchRestaurant, dispatch),
    updateMenuForm: bindActionCreators(updateMenuForm, dispatch),
    confirmMenuForm: bindActionCreators(confirmMenuForm, dispatch),
    fetchCalendar: bindActionCreators(fetchCalendar, dispatch),
  }
}

class MenuReserveFormContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSaveUser: true,
    }
  }
  componentDidMount() {
    const urlQuery = queryString.parse(this.props.location.search)
    let host = urlQuery.host ? urlQuery.host : ls.get('hostCode')

    const restaurantId = this.props.match.params.restaurantId
    const today = new Date()
    const year = today.getFullYear()
    const month = today.getMonth() + 1
    const partySize = this.props.menuForm.adult + this.props.menuForm.child

    this.props.fetchMenus({ restaurantId })
    this.props.fetchRestaurant({ restaurantId })
    this.props.fetchCalendar({
      restaurantId,
      year,
      month,
      partySize,
    })

    this.props.updateMenuForm({
      ...this.props.menuForm,
      host: host,
      restaurant: {
        id: Number(restaurantId),
        plans: [],
      },
      alternativeBeforeDate: false,
      alternativeAfterDate: false,
      confirmedCancellationPolicy: false,
    })
  }

  onUpdate = data => {
    const menuForm = this.props.menuForm

    const meta = {
      key: Object.keys(data)[0],
      value: data[Object.keys(data)[0]],
      restaurantId: this.props.match.params.restaurantId,
    } // middleware での判定用
    this.props.updateMenuForm({ ...menuForm, ...data }, meta)
  }

  reFetchCalendar = (size, type) => {
    const restaurantId = this.props.match.params.restaurantId
    const today = new Date()
    const year = today.getFullYear()
    const month = today.getMonth() + 1
    var partySize = 0
    if (type === 'adult') {
      partySize = this.props.menuForm.child + size
    }

    if (type === 'child') {
      partySize = this.props.menuForm.adult + size
    }

    this.props.fetchCalendar({ restaurantId, year, month, partySize })
  }

  submit = () => {
    this.props.confirmMenuForm(this.props.menuForm, {
      isSaveUser: this.state.isSaveUser,
    })
  }
  // ユーザ情報をローカルストレージ保存用チェックボックスのイベント
  handleIsSaveUser = () => {
    this.setState(prevState => ({
      isSaveUser: !this.state.isSaveUser,
    }))
  }

  handleAllowAlternativeBeforeDate = () => {
    this.onUpdate({
      alternativeBeforeDate: !this.props.menuForm.alternativeBeforeDate,
    })
  }

  handleAllowAlternativeAfterDate = () => {
    this.onUpdate({
      alternativeAfterDate: !this.props.menuForm.alternativeAfterDate,
    })
  }

  handleConfirmedCancellationPolicy = () => {
    this.onUpdate({
      confirmedCancellationPolicy: !this.props.menuForm
        .confirmedCancellationPolicy,
    })
  }

  render() {
    var { menuForm, menu, restaurant, calendar } = this.props
    const partySize = menuForm.adult + menuForm.child
    const isHostPrice =
      menuForm.host &&
      restaurant.data.hostCode &&
      menuForm.host === restaurant.data.hostCode
    const head = {
      title: `${restaurant.data.nameJa ? restaurant.data.nameJa + ' ' : ''}${
        restaurant.data.name
      } Reservation | Chefle - 予約`,
      description: '',
    }
    return (
      <div className="menu-reserve-form">
        <Helmet>
          <title>{head.title}</title>
          <meta property="og:title" content={head.title} />
          <meta property="og:description" content={head.description} />
          <meta name="description" content={head.description} />
          <meta name="robots" content="noindex" />
        </Helmet>
        <MenuReserveHeader src="/assets/img/store_img.jpg" />
        {restaurant.data.isStrict && (
          <StrictNotice
            isVacancyManagement={restaurant.data.isVacancyManagement}
          />
        )}
        <main>
          <form action="">
            <div className="menu-reserve-store-head">
              {/* <BraedCrumb /> */}
              <RestaurantName
                en={restaurant.data.name}
                ja={restaurant.data.nameJa}
              />
            </div>
            <div className="reserve-form-main-container">
              <div className="reserve-form-left-container">
                <h3 className="party-size-title">人数</h3>
                <ul className="conditions-chice">
                  <li className="reserve-form-adult">
                    <span
                      style={
                        !!menuForm.errors.adult ? { color: '#f44336' } : {}
                      }
                    >
                      大人
                    </span>
                    <div style={{ textAlign: 'center' }}>
                      <MemberSelector
                        partySizeType={'adult'}
                        menuForm={menuForm.adult}
                        onUpdate={this.onUpdate}
                        reFetchCalendar={this.reFetchCalendar}
                        error={menuForm.errors.adult}
                      />
                    </div>
                  </li>
                  <li className="reserve-form-child">
                    <span
                      style={
                        !!menuForm.errors.adult ? { color: '#f44336' } : {}
                      }
                    >
                      子供(3-11)
                    </span>
                    <div style={{ textAlign: 'center' }}>
                      <MemberSelector
                        partySizeType={'child'}
                        menuForm={menuForm.child}
                        onUpdate={this.onUpdate}
                        reFetchCalendar={this.reFetchCalendar}
                        error={menuForm.errors.child}
                      />
                    </div>
                  </li>
                  <li className="reserve-form-baby">
                    <span
                      style={
                        !!menuForm.errors.adult ? { color: '#f44336' } : {}
                      }
                    >
                      乳幼児(0-2)
                    </span>
                    <div style={{ textAlign: 'center' }}>
                      <MemberSelector
                        partySizeType={'baby'}
                        menuForm={menuForm.baby}
                        onUpdate={this.onUpdate}
                        error={menuForm.errors.baby}
                      />
                    </div>
                  </li>
                </ul>
                <ul className="conditions-chice">
                  <CustomDatePicker
                    onUpdate={this.onUpdate}
                    fetchCalendar={this.props.fetchCalendar}
                    restaurantId={this.props.match.params.restaurantId}
                    calendar={calendar.data}
                    error1={menuForm.errors.date}
                    error2={menuForm.errors.time}
                    partySize={partySize}
                    selectedDate={menuForm.date}
                  />
                </ul>
                <MenuSelector
                  menus={menu.data}
                  menuForm={menuForm}
                  onUpdate={this.onUpdate}
                  isHostPrice={isHostPrice}
                  isSameMenu={restaurant.data.isSameMenu}
                  error={
                    menuForm.errors.restaurant
                      ? menuForm.errors.restaurant.plans
                      : ''
                  }
                />
                <UserInfoInput
                  menuForm={menuForm}
                  onUpdate={this.onUpdate}
                  errors={menuForm.errors.guest}
                  handleIsSaveUser={() => this.handleIsSaveUser()}
                  isSaveUser={this.state.isSaveUser}
                  reservationType={restaurant.data.reservationType}
                  label="追加コメント(例.質問事項などその他)"
                />
              </div>
              <div className="reserve-form-right-container">
                <SelectedData
                  restaurant={restaurant}
                  menuForm={menuForm}
                  isHostPrice={isHostPrice}
                />
                <MenuCancellationPolicy
                  cancellationPolicy={restaurant.data.cancellationPolicy}
                />
                <div className="policy-box">
                  <Checkbox
                    checked={menuForm.confirmedCancellationPolicy}
                    onChange={this.handleConfirmedCancellationPolicy}
                    name={'confirmedCancellationPolicy'}
                    value={menuForm.confirmedCancellationPolicy}
                    error={menuForm.confirmedCancellationPolicy}
                    Style="padding: 0 10px 0 0;"
                  />
                  <p Style="font-size: 14px; line-height: 22px;">
                    キャンセルポリシー、
                    <CancelationPolicy
                      text={'ユーザーガイド'}
                      btnclassname={'policy-link'}
                    />
                    、
                    <PersonalInformation
                      text={'個人情報保護方針'}
                      btnclassname={'policy-link'}
                    />
                    に同意します。
                  </p>
                </div>
                <p Style={'padding:0 13px 20px; font-size: 0.8rem;'}>
                  <span
                    style={
                      !!menuForm.errors.confirmedCancellationPolicy
                        ? { color: '#f44336' }
                        : {}
                    }
                  >
                    {menuForm.errors.confirmedCancellationPolicy}
                  </span>
                </p>
                <p className="reserve-confirm-btn">
                  <input
                    style={{ cursor: 'pointer' }}
                    type="button"
                    value="予約確認"
                    onClick={() => this.submit()}
                  />
                </p>
              </div>
            </div>
          </form>
          <div className="fixed-footer sp">
            <div className="fixed-footer-inner">
              <BackButton position={'left'} />
              <ChatButton position={'right'} />
            </div>
          </div>
        </main>
      </div>
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MenuReserveFormContainer)
)
