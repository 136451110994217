const initialState = {
  user_no: '',
  token: '',
  withdrawal: '',
  reason1: false, //サービスを利用する機会がなくなった。
  reason2: false, //サービスが使いづらかった。
  reason3: false, //気になるお店がなかった。
  reason4: false, //サービスの対応が悪かった。
  reason5: false,
  errors: {},
  postingFlag: false,
}
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_DELETE_ACCOUNT_FORM':
      return {
        ...state,
        ...action.payload,
      }
    case 'CONFIRM_DELETE_ACCOUNT_FORM':
      return {
        ...state,
        ...action.payload,
      }
    case 'DELETE_ACCOUNT_POST_REQUEST':
      return {
        ...state,
        postingFlag: true,
      }
    case 'DELETE_ACCOUNT_POST_OK':
      return {
        ...state,
        postingFlag: false,
      }
    case 'DELETE_ACCOUNT_POST_NG':
      return {
        ...state,
        postingFlag: false,
      }
    default:
      return state
  }
}
