import { Component } from 'react'
import { getAuthUser, postSignOut } from '../actions/authUser'
import { bindActionCreators } from 'redux'

export function authMapStateToProps(state) {
  return {
    authUser: state.authUser,
  }
}

export function authMapDispatchToProps(dispatch) {
  return {
    getAuthUser: bindActionCreators(getAuthUser, dispatch),
    postSignOut: bindActionCreators(postSignOut, dispatch),
  }
}

class AuthComponent extends Component {
  componentDidMount() {
    // ログインユーザ情報取得・トークン有効期限チェック
    if (this.props.authUser.authInfo.isLogin) {
      this.props.getAuthUser({ data: this.props.authUser.authInfo })
    }
  }

  componentDidUpdate(prevProps) {
    // ユーザがログイン/ログアウト状態になったら、リダイレクトする
    if (this.props.authUser.userInfo !== prevProps.authUser.userInfo) {
      if (this.props.authUser.redirectPath !== '') {
        window.location.href = this.props.authUser.redirectPath
      }
    }
  }
}

export default AuthComponent
