import React, { Component } from 'react'
import classNames from 'classnames'
import StringHeler from '../../helpers/StringHelper'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'

const errorLiStyle = {
  borderColor: '#f44336',
  borderStyle: 'solid',
  borderWidth: '2px',
}

const errorMessageStyle = {
  position: 'absolute',
  marginTop: '8px',
  color: 'red',
  fontSize: '0.8rem',
}

export default class MenuSelector extends Component {
  componentDidUpdate(prevProps) {
    let prevTimePeriod = this.selectedTimePeriod(prevProps.menuForm.time)
    let timePeriod = this.selectedTimePeriod(this.props.menuForm.time)
    let menus = this.props.menuForm.restaurant.plans

    if (prevTimePeriod !== timePeriod) {
      let filteredMenus = menus.filter(m => this.isIncludeTime(m, timePeriod))

      this.props.onUpdate({
        restaurant: { ...this.props.menuForm.restaurant, menus: filteredMenus },
      })
    }

    let prevMenus = prevProps.menuForm.restaurant.plans

    // 初期表示時はメニューオブジェクトが存在しないためチェックを行う。
    if (Object.keys(menus).length && this.props.isSameMenu === true) {
      let prevSelectedMenu = prevMenus[0]
      let selectedMenu = menus[0]

      // 初回の更新
      if (!Object.keys(prevMenus).length && 0 < selectedMenu.quantity) {
        this.props.onUpdate({
          selectedMenuId: selectedMenu.id,
        })
      }

      // 数を0に戻した時の処理 リセットする
      if (selectedMenu.quantity === '0') {
        this.props.onUpdate({
          selectedMenuId: 0,
          restaurant: {
            ...this.props.menuForm.restaurant,
            plans: [],
          },
        })
      }

      // 二回目以降更新
      if (Object.keys(prevMenus).length) {
        if (
          0 < selectedMenu.quantity &&
          prevSelectedMenu.quantity !== selectedMenu.quantity
        ) {
          this.props.onUpdate({
            selectedMenuId: selectedMenu.id,
          })
        }
      }
    }
  }

  // [{id: 1, quantity: 1}] などに整形するための処理
  onCountChange = (menu, quantity) => {
    let plans = this.props.menuForm.restaurant.plans

    if (quantity === 0) {
      plans = plans.filter(m => m.id !== menu.id)
    }
    let pushFlag = true
    let result = plans.map(m => {
      if (m.id === menu.id) {
        pushFlag = false
        return { ...menu, quantity } // 同じ id なら配列を上書き
      }
      return m
    })
    if (pushFlag) {
      result.push({ ...menu, quantity })
    }

    this.props.onUpdate({
      restaurant: { ...this.props.menuForm.restaurant, plans: result },
    })
  }

  filterMenu = id =>
    this.props.menuForm.restaurant.plans.filter(m => m.id === id)[0]

  isActiveMenuCss(id) {
    const menuReserveForMenuBox = classNames('menu-reserve-form-menu-box', {
      active: id === this.props.menuForm.selectedMenuId,
      inactive:
        id !== this.props.menuForm.selectedMenuId &&
        this.props.menuForm.selectedMenuId !== 0,
    })
    return menuReserveForMenuBox
  }

  isActiveMenuSelectField(id) {
    if (
      id !== this.props.menuForm.selectedMenuId &&
      this.props.menuForm.selectedMenuId !== 0
    ) {
      return true
    }
  }

  selectedTimePeriod(time) {
    if (!time) {
      return
    }
    let intTime = Number(time.replace(':', ''))
    return intTime < 1600 ? 'lunch' : 'dinner'
  }

  isIncludeTime = (menu, timePeriod) => {
    if (timePeriod === 'lunch') {
      return !!menu.isLunch
    }
    if (timePeriod === 'dinner') {
      return !!menu.isDinner
    }
    return true
  }

  render() {
    let timePeriod = this.selectedTimePeriod(this.props.menuForm.time)

    return (
      <>
        <div className="menu-reserve-form-container-top" />
        <ul className="menu-reserve-form-menu-container">
          {this.props.menus
            .filter(m => this.isIncludeTime(m, timePeriod))
            .map((m, i) => (
              <MenuList
                key={i + 'menu'}
                onPlus={this.onPlus}
                menu={m}
                count={
                  this.filterMenu(m.id) ? this.filterMenu(m.id).quantity : 0
                }
                menuReserveForMenuBox={
                  this.props.isSameMenu
                    ? this.isActiveMenuCss(m.id)
                    : 'menu-reserve-form-menu-box'
                }
                disabled={
                  this.props.isSameMenu
                    ? this.isActiveMenuSelectField(m.id)
                    : false
                }
                onCountChange={this.onCountChange}
                isHostPrice={this.props.isHostPrice}
                error={this.props.error}
              />
            ))}
          {this.props.error && (
            <div style={errorMessageStyle}>{this.props.error}</div>
          )}
        </ul>
      </>
    )
  }
}

class MenuList extends Component {
  state = {
    open: false,
  }

  handleOpen = () => this.setState({ open: true })
  handleClose = () => this.setState({ open: false })
  handleChange = (e, menu) => {
    this.props.onCountChange(menu, e.target.value)
  }

  createList = () => {
    let list = []
    for (let i = 1; i < 20; i++) {
      list.push(i)
    }
    return list
  }

  render() {
    const personList = this.createList()
    const { menu } = this.props

    return (
      // 同一メニュー制御を行う際はメニュー選択後に他のメニューを非活性にする
      <li
        className={this.props.menuReserveForMenuBox}
        style={!!this.props.error ? errorLiStyle : {}}
      >
        <h3 className="menu-reserve-form-menu-name">
          {menu.isLunch && <img src="/assets/img/lunch.svg" alt="" />}
          {menu.isDinner && <img src="/assets/img/dinner.svg" alt="" />}
          {menu.name}
        </h3>
        <div className="menu-reserve-form-detail">
          <div className="menu-reserve-form-detail-txt">
            <p className="menu-reserve-form-course-name">
              {StringHeler.getCourse(menu.description)}
            </p>
            <p className="menu-reserve-form-notice">
              {StringHeler.parseDescription(menu.description)}
            </p>
            <p className="menu-reserve-form-price">
              ¥&nbsp;
              {StringHeler.numberWithCommas(
                this.props.isHostPrice && menu.hostPrice
                  ? menu.hostPrice
                  : menu.price
              )}
              <span>&nbsp;/&nbsp;person</span>
            </p>
          </div>
          <FormControl className="menu-reserve-form-menu-quantity">
            <Select
              open={this.state.open}
              onClose={this.handleClose}
              onOpen={this.handleOpen}
              value={this.props.count}
              disabled={this.props.disabled}
              onChange={e => this.handleChange(e, menu)}
            >
              <MenuItem value="0">0</MenuItem>
              {personList.map((m, i) => (
                <MenuItem key={i + 'menu'} value={m}>
                  {m}
                </MenuItem>
              ))}
              <MenuItem value={20}>over 20</MenuItem>
            </Select>
          </FormControl>
        </div>
      </li>
    )
  }
}
