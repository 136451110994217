import { CALL_API } from '../middlewares/api'
import {
  UPDATE_SIGN_IN_FORM,
  SIGN_IN_POST_REQUEST,
  SIGN_IN_POST_OK,
  SIGN_IN_POST_NG,
} from './index'

export const updateSignInForm = (payload, meta) => ({
  type: UPDATE_SIGN_IN_FORM,
  payload,
  meta,
})

export const postSignIn = (payload = {}, meta = {}) => ({
  [CALL_API]: {
    types: [SIGN_IN_POST_REQUEST, SIGN_IN_POST_OK, SIGN_IN_POST_NG],
    endpoint: `/chefle/login`,
    payload: {
      ...payload,
      method: 'POST',
    },
  },
})
