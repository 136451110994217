import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { postMenu } from '../../actions/menuForm'
import StringHelper from '../../helpers/StringHelper'
import {
  SelectedData,
  StrictNotice,
} from '../../components/MenuReserveForm/MenuReserveParts'
import { LoadingIconGray } from '../../components/common/LoadingIcon'
import {
  Detail,
  Guest,
  Message,
} from '../../components/MenuReserveForm/ConfirmParts'
import cleanDeep from 'clean-deep'
import { Helmet } from 'react-helmet'

function mapStateToProps(state) {
  return {
    menuForm: state.menuForm,
    restaurant: state.restaurant,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    postMenu: bindActionCreators(postMenu, dispatch),
  }
}
class MenuReserveConfirmContainer extends Component {
  normalizeData = () => {
    const { menuForm } = this.props

    let tmp = {
      ...menuForm,
      date: StringHelper.dateForServer(menuForm.date),
      alternativeDates: this.createAlternativeDates(menuForm),
      restaurant: {
        ...menuForm.restaurant,
        menus: menuForm.restaurant.plans.map(m => ({
          id: m.id,
          quantity: m.quantity,
        })),
      },
    }
    delete tmp.validation
    delete tmp.postingFlag
    return cleanDeep(tmp)
  }
  handleSubmit = e => {
    e.preventDefault()
    const data = this.normalizeData()
    this.props.postMenu({ data })
  }

  createAlternativeDates = menuForm => {
    let alternativeDates = []
    if (menuForm.alternativeBeforeDate === true) {
      alternativeDates.push(
        StringHelper.alternativeDateForServer(menuForm.date, -1)
      )
    }
    if (menuForm.alternativeAfterDate === true) {
      alternativeDates.push(
        StringHelper.alternativeDateForServer(menuForm.date, 1)
      )
    }
    return alternativeDates
  }

  render() {
    const { menuForm, restaurant } = this.props
    const isHostPrice =
      menuForm.host &&
      restaurant.data.hostCode &&
      menuForm.host === restaurant.data.hostCode
    const head = {
      title: 'Confirmation Screen | シェフル(chefle) - 予約',
      description: '有名高級店をおうちで召し上がれ。',
    }
    return (
      <>
        <Helmet>
          <title>{head.title}</title>
          <meta property="og:title" content={head.title} />
          <meta property="og:description" content={head.description} />
          <meta name="description" content={head.description} />
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className="menu-reserve-confirmation">
          <header>
            <h2 className="reserve-form-title sp">
              <span>CONFIRMATION SCREEN</span>
            </h2>
            <h2 className="reserve-form-title pc">
              <span className="reserve-form-title-menu">Chefle 予約</span>
            </h2>
          </header>
          {restaurant.data.isStrict && (
            <StrictNotice
              isVacancyManagement={restaurant.data.isVacancyManagement}
            />
          )}
          <main>
            <form action="">
              <p className="reserve-form-screen-title pc">
                CONFIRMATION SCREEN
              </p>
              <div className="reserve-confirmation-container">
                <div className="reserve-confirmation-detail-container">
                  <Detail
                    form={menuForm}
                    restaurantName={restaurant.data.name}
                  />
                  <Guest
                    guest={menuForm.guest}
                    allergy={menuForm.allergy}
                    comment={menuForm.comment}
                  />
                </div>
                <div className="reserve-confirmation-right-container">
                  <SelectedData
                    restaurant={restaurant}
                    menuForm={menuForm}
                    isHostPrice={isHostPrice}
                  />
                  <div className="reserve-submit-box">
                    <Message
                      reservationType={restaurant.data.reservationType}
                      isVacancyManagement={restaurant.data.isVacancyManagement}
                    />
                    {menuForm.postingFlag ? (
                      <LoadingIconGray />
                    ) : (
                      <p className="reserve-submit-btn">
                        <form onSubmit={this.handleSubmit}>
                          <input
                            type="submit"
                            value="決済入力画面へ"
                            onSubmit={this.handleSubmit}
                          />
                        </form>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </main>
        </div>
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuReserveConfirmContainer)
