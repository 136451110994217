import React from 'react'
import StringHelper from '../../helpers/StringHelper'
import DateHelper from '../../helpers/DateHelper'

const Detail = props => (
  <>
    <h3 className="reserve-confirmation-detail-title">ご予約詳細</h3>
    <ul className="reserve-confirmation-matter">
      <li className="reserve-confirmation-date">
        {StringHelper.dateParse(props.form.date)}
      </li>
      <li className="reserve-confirmation-time">{props.form.time}</li>
      {props.form.alternativeBeforeDate ? (
        <li className="reserve-confirmation-alternativedate">
          Agree: {DateHelper.alternativeDateForUser(props.form.date, -1)} (One
          day before)
        </li>
      ) : (
        ''
      )}
      {props.form.alternativeAfterDate ? (
        <li className="reserve-confirmation-alternativedate">
          Agree: {DateHelper.alternativeDateForUser(props.form.date, +1)} (One
          day after)
        </li>
      ) : (
        ''
      )}
      {props.form.adult ? (
        <li className="reserve-confirmation-adult-people">
          {props.form.adult}
          {props.form.adult >= 2 ? '\nadults' : '\nadult'}
        </li>
      ) : (
        ''
      )}
      {props.form.child ? (
        <li className="reserve-confirmation-child-people">
          {props.form.child}
          {props.form.child >= 2 ? '\nchildren(3-11)' : '\nchild(3-11)'}
        </li>
      ) : (
        ''
      )}
      {props.form.baby ? (
        <li className="reserve-confirmation-baby-people">
          {props.form.baby}
          {props.form.baby >= 2 ? '\nbabys(0-2)' : '\nbaby(0-2)'}
        </li>
      ) : (
        ''
      )}
      <li className="reserve-confirmation-store">{props.restaurantName}</li>
    </ul>
  </>
)

const Guest = ({ guest, allergy, comment }) => (
  <div className="reserve-gest-details">
    <h2 className="reserve-gest-details-title">お客様詳細</h2>
    <ul className="reserve-gest-details-list">
      <li className="reserve-gest-details-name">
        <p className="reserve-gest-details-head">姓名</p>
        <p className="reserve-gest-details-txt">
          {guest.lastName + ' ' + guest.firstName}
        </p>
      </li>
      <li className="reserve-gest-details-email">
        <p className="reserve-gest-details-head">メールアドレス</p>
        <p className="reserve-gest-details-txt">{guest.email}</p>
      </li>
      <li className="reserve-gest-details-phone">
        <p className="reserve-gest-details-head">電話番号</p>
        <p className="reserve-gest-details-txt">{guest.phone}</p>
      </li>
      <li className="reserve-gest-details-note">
        <p className="reserve-gest-details-head">アレルギー</p>
        <p className="reserve-gest-details-txt">{allergy}</p>
      </li>
      <li className="reserve-gest-details-note">
        <p className="reserve-gest-details-head">追加コメント</p>
        <p className="reserve-gest-details-txt">{comment}</p>
      </li>
    </ul>
  </div>
)

const getMessage = isVacancyManagement => {
  return isVacancyManagement
    ? 'ご予約送信前に、必ずキャンセルポリシーのご確認をお願いいたします。'
    : 'ご予約リクエスト送信前に、必ずキャンセルポリシーのご確認をお願いいたします。'
}

const Message = ({ reservationType, isVacancyManagement }) => (
  <div className="reserve-message">
    <h2 className="reserve-message-head">Chefleからのご案内</h2>
    {reservationType === 'chefle' ? (
      <p className="reserve-message-txt">
        1：ご予約リクエスト送信前に、必ずキャンセルポリシーのご確認をお願いいたします。
        <br />
        <br />
        2：もしご予約のキャンセルや変更をご希望の場合、すぐにご連絡頂くようご協力頂けますと幸いです。ご予約確定後にご連絡いただいた場合、サービス利用料の返金はございません。また、キャンセルポリシーに準じ、返金手配をいたします。
        <br />
        <br />
        3：リクエストを送信いただきました後、自動メールが送信されます。アンケートのご回答をお願いいたします。
        <br />
        <br />
      </p>
    ) : (
      <p className="reserve-message-txt">
        {getMessage(isVacancyManagement)}
        <br />
        <br />
      </p>
    )}
  </div>
)

export { Detail, Guest, Message }
