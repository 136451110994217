import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AuthComponent, {
  authMapStateToProps,
  authMapDispatchToProps,
} from '../../../components/AuthComponent'
import {
  updateAccountForm,
  postUpdateAccount,
} from '../../../actions/updateAccount'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Formik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import PREFECTURES from '../../../constants/prefectures'
import { withRouter } from 'react-router'
import { PcHeader, SpHeader } from '../../../components/top/Header'
import cleanDeep from 'clean-deep'

const styles = theme => ({
  textField: {
    width: '100%',
    fontSize: '16px',
  },
  formControl: {
    minWidth: 100,
  },
})

const Text = ({
  label,
  disabled,
  handleChange,
  costomChange,
  values,
  errors,
  name,
  classes,
  touched,
  setFieldTouched,
  rows,
  parentErrors,
}) => (
  <div style={{ position: 'relative', fontSize: '40px' }}>
    <TextField
      className={classes.textField}
      name={name}
      label={label}
      disabled={disabled}
      inputProps={{ style: { fontSize: 16 } }}
      error={
        (!!parentErrors && !!parentErrors[name]) ||
        (!!touched[name] && !!errors[name])
      }
      value={values[name]}
      rows={rows}
      onFocus={() => setFieldTouched(name, true, false)}
      onChange={e => {
        handleChange(e)
        costomChange(e, name)
      }}
    />
    {!!parentErrors && !!parentErrors[name] && (
      <div style={{ position: 'absolute', color: 'red', fontSize: '0.8rem' }}>
        {parentErrors[name]}
      </div>
    )}
    {!!touched[name] && errors[name] && (
      <div style={{ position: 'absolute', color: 'red', fontSize: '0.8rem' }}>
        {errors[name]}
      </div>
    )}
  </div>
)

function mapStateToProps(state) {
  return Object.assign(authMapStateToProps(state), {
    updateAccount: state.updateAccount,
  })
}

function mapDispatchToProps(dispatch) {
  return Object.assign(authMapDispatchToProps(dispatch), {
    updateAccountForm: bindActionCreators(updateAccountForm, dispatch),
    postUpdateAccount: bindActionCreators(postUpdateAccount, dispatch),
  })
}

class AccountUpdateContainer extends AuthComponent {
  constructor(props) {
    super(props)
    this.state = {
      btnDidabled: false, //初期値が入力されるためfalseとする
    }
  }
  componentDidMount() {
    super.componentDidMount()
    this.props.updateAccount.user_no = this.props.authUser.authInfo.user_no
    this.props.updateAccount.token = this.props.authUser.authInfo.token
    this.props.updateAccount.sei = this.props.authUser.userInfo.sei
    this.props.updateAccount.mei = this.props.authUser.userInfo.mei
    this.props.updateAccount.seikana = this.props.authUser.userInfo.seikana
    this.props.updateAccount.meikana = this.props.authUser.userInfo.meikana
    if (this.props.authUser.userInfo.birthday) {
      this.props.updateAccount.birthday = this.props.authUser.userInfo.birthday.replace(
        ' 00:00:00',
        ''
      )
    }
    this.props.updateAccount.allergy = this.props.authUser.userInfo.allergy
    this.props.updateAccount.tel = this.props.authUser.userInfo.tel
    this.props.updateAccount.prefectures = this.props.authUser.userInfo.prefectures
    this.props.updateAccount.mailmagazine = this.props.authUser.userInfo.mailmagazine
  }
  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
    this.isDisabled()
  }

  isDisabled() {
    if (!this.state.btnDidabled) {
      if (this.props.updateAccount.sei === '') {
        this.setState({ btnDidabled: true })
      }
      if (this.props.updateAccount.mei === '') {
        this.setState({ btnDidabled: true })
      }
      if (this.props.updateAccount.seikana === '') {
        this.setState({ btnDidabled: true })
      }
      if (this.props.updateAccount.meikana === '') {
        this.setState({ btnDidabled: true })
      }
      if (this.props.updateAccount.birthday === '') {
        this.setState({ btnDidabled: true })
      }
      if (this.props.updateAccount.tel === '') {
        this.setState({ btnDidabled: true })
      }
    }
    if (this.state.btnDidabled) {
      if (
        this.props.updateAccount.sei !== '' &&
        this.props.updateAccount.mei !== '' &&
        this.props.updateAccount.seikana !== '' &&
        this.props.updateAccount.meikana !== '' &&
        this.props.updateAccount.birthday !== '' &&
        this.props.updateAccount.tel !== ''
      ) {
        this.setState({ btnDidabled: false })
      }
    }
  }

  handleChange = (event, name, formikProps) => {
    this.onUpdate({
      ...this.props.updateAccount,
      [name]: event.target.value,
    })
  }

  onUpdate = data => {
    const updateAccount = this.props.updateAccount
    this.props.updateAccountForm({ ...updateAccount, ...data })
  }

  normalizeData = () => {
    const { updateAccount } = this.props
    return cleanDeep(updateAccount)
  }
  submit = () => {
    const data = this.normalizeData()
    this.props.postUpdateAccount({ data })
  }

  handleSelectChange = (name, formikProps, event) => {
    this.handleChange(event, name, formikProps)
  }

  handleCheckboxChange = (name, formikProps, event) => {
    this.onUpdate({
      ...this.props.updateAccount,
      [name]: event.target.checked,
    })
  }

  render() {
    const { classes, authUser, updateAccount } = this.props

    return (
      <>
        <div className="account-setting top-page">
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
          <PcHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
          />
          <SpHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
          />
          <div className="account-setting-contents">
            <section className="setting-menu">
              <ul>
                <li>
                  <Link to="/setting/reservation">ご予約一覧</Link>
                </li>
                <li>
                  <Link to="/setting/creditcard">お支払い情報</Link>
                </li>
                <li Style="color: #ff5800;">
                  <Link to="/setting/user_info">アカウント設定</Link>
                </li>
                <li>
                  <Link to="/setting/password">パスワード変更</Link>
                </li>
                <li>
                  <Link to="/setting/delete">退会</Link>
                </li>
              </ul>
            </section>
            <section className="setting-form">
              <Formik
                // initialValues={{
                //   sei: this.props.updateAccount.sei,
                //   mei: this.props.updateAccount.mei,
                // }}
                // validationSchema={validationSchema}
                enableReinitialize={true}
              >
                {props => (
                  <>
                    <div className="reserve-form-guest-ditails reserve-form-container">
                      <h3 className="block-title">アカウント設定</h3>
                      <p
                        style={{
                          position: 'absolute',
                          color: 'red',
                          fontSize: '0.8rem',
                          paddingLeft: '20px',
                        }}
                      >
                        {updateAccount.errorMessage}
                      </p>
                      <ul className="reserve-form-input-area">
                        <Text
                          {...props}
                          name="sei"
                          label="*姓 (漢字)"
                          values={updateAccount}
                          classes={classes}
                          costomChange={(e, name) =>
                            this.handleChange(e, name, props)
                          }
                        />
                        <Text
                          {...props}
                          name="mei"
                          label="*名 (漢字)"
                          values={updateAccount}
                          classes={classes}
                          costomChange={(e, name) =>
                            this.handleChange(e, name, props)
                          }
                        />
                        <Text
                          {...props}
                          name="seikana"
                          label="*セイ（フリガナ）"
                          values={updateAccount}
                          classes={classes}
                          costomChange={(e, name) =>
                            this.handleChange(e, name, props)
                          }
                        />
                        <Text
                          {...props}
                          name="meikana"
                          label="*メイ（フリガナ）"
                          values={updateAccount}
                          classes={classes}
                          costomChange={(e, name) =>
                            this.handleChange(e, name, props)
                          }
                        />
                        <Text
                          {...props}
                          name="birthday"
                          label="*生年月日 YYYYMMDD 例) 1985年1月1日の場合は19850101"
                          values={updateAccount}
                          classes={classes}
                          costomChange={(e, name) =>
                            this.handleChange(e, name, props)
                          }
                        />
                        <Text
                          {...props}
                          name="tel"
                          label="*電話番号 ハイフンあり 例) XXX-XXXX-XXXX"
                          values={updateAccount}
                          classes={classes}
                          costomChange={(e, name) =>
                            this.handleChange(e, name, props)
                          }
                        />
                        <FormControl Style="width: 100%;">
                          <InputLabel>*お住まいの都道府県</InputLabel>
                          <Select
                            {...props}
                            name="prefectures"
                            label="お住まいの都道府県"
                            classes={classes}
                            value={updateAccount.prefectures}
                            inputProps={{ style: { fontSize: 16 } }}
                            SelectDisplayProps={{ style: { fontSize: 16 } }}
                            onChange={this.handleSelectChange.bind(
                              this,
                              'prefectures',
                              props
                            )}
                          >
                            {PREFECTURES.map(list => (
                              <MenuItem key={list} value={list}>
                                {list}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Text
                          {...props}
                          name="allergy"
                          label="アレルギー"
                          values={updateAccount}
                          classes={classes}
                          costomChange={(e, name) =>
                            this.handleChange(e, name, props)
                          }
                        />
                        <div className="checkbox-label">
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="mailmagazine"
                                onChange={this.handleCheckboxChange.bind(
                                  this,
                                  'mailmagazine',
                                  props
                                )}
                                checked={updateAccount.mailmagazine}
                                value={updateAccount.mailmagazine}
                              />
                            }
                            label="Chefleからのメルマガを受け取る"
                          />
                        </div>
                      </ul>
                    </div>
                    <p className="reserve-confirm-btn">
                      <input
                        style={{ cursor: 'pointer' }}
                        type="submit"
                        value="変更する"
                        disabled={this.state.btnDidabled}
                        onClick={() => this.submit()}
                      />
                    </p>
                  </>
                )}
              </Formik>
            </section>
          </div>
        </div>
      </>
    )
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(AccountUpdateContainer))
)
