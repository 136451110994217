export const save = guest => {
  let setStorage = (key, value) => {
    value ? localStorage.setItem(key, value) : localStorage.setItem(key, '')
  }
  setStorage('guest.firstName', guest.firstName)
  setStorage('guest.lastName', guest.lastName)
  setStorage('guest.email', guest.email)
  setStorage('guest.country', guest.country)
  setStorage('guest.phone', guest.phone)
}

export const remove = () => {
  localStorage.removeItem('guest.firstName')
  localStorage.removeItem('guest.lastName')
  localStorage.removeItem('guest.email')
  localStorage.removeItem('guest.country')
  localStorage.removeItem('guest.phone')
}
