import React from 'react'
import CompleteIcon from '../../components/common/CompleteIcon'
import { Helmet } from 'react-helmet'
import ReactPixel from 'react-facebook-pixel'
import { PcHeader, SpHeader } from '../../components/top/Header'
import { connect } from 'react-redux'
import AuthComponent, {
  authMapStateToProps,
  authMapDispatchToProps,
} from '../../components/AuthComponent'

const head = {
  title: `Reservation | Chefle - Reservation`,
}

function mapStateToProps(state) {
  return Object.assign(authMapStateToProps(state), {})
}

function mapDispatchToProps(dispatch) {
  return Object.assign(authMapDispatchToProps(dispatch), {})
}

class SignUpCompleteContainer extends AuthComponent {
  componentDidMount() {
    super.componentDidMount()
    ReactPixel.init('502037183268067')
    ReactPixel.pageView()
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
  }

  render() {
    const { authUser } = this.props
    return (
      <div className="sign-up">
        <Helmet>
          <title>{head.title}</title>
          <meta property="og:title" content={head.title} />
          <meta property="og:description" content={head.description} />
          <meta name="description" content={head.description} />
          <meta name="robots" content="noindex" />
        </Helmet>
        <PcHeader
          authUser={authUser}
          logout={() =>
            this.props.postSignOut({ data: this.props.authUser.authInfo })
          }
        />
        <SpHeader
          authUser={authUser}
          logout={() =>
            this.props.postSignOut({ data: this.props.authUser.authInfo })
          }
        />
        <div className="reserve-complete">
          <div className="main-inner">
            <CompleteIcon />
            <p className="reserve-complete-txt">
              会員登録ありがとうございます。
            </p>
            <p className="reserve-complete-txt2">
              ご登録されたメールアドレスに
              <br className="sp" />
              仮登録完了メールを送信しました。
              <br />
              メールに記載の暫定パスワードにてログインいただきますと、
              <br className="sp" />
              会員登録が完了いたします。
              <br className="pc" />
              <br />
              <br />
              <br className="sp" />
              【登録完了メールが届かない場合】
              <br className="sp" />
              <br />
              <b>Gmailをご利用のお客様</b>
              <br />
              本登録用のメールが迷惑メールに振り分けられる場合がございます。受信フォルダの他、迷惑メールフォルダもご確認ください。
              <br />
              <br />
              <b>その他</b>
              <br />
              登録されたメールアドレスが入力間違いの場合がございます。
              <br />
              この場合、お手数ですが、もう
              一度新規登録を行っていただくきますようお願 い申し上げます。
            </p>
            <a href="/" className="reserve-complete-return-btn">
              トップページへ戻る
            </a>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpCompleteContainer)
