import { combineReducers } from 'redux'
import signUpForm from './signUpForm'
import signInForm from './signInForm'
import authUser from './authUser'
import resendEmailForm from './resendEmailForm'
import updatePassword from './updatePassword'
import updateAccount from './updateAccount'
import deleteAccount from './deleteAccount'
import restaurant from './restaurant'
import restaurantList from './restaurantList'
import immediateRestaurantList from './immediateRestaurantList'
import menuForm from './menuForm'
import pickupForm from './pickupForm'
import chefleMenuForm from './chefleMenuForm'
import menu from './plan'
import seatForm from './seatForm'
import suggestions from './suggestions'
import googlePlace from './googlePlace'
import areas from './areas'
import categories from './categories'
import reservation from './reservation'
import reservation2 from './reservation2'
import creditcard from './creditcard'
import creditcardPayment from './creditcardPayment'
import payment from './payment'
import campaign from './campaign'
import calendar from './calendar'
import { connectRouter } from 'connected-react-router'

const createRootReducer = history =>
  combineReducers({
    signUpForm: signUpForm,
    signInForm: signInForm,
    authUser: authUser,
    resendEmailForm: resendEmailForm,
    updatePassword: updatePassword,
    updateAccount: updateAccount,
    deleteAccount: deleteAccount,
    restaurant: restaurant,
    restaurantList: restaurantList,
    immediateRestaurantList: immediateRestaurantList,
    menuForm: menuForm,
    pickupForm: pickupForm,
    chefleMenuForm: chefleMenuForm,
    seatForm: seatForm,
    suggestions: suggestions,
    menu: menu,
    googlePlace: googlePlace,
    areas: areas,
    categories: categories,
    reservation: reservation,
    reservation2: reservation2,
    creditcard: creditcard,
    creditcardPayment: creditcardPayment,
    payment: payment,
    campaign: campaign,
    calendar: calendar,
    router: connectRouter(history),
  })

export default createRootReducer
