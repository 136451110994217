import React from 'react'
import { PcHeader, SpHeader } from '../../components/top/Header'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Helmet } from 'react-helmet'
import { postSignUp } from '../../actions/signUpForm'
import cleanDeep from 'clean-deep'
import TermsOfService from '../../components/common/TermsOfService'
import { history } from '../../configureStore'
import AuthComponent, {
  authMapStateToProps,
  authMapDispatchToProps,
} from '../../components/AuthComponent'
import { CommonBackButton } from '../../components/common/Buttons'

function mapStateToProps(state) {
  return Object.assign(authMapStateToProps(state), {
    signUpForm: state.signUpForm,
  })
}

function mapDispatchToProps(dispatch) {
  return Object.assign(authMapDispatchToProps(dispatch), {
    postSignUp: bindActionCreators(postSignUp, dispatch),
  })
}

class SignUpConfirmContainer extends AuthComponent {
  componentDidMount() {
    super.componentDidMount()
    this.props.signUpForm.errorMessage = ''
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
    if (this.props.signUpForm !== prevProps.signUpForm) {
      if (this.props.signUpForm.isCompleteSignUp) {
        history.push('/sign_up/complete')
      }
    }
  }

  normalizeData = () => {
    const { signUpForm } = this.props

    return cleanDeep(signUpForm)
  }
  handleSubmit = e => {
    e.preventDefault()
    const data = this.normalizeData()
    this.props.postSignUp({ data })
  }
  render() {
    const { authUser } = this.props
    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <section className="sign-up">
          <PcHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
          />
          <SpHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
          />

          <div className="menu-reserve-confirmation">
            <div className="reserve-confirmation-detail-container">
              <div className="reserve-confirmation-container">
                <div className="reserve-gest-details">
                  <h2 className="reserve-gest-details-title">
                    新規会員登録確認
                  </h2>
                  <div
                    style={{
                      position: 'absolute',
                      color: 'red',
                      fontSize: '0.8rem',
                    }}
                  >
                    {this.props.signUpForm.errorMessage !== ''
                      ? this.props.signUpForm.errorMessage
                      : ''}
                  </div>
                  <ul className="reserve-gest-details-list">
                    <li className="reserve-gest-details-email">
                      <p className="reserve-gest-details-head">
                        メールアドレス
                      </p>
                      <p className="reserve-gest-details-txt">
                        {this.props.signUpForm.ID}
                      </p>
                    </li>
                    <li className="reserve-gest-details-name">
                      <p className="reserve-gest-details-head">姓名</p>
                      <p className="reserve-gest-details-txt">
                        {this.props.signUpForm.sei +
                          ' ' +
                          this.props.signUpForm.mei}
                      </p>
                    </li>
                    <li className="reserve-gest-details-name">
                      <p className="reserve-gest-details-head">セイメイ</p>
                      <p className="reserve-gest-details-txt">
                        {' '}
                        {this.props.signUpForm.seikana +
                          ' ' +
                          this.props.signUpForm.meikana}
                      </p>
                    </li>
                    <li className="reserve-gest-details-name">
                      <p className="reserve-gest-details-head">生年月日</p>
                      <p className="reserve-gest-details-txt">
                        {this.props.signUpForm.birthday}
                      </p>
                    </li>

                    <li className="reserve-gest-details-phone">
                      <p className="reserve-gest-details-head">電話番号</p>
                      <p className="reserve-gest-details-txt">
                        {this.props.signUpForm.tel}
                      </p>
                    </li>
                    <li className="reserve-gest-details-phone">
                      <p className="reserve-gest-details-head">
                        お住まいの都道府県
                      </p>
                      <p className="reserve-gest-details-txt">
                        {this.props.signUpForm.prefectures}
                      </p>
                    </li>
                    <li className="reserve-gest-details-note">
                      <p className="reserve-gest-details-head">アレルギー</p>
                      <p className="reserve-gest-details-txt">
                        {this.props.signUpForm.allergy}
                      </p>
                    </li>
                    <li className="reserve-gest-details-note">
                      <p className="reserve-gest-details-head">
                        Chefleからのメルマガ受信
                      </p>
                      <p className="reserve-gest-details-txt">
                        {this.props.signUpForm.mailmagazine && '受け取る'}
                        {!this.props.signUpForm.mailmagazine && '受け取らない'}
                      </p>
                    </li>
                  </ul>
                  <div className="sign-up-message">
                    <p className="terms-of-service-btn">
                      利用規約は
                      <TermsOfService
                        text={'こちら'}
                        btnclassname={'policy-link'}
                      />
                    </p>
                    <p className="sign-up-message-content">
                      <b>登録する</b>
                      ボタンを押していただくと、
                      <br />
                      パスワードを記載したメールを送らせていただきます。
                      本登録用のメールを送らせていただきます。
                      <br />
                      本文中のURLより、会員登録の完了をお願いします。
                      <br />
                      <br />
                      <span>【Gmailをご利用のお客様】</span>
                      <br />
                      本登録用のメールが迷惑メールに振り
                      分けられる場合がございます。受信
                      フォルダの他、迷惑メールフォルダも ご確認ください。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            Style="display: flex; margin: 0;"
            className="sign-up-confirm-btn"
          >
            <CommonBackButton position={'left'} />
            <p className="reserve-submit-btn reserve-confirm-btn">
              <form onSubmit={this.handleSubmit}>
                <input
                  type="submit"
                  value="登録する"
                  onSubmit={this.handleSubmit}
                />
              </form>
            </p>
          </div>
        </section>
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpConfirmContainer)
