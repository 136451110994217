import React from 'react'
import { MenuIcon, SideMenu } from './menu/SideMenu'
import { Helmet } from 'react-helmet'

const Error = props => (
  <div className="notfound">
    <Helmet>
      <title>Error | Chefle</title>
      <meta property="og:title" content="Error | Chefle" />
      <meta name="robots" content="noindex" />
    </Helmet>
    <header>
      <MenuIcon />
    </header>
    <main>
      <div className="main-inner">
        <div className="notfound-img">
          {!!props.title ? (
            <h1
              style={{
                fontSize: '64px',
                color: '#FF5600',
              }}
            >
              {props.title}
            </h1>
          ) : (
            <img src="/assets/img/404img.png" alt="エラー" />
          )}
        </div>
        <p className="notfound-txt">
          {!!props.message
            ? props.message
            : 'The page you were looking for does not exist'}
        </p>
        <a href="/" className="notfound-return-btn">
          Go back
        </a>
      </div>
      <SideMenu />
    </main>
  </div>
)
export default Error
