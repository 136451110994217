import React from 'react'
import queryString from 'query-string'
import Measure from 'react-measure'
import { ApiKey } from '../../constants/googleApi'
import { Component } from 'react'

class GoogleMap extends Component {
  state = {
    width: 0,
    height: 0,
  }

  render() {
    const { width } = this.state
    return (
      <Measure
        bounds
        onResize={contentRect => {
          this.setState({ ...contentRect.bounds })
        }}
      >
        {({ measureRef }) => (
          <a
            href={
              'https://www.google.com/maps/search/?' +
              queryString.stringify({
                api: 1,
                query: `${this.props.geometry.location.lat}+${
                  this.props.geometry.location.lng
                }`,
                query_place_id: this.props.googlePlaceId,
                maptype: 'roadmap',
                key: ApiKey,
              })
            }
            rel="noopener noreferrer"
            target="_blank"
          >
            <div
              ref={measureRef}
              className="map-wrapper"
              style={{
                width: '100%',
                height: '150px',
              }}
            >
              <img
                src={
                  'https://maps.googleapis.com/maps/api/staticmap?' +
                  queryString.stringify({
                    markers:
                      this.props.geometry.location.lat +
                      ',' +
                      this.props.geometry.location.lng,
                    zoom: '15',
                    size: `${parseInt(width)}x150`,
                    maptype: 'roadmap',
                    key: ApiKey,
                  })
                }
                alt="map"
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            </div>
          </a>
        )}
      </Measure>
    )
  }
}

export default GoogleMap
