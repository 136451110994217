import React, { Component } from 'react'
import Modal from 'react-modal'

class CancelationPolicy extends Component {
  state = {
    modalIsOpen: false,
  }
  openModal() {
    this.setState({ modalIsOpen: true })
  }
  closeModal() {
    this.setState({ modalIsOpen: false })
  }
  changeModalState() {
    this.setState({ modalIsOpen: !this.state.modalIsOpen })
  }
  render() {
    return (
      <>
        <a
          href={() => false}
          onClick={this.openModal.bind(this)}
          className={this.props.btnclassname}
        >
          {this.props.text}
        </a>
        <Modal
          closeTimeoutMS={200}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal.bind(this)}
          style={customStyles}
          contentLabel="CancelationPolicy Modal"
        >
          <div className="modal">
            <h3 className="modal-title">Chefleポイントについて</h3>
            <div className="modal-content">
              <div className="sentence">
                1. 獲得ポイント以上の飲食が必要となります。
              </div>
              <div className="sentence">
                2.
                コース注文のないお子様に関しても、上記同様です。現地にて、ランチ時間500P以上
                / ディナー時間1000P以上のオーダーが必須です。
              </div>
              <div className="sentence">
                3.
                予約人数につきまして、予約確定前に確認をさせて頂く可能性がございます。
              </div>
            </div>
            {/* <h3 className="modal-title">
              Go To Eatキャンペーンをご利用いただく皆様へ
            </h3>
            <div className="modal-content">
              <div className="sentence">
                オンライン予約サイトを通じてポイントの付与をうけるには、
                <a
                  href="https://tinyurl.com/y4w5euq9"
                  target="blank"
                  Style="color: black;"
                >
                  サービス産業消費喚起事業(Go To Eat キャンペーン)給付金給付規程
                </a>
                (令和２年10月9日付け2食産第
                3487号)に基づく申請が必要となります。
                <br />
                <br />
                申請については、オンライン飲食予約事業者(株式会社Fesbase)が利用者の皆様に代わり申請することによりポイントの付与が可能となります。
                <br />
                <br />
                なお、サービス産業消費喚起事業(Go To Eat
                キャンペーン)給付金を申請するにあたり、下記の4項目に対して宣誓いただく必要があります(Go
                To Eat
                キャンペーンの利用者は、オンライン予約サイトを通じて飲食を予約することにより、以下の宣誓事項に同意するものとします。)。
                <br />
                <br />
              </div>
              <div className="sentence">
                1. 申請書類の内容に虚偽がないこと。
                <br />
                <br />
              </div>
              <div className="sentence">
                2.
                農林水産省大臣官房参事官(経理)又は大臣官房予算課経理調査官の委任した者が行う関係書類の提出指導、事情聴取等の調査に応じること。
                <br />
                <br />
              </div>
              <div className="sentence">
                3. 不正受給が判明した場合には、「サービス産業消費喚起事業(Go To
                Eat
                キャンペーン)給付金給付規程」に従い給付金の返還等を行うこと。
                <br />
                <br />
              </div>
              <div className="sentence">4. 「給付金給付規程」に従うこと。</div>
            </div> */}

            <h3 className="modal-title">キャンセルポリシー</h3>
            <div className="modal-content">
              <h2 Style="color: #ff5800;">【在宅レストラン】</h2>
              <div className="sentence">
                1.
                ユーザーは、ご予約成立後であっても、ご予約キャンセルすることは可能です。
              </div>
              <div className="sentence">
                2.
                ご予約成立後に、キャンセルする場合、当社は10%のサービス利用手数料を徴収いたします。
              </div>
              <div className="sentence">
                3.
                ご予約成立後にシェフの変更をしたい場合、再度のお申込み手続きをお願いいたしております。その際、項の規定に基づいて、サービス手数料の徴収をいたします。
              </div>
              <div className="sentence">
                4. キャンセルポリシーは各シェフによって設定されております。
              </div>
              <div className="sentence">
                5.
                キャンセル料発生起算日は各シェフ所属の営業稼働日に基づき判断いたします。
              </div>
              <div className="sentence">
                6.
                ご予約成立後の変更（人数増減、日程変更など）やキャンセルは各リクエスト一回のみ可能です。注意点として本ケースがあげられます。ご予約変更後、何らかの理由によりキャンセルしなければならない場合、キャンセル料の支払い義務が課せられます。
              </div>
              <div className="sentence">
                7.
                当該キャンセル料の取り扱いについては、その理由が天災地変による場合でも、徴収の有無を変えうることはございません。
              </div>
              <div className="sentence">
                8.
                本件取引において、指定された日時・場所にシェフが訪問した際に、ユーザーが不在であった場合は、当日のキャンセルとみなします。
              </div>
              <div className="sentence">
                9.シェフ又はシェフから依頼を受けた当社は、以下のユーザーの責めに帰すべからざる事由により、本件取引をキャンセルすることがあります。
                <br />
                (1)体調不良などの理由によりシェフの訪問が不可能であるとシェフ自身が判断する場合
                <br />
                (2)その他シェフの本件業務遂行が不可能又は不適切であると当社が判断する場合
              </div>
              <div className="sentence">
                10.
                当社が前項の規定に基づいて、本件取引をキャンセルしたときは、遂行されなかった本件業務に係る本件取引の料金について当社はユーザーに全額返金します。
              </div>
              <div className="sentence">
                11.
                当社は、ユーザーの責めに帰すべき以下のいずれかの事由により、本件取引をキャンセルすることがあります。
                <br />
                (1)ユーザーがサービス利用に関し、法令の規定、公の秩序若しくは善良の風俗に反する行為をするおそれがあると認められるとき、又は同行為をしたと認められるとき。
                <br />
                (2)ユーザーが、次のイからハに該当すると認められるとき。
                <br />
                　　イ）暴力団、暴力団員、暴力団準構成員又は暴力団関係者その他の反社会勢力。
                <br />
                　　ロ）暴力団又は暴力団員が事業活動を支配する法人その他の団体であるとき
                <br />
                　　ハ）法人でその役員のうちに暴力団員に該当する者があるもの
                <br />
                (3)ユーザーが著しい迷惑を及ぼす言動をしたとき。
                <br />
                (4)ユーザーが、伝染病であると明らかに認められるとき。
                <br />
                (5)ユーザーがシェフに対し、暴力、脅迫、恐喝、威圧的な不当要求を行い、あるいは合理的範囲を超える負担を要求したとき、又はかつて同様な行為を行ったと認められるとき。
                <br />
                (6)ユーザー様が泥酔し、又は言動が著しく異常で本サービスの提供に支障があるとき。
                <br />
                (7)その他、本サービスの利用が不適当であると当社が判断したとき。
                <br />
              </div>
              <div className="sentence">
                12.
                前項の規定に基づいて本件取引がキャンセルされた場合、本件業務の提供有無にかかわらず本件料金は返金されません。
              </div>
              <br />
              <br />
              <h2 Style="color: #ff5800;">【店頭受取】</h2>
              <div className="sentence">
                1.
                ユーザーは、ご予約成立後に、ご予約キャンセルをすることはできません。
              </div>
              <div className="sentence">
                2.
                ご予約成立後に、キャンセルする場合、当社サービス手数料5％も含め、一切の返金はございません。
              </div>
              <div className="sentence">
                3.
                ご予約成立後に内容変更をしたい場合、差額分の返金はございません。個数の増加分は、再度のお申込みをお願い致します。
              </div>
              <div className="sentence">
                4.シェフ又はシェフから依頼を受けた当社は、以下のユーザーの責めに帰すべからざる事由により、本件取引をキャンセルすることがあります。
                <br />
                (1)体調不良などの理由によりシェフ自身が店舗休業と判断する場合　
                <br />
                (2)その他シェフの本件業務遂行が不可能又は不適切であると当社が判断する場合
              </div>
              <div className="sentence">
                5.
                当社が前項の規定に基づいて、本件取引をキャンセルしたときは、遂行されなかった本件業務に係る本件取引の料金について当社はユーザーに全額返金します。
              </div>
              <br />
              <br />
              <h2 Style="color: #ff5800;">【来店予約】</h2>
              <div className="sentence">
                1.
                ユーザーは、ご予約成立後であっても、ご予約キャンセルすることは可能です。
              </div>
              <div className="sentence">
                2. キャンセルポリシーは各シェフによって設定されております。
              </div>
              <div className="sentence">
                3.キャンセル料発生起算日は各レストランの営業稼働日に基づき判断いたします。
              </div>
              <div className="sentence">
                4.
                ご予約成立後の変更（人数増減、日程変更など）やキャンセルは各リクエスト一回のみ可能です。但し、ご予約確定時にキャンセル料100%が発生するレストランを除きます。
              </div>
              <div className="sentence">
                5.当該キャンセル料の取り扱いについては、その理由が天災地変による場合でも、徴収の有無を変えうることはございません。
              </div>
              <div className="sentence">
                6.シェフ又はシェフから依頼を受けた当社は、以下のユーザーの責めに帰すべからざる事由により、本件取引をキャンセルすることがあります。
                <br />
                (1)体調不良などの理由によりシェフ自身が店舗休業と判断する場合
                <br />
                (2)その他シェフの本件業務遂行が不可能又は不適切であると当社が判断する場合
              </div>
              <div className="sentence">
                7.
                当社が前項の規定に基づいて、本件取引をキャンセルしたときは、遂行されなかった本件業務に係る本件取引の料金について当社はユーザーに全額返金します。
              </div>
              <div className="sentence">
                8.「在宅レストラン」11項以降の内容に準ずることと致します。
              </div>
            </div>

            <h3 className="modal-title">ユーザーガイド</h3>
            <div className="modal-content">
              <div className="sentence">
                <p>
                  <strong>【シェフルサービス手数料について】</strong>
                </p>
                1. 当社は、サービス手数料として5~10％を加算しております。
                <br />
                2.
                サービス手数料は支払い金額の中に含まれております。その他、食材費、出張交通費、出張派遣費も例外ではございません。
                <br />
                <br />
              </div>
              <div className="sentence">
                <p>
                  <strong>【支払い方法について】</strong>
                </p>
                1. クレジットカード（Visa, MasterCard, American Express, JCB and
                Diners Card）のお支払いのみ対応してございます。
                <br />
                2.
                申し込み時に、クレジットカードの入力をしていただきます。ご予約成立と同時に決済が実行され、それ以前は与信の状態です。ご予約不成立にも関わらず、カード会社より請求がかかった場合、クレジットカード会社にお問い合わせください。
                <br />
                <br />
              </div>
              <br />
              <div className="sentence">
                <p>
                  <strong>【ご予約成立の流れ】</strong>
                </p>
                <h2 Style="color: #ff5800;">※在宅レストランの場合</h2>
                STEP1：リクエスト送信
                <br />
                <br />
                STEP2：訪問先設備等のご確認依頼
                自動メール返信にて、アンケートをお送りします。
                <br />
                <br />
                STEP3：当社コンシェルジュにてレストランとの予約確定連絡
                <br />
                STEP2をご入力完了後、24時間以内のご対応を厳守致します。
                <br />
                ※レストランが休業の場合、例外となりますことをご了承ください。
                <br />
                <br />
                ご予約が成立した場合や、追加情報が必要な場合、全てメールにてご案内申し上げます。
                弊社サポートアドレス（ chefle_support@fesbase.co.jp
                ）からのご連絡が迷惑フォルダに振り分けとならないよう、ご設定をお願い致します。
                <br />
                <br />
                STEP4：ご成立
                <br />
                (1)ご予約日の前日17:00頃リマインダーメールをお送り致します。
                <br />
                (2)ご予約日の翌日10:00頃アンケートメールをお送り致します。
              </div>
              <br />
              <div className="sentence">
                <h2 Style="color: #ff5800;">※店頭受取サービスの場合</h2>
                STEP1：リクエスト送信
                <br />
                <br />
                STEP2：当社コンシェルジュにてレストランとの予約確認連絡
                <br />
                ※24時間以内のご対応を厳守しておりますが、レストランが休業の場合、例外となりますことをご了承ください。
                <br />
                <br />
                STEP3：ご成立　
                <br />
                ご予約日前日17:00頃リマインダーメールをお送り致します。
                <br />
                <br />
                STEP4：ゲスト様ご自身にて店頭受取
                <br />
              </div>
              <br />
              <div className="sentence">
                <h2 Style="color: #ff5800;">※来店予約サービスの場合</h2>
                STEP1：リクエスト送信
                <br />
                <br />
                STEP2：当社コンシェルジュにてレストランとの予約確認連絡
                <br />
                ※24時間以内のご対応を厳守しておりますが、レストランが休業の場合、例外となりますことをご了承ください。
                <br />
                <br />
                STEP3：リクエスト確定連絡 / 事前決済依頼
                <br />
                メール受信後2日以内にクレジットカードにて事前決済をお願いいたします。
                <br />
                <br />
                STEP4：ご成立
                <br />
                (1)ご予約日の前日17:00頃リマインダーメールをお送り致します。
                <br />
                (2)ご予約日の翌日10:00頃アンケートメールをお送り致します。
                <br />
              </div>
            </div>
          </div>
        </Modal>
      </>
    )
  }
}

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    'z-index': '1000',
  },
  content: {
    margin: 'auto',
    'max-width': '900px',
    'border-radius': '18px',
    'z-index': '1000',
  },
}

export default CancelationPolicy
