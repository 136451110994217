function getApiOrigin() {
  switch (process.env.REACT_APP_ENV) {
    case 'production': {
      return 'https://api.jpneazy.com/v1'
    }
    case 'development': {
      return 'https://dev-api.jpneazy.com/v1'
    }
    default: {
      // モックサーバ
      return 'http://localhost:8000/v1'
    }
  }
}

const ApiOrigin = getApiOrigin()
export default ApiOrigin
