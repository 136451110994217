import { UPDATE_SEAT_FORM, CONFIRM_SEAT_FORM } from '../actions'
import { history } from '../configureStore'
import { validateGuest, validateSeatRestaurant } from './logic/validator'
import { save as saveGuest, remove as removeGuest } from './logic/guest'

const validateSeatForm = seatForm => {
  let errors = {}

  !seatForm.date && (errors.date = 'date is required')
  !seatForm.time && (errors.time = 'time is required')
  !seatForm.adult && (errors.adult = 'adult party size is required')

  let guestErrors = validateGuest(seatForm.guest)
  Object.keys(guestErrors).length && (errors.guest = guestErrors)

  let restaurantErroes = validateSeatRestaurant(seatForm.restaurant)
  Object.keys(restaurantErroes).length && (errors.restaurant = restaurantErroes)

  return errors
}

const update = action => {
  const seatForm = action.payload

  if (Object.keys(seatForm.errors).length) {
    let errors = validateSeatForm(seatForm)
    return { ...action, payload: { ...action.payload, errors: errors } }
  }
  return action
}

const confirm = action => {
  const seatForm = action.payload
  let errors = validateSeatForm(seatForm)

  if (!!Object.keys(errors).length) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    return { ...action, payload: { ...action.payload, errors: errors } }
  }

  action.meta && action.meta.isSaveUser
    ? saveGuest(seatForm.guest)
    : removeGuest()

  history.push('/restaurants/reservation/confirm/')
  return action
}

export default store => next => action => {
  if (action.type === UPDATE_SEAT_FORM) {
    return next(update(action))
  }

  if (action.type === CONFIRM_SEAT_FORM) {
    return next(confirm(action))
  }
  return next(action)
}
