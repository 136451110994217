import { UPDATE_SIGN_UP_FORM, CONFIRM_SIGN_UP_FORM } from '../actions'
import { history } from '../configureStore'

function validateBirthday(str) {
  //null or 8文字でない or 数値でない場合はfalse
  if (str == null || str.length !== 8 || isNaN(str)) {
    return false
  }
  //年,月,日を取得する
  var y = parseInt(str.substr(0, 4))
  var m = parseInt(str.substr(4, 2)) - 1 //月は0～11で指定するため-1しています。
  var d = parseInt(str.substr(6, 2))
  var dt = new Date(y, m, d)
  //判定する
  return y === dt.getFullYear() && m === dt.getMonth() && d === dt.getDate()
}

const validateSignUpForm = signUpForm => {
  let errors = {}

  // TODO: formikの実装だとフォーム戻ってきた時のバリデーションが変なので、JSで対応した
  !signUpForm.ID && (errors.ID = 'IDが必要です')
  !signUpForm.ID.match(/.+@.+\..+/) && (errors.ID = '形式がemailではありません')
  !signUpForm.sei && (errors.sei = '姓が必要です')
  signUpForm.sei.match(/[0-9]/) && (errors.sei = '姓には数値が入力できません')
  !signUpForm.mei && (errors.mei = '名が必要です')
  signUpForm.mei.match(/[0-9]/) && (errors.mei = '名には数値が入力できません')
  !signUpForm.seikana && (errors.seikana = 'セイ（フリガナ）が必要です')
  !signUpForm.seikana.match(/^[ァ-ヶー]+$/) &&
    (errors.seikana = '全角カタカナを入れてください')
  !signUpForm.meikana && (errors.meikana = 'メイ（フリガナ）が必要です')
  !signUpForm.meikana.match(/^[ァ-ヶー]+$/) &&
    (errors.meikana = '全角カタカナを入れてください')
  !signUpForm.birthday && (errors.birthday = '生年月日が必要です')
  !validateBirthday(signUpForm.birthday) &&
    (errors.birthday = 'YYYYMMDD形式で入れてください')
  !signUpForm.tel && (errors.tel = '電話番号が必要です')
  !signUpForm.tel.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) &&
    (errors.tel = '形式が電話番号ではありません')

  // セレクトボックスやチェックボックスのバリデーション実装
  !signUpForm.prefectures &&
    (errors.prefectures = 'お住まいの都道府県が必要です')
  !signUpForm.signUpTermsOfService &&
    (errors.signUpTermsOfService = '利用規約に同意するのチェックが必要です')

  return errors
}

const validateSignUpUpdateForm = signUpForm => {
  if (signUpForm.ID !== '') {
    signUpForm.errors.ID = ''
    !signUpForm.ID.match(/.+@.+\..+/) &&
      (signUpForm.errors.ID = '形式がemailではありません')
  }
  if (signUpForm.sei !== '') {
    signUpForm.errors.sei = ''
    signUpForm.sei.match(/[0-9]/) &&
      (signUpForm.errors.sei = '姓には数値が入力できません')
  }
  if (signUpForm.mei !== '') {
    signUpForm.errors.mei = ''
    signUpForm.mei.match(/[0-9]/) &&
      (signUpForm.errors.mei = '名には数値が入力できません')
  }

  if (signUpForm.seikana !== '') {
    signUpForm.errors.seikana = ''
    !signUpForm.seikana.match(/^[ァ-ヶー]+$/) &&
      (signUpForm.errors.seikana = '全角カタカナを入れてください')
  }

  if (signUpForm.meikana !== '') {
    signUpForm.errors.meikana = ''
    !signUpForm.meikana.match(/^[ァ-ヶー]+$/) &&
      (signUpForm.errors.meikana = '全角カタカナを入れてください')
  }

  if (signUpForm.birthday !== '') {
    signUpForm.errors.birthday = ''
    !validateBirthday(signUpForm.birthday) &&
      (signUpForm.errors.birthday = 'YYYYMMDD形式で入れてください')
  }

  if (signUpForm.tel !== '') {
    signUpForm.errors.tel = ''
    !signUpForm.tel.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) &&
      (signUpForm.errors.tel = '形式が電話番号ではありません')
  }

  if (signUpForm.prefectures !== '') {
    signUpForm.errors.prefectures = ''
    !signUpForm.prefectures &&
      (signUpForm.errors.prefectures = 'お住まいの都道府県が必要です')
  }

  if (signUpForm.signUpTermsOfService) {
    signUpForm.errors.signUpTermsOfService = ''
  }

  return signUpForm.errors
}

const update = action => {
  const signUpForm = action.payload
  if (signUpForm.errors) {
    let errors = validateSignUpUpdateForm(signUpForm)
    return { ...action, payload: { ...action.payload, errors: errors } }
  }
  return action
}

const confirm = action => {
  const signUpForm = action.payload
  let errors = validateSignUpForm(signUpForm)
  if (!!Object.keys(errors).length) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    return { ...action, payload: { ...action.payload, errors: errors } }
  }
  history.push('/sign_up/confirm')
  return action
}

export default store => next => action => {
  if (action.type === UPDATE_SIGN_UP_FORM) {
    return next(update(action))
  }
  if (action.type === CONFIRM_SIGN_UP_FORM) {
    return next(confirm(action))
  }
  return next(action)
}
