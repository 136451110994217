import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AuthComponent, {
  authMapStateToProps,
  authMapDispatchToProps,
} from '../../../components/AuthComponent'
import {
  updateAccountForm,
  postUpdateAccount,
} from '../../../actions/updateAccount'
import { fetchReservation } from '../../../actions/fetchReservation'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Formik } from 'formik'
import { withRouter } from 'react-router'
import { PcHeader, SpHeader } from '../../../components/top/Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWarning } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons'
import { faPerson } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-modal'
import DateHelper from "../../../helpers/DateHelper";

function mapStateToProps(state) {
  return Object.assign(authMapStateToProps(state), {
    updateAccount: state.updateAccount,
    reservation2: state.reservation2,
  })
}

function mapDispatchToProps(dispatch) {
  return Object.assign(authMapDispatchToProps(dispatch), {
    updateAccountForm: bindActionCreators(updateAccountForm, dispatch),
    postUpdateAccount: bindActionCreators(postUpdateAccount, dispatch),
    fetchReservation: bindActionCreators(fetchReservation, dispatch),
  })
}

class Reservation extends AuthComponent {
  constructor(props) {
    super(props)
    this.state = {
      component: 'ReservationList',
      detailNumber: 0,
      modalChatIsOpen: false,
    }
  }
  componentDidMount() {
    super.componentDidMount()
    this.props.fetchReservation({
      userNo: this.props.authUser.authInfo.user_no,
      token: this.props.authUser.authInfo.token,
    })
  }
  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
  }

  render() {
    const { authUser, reservation2 } = this.props

    // APIからのステータスコード
    // /** 未対応 */
    // const NOT_YET = 0

    // /** 受付 */
    // const ACCEPT = 1

    // /** ゲスト確認中 */
    // const GUEST_CONFIRMING = 2

    // /** 予約待機 */
    // const WAITING_FOR_RESERVATION = 3

    // /** 予約済み */
    // const RESERVED = 4

    // /** 入金待ち */
    // const WAITING_FOR_PAYMENT = 5

    // /** 決済済み */
    // const PAID = 6

    // /** 店舗支払い済み */
    // const STORE_PAID = 7

    // /** キャンセル */
    // const CANCEL = 9

    const getStatus = status => {
      switch (status) {
        case 0:
          return '対応中'
        case 1:
          return '予約待機'
        case 2:
          return 'ゲスト様ご確認中'
        case 3:
          return '対応中'
        case 5:
          return '入金待ち'
        case 6:
        case 7:
          return '予約確定'
        case 9:
          return 'キャンセル'
        default:
          return '-'
      }
    }

    const getNoticeMark = data => {
      const status = getStatus(data)
      if (status === '入金待ち' || status === 'ゲスト様ご確認中') {
        return (
          <FontAwesomeIcon
            className="chefle-reservation__warning"
            icon={faWarning}
          />
        )
      }
    }

    const isPast = iso8601 => {
      return new Date().toISOString() > iso8601
    }

    const getPath = item => {
      let path = ''
      if (item.reservationType === 'chefle-menu') {
        path = '/menu/restaurants/' + item.restaurant.id
      }
      if (item.reservationType === 'chefle') {
        path = '/restaurants/' + item.restaurant.id
      }
      if (item.reservationType === 'pickup') {
        path = '/pickup/restaurants/' + item.restaurant.id
      }
      return path
    }

    const modalSettingsPC = {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        zIndex: '1000',
      },
      content: {
        margin: 'auto',
        maxWidth: '400px',
        maxHeight: '400px',
        borderRadius: '18px',
        zIndex: '1000',
      },
    }

    const ReservationList = () => {
      return (
        <section className="setting-form">
          <Formik enableReinitialize={true}>
            <>
              <div className="reserve-form-guest-ditails reserve-form-container chefle-reservation">
                <h3 className="block-title" Style="margin-bottom: 30px;">
                  今後のご予約
                </h3>
                <table>
                  <tr className="chefle-reservation__head">
                    <th className="chefle-reservation__th" width="120">
                      レストラン名
                    </th>
                    <th width="150">予約日時</th>
                    <th width="120">
                      <a
                        href="#"
                        onClick={() =>
                          this.setState({
                            modalChatIsOpen: true,
                          })
                        }
                        className="chefle-reservation__link"
                        Style="color: #808080;"
                      >
                        予約の状態
                      </a>
                    </th>
                    <th width="100" />
                  </tr>
                  {reservation2.data.items.map(
                    item =>
                      !isPast(item.datetime) && (
                        <tr>
                          <td Style="padding-top: 40px;">
                            <Link
                              className="chefle-reservation__link"
                              to={getPath(item)}
                            >
                              {item.restaurant.name}
                            </Link>
                          </td>
                          <td Style="text-align: center;">
                            {DateHelper.formatDateTimeForDisplay(item.datetime)}
                          </td>
                          <td Style="text-align: center;">
                            {getStatus(item.status)}
                            {getNoticeMark(item.status)}
                          </td>
                          <td Style="text-align: center;">
                            <Link
                              className="chefle-reservation__link"
                              to="#"
                              onClick={() =>
                                this.setState({
                                  component: 'detail',
                                  detailNumber: item.id,
                                })
                              }
                            >
                              詳細へ
                            </Link>
                          </td>
                        </tr>
                      )
                  )}
                </table>
              </div>
              <div className="reserve-form-guest-ditails reserve-form-container chefle-reservation__past">
                <h3 className="block-title" Style="margin-bottom: 30px;">
                  過去のご予約
                </h3>
                <table>
                  <tr className="chefle-reservation__head">
                    <th className="chefle-reservation__th" width="120">
                      レストラン名
                    </th>
                    <th width="130">予約日時</th>
                    <th width="120" />
                  </tr>
                  {reservation2.data.items.map(
                    item =>
                      getStatus(item.status) === '予約確定' &&
                      isPast(item.datetime) && (
                        <tr>
                          <td
                            Style="padding-top: 40px; word-break: break-all;"
                            width="20"
                          >
                            <Link
                              className="chefle-reservation__link"
                              to={getPath(item)}
                            >
                              {item.restaurant.name}
                            </Link>
                          </td>
                          <td Style="text-align: center;">
                            {DateHelper.formatDateTimeForDisplay(item.datetime)}
                          </td>
                          <td Style="text-align: center;">
                            <Link
                              className="chefle-reservation__link"
                              to={'/menu/restaurants/' + item.restaurant.id}
                            >
                              もう一度予約
                            </Link>
                          </td>
                        </tr>
                      )
                  )}
                </table>
              </div>
              <Modal
                closeTimeoutMS={200}
                isOpen={this.state.modalChatIsOpen}
                onRequestClose={() =>
                  this.setState({
                    modalChatIsOpen: false,
                  })
                }
                style={modalSettingsPC}
                contentLabel="CancelationPolicy Modal"
              >
                <div class="chat-modal">
                  <button
                    className="chat-modal-close-button"
                    Style="top: -12px;"
                    onClick={() =>
                      this.setState({
                        modalChatIsOpen: false,
                      })
                    }
                  >
                    <img
                      src="/assets/img/close_icon_01.svg"
                      alt="close-icon"
                      Style="width: 20px"
                    />
                  </button>
                  <div>
                    <p className="chefle-reservation__modal-head">
                      [予約の状態]
                    </p>
                    <div>
                      <div className="chefle-reservation__modal-content">
                        <div className="chefle-reservation__modal-content-left">
                          キャンセル
                        </div>
                        <div Style="margin-left: 50px;">
                          ご予約はキャンセルされました。
                        </div>
                      </div>
                      <div className="chefle-reservation__modal-content">
                        <div className="chefle-reservation__modal-content-left">
                          予約確定
                        </div>
                        <div Style="margin-left: 65px;">
                          予約が確定しました。
                        </div>
                      </div>
                      <div className="chefle-reservation__modal-content">
                        <div className="chefle-reservation__modal-content-left">
                          入金待ち
                        </div>
                        <div Style="margin-left: 65px;">
                          予約確定のため、ゲスト様のご決済をお待ちしております。
                        </div>
                      </div>
                      <div className="chefle-reservation__modal-content">
                        <div className="chefle-reservation__modal-content-left">
                          予約待機
                        </div>
                        <div Style="margin-left: 65px;">
                          レストランへ予約の確認中です。今しばらくお待ち下さい。
                        </div>
                      </div>
                      <div className="chefle-reservation__modal-content">
                        <div className="chefle-reservation__modal-content-left">
                          ゲスト様
                          <br />
                          ご確認中
                        </div>
                        <div Style="margin-left: 65px;">
                          ゲスト様に予約のためのご質問を返送しております。メールにてご確認ください。
                        </div>
                      </div>
                      <div className="chefle-reservation__modal-content">
                        <div className="chefle-reservation__modal-content-left">
                          対応中
                        </div>
                        <div Style="margin-left: 80px;">
                          ご予約リクエストを受付けました。予約確定まで今しばらくお待ち下さい。
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </>
          </Formik>
        </section>
      )
    }

    const ReservationDetail = () => {
      const data = reservation2.data.items.find(
        item => item.id === this.state.detailNumber
      )

      return (
        data && (
          <section className="setting-form">
            <Formik enableReinitialize={true}>
              <>
                <div
                  className="reserve-form-guest-ditails reserve-form-container"
                  Style="margin-bottom: 50px; position: relative;"
                >
                  <h3 className="block-title">ご予約詳細画面</h3>
                  <Link
                    className="chefle-reservation__icon-times"
                    to="#"
                    onClick={() =>
                      this.setState({
                        component: 'ReservationList',
                      })
                    }
                  >
                    <FontAwesomeIcon
                      className="chefle-reservation__icon-phone"
                      icon={faTimes}
                    />
                  </Link>
                  <div className="chefle-reservation__detail">
                    <div className="chefle-reservation__detail-head">
                      <div className="chefle-reservation__detail-number">
                        <div>予約番号：</div>
                        <div>{data.id}</div>
                      </div>
                      <div className="chefle-reservation__detail-status">
                        <div>予約の状態：</div>
                        <div>
                          {getStatus(data.status)}
                          {getNoticeMark(data.status)}
                        </div>
                      </div>
                    </div>
                    <div className="chefle-reservation__information">
                      <div>
                        <img
                          className="chefle-reservation__image"
                          src={data.restaurant.images[0]}
                          alt=""
                        />
                      </div>
                      <div>
                        <div>
                          <a
                            className="chefle-reservation__detail-restaurant-tel"
                            href={`tel:${data.restaurant.tel}`}
                          >
                            <FontAwesomeIcon
                              className="chefle-reservation__icon-phone"
                              icon={faPhone}
                            />
                            {data.restaurant.tel}
                          </a>
                        </div>
                        <div className="chefle-reservation__detail-restaurant-name">
                          {data.restaurant.name}
                        </div>
                        <div className="chefle-reservation__detail-restaurant-time">
                          <FontAwesomeIcon
                            className="chefle-reservation__icon-calendar"
                            icon={faCalendarDay}
                          />
                          {DateHelper.formatDateTimeForDisplay(data.datetime)}
                        </div>
                        <div className="chefle-reservation__detail-family">
                          <FontAwesomeIcon
                            className="chefle-reservation__icon-person"
                            icon={faPerson}
                          />
                          総人数　
                          {data.party.adult +
                            data.party.child +
                            data.party.baby}
                          名 (大人{data.party.adult}名、 お子様
                          {data.party.child + data.party.baby}名)
                        </div>
                      </div>
                    </div>
                    <div className="chefle-reservation__detail-order">
                      <div className="chefle-reservation__detail-order-left">
                        予約詳細
                      </div>
                      <div className="chefle-reservation__detail-order-right">
                        {data.orderDetail}
                      </div>
                    </div>
                    <div className="chefle-reservation__detail-price">
                      <div className="chefle-reservation__detail-price-left">
                        料金
                      </div>
                      <div className="chefle-reservation__detail-price-right">
                        {Number(data.price).toLocaleString()}円
                      </div>
                    </div>
                    <div className="chefle-reservation__detail-guest">
                      <div className="chefle-reservation__detail-guest-left">
                        ご予約者
                      </div>
                      <div className="chefle-reservation__detail-guest-right">
                        {data.guest.name}様
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </Formik>
          </section>
        )
      )
    }

    return (
      <>
        <div className="account-setting top-page">
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
          <PcHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
          />
          <SpHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
          />
          <div className="account-setting-contents">
            <section className="setting-menu">
              <ul>
                <li Style="color: #ff5800;">
                  <Link to="/setting/reservation">ご予約一覧</Link>
                </li>
                <li>
                  <Link to="/setting/creditcard">お支払い情報</Link>
                </li>
                <li>
                  <Link to="/setting/user_info">アカウント設定</Link>
                </li>
                <li>
                  <Link to="/setting/password">パスワード変更</Link>
                </li>
                <li>
                  <Link to="/setting/delete">退会</Link>
                </li>
              </ul>
            </section>
            {this.state.component === 'ReservationList'
              ? ReservationList()
              : ReservationDetail()}
          </div>
        </div>
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Reservation))
