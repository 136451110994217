import React from 'react'

const Notification = () => (
  <div className="notice">
    <div className="header-inner" id="modal">
      <p className="notification-title">お知らせ</p>
      <p className="notification-txt">
      コンシェルジュサポートは10時〜18時となります。（土曜午前、日曜は定休日となります。）ご理解のほどお願い申し上げます。
      <br />
      ご予約時に500P/人（ランチ)、1000P/人（ディナー）のポイントキャンペーン中です。
      </p>
    </div>
  </div>
)

export default Notification
