import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { fetchReservation } from '../actions/reservation'
import { fetchPaymentForm } from '../actions/payment'
import Error from '../components/Error'
import { Helmet } from 'react-helmet'

function mapStateToProps(state) {
  return {
    reservation: state.reservation,
    payment: state.payment,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetch: bindActionCreators(fetchReservation, dispatch),
    fetchPaymentForm: bindActionCreators(fetchPaymentForm, dispatch),
  }
}

class PaymentContainer extends Component {
  componentDidMount() {
    const reservationId = this.props.match.params.reservationId
    if (reservationId) {
      this.props.fetch({ reservationId })
    }
  }

  render() {
    if (this.props.reservation.loadingFlag) {
      return <></>
    }
    const reservation = this.props.reservation.data
    if (!reservation || !reservation.id) {
      return <Error title={'Error'} message={'Invalid reserve id'} />
    }
    if (!reservation.isProvisional) {
      if (reservation.status < 5) {
        return (
          <Error
            title={'Error'}
            message={'It is not yet available for payment'}
          />
        )
      }
      if (reservation.status > 5 && reservation.status < 9) {
        return (
          <Error
            title={'Payment Completed'}
            message={'Your payment has already been completed'}
          />
        )
      }
    }
    const head = {
      title: 'Payment | Chefle',
      description: '',
    }
    return (
      <body>
        <Helmet>
          <title>{head.title}</title>
          <meta property="og:title" content={head.title} />
          <meta property="og:description" content={head.description} />
          <meta name="description" content={head.description} />
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className="payment-selection">
          <header>
            <h1 Style="display: flex; justify-content: center;">
              <img
                src="/assets/img/chefle/chefle_logo_o.png"
                alt="ロゴ"
                className="JPNEAZY"
                Style="width: 100px; height: 46px;"
              />
            </h1>
          </header>
          <main>
            <p className="thanks-txt">Thank you for using Chefle</p>
            <div className="reserve-form-main-container">
              {/* <p className="payment-txt1">Payment details</p> */}
              <p className="payment-txt2">{reservation.restaurantName}</p>
              <div className="reserve-form-right-container">
                <div className="reservation-fee-01 reserve-form-container">
                  <h3 className="reservation-fee-title">Payment details</h3>
                  {/* <p className="reservation-fee-num">6</p>
                    <div className="reservation-fee-box_in">
                      <p className="reservation-fee-txt">
                        Seat reservation fee depends on party size.
                      </p>
                      <div className="reservation-fee-box_in_in">
                        <div className="reservation-fee-box_in_in_l">
                          1-5 person(s)<span>￥800</span>
                        </div>
                        <div className="reservation-fee-box_in_in_r">
                          6+persons<span>￥300/person</span>
                        </div>
                      </div>
                    </div> */}
                  {/* <p className="reservation-fee-notes">
                      <span>
                        Reservation requests are free. An Invoice is sent once
                        the reservation has been made. Reservation is confirmed
                        once payment has been received.
                      </span>
                      <br />
                      Same-day reservations are not available.
                    </p> */}
                  <div className="reservation-fee-line-box">
                    <hr className="reservation-fee-line" />
                  </div>
                  <div className="reservation-fee-type">
                    <p className="reservation-fee-conditions">
                      {reservation.orderDetail}
                    </p>
                    <div className="reservation-fee-price">
                      Total：
                      <span>￥{reservation.price}</span>
                    </div>
                  </div>
                </div>
                <div className="reserve-form-submit-container">
                  {reservation.isProvisional && (
                    <div className="reserve-form-notification">
                      <p className="reserve-form-notification-title">
                        注意事項
                      </p>
                      <p className="payment-notification-txt">
                        ご予約確定時に、決済が発生します。ご予約確定に至らなかった場合、決済されることはございません。
                        稀に、与信審査のため、ご請求が発生したように明細表示させることがございます。30日以上同じ状態が続く場合は、クレジットカード会社にお問い合わせください。
                      </p>
                    </div>
                  )}
                  <div className="reserve-form-cancelpolicy">
                    <p className="reserve-form-cancelpolicy-title">
                      キャンセルポリシー
                    </p>
                    <p className="payment-selection-cancelpolicy-txt">
                      キャンセルポリシーは各レストランにより異なりますので、お客様ご自身にてご確認をお願いいたします。
                      ご予約確定前であれば、キャンセルや変更を無料で行います。ご予約確定後の変更は1度のみ承ることが可能です。サービス利用料の返金はございません。
                      また、キャンセルポリシーに準じ、返金手配をいたします。詳しくは、ウェブページをご確認ください。
                    </p>
                  </div>
                  <div className="paymethod-box">
                    <p className="paymethod-txt">決済方法</p>
                    <div className="paymethod-radio">
                      <div>
                        <input
                          type="radio"
                          id="radio1"
                          name="paymethod"
                          value="credit"
                          onChange={() =>
                            this.props.fetchPaymentForm({
                              reservationId: reservation.id,
                              paymentMethod: 'credit',
                            })
                          }
                        />
                        <label htmlFor="radio1" className="radio">
                          クレジットカード
                        </label>
                        <div className="creditcard_img">
                          <img
                            src="/assets/img/creditcard_img.png"
                            alt="クレジットカード"
                          />
                        </div>
                      </div>
                      {!reservation.isProvisional && (
                        <>
                          <div>
                            <input
                              type="radio"
                              id="radio2"
                              name="paymethod"
                              value="unionpay"
                              onChange={() =>
                                this.props.fetchPaymentForm({
                                  reservationId: reservation.id,
                                  paymentMethod: 'unionpay',
                                })
                              }
                            />
                            <label htmlFor="radio2" className="radio">
                              UnionPay
                            </label>
                            <div className="unionpay_img">
                              <img
                                src="/assets/img/unionpay_img.png"
                                alt="ユニオンペイ"
                              />
                            </div>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="radio3"
                              name="paymethod"
                              value="alipay"
                              onChange={() =>
                                this.props.fetchPaymentForm({
                                  reservationId: reservation.id,
                                  paymentMethod: 'alipay',
                                })
                              }
                            />
                            <label htmlFor="radio3" className="radio">
                              Alipay
                            </label>
                            <div className="alipay_img">
                              <img
                                src="/assets/img/alipay_img.png"
                                alt="アリペイ"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <form action={this.props.payment.data.buyUrl} method="POST">
                      {Object.keys(this.props.payment.data.inputs).map(
                        (key, i) => {
                          return (
                            <input
                              type="hidden"
                              name={key}
                              value={this.props.payment.data.inputs[key]}
                            />
                          )
                        }
                      )}
                      <p className="paymethod-submit-btn">
                        <span>
                          <input type="submit" value="決済へ進む" />
                        </span>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </body>
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PaymentContainer)
)
