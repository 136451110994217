import React from 'react'
import ReactBodymovin from 'react-bodymovin/lib/ReactBodymovinFull'
import check from './check.json'

const CompleteIcon = () => {
  const bodymovinOptions = {
    animType: 'svg',
    loop: false,
    animationData: check,
  }

  return (
    <div id="svgContainer">
      <ReactBodymovin options={bodymovinOptions} />
    </div>
  )
}

export default CompleteIcon
