import React from 'react'
import { Formik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import * as Yup from 'yup'
import { PcHeader, SpHeader } from '../../components/top/Header'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import {
  updateResendEmailForm,
  postResendEmail,
} from '../../actions/resendEmailForm'
import cleanDeep from 'clean-deep'
import { history } from '../../configureStore'
import AuthComponent, {
  authMapStateToProps,
  authMapDispatchToProps,
} from '../../components/AuthComponent'

const styles = theme => ({
  textField: {
    width: '100%',
    fontSize: '12px',
  },
  formControl: {
    minWidth: 100,
  },
})

function mapStateToProps(state) {
  return Object.assign(authMapStateToProps(state), {
    resendEmailForm: state.resendEmailForm,
  })
}

function mapDispatchToProps(dispatch) {
  return Object.assign(authMapDispatchToProps(dispatch), {
    updateResendEmailForm: bindActionCreators(updateResendEmailForm, dispatch),
    postResendEmail: bindActionCreators(postResendEmail, dispatch),
  })
}

const validationSchema = Yup.object({
  ID: Yup.string().required('メールアドレスが必要です'),
  birthday: Yup.string()
    .test('checkDate', 'YYYYMMDD形式で入れてください', str => {
      //null or 8文字でない or 数値でない場合はfalse
      if (str == null || str.length !== 8 || isNaN(str)) {
        return false
      }
      //年,月,日を取得する
      var y = parseInt(str.substr(0, 4))
      var m = parseInt(str.substr(4, 2)) - 1 //月は0～11で指定するため-1しています。
      var d = parseInt(str.substr(6, 2))
      var dt = new Date(y, m, d)
      //判定する
      return y === dt.getFullYear() && m === dt.getMonth() && d === dt.getDate()
    })
    .required('生年月日が必要です'),
})

const Text = ({
  label,
  handleChange,
  costomChange,
  values,
  errors,
  name,
  classes,
  touched,
  setFieldTouched,
  multiline,
  rows,
  parentErrors,
}) => (
  <div style={{ position: 'relative' }}>
    <TextField
      className={classes.textField}
      name={name}
      label={label}
      margin="dense"
      error={
        (!!parentErrors && !!parentErrors[name]) ||
        (!!touched[name] && !!errors[name])
      }
      value={values[name]}
      multiline={multiline}
      rows={rows}
      onFocus={() => setFieldTouched(name, true, false)}
      onChange={e => {
        handleChange(e)
        costomChange(e, name)
      }}
    />
    {!!parentErrors && !!parentErrors[name] && (
      <div style={{ position: 'absolute', color: 'red', fontSize: '0.8rem' }}>
        {parentErrors[name]}
      </div>
    )}
    {!!touched[name] && errors[name] && (
      <div style={{ position: 'absolute', color: 'red', fontSize: '0.8rem' }}>
        {errors[name]}
      </div>
    )}
  </div>
)

class ResendEmailContainer extends AuthComponent {
  componentDidMount() {
    super.componentDidMount()
    this.props.updateResendEmailForm({
      ...this.props.resendEmailForm,
    })
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
    if (this.props.resendEmailForm !== prevProps.resendEmailForm) {
      if (this.props.resendEmailForm.isCompleteResendEmail) {
        history.push('/sign_up/resend_email/complete')
      }
    }
  }

  normalizeData = () => {
    const { resendEmailForm } = this.props
    return cleanDeep(resendEmailForm)
  }

  submit = () => {
    const data = this.normalizeData()
    this.props.postResendEmail({ data })
  }

  handleChange = (event, name, formikProps) => {
    this.onUpdate({
      ...this.props.resendEmailForm,
      [name]: event.target.value,
    })
  }

  onUpdate = data => {
    const resendEmailForm = this.props.resendEmailForm
    this.props.updateResendEmailForm({ ...resendEmailForm, ...data })
  }

  render() {
    const { classes, authUser, resendEmailForm } = this.props
    return (
      <>
        <div className="sign-up">
          <PcHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
          />
          <SpHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
          />
          <Formik validationSchema={validationSchema} enableReinitialize={true}>
            {props => (
              <>
                <div className="reserve-form-guest-ditails reserve-form-container reserve-form-container-email">
                  <h3 className="block-title">パスワードメール再発行</h3>
                  <div
                    style={{
                      position: 'absolute',
                      color: 'red',
                      fontSize: '0.8rem',
                    }}
                  >
                    {resendEmailForm.errorMessage}
                  </div>
                  <ul className="reserve-form-input-area">
                    <Text
                      {...props}
                      name="ID"
                      label="メールアドレス"
                      classes={classes}
                      costomChange={(e, name) =>
                        this.handleChange(e, name, props)
                      }
                    />
                    <Text
                      {...props}
                      name="birthday"
                      label="生年月日 YYYYMMDD 例) 1985年1月1日の場合は19850101"
                      classes={classes}
                      costomChange={(e, name) =>
                        this.handleChange(e, name, props)
                      }
                    />
                  </ul>
                </div>
                <p className="reserve-confirm-btn">
                  <input
                    style={{ cursor: 'pointer' }}
                    type="button"
                    value="送信する"
                    disabled={!props.isValid}
                    onClick={() => this.submit()}
                  />
                </p>
              </>
            )}
          </Formik>
        </div>
      </>
    )
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ResendEmailContainer)
)
