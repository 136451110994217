import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { fetchReservation } from '../../../actions/reservation'
import Error from '../../../components/Error'
import { Helmet } from 'react-helmet'
import { Formik } from 'formik'
import { isMobile } from 'react-device-detect'
import { PaymentInputsContainer } from 'react-payment-inputs'
import { PaymentInputsWrapper } from 'react-payment-inputs'
import images from 'react-payment-inputs/images'
import Modal from 'react-modal'
import AuthComponent, {
  authMapStateToProps,
  authMapDispatchToProps,
} from '../../../components/AuthComponent'
import { fetchCreditcard } from '../../../actions/creditcard'
import { postCreditcardPayment } from '../../../actions/creditcardPayment'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Link } from 'react-router-dom'

function mapStateToProps(state) {
  return Object.assign(authMapStateToProps(state), {
    reservation: state.reservation,
    creditcard: state.creditcard,
    creditcardPayment: state.creditcardPayment,
  })
}

function mapDispatchToProps(dispatch) {
  return Object.assign(authMapDispatchToProps(dispatch), {
    fetch: bindActionCreators(fetchReservation, dispatch),
    fetchCreditcard: bindActionCreators(fetchCreditcard, dispatch),
    postCreditcardPayment: bindActionCreators(postCreditcardPayment, dispatch),
  })
}

class CreditCardPayment extends AuthComponent {
  constructor(props) {
    super(props)
    this.state = {
      modalChatIsOpen: false,
      modalType: '',
      cardNumber: '',
      expiryDate: '',
      cvc: '',
      saveCard: false,
      useRegisterdCard: true,
      open: true,
    }
  }

  componentDidMount() {
    super.componentDidMount()
    this.props.authUser.redirectPath = this.props.history.location.pathname
    this.props.fetchCreditcard({
      userNo: this.props.authUser.authInfo.user_no,
      token: this.props.authUser.authInfo.token,
    })
    const reservationId = this.props.match.params.reservationId
    if (reservationId) {
      this.props.fetch({ reservationId })
    }
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
  }

  render() {
    if (this.props.reservation.loadingFlag) {
      return <></>
    }
    const reservation = this.props.reservation.data
    if (!reservation || !reservation.id) {
      return <Error title={'Error'} message={'Invalid reserve id'} />
    }
    if (!reservation.isProvisional) {
      if (reservation.status < 5) {
        return (
          <Error
            title={'Error'}
            message={'It is not yet available for payment'}
          />
        )
      }
      if (reservation.status > 5 && reservation.status < 9) {
        return (
          <Error
            title={'Payment Completed'}
            message={'Your payment has already been completed'}
          />
        )
      }
    }

    const head = {
      title: 'Payment | Chefle',
      description: '',
    }

    const { creditcard, creditcardPayment } = this.props
    const reservationId = this.props.match.params.reservationId

    const hasCreditcard = () => {
      return Object.keys(creditcard.data).length >= 4
    }

    const handleSubmit = () => {
      // 登録済みのクレジットカードで決済する場合
      if (this.state.useRegisterdCard && hasCreditcard()) {
        const data = {
          user_no: this.props.authUser.authInfo.user_no,
          token: this.props.authUser.authInfo.token,
          reservationId,
          cardSave: this.state.saveCard,
        }

        this.props.postCreditcardPayment({ data })
        return
      }

      // トークン生成ロジック呼び出し
      const ccNumber = this.state.cardNumber.replace(/\s+/g, '')
      const expiryDate = this.state.expiryDate.replace(/\s+/g, '').split('/')
      const ccExpiration = '20' + expiryDate[1] + expiryDate[0]
      const securityCode = this.state.cvc

      // eslint-disable-next-line no-undef
      com_sbps_system.generateToken(
        {
          merchantId: '73342',
          serviceId: '001',
          ccNumber,
          ccExpiration,
          securityCode,
        },
        afterGenerateToken
      )
    }

    const afterGenerateToken = response => {
      if (response.result === 'OK') {
        const data = {
          user_no: this.props.authUser.authInfo.user_no,
          token: this.props.authUser.authInfo.token,
          sbpToken: response.tokenResponse.token,
          sbpTokenKey: response.tokenResponse.tokenKey,
          reservationId,
          cardSave: this.state.saveCard,
        }

        this.props.postCreditcardPayment({ data })
      } else {
        alert('トークン取得に失敗しました。')
      }
    }

    const handleChange = e => {
      const target = e.target
      if (target.name === 'cardNumber') {
        this.setState({ cardNumber: target.value })
      } else if (target.name === 'expiryDate') {
        this.setState({ expiryDate: target.value })
      } else if (target.name === 'cvc') {
        this.setState({ cvc: target.value })
      }
    }

    const getBrandImage = brandCode => {
      switch (brandCode) {
        case 'JCB':
          return '/assets/img/chefle/jcb.png'
        case 'Visa':
          return '/assets/img/chefle/visa.png'
        case 'Master':
          return '/assets/img/chefle/master.png'
        case 'AMEX':
          return '/assets/img/chefle/amex.png'
        case 'Diners':
          return '/assets/img/chefle/diners.png'
        default:
          return ''
      }
    }

    const splitByChunk = (str, size) => {
      const numChunks = Math.ceil(str.length / size)
      const chunks = new Array(numChunks)
      for (let i = 0, x = 0; i < numChunks; ++i, x += size) {
        chunks[i] = str.substr(x, size).replace(/\s+/g, '')
      }
      return chunks
    }

    const getCardNumber = cardNumber => {
      const numbers = splitByChunk(cardNumber, 4)
      return numbers[0] + ' ' + numbers[1] + ' ' + numbers[2] + ' ' + numbers[3]
    }

    const getExpiration = cardExpiration => {
      const expirations = splitByChunk(cardExpiration, 4)

      return expirations[0] + '年 ' + expirations[1] + '月'
    }

    const modalSettingsPC = {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        zIndex: '1000',
      },
      content: {
        margin: 'auto',
        maxWidth: '400px',
        maxHeight: '120px',
        borderRadius: '18px',
        zIndex: '1000',
      },
    }

    const ReminderToSignIn = () => {
      return (
        <>
          <Dialog
            open={this.state.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'決済はログイン後に行えます。'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" className="">
                <Link
                  to="/sign_in"
                  color="primary"
                  className={'sign-in-btn sign-in-btn-w'}
                >
                  ログインする
                </Link>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </>
      )
    }

    const CreditcardComponent = () => {
      return (
        <section className="setting-form chefle-creditcard">
          <Helmet>
            <script
              type="text/javascript"
              src={process.env.REACT_APP_SBPS_TOKEN}
            />
          </Helmet>
          <Formik enableReinitialize={true}>
            <>
              {!hasCreditcard() ? (
                <div
                  className="reserve-form-container chefle-reservation chefle-creditcard__wrapper"
                  Style="background: #FFF4F6; padding: 10px;"
                >
                  <div Style="padding: 10px;">
                    <p className="chefle-creditcard__warning-head">
                      ご予約にはクレジットカードのご登録が必要です。
                    </p>
                    <p className="chefle-creditcard__warning-text">
                      ・現在、VISA、Master、AMEX、JCB、ダイナーズがご利用いただけます。
                    </p>
                    <p className="chefle-creditcard__warning-text">
                      ・クレジットカードは一枚のみ登録可能です。
                    </p>
                  </div>
                </div>
              ) : (
                <div
                  className="reserve-form-container chefle-reservation chefle-creditcard__wrapper"
                  Style="padding: 10px;"
                >
                  <div Style="padding: 10px;">
                    <p className="chefle-creditcard__head">
                      登録済みクレジットカード
                    </p>
                  </div>
                  <div className="chefle-creditcard__infos">
                    <div className="chefle-creditcard__info">
                      <div>
                        <p className="chefle-creditcard__number">
                          {getCardNumber(creditcard.data.ccNumber)}
                        </p>
                        <p className="chefle-creditcard__expiration">
                          有効期限 {getExpiration(creditcard.data.ccExpiration)}
                        </p>
                      </div>
                      {creditcard.data.brandCode !== 'その他' && (
                        <img
                          src={getBrandImage(creditcard.data.brandCode)}
                          alt="creditcard"
                          className="chefle-creditcard__image"
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
              {hasCreditcard() && (
                <>
                  <div className="chefle-creditcard__radio">
                    <input
                      type="radio"
                      id="radio1"
                      name="ラジオグループの名前"
                      checked={this.state.useRegisterdCard}
                      onChange={() => {
                        this.setState({
                          useRegisterdCard: true,
                        })
                      }}
                    />
                    <label className="chefle-creditcard__label" for="radio1">
                      登録済みのクレジットカードを使用する
                    </label>
                  </div>
                  <div className="chefle-creditcard__radio">
                    <input
                      type="radio"
                      id="radio2"
                      name="ラジオグループの名前"
                      checked={!this.state.useRegisterdCard}
                      onChange={() => {
                        this.setState({
                          useRegisterdCard: false,
                        })
                      }}
                    />
                    <label className="chefle-creditcard__label" for="radio2">
                      別のクレジットカードを使用する
                    </label>
                  </div>
                </>
              )}
              {hasCreditcard() && this.state.useRegisterdCard && (
                <div>
                  <button
                    type=""
                    onClick={() =>
                      this.setState({
                        modalChatIsOpen: true,
                      })
                    }
                    className="chefle-creditcard__submitButton"
                  >
                    決済を行う
                  </button>
                </div>
              )}
              {!hasCreditcard() && (
                <div
                  className="reserve-form-container chefle-reservation chefle-creditcard__wrapper"
                  Style={
                    isMobile
                      ? 'margin: 10px;'
                      : 'padding: 10px; margin: 20px 0 20px 0;'
                  }
                >
                  <div Style="padding: 10px;">
                    <p className="chefle-creditcard__head">
                      クレジットカードを新規登録
                    </p>
                    <img
                      src="/assets/img/chefle/card_5brand.png"
                      alt="creditcard"
                      className="chefle-creditcard__images"
                    />
                    <div className="chefle-creditcard__warnings">
                      <p className="chefle-creditcard__warning-text2">
                        ・デビットカードはお使いいただけません。
                      </p>
                      <p className="chefle-creditcard__warning-text2">
                        ・必ずお客様ご本人のクレジットカードをご登録ください。ご本人のものでないものをご登録された場合、お客様のアカウントを停止させて頂くことがございます。
                      </p>
                    </div>
                    <PaymentInputsContainer>
                      {({
                        wrapperProps,
                        getCardImageProps,
                        getCardNumberProps,
                        getExpiryDateProps,
                        getCVCProps,
                      }) => (
                        <>
                          <PaymentInputsWrapper
                            {...wrapperProps}
                            Style="margin: 10px;"
                          >
                            <svg {...getCardImageProps({ images })} />
                            <input
                              {...getCardNumberProps({
                                onChange: handleChange,
                              })}
                            />
                            <input
                              {...getExpiryDateProps({
                                onChange: handleChange,
                              })}
                            />
                            <input
                              {...getCVCProps({ onChange: handleChange })}
                            />
                          </PaymentInputsWrapper>
                          <div>
                            {creditcardPayment.data.error && (
                              <p className="sc-dkPtRN">
                                ※クレジットカードの処理に失敗しました。
                              </p>
                            )}
                            <div className="chefle-creditcard__checkbox1">
                              <input
                                type="checkbox"
                                id="register"
                                name="apple"
                                value="Apple"
                                onChange={() =>
                                  this.setState({
                                    saveCard: !this.state.saveCard,
                                  })
                                }
                              />
                              <label
                                className="chefle-creditcard__label"
                                for="register"
                              >
                                このカードを登録する
                              </label>
                            </div>
                            <div className="paymethod-box">
                              <p className="paymethod-submit-btn">
                                <button
                                  type=""
                                  onClick={() =>
                                    this.setState({
                                      modalChatIsOpen: true,
                                    })
                                  }
                                  className="chefle-creditcard__submitButton"
                                  disabled={wrapperProps.error}
                                >
                                  決済を行う
                                </button>
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    </PaymentInputsContainer>
                  </div>
                </div>
              )}
              {hasCreditcard() && !this.state.useRegisterdCard && (
                <div
                  className="reserve-form-container chefle-reservation chefle-creditcard__wrapper"
                  Style={
                    isMobile
                      ? 'margin: 10px;'
                      : 'padding: 10px; margin: 20px 0 20px 0;'
                  }
                >
                  <div Style="padding: 10px;">
                    <img
                      src="/assets/img/chefle/card_5brand.png"
                      alt="creditcard"
                      className="chefle-creditcard__images"
                    />
                    <div className="chefle-creditcard__warnings">
                      <p className="chefle-creditcard__warning-text2">
                        ・デビットカードはお使いいただけません。
                      </p>
                      <p className="chefle-creditcard__warning-text2">
                        ・必ずお客様ご本人のクレジットカードをご登録ください。ご本人のものでないものをご登録された場合、お客様のアカウントを停止させて頂くことがございます。
                      </p>
                    </div>
                    <PaymentInputsContainer>
                      {({
                        wrapperProps,
                        getCardImageProps,
                        getCardNumberProps,
                        getExpiryDateProps,
                        getCVCProps,
                      }) => (
                        <>
                          <PaymentInputsWrapper
                            {...wrapperProps}
                            Style="margin: 10px;"
                          >
                            <svg {...getCardImageProps({ images })} />
                            <input
                              {...getCardNumberProps({
                                onChange: handleChange,
                              })}
                            />
                            <input
                              {...getExpiryDateProps({
                                onChange: handleChange,
                              })}
                            />
                            <input
                              {...getCVCProps({ onChange: handleChange })}
                            />
                          </PaymentInputsWrapper>
                          <div>
                            {creditcardPayment.data.error && (
                              <p className="sc-dkPtRN">
                                ※クレジットカードの処理に失敗しました。
                              </p>
                            )}
                            <div className="chefle-creditcard__checkbox1">
                              <input
                                type="checkbox"
                                id="register"
                                name="apple"
                                value="Apple"
                                onChange={() =>
                                  this.setState({
                                    saveCard: !this.state.saveCard,
                                  })
                                }
                              />
                              <label
                                className="chefle-creditcard__label"
                                for="register"
                              >
                                このカードを登録する
                              </label>
                            </div>
                            <div className="paymethod-box">
                              <p className="paymethod-submit-btn">
                                <button
                                  type=""
                                  onClick={() =>
                                    this.setState({
                                      modalChatIsOpen: true,
                                    })
                                  }
                                  className="chefle-creditcard__submitButton"
                                  disabled={wrapperProps.error}
                                >
                                  決済を行う
                                </button>
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    </PaymentInputsContainer>
                  </div>
                </div>
              )}
            </>
          </Formik>
          <Modal
            closeTimeoutMS={200}
            isOpen={this.state.modalChatIsOpen}
            onRequestClose={() =>
              this.setState({
                modalChatIsOpen: false,
              })
            }
            style={modalSettingsPC}
            contentLabel="CancelationPolicy Modal"
          >
            <div class="chat-modal">
              <>
                <p className="chefle-creditcard__modalText">
                  決済を行います。
                  <br />
                  本当によろしいですか？
                </p>
                <div className="chefle-creditcard__modalButtons">
                  <button
                    type=""
                    onClick={() =>
                      this.setState({
                        modalChatIsOpen: false,
                      })
                    }
                    className="chefle-creditcard__modalCancelButton"
                  >
                    キャンセル
                  </button>
                  <button
                    type=""
                    onClick={() => {
                      handleSubmit()
                      this.setState({
                        modalChatIsOpen: false,
                      })
                    }}
                    className="chefle-creditcard__modalDeleteButton"
                  >
                    決済する
                  </button>
                </div>
              </>
            </div>
          </Modal>
        </section>
      )
    }

    return (
      <body>
        <Helmet>
          <title>{head.title}</title>
          <meta property="og:title" content={head.title} />
          <meta property="og:description" content={head.description} />
          <meta name="description" content={head.description} />
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className="payment-selection">
          <header>
            <h1 Style="display: flex; justify-content: center;">
              <img
                src="/assets/img/chefle/chefle_logo_o.png"
                alt="ロゴ"
                className="JPNEAZY"
                Style="width: 100px; height: 46px;"
              />
            </h1>
          </header>
          <main>
            <p className="thanks-txt">Thank you for using Chefle</p>
            <div className="reserve-form-main-container">
              <p className="payment-txt2">{reservation.restaurantName}</p>
              <div className="reserve-form-right-container">
                <div className="reservation-fee-01 reserve-form-container">
                  <h3 className="reservation-fee-title">Payment details</h3>
                  <div className="reservation-fee-line-box">
                    <hr className="reservation-fee-line" />
                  </div>
                  <div className="reservation-fee-type">
                    <p className="reservation-fee-conditions">
                      {reservation.orderDetail}
                    </p>
                    <div className="reservation-fee-price">
                      Total：
                      <span>￥{reservation.price}</span>
                    </div>
                  </div>
                </div>
                <div className="reserve-form-submit-container">
                  {reservation.isProvisional && (
                    <div className="reserve-form-notification">
                      <p className="reserve-form-notification-title">
                        注意事項
                      </p>
                      <p className="payment-notification-txt">
                        ご予約確定時に、決済が発生します。ご予約確定に至らなかった場合、決済されることはございません。
                        稀に、与信審査のため、ご請求が発生したように明細表示させることがございます。30日以上同じ状態が続く場合は、クレジットカード会社にお問い合わせください。
                      </p>
                    </div>
                  )}
                  <div className="reserve-form-cancelpolicy">
                    <p className="reserve-form-cancelpolicy-title">
                      キャンセルポリシー
                    </p>
                    <p className="payment-selection-cancelpolicy-txt">
                      キャンセルポリシーは各レストランにより異なりますので、お客様ご自身にてご確認をお願いいたします。
                      ご予約確定前であれば、キャンセルや変更を無料で行います。ご予約確定後の変更は1度のみ承ることが可能です。サービス利用料の返金はございません。
                      また、キャンセルポリシーに準じ、返金手配をいたします。詳しくは、ウェブページをご確認ください。
                    </p>
                  </div>
                  {!this.props.authUser.authInfo.isLogin && ReminderToSignIn()}
                  {CreditcardComponent()}
                </div>
              </div>
            </div>
          </main>
        </div>
      </body>
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreditCardPayment)
)
