import { CALL_API } from '../middlewares/api'
import { RESERVATION_REQUEST, RESERVATION_OK, RESERVATION_NG } from './index'

export const fetchReservation = payload => ({
  [CALL_API]: {
    types: [RESERVATION_REQUEST, RESERVATION_OK, RESERVATION_NG],
    endpoint: `/reservations/${payload.reservationId}`,
    payload,
  },
})
