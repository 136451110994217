const initialState = {
  guest: {
    firstName: localStorage.getItem('guest.firstName'),
    lastName: localStorage.getItem('guest.lastName'),
    email: localStorage.getItem('guest.email'),
    country: localStorage.getItem('guest.country'),
    phone: localStorage.getItem('guest.phone'),
  },
  host: '',
  comment: '',
  date: '',
  time: '',
  alternativeBeforeDate: false,
  alternativeAfterDate: false,
  adult: 0,
  child: 0,
  baby: 0,
  restaurant: {
    id: null,
    name: '',
    url: '',
    tel: '',
  },
  errors: {},
  postingFlag: false,
}
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_SEAT_FORM':
      return {
        ...state,
        ...action.payload,
      }
    case 'CONFIRM_SEAT_FORM':
      return {
        ...state,
        ...action.payload,
      }
    case 'SEAT_POST_REQUEST':
      return {
        ...state,
        postingFlag: true,
      }
    case 'SEAT_POST_OK':
      return {
        ...state,
        postingFlag: false,
      }
    case 'SEAT_POST_NG':
      return {
        ...state,
        postingFlag: false,
      }
    default:
      return state
  }
}
