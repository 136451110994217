import React from 'react'
import { bindActionCreators } from 'redux'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {
  MenuReserveHeader,
  StrictNotice,
  RestaurantName,
  SelectedData,
  MenuCancellationPolicy,
} from '../../components/MenuReserveForm/MenuReserveParts'
import CancelationPolicy from '../../components/common/CancelationPolicy'
import { CustomDatePicker } from '../../components/MenuReserveForm/DatePicker'
import MenuSelector from '../../components/MenuReserveForm/MenuSelector'
import UserInfoInput from '../../components/MenuReserveForm/UserInfoInput'
import {
  updateMenuForm,
  fetchMenus,
  confirmMenuForm,
} from '../../actions/pickupForm'
import { fetchPickupRestaurant } from '../../actions/restaurant'
import { fetchCalendar } from '../../actions/calendar'
import { BackButton, ChatButton } from '../../components/common/Buttons'
import ls from 'localstorage-ttl'
import { Helmet } from 'react-helmet'
import Checkbox from '@material-ui/core/Checkbox'
import UsePoint from '../../components/MenuReserveForm/UsePoint'
import AuthComponent, {
  authMapStateToProps,
  authMapDispatchToProps,
} from '../../components/AuthComponent'
import PersonalInformation from '../../components/common/PersonalInformation'

function mapStateToProps(state) {
  return Object.assign(authMapStateToProps(state), {
    pickupForm: state.pickupForm,
    menu: state.menu,
    restaurant: state.restaurant,
    calendar: state.calendar,
  })
}

function mapDispatchToProps(dispatch) {
  return Object.assign(authMapDispatchToProps(dispatch), {
    fetchMenus: bindActionCreators(fetchMenus, dispatch),
    fetchRestaurant: bindActionCreators(fetchPickupRestaurant, dispatch),
    updateMenuForm: bindActionCreators(updateMenuForm, dispatch),
    confirmMenuForm: bindActionCreators(confirmMenuForm, dispatch),
    fetchCalendar: bindActionCreators(fetchCalendar, dispatch),
  })
}

class PickupReserveFormContainer extends AuthComponent {
  constructor(props) {
    super(props)
    this.state = {
      isSaveUser: true,
    }
  }
  componentDidMount() {
    super.componentDidMount()
    const urlQuery = queryString.parse(this.props.location.search)
    let host = urlQuery.host ? urlQuery.host : ls.get('hostCode')

    const restaurantId = this.props.match.params.restaurantId
    const today = new Date()
    const year = today.getFullYear()
    const month = today.getMonth() + 1
    const partySize = 0

    this.props.fetchMenus({ restaurantId })
    this.props.fetchRestaurant({ restaurantId })
    this.props.fetchCalendar({
      restaurantId,
      year,
      month,
      partySize,
    })
    this.props.updateMenuForm({
      ...this.props.pickupForm,
      host: host,
      restaurant: {
        id: Number(restaurantId),
        plans: [],
      },
      alternativeBeforeDate: false,
      alternativeAfterDate: false,
      confirmedCancellationPolicy: false,
      usePoint: 0,
      isUsePoint: 'unuse',
    })
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
    // 保有ポイントを取得
    this.props.pickupForm.effectivePoint = this.props.authUser.userInfo.effectivePoint
    // if (
    //   this.props.authUser.userInfo.effectivePoint !==
    //   prevProps.authUser.userInfo.effectivePoint
    // ) {
    //   this.props.pickupForm.effectivePoint = this.props.authUser.userInfo.effectivePoint
    // }
  }

  onUpdate = data => {
    const pickupForm = this.props.pickupForm

    const meta = {
      key: Object.keys(data)[0],
      value: data[Object.keys(data)[0]],
      restaurantId: this.props.match.params.restaurantId,
    } // middleware での判定用
    this.props.updateMenuForm({ ...pickupForm, ...data }, meta)
  }

  submit = () => {
    this.props.confirmMenuForm(this.props.pickupForm, {
      isSaveUser: this.state.isSaveUser,
    })
  }
  // ユーザ情報をローカルストレージ保存用チェックボックスのイベント
  handleIsSaveUser = () => {
    this.setState(prevState => ({
      isSaveUser: !this.state.isSaveUser,
    }))
  }

  handleAllowAlternativeBeforeDate = () => {
    this.onUpdate({
      alternativeBeforeDate: !this.props.pickupForm.alternativeBeforeDate,
    })
  }

  handleAllowAlternativeAfterDate = () => {
    this.onUpdate({
      alternativeAfterDate: !this.props.pickupForm.alternativeAfterDate,
    })
  }

  handleConfirmedCancellationPolicy = () => {
    this.onUpdate({
      confirmedCancellationPolicy: !this.props.pickupForm
        .confirmedCancellationPolicy,
    })
  }

  render() {
    var { pickupForm, menu, restaurant, authUser, calendar } = this.props

    const isHostPrice =
      pickupForm.host &&
      restaurant.data.hostCode &&
      pickupForm.host === restaurant.data.hostCode
    const head = {
      title: `${restaurant.data.nameJa ? restaurant.data.nameJa + ' ' : ''}${
        restaurant.data.name
      } Reservation | シェフル(chefle) - 店頭受取`,
      description: '',
    }
    return (
      <div className="menu-reserve-form">
        <Helmet>
          <title>{head.title}</title>
          <meta property="og:title" content={head.title} />
          <meta property="og:description" content={head.description} />
          <meta name="description" content={head.description} />
          <meta name="robots" content="noindex" />
        </Helmet>
        <MenuReserveHeader src="/assets/img/store_img.jpg" />
        {restaurant.data.isStrict && (
          <StrictNotice
            isVacancyManagement={restaurant.data.isVacancyManagement}
          />
        )}
        <main>
          <form action="">
            <div className="menu-reserve-store-head">
              {/* <BraedCrumb /> */}
              <RestaurantName
                en={restaurant.data.name}
                ja={restaurant.data.nameJa}
              />
            </div>
            <div className="reserve-form-main-container">
              <div className="reserve-form-left-container">
                <ul className="conditions-chice">
                  <CustomDatePicker
                    onUpdate={this.onUpdate}
                    fetchCalendar={this.props.fetchCalendar}
                    restaurantId={this.props.match.params.restaurantId}
                    calendar={calendar.data}
                    error1={pickupForm.errors.date}
                    error2={pickupForm.errors.time}
                    partySize={0}
                    selectedDate={pickupForm.date}
                  />
                </ul>

                <MenuSelector
                  menus={menu.data}
                  menuForm={pickupForm}
                  onUpdate={this.onUpdate}
                  isHostPrice={isHostPrice}
                  isSameMenu={restaurant.data.isSameMenu}
                  error={
                    pickupForm.errors.restaurant
                      ? pickupForm.errors.restaurant.plans
                      : ''
                  }
                />
                <UserInfoInput
                  menuForm={pickupForm}
                  onUpdate={this.onUpdate}
                  errors={pickupForm.errors.guest}
                  handleIsSaveUser={() => this.handleIsSaveUser()}
                  isSaveUser={this.state.isSaveUser}
                  label="追加コメント(例.質問事項などその他)"
                />
              </div>
              <div className="reserve-form-right-container">
                <UsePoint
                  authUser={authUser}
                  menuForm={pickupForm}
                  onUpdate={this.onUpdate}
                  errors={pickupForm.errors}
                />
                <SelectedData
                  restaurant={restaurant}
                  menuForm={pickupForm}
                  isHostPrice={isHostPrice}
                />
                <MenuCancellationPolicy
                  cancellationPolicy={restaurant.data.cancellationPolicy}
                />
                <div className="policy-box">
                  <Checkbox
                    checked={pickupForm.confirmedCancellationPolicy}
                    onChange={this.handleConfirmedCancellationPolicy}
                    name={'confirmedCancellationPolicy'}
                    value={pickupForm.confirmedCancellationPolicy}
                    error={pickupForm.confirmedCancellationPolicy}
                    Style="padding: 0 10px 0 0;"
                  />
                  <p Style="font-size: 14px; line-height: 22px;">
                    キャンセルポリシー、
                    <CancelationPolicy
                      text={'ユーザーガイド'}
                      btnclassname={'policy-link'}
                    />
                    、
                    <PersonalInformation
                      text={'個人情報保護方針'}
                      btnclassname={'policy-link'}
                    />
                    に同意します。
                  </p>
                </div>
                <p Style={'padding:0 13px 20px; font-size: 0.8rem;'}>
                  <span
                    style={
                      !!pickupForm.errors.confirmedCancellationPolicy
                        ? { color: '#f44336' }
                        : {}
                    }
                  >
                    {pickupForm.errors.confirmedCancellationPolicy}
                  </span>
                </p>
                <p className="reserve-confirm-btn">
                  <input
                    style={{ cursor: 'pointer' }}
                    type="button"
                    value="予約確認"
                    onClick={() => this.submit()}
                  />
                </p>
              </div>
            </div>
          </form>
          <div className="fixed-footer sp">
            <div className="fixed-footer-inner">
              <BackButton position={'left'} />
              <ChatButton position={'right'} />
            </div>
          </div>
        </main>
      </div>
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PickupReserveFormContainer)
)
