import {
  MENU_POST_OK,
  SEAT_POST_OK,
  PICKUP_POST_OK,
  CHEFLE_MENU_POST_OK,
  UPDATE_PASSWORD_OK,
  UPDATE_ACCOUNT_OK,
  DELETE_ACCOUNT_POST_OK,
  CREDITCARD_POST_OK,
  CREDITCARD_UPDATE_OK,
  CREDITCARD_DELETE_OK,
  CREDITCARD_PAYMENT_POST_OK,
} from '../actions'
import { history } from '../configureStore'

export default store => next => action => {
  if (action.type === MENU_POST_OK) {
    if (action.payload.data.isProvisional) {
      history.push(
        `/restaurants/menu-reservation/credit/${action.payload.data.id}`
      )
    } else {
      history.push('/restaurants/reservation/complete')
    }
  } else if (action.type === PICKUP_POST_OK) {
    if (action.payload.data.isProvisional) {
      history.push(
        `/restaurants/menu-reservation/credit/${action.payload.data.id}`
      )
    } else {
      history.push('/restaurants/reservation/complete')
    }
  } else if (action.type === CHEFLE_MENU_POST_OK) {
    if (action.payload.data.isProvisional) {
      history.push(
        `/restaurants/menu-reservation/credit/${action.payload.data.id}`
      )
    } else {
      history.push('/restaurants/reservation/complete')
    }
  } else if (action.type === UPDATE_PASSWORD_OK) {
    if (action.payload.data.error === 0) {
      history.push('/setting/password/updated')
    }
  } else if (action.type === UPDATE_ACCOUNT_OK) {
    if (action.payload.data.error === 0) {
      history.push('/setting/user_info/updated')
    }
  } else if (action.type === DELETE_ACCOUNT_POST_OK) {
    if (action.payload.data.error === 0) {
      history.push('/setting/delete/complete')
    }
  } else if (action.type === SEAT_POST_OK) {
    history.push('/restaurants/reservation/complete')
    next(action)
  } else if (action.type === CREDITCARD_PAYMENT_POST_OK) {
    history.push('/restaurants/reservation/complete')
  } else if (action.type === CREDITCARD_POST_OK) {
    window.location.reload()
  } else if (action.type === CREDITCARD_UPDATE_OK) {
    window.location.reload()
  } else if (action.type === CREDITCARD_DELETE_OK) {
    window.location.reload()
  }

  next(action)
}
