const initialState = {
  user_no: '',
  token: '',
  sei: '',
  mei: '',
  seikana: '',
  meikana: '',
  birthday: '',
  allergy: '',
  tel: '',
  prefectures: '',
  mailmagazine: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_ACCOUNT_FORM':
      return {
        ...state,
        ...action.payload,
      }
    case 'UPDATE_ACCOUNT_POST_REQUEST':
      return {
        ...state,
        postingFlag: true,
      }
    case 'UPDATE_ACCOUNT_OK':
      if (action.payload.data.error === 0) {
        // 正常時
        return {
          ...state,
          postingFlag: false,
        }
      } else {
        // エラー時
        return {
          ...state,
          postingFlag: false,
          errorMessage: action.payload.data.body.message,
        }
      }
    case 'UPDATE_ACCOUNT_NG':
      return {
        ...state,
        postingFlag: false,
        errorMessage:
          'システムエラーが発生しております。しばらくお待ちください。',
      }
    default:
      return state
  }
}
