import { CALL_API } from '../middlewares/api'
import { CAMPAIGN_REQUEST, CAMPAIGN_OK, CAMPAIGN_NG } from './index'

export const getActiveCampaign = (payload = {}) => ({
  [CALL_API]: {
    types: [CAMPAIGN_REQUEST, CAMPAIGN_OK, CAMPAIGN_NG],
    endpoint: `/campaign/active`,
    payload,
  },
})
