import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AuthComponent, {
  authMapStateToProps,
  authMapDispatchToProps,
} from '../../../components/AuthComponent'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router'
import { PcHeader, SpHeader } from '../../../components/top/Header'
import { withStyles } from '@material-ui/core/styles'
import { CommonBackButton } from '../../../components/common/Buttons'
import cleanDeep from 'clean-deep'
import { postDeleteAccount } from '../../../actions/deleteAccount'

const styles = theme => ({
  textField: {
    width: '100%',
    fontSize: '16px',
  },
  formControl: {
    minWidth: 100,
  },
})

function mapStateToProps(state) {
  return Object.assign(authMapStateToProps(state), {
    deleteAccount: state.deleteAccount,
  })
}

function mapDispatchToProps(dispatch) {
  return Object.assign(authMapDispatchToProps(dispatch), {
    postDeleteAccount: bindActionCreators(postDeleteAccount, dispatch),
  })
}
class AccountDeleteConfirmContainer extends AuthComponent {
  componentDidMount() {
    super.componentDidMount()
    this.props.deleteAccount.user_no = this.props.authUser.authInfo.user_no
    this.props.deleteAccount.token = this.props.authUser.authInfo.token
  }

  normalizeData = () => {
    const { deleteAccount } = this.props
    if (deleteAccount.withdrawal === '') {
      if (deleteAccount.reason1) {
        deleteAccount.withdrawal += 'サービスを利用する機会がなくなった。'
      }
      if (deleteAccount.reason2) {
        deleteAccount.withdrawal += 'サービスが使いづらかった。'
      }
      if (deleteAccount.reason3) {
        deleteAccount.withdrawal += '気になるお店がなかった。'
      }
      if (deleteAccount.reason4) {
        deleteAccount.withdrawal += 'サービスの対応が悪かった。'
      }
    }
    return cleanDeep(deleteAccount)
  }
  handleSubmit = () => {
    const data = this.normalizeData()
    console.log(data, 'deleteAccount')
    this.props.postDeleteAccount({ data })
  }
  render() {
    const { authUser, deleteAccount } = this.props
    return (
      <>
        <div className="account-setting top-page">
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
          <PcHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
          />
          <SpHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
          />
          <div className="account-setting-contents">
            <div className="menu-reserve-confirmation">
              <div className="reserve-confirmation-detail-container">
                <div className="reserve-confirmation-container">
                  <div className="reserve-gest-details">
                    <h2 className="reserve-gest-details-title">退会(確認)</h2>
                    <div
                      style={{
                        position: 'absolute',
                        color: 'red',
                        fontSize: '0.8rem',
                      }}
                    >
                      {deleteAccount.errorMessage}
                    </div>
                    <ul className="reserve-gest-details-list">
                      <p className="account-delete-word">
                        シェフル(Chefle)を退会します。本当によろしいでしょうか?
                      </p>

                      <li className="reserve-gest-details-email">
                        <p className="reserve-gest-details-head">退会理由</p>
                        {deleteAccount.reason1 ? (
                          <p className="reserve-gest-details-txt">
                            サービスを利用する機会がなくなった。
                          </p>
                        ) : (
                          ''
                        )}
                        {deleteAccount.reason2 ? (
                          <p className="reserve-gest-details-txt">
                            サービスが使いづらかった。
                          </p>
                        ) : (
                          ''
                        )}
                        {deleteAccount.reason3 ? (
                          <p className="reserve-gest-details-txt">
                            気になるお店がなかった。
                          </p>
                        ) : (
                          ''
                        )}
                        {deleteAccount.reason4 ? (
                          <p className="reserve-gest-details-txt">
                            サービスの対応が悪かった。
                          </p>
                        ) : (
                          ''
                        )}
                        <p className="reserve-gest-details-txt">
                          {deleteAccount.withdrawal}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            Style="display: flex; margin: 0;"
            className="sign-up-confirm-btn"
          >
            <CommonBackButton position={'left'} />
            <p className="reserve-submit-btn reserve-confirm-btn">
              <input
                // type="submit"
                type="button"
                value="退会する"
                // onSubmit={this.handleSubmit}
                onClick={() => this.handleSubmit()}
              />
            </p>
          </div>
        </div>
      </>
    )
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(AccountDeleteConfirmContainer))
)
