import { CALL_API } from '../middlewares/api'
import {
  GOOGLE_PLACE_REQUEST,
  GOOGLE_PLACE_REQUEST_OK,
  GOOGLE_PLACE_REQUEST_NG,
  CLEAR_GOOGLE_PLACE,
} from './index'

export const fetchGooglePlace = payload => ({
  [CALL_API]: {
    types: [
      GOOGLE_PLACE_REQUEST,
      GOOGLE_PLACE_REQUEST_OK,
      GOOGLE_PLACE_REQUEST_NG,
    ],
    // レビューと位置情報のみ取得する
    endpoint: `/restaurants/${payload.restaurantId}/google-places`,
    payload,
  },
})

export const clearGooglePlace = (payload = [], meta = {}) => ({
  type: CLEAR_GOOGLE_PLACE,
  payload,
  meta,
})
