import React, { useState } from 'react'
import Slider from 'react-slick'
import ResponsiveEmbed from 'react-responsive-embed'
import Modal from 'react-modal'
import { isMobile } from 'react-device-detect'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const ChefleKitchenSlider = () => {
  const sliderSettingsPC = {
    dots: true,
    className: 'center',
    centerMode: true,
    infinite: true,
    slidesToShow: 4,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
  }

  const sliderSettingsSP = {
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    className: '',
    infinite: true,
    slidesToShow: 2.02,
    slidesToScroll: 2,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: false,
  }

  const modalSettingsPC = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      zIndex: '1000',
    },
    content: {
      margin: 'auto',
      maxWidth: '700px',
      maxHeight: '600px',
      borderRadius: '18px',
      zIndex: '1000',
    },
  }

  const modalSettingsSP = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      zIndex: '1000',
    },
    content: {
      margin: 'auto -20px',
      maxWidth: '800px',
      maxHeight: '400px',
      borderRadius: '18px',
      zIndex: '1000',
    },
  }

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalContent, setModalContent] = useState(null)

  const getSliderSettings = () => {
    return isMobile ? sliderSettingsSP : sliderSettingsPC
  }

  const createCardContent = props => {
    const { src, title, text } = props
    return (
      <Card className="chefle-kitchen__card">
        <CardActionArea>
          <CardMedia
            className="chefle-kitchen__card-media"
            component="img"
            alt={title}
            src={src}
          />
          <CardContent className="chefle-kitchen__card-content">
            <Typography
              color="initial"
              component="p"
              className="chefle-kitchen__card-text font"
            >
              {text}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    )
  }

  const createMovieContent = props => {
    const { src, head, description, href, food } = props
    return (
      <div>
        <ResponsiveEmbed src={src} allowFullScreen />
        <div className="chefle-kitchen__movie-content">
          <p className="chefle-kitchen__movie-header">{head}</p>
          <p className="chefle-kitchen__movie-description">{description}</p>
          <a className="chefle-kitchen__movie-button" href={href}>
            {food}を購入
          </a>
        </div>
      </div>
    )
  }

  return (
    <div className="chefle-kitchen__slider">
      <Slider {...getSliderSettings()}>
        <div
          className=""
          onClick={() => {
            setModalIsOpen(true)
            setModalContent(
              createMovieContent({
                src: 'https://www.youtube.com/embed/GzetP2syzoA',
                head: 'あじのさんが焼き',
                description:
                  '全身がまぐろの大トロのように脂がのった北海道 松前沖の高級魚「あぶらぼう」を西京漬けに加工しました。',
                href: 'https://shop.chefle.com/products/ikkonset',
                food: '鯵',
              })
            )
          }}
        >
          {createCardContent({
            src: '/assets/img/chefle/recipe-azi.jpg',
            title: 'あじのさんが焼き',
            text: 'あじのさんが焼き',
          })}
        </div>
        <div
          className="chefle-kitchen__slider-dots"
          onClick={() => {
            setModalIsOpen(true)
            setModalContent(
              createMovieContent({
                src: 'https://www.youtube.com/embed/LWoJp6LhQ20',
                head: '鯛めし',
                description:
                  '鱗をとりグリル調理済みの淡路島産天然真鯛と、鯛めし用オリジナルブレンド米をセットでお届け。ご家庭で、お米を研ぐだけで、簡単に鯛めしがご堪能いただけます。',
                href: 'https://shop.chefle.com/products/ikkonset',
                food: '鯛',
              })
            )
          }}
        >
          {createCardContent({
            src: '/assets/img/chefle/recipe-taimesi.jpg',
            title: '鯛めし',
            text: '鯛めし',
          })}
        </div>
        <div
          className=""
          onClick={() => {
            setModalIsOpen(true)
            setModalContent(
              createMovieContent({
                src: 'https://www.youtube.com/embed/SctYvNYKJ1c',
                head: '干し貝柱チャーハン',
                description: '干し貝柱のチャーハンレシピを公開いたします。',
                href: 'https://shop.chefle.com/products/dryhotate',
                food: '干し貝柱',
              })
            )
          }}
        >
          {createCardContent({
            src: '/assets/img/chefle/recipe-kai.jpg',
            title: '干し貝柱チャーハン',
            text: '干し貝柱チャーハン',
          })}
        </div>
        <div
          className=""
          onClick={() => {
            setModalIsOpen(true)
            setModalContent(
              createMovieContent({
                src: 'https://www.youtube.com/embed/hE6y8biIksg',
                head: '黒椎茸カルボナーラ',
                description:
                  '希少性が高く、市場でも最高値で取引される最高峰の黒椎茸を使ったカルボナーラレシピです。その他、天ぷら、焼き、バター炒め、煮物に調理するのがオススメ。',
                href: 'https://shop.chefle.com/products/kuroshiitake',
                food: '黒舞茸',
              })
            )
          }}
        >
          {createCardContent({
            src: '/assets/img/chefle/recipe-matutake.jpg',
            title: '黒椎茸カルボナーラ',
            text: '黒椎茸カルボナーラ',
          })}
        </div>
        <div
          className=""
          onClick={() => {
            setModalIsOpen(true)
            setModalContent(
              createMovieContent({
                src: 'https://www.youtube.com/embed/yIquktfIKBk',
                head: 'うに炊き込みご飯',
                description:
                  '昆布で出汁とうにを贅沢に使用した炊き込みご飯レシピ。ぜひ、特別な日の食卓にいかがでしょうか。',
                href: 'https://shop.chefle.com/collections/uni',
                food: 'うに',
              })
            )
          }}
        >
          {createCardContent({
            src: '/assets/img/chefle/recipe-uni.jpg',
            title: 'うに炊き込みご飯',
            text: 'うに炊き込みご飯',
          })}
        </div>
        <div
          className=""
          onClick={() => {
            setModalIsOpen(true)
            setModalContent(
              createMovieContent({
                src: 'https://www.youtube.com/embed/4M5XDozN-KQ',
                head: 'ぶりのカルパッチョ',
                description:
                  '彩り鮮やかな、ぶりのカルパッチョをフレンチシェフがご提案。ソースにこだわりました。',
                href: 'https://shop.chefle.com/products/buriou',
                food: 'ぶり',
              })
            )
          }}
        >
          {createCardContent({
            src: '/assets/img/chefle/recipe-buri.jpg',
            title: 'ぶりのカルパッチョ',
            text: 'ぶりのカルパッチョ',
          })}
        </div>
        <div
          className=""
          onClick={() => {
            setModalIsOpen(true)
            setModalContent(
              createMovieContent({
                src: 'https://www.youtube.com/embed/CMxQ9olyhwg',
                head: '伊勢海老のお味噌汁',
                description:
                  '活きた伊勢海老を使った味噌汁の調理法をご紹介。半身はお刺身、残りは味噌汁というのもおすすめです。',
                href: 'https://shop.chefle.com/products/iseebi',
                food: '伊勢海老',
              })
            )
          }}
        >
          {createCardContent({
            src: '/assets/img/chefle/recipe-iseebi.jpg',
            title: '伊勢海老のお味噌汁',
            text: '伊勢海老のお味噌汁',
          })}
        </div>
        <div
          className=""
          onClick={() => {
            setModalIsOpen(true)
            setModalContent(
              createMovieContent({
                src: 'https://www.youtube.com/embed/NCG31qF-oDc',
                head: '車海老の塩焼き',
                description:
                  'まずは、活きた車海老を氷締め。ご家庭にある調味料とひと手間加えるだけで、料亭の味に大変身させてみました。',
                href: 'https://shop.chefle.com/products/kurumaebi',
                food: '車海老',
              })
            )
          }}
        >
          {createCardContent({
            src: '/assets/img/chefle/recipe-shioyaki.jpg',
            title: '車海老の塩焼き',
            text: '車海老の塩焼き',
          })}
        </div>
        <div
          className=""
          onClick={() => {
            setModalIsOpen(true)
            setModalContent(
              createMovieContent({
                src: 'https://www.youtube.com/embed/d4iSgSgCJmA',
                head: '車海老の天ぷら',
                description:
                  '車海老の下ごしらえから、火の入れ方までこの動画で解決。ハードルの高い、本場仕込みの天ぷらがご家庭でも提供できたら大感謝されること間違いなし。',
                href: 'https://shop.chefle.com/products/kurumaebi',
                food: '車海老',
              })
            )
          }}
        >
          {createCardContent({
            src: '/assets/img/chefle/recipe-tenpura.jpg',
            title: '車海老の天ぷら',
            text: '車海老の天ぷら',
          })}
        </div>
      </Slider>
      <Modal
        closeTimeoutMS={200}
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={isMobile ? modalSettingsSP : modalSettingsPC}
        contentLabel="CancelationPolicy Modal"
      >
        <div>{modalContent}</div>
      </Modal>
    </div>
  )
}

export default ChefleKitchenSlider
