import React from 'react'
import { Formik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import * as Yup from 'yup'
import CONTRY_CODE from '../../constants/countryCode'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import AuthComponent, {
  authMapStateToProps,
  authMapDispatchToProps,
} from '../../components/AuthComponent'

function mapStateToProps(state) {
  return Object.assign(authMapStateToProps(state), {})
}

function mapDispatchToProps(dispatch) {
  return Object.assign(authMapDispatchToProps(dispatch), {})
}

const styles = theme => ({
  textField: {
    width: '100%',
    fontSize: '12px',
  },
  formControl: {
    minWidth: 100,
  },
})

const validationSchema = Yup.object({
  firstName: Yup.string().required('名が必要です'),
  lastName: Yup.string().required('姓が必要です'),
  email: Yup.string()
    .email('enter a valid email')
    .required('メールアドレスが必要です'),
  // country: Yup.string().required('contry code is required'),
})

const CustomTextField = ({
  label,
  disabled,
  handleChange,
  costomChange,
  values,
  errors,
  name,
  classes,
  touched,
  setFieldTouched,
  multiline,
  rows,
  parentErrors,
}) => (
  <div style={{ position: 'relative' }}>
    <TextField
      disabled={disabled}
      className={classes.textField}
      name={name}
      label={label}
      margin="dense"
      error={
        (!!parentErrors && !!parentErrors[name]) ||
        (!!touched[name] && !!errors[name])
      }
      value={values[name]}
      multiline={multiline}
      rows={rows}
      onFocus={() => setFieldTouched(name, true, false)}
      onChange={e => {
        handleChange(e)
        costomChange(e, name)
      }}
    />
    {!!parentErrors && !!parentErrors[name] && (
      <div style={{ position: 'absolute', color: 'red', fontSize: '0.8rem' }}>
        {parentErrors[name]}
      </div>
    )}
    {!!touched[name] && errors[name] && (
      <div style={{ position: 'absolute', color: 'red', fontSize: '0.8rem' }}>
        {errors[name]}
      </div>
    )}
  </div>
)

class TextFields extends AuthComponent {
  constructor(props) {
    super(props)
    this.countries = this.createCountries()
  }

  componentDidMount() {
    super.componentDidMount()
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
    // フォームにユーザ情報を自動入力
    if (this.props.authUser.userInfo !== prevProps.authUser.userInfo) {
      this.props.menuForm.guest.id = this.props.authUser.authInfo.user_no
      this.props.menuForm.guest.email = this.props.authUser.userInfo.email
      this.props.menuForm.guest.firstName = this.props.authUser.userInfo.mei
      this.props.menuForm.guest.lastName = this.props.authUser.userInfo.sei
      this.props.menuForm.guest.phone = this.props.authUser.userInfo.tel
      this.props.menuForm.allergy = this.props.authUser.userInfo.allergy
    }
  }

  handleChange = (event, name, formikProps) => {
    if (name === 'comment' || name === 'allergy') {
      this.props.onUpdate({ [name]: event.target.value })
    } else {
      this.props.onUpdate({
        guest: { ...this.props.menuForm.guest, [name]: event.target.value },
      })
    }
  }

  createCountries = () => {
    return CONTRY_CODE.map((c, i) => (
      <MenuItem key={i + 'contry'} value={c}>
        {c}
      </MenuItem>
    ))
  }
  render() {
    const { classes, menuForm, errors } = this.props
    const { guest } = menuForm

    return (
      <div className="reserve-form-guest-ditails reserve-form-container">
        <h3 className="block-title">お客様詳細</h3>
        <ul className="reserve-form-input-area">
          <Formik
            initialValues={{
              allergy: this.props.menuForm.allergy,
              ...guest,
            }}
            validationSchema={validationSchema}
            enableReinitialize={true}
            disabled={true}
          >
            {props => (
              <>
                <CustomTextField
                  {...props}
                  name="lastName"
                  label="姓"
                  classes={classes}
                  disabled={true}
                  parentErrors={errors}
                />
                <CustomTextField
                  {...props}
                  name="firstName"
                  label="名"
                  classes={classes}
                  disabled={true}
                  parentErrors={errors}
                />
                <CustomTextField
                  {...props}
                  name="email"
                  label="メールアドレス"
                  classes={classes}
                  disabled={true}
                  parentErrors={errors}
                />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CustomTextField
                    {...props}
                    name="phone"
                    label="電話番号"
                    classes={classes}
                    disabled={true}
                  />
                </div>
                <CustomTextField
                  {...props}
                  name="allergy"
                  label="アレルギー"
                  classes={classes}
                  multiline={true}
                  rows={2}
                  costomChange={(e, name) => this.handleChange(e, name)}
                />
                <CustomTextField
                  {...props}
                  name="comment"
                  label={this.props.label}
                  classes={classes}
                  multiline={true}
                  rows={2}
                  costomChange={(e, name) => this.handleChange(e, name)}
                />
              </>
            )}
          </Formik>
          {/* menuとpickupはログイン必須とするため非表示 */}
          {this.props.reservationType === 'chefle' && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.props.isSaveUser}
                  onChange={this.props.handleIsSaveUser}
                  value={this.props.isSaveUser}
                />
              }
              label="情報をブラウザに保存"
            />
          )}
        </ul>
      </div>
    )
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(TextFields))
)
