const initialState = {
  loadingFlag: false,
  data: [],
}

/*
  description: '',
  id: 0,
  is_dinner: false,
  is_lunch: true,
  jpneazy_fee: 0,
  meal_fee: 0,
  name: '',
  price: 0,
  restaurant_id: 0,
*/

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'MENUS_REQUEST':
      return {
        ...state,
        loadingFlag: true,
      }
    case 'MENUS_OK':
      return {
        ...state,
        data: action.payload.data,
        loadingFlag: false,
      }
    case 'MENUS_NG':
      return {
        ...state,
        loadingFlag: false,
      }
    default:
      return state
  }
}
