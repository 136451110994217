import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import logger from 'redux-logger' // Redux DevTools でも良い
import { routerMiddleware } from 'connected-react-router'
import api from './middlewares/api'
import chainMenus from './middlewares/chainMenus'
import chainGooglePlace from './middlewares/chainGooglePlace'
import signUpForm from './middlewares/signUpForm'
import menuForm from './middlewares/menuForm'
import pickupForm from './middlewares/pickupForm'
import chefleMenuForm from './middlewares/chefleMenuForm'
import seatForm from './middlewares/seatForm'
import fetchReservableTime from './middlewares/fetchReservableTime'
import redirector from './middlewares/redirector'
import createRootReducer from './reducers'
import ReactGA from 'react-ga'
import ls from 'localstorage-ttl'
import LogHelper from './helpers/LogHelper'
import deleteAccount from './middlewares/deleteAccount'
import updatePassword from './middlewares/updatePassword'

ReactGA.initialize('UA-88095761-2')
const history = createBrowserHistory()

history.listen(({ pathname }) => {
  ReactGA.set({ page: pathname })
  ReactGA.pageview(pathname)

  let hostCode = ls.get('hostCode')
  if (hostCode) {
    ls.set('hostCode', hostCode, 1800000)
  }

  LogHelper.postPageAccessLog(history)
})

export { history }

export default function configureStore(preloadedState) {
  const composeEnhancers =
    process.env.NODE_ENV === 'production'
      ? compose
      : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        // thunk,
        signUpForm,
        deleteAccount,
        updatePassword,
        fetchReservableTime,
        api,
        chainMenus,
        chainGooglePlace,
        menuForm,
        pickupForm,
        chefleMenuForm,
        seatForm,
        redirector,
        logger,
        routerMiddleware(history)
      )
    )
  )
  return store
}
