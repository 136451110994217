import { CALL_API } from '../middlewares/api'
import {
  FETCH_RESERVATION_NG,
  FETCH_RESERVATION_OK,
  FETCH_RESERVATION_REQUEST,
} from './index'
import queryString from 'query-string'

export const fetchReservation = payload => ({
  [CALL_API]: {
    types: [
      FETCH_RESERVATION_REQUEST,
      FETCH_RESERVATION_OK,
      FETCH_RESERVATION_NG,
    ],
    endpoint: `/chefle/reservations?${queryString.stringify({
      user_no: payload.userNo,
      token: payload.token,
      'status[]': [0, 2, 3, 5, 6, 7, 9],
      sort: 'datetime',
      order: 'asc',
    })}`,
    payload,
  },
})
