import { CALL_API } from '../middlewares/api'
import { CATEGORIES_REQUEST, CATEGORIES_OK, CATEGORIES_NG } from './index'

export const fetchCategories = (payload = {}) => ({
  [CALL_API]: {
    types: [CATEGORIES_REQUEST, CATEGORIES_OK, CATEGORIES_NG],
    endpoint: `/chefle/categories`,
    payload,
  },
})
