import { CALL_API } from '../middlewares/api'
import {
  UPDATE_RESEND_EMAIL_FORM,
  RESEND_EMAIL_POST_REQUEST,
  RESEND_EMAIL_POST_OK,
  RESEND_EMAIL_POST_NG,
} from './index'

export const updateResendEmailForm = (payload, meta) => ({
  type: UPDATE_RESEND_EMAIL_FORM,
  payload,
  meta,
})

export const postResendEmail = (payload = {}, meta = {}) => ({
  [CALL_API]: {
    types: [RESEND_EMAIL_POST_REQUEST, RESEND_EMAIL_POST_OK, RESEND_EMAIL_POST_NG],
    endpoint: `/chefle/remaind`,
    payload: {
      ...payload,
      method: 'POST',
    },
  },
})
