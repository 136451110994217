// eslint-disable-next-line no-unused-vars
import React from 'react'
import TextField from '@material-ui/core/TextField'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Formik } from 'formik'
import AuthComponent, {
  authMapDispatchToProps,
  authMapStateToProps,
} from '../../components/AuthComponent'
import Radio from '@material-ui/core/Radio'
import * as Yup from 'yup'

const CustomTextField = ({
  label,
  inputProps,
  disabled,
  handleChange,
  costomChange,
  values,
  errors,
  name,
  touched,
  setFieldTouched,
  multiline,
  rows,
  type,
  InputLabelProps,
}) => (
  <div style={{ position: 'relative' }}>
    <TextField
      disabled={disabled}
      inputProps={inputProps}
      // className={classes.textField}
      name={name}
      label={label}
      margin="dense"
      error={!!touched[name] && !!errors[name]}
      type={type}
      value={values[name]}
      multiline={multiline}
      rows={rows}
      onFocus={() => setFieldTouched(name, true, false)}
      onChange={e => {
        handleChange(e)
        costomChange(e, name)
      }}
      InputLabelProps={InputLabelProps}
    />
    {!disabled && !!touched[name] && errors[name] && (
      <div style={{ position: 'absolute', color: 'red', fontSize: '0.8rem' }}>
        {errors[name]}
      </div>
    )}
  </div>
)

function mapStateToProps(state) {
  return Object.assign(authMapStateToProps(state), {})
}

function mapDispatchToProps(dispatch) {
  return Object.assign(authMapDispatchToProps(dispatch), {})
}

class UsePoint extends AuthComponent {
  validationSchema = props => {
    return Yup.object({
      usePoint: Yup.number()
        .min(0, '0ポイント未満は指定できません。')
        .test('checkPerHundredPoint', '100P単位で入力してください。', point => {
          // 100で割り切れない場合、false
          return !(point % 100 !== 0 && point !== 0)
        })
        .test('checkEffectivePoint', 'ポイントが足りません。', point => {
          let effectivePoint = props.authUser.userInfo.effectivePoint
          // 利用ポイントがundefinedの時
          if (effectivePoint === undefined) {
            effectivePoint = 0
          }
          // ポイントがeffectivePoint未満の場合、false
          return Number(point) <= Number(effectivePoint)
        })
        .test(
          'checkTotalPrice',
          '利用ポイントはご予約金額内で指定してください。',
          point => {
            return Number(point) <= Number(props.menuForm.totalPrice)
          }
        ),
    })
  }

  handleChange = (event, name) => {
    // チェックボックスの場合
    if (name === 'isUsePoint') {
      if (event.target.value === 'unuse') {
        localStorage.setItem('formValue', this.props.menuForm.usePoint)
        this.props.onUpdate({
          ...this.props.menuForm,
          [name]: event.target.value,
          usePoint: 0,
        })
      }
      if (event.target.value === 'use') {
        this.props.onUpdate({
          ...this.props.menuForm,
          [name]: event.target.value,
          usePoint: localStorage.getItem('formValue'),
        })
        localStorage.removeItem('formValue')
      }
    } else {
      // フォーム入力された場合
      this.props.onUpdate({
        ...this.props.menuForm,
        [name]: event.target.value === '' ? 0 : event.target.value,
      })
    }
  }

  render() {
    const { classes, menuForm, errors } = this.props
    return (
      <>
        {/* TODO cssやhtmlタグのリファクタリング */}
        <h3 className="party-size-title use-point-content">
          <div className="use-point-title">ポイントの利用</div>
          <div />
          <div>
            <span style={{ fontSize: '0.8rem' }}>
              *100P単位で利用できます。
            </span>
            <br className="pc" />
            <span style={{ fontSize: '0.8rem' }} className="pc">
              *メニューをお選びいただいた後、利用ポイントをお決めください。
            </span>
          </div>
        </h3>

        <ul className="conditions-chice">
          <li className="use-points">
            <div
              style={{ display: 'flex', marginTop: '10px', fontSize: '16px' }}
            >
              Chefleポイント :
              <b style={{ color: 'black', marginLeft: '3px' }}>
                {this.props.authUser.userInfo.effectivePoint
                  ? this.props.authUser.userInfo.effectivePoint
                  : 0}
                P
              </b>
            </div>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Radio
                checked={menuForm.isUsePoint === 'use'}
                value="use"
                name="isUsePoint"
                onChange={e => this.handleChange(e, 'isUsePoint')}
              />
              <Formik
                validationSchema={() => this.validationSchema(this.props)}
                enableReinitialize={true}
                disabled={true}
                initialValues={0}
                onSubmit={null}
              >
                {props => (
                  <>
                    <CustomTextField
                      {...props}
                      type="number"
                      name="usePoint"
                      inputProps={{ step: 100 }}
                      classes={classes}
                      disabled={this.props.menuForm.isUsePoint === 'unuse'}
                      InputLabelProps={{ shrink: true }}
                      parentErrors={errors}
                      costomChange={(e, name) =>
                        this.handleChange(e, name, props)
                      }
                    />
                  </>
                )}
              </Formik>
              Pを利用する
            </div>

            <div>
              <Radio
                checked={menuForm.isUsePoint === 'unuse'}
                value="unuse"
                name="isUsePoint"
                onChange={e => {
                  this.handleChange(e, 'isUsePoint')
                }}
              />
              ポイントを利用しない
            </div>
          </li>
        </ul>
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UsePoint))
