import {
  UPDATE_MENU_FORM,
  UPDATE_PICKUP_FORM,
  UPDATE_CHEFLE_MENU_FORM,
} from '../actions'
import StringHelper from '../helpers/StringHelper'
import { fetchResevableTime } from '../actions/restaurant'

export default store => next => action => {
  if (action.type === UPDATE_MENU_FORM) {
    if (action.meta && action.meta.key === 'date') {
      const query = StringHelper.dateForServer(action.meta.value)
      const payload = {
        restaurantId: action.meta.restaurantId,
        query,
      }
      // next(fetchResevableTime(payload))
    }
  }
  if (action.type === UPDATE_PICKUP_FORM) {
    if (action.meta && action.meta.key === 'date') {
      const query = StringHelper.dateForServer(action.meta.value)
      const payload = {
        restaurantId: action.meta.restaurantId,
        query,
      }
      // next(fetchResevableTime(payload))
    }
  }
  if (action.type === UPDATE_CHEFLE_MENU_FORM) {
    if (action.meta && action.meta.key === 'date') {
      const query = StringHelper.dateForServer(action.meta.value)
      const payload = {
        restaurantId: action.meta.restaurantId,
        query,
      }
      // next(fetchResevableTime(payload))
    }
  }
  next(action)
}
