import React from 'react'
import { history } from '../../configureStore'
// import Modal from 'react-modal'

const CenterAscButton = props => (
  <a href="/reservation/chat.html" target="_blank">
    <img
      src="/assets/img/chat_icon_01.svg"
      alt=""
      className="fixed-footer-center"
    />
  </a>
)

// スマホ時に固定footerに表示させるボタン
const BackButton = props => {
  return (
    <a href="#!">
      <img
        src="/assets/img/back_icon_01.svg"
        alt=""
        className={footerClass[props.position]}
        onClick={e => {
          e.preventDefault()
          history.goBack()
        }}
      />
    </a>
  )
}

const CommonBackButton = props => {
  return (
    <p className="reserve-submit-btn reserve-confirm-btn">
      <form Style="text-align: center;">
        <input
          value="戻る"
          Style="text-align: center; background-color: #bcbcbc;"
          className={footerClass[props.position]}
          onClick={e => {
            e.preventDefault()
            history.goBack()
          }}
        />
      </form>
    </p>
  )
}

const ChatButton = props => {
  return (
    <a href="https://jpneazy.com/reservation/chat.html" target="blank">
      <img
        src="/assets/img/chat_icon_02.svg"
        alt=""
        className={footerClass[props.position]}
      />
    </a>
  )
}

const footerClass = {
  left: 'fixed-footer-left',
  right: 'fixed-footer-right',
}

export { CenterAscButton, BackButton, CommonBackButton, ChatButton }
