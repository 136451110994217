const initialState = {
  authInfo: {
    user_no: localStorage.getItem('authInfo.userNo'),
    token: localStorage.getItem('authInfo.token'),
    isLogin: localStorage.getItem('authInfo.isLogin'),
  },
  tempAuthInfo: {},
  userInfo: {},
  errors: {},
  errorMessage: '',
  redirectPath: '',
}

let setStorage = (key, value) => {
  value ? localStorage.setItem(key, value) : localStorage.setItem(key, '')
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SIGN_IN_POST_OK':
      if (action.payload.data.error === 0) {
        // 正常時
        setStorage('authInfo.userNo', action.payload.data.body.userNo)
        setStorage('authInfo.token', action.payload.data.body.token)
        setStorage('authInfo.isLogin', true)
        return {
          ...state,
          tempAuthInfo: {
            user_no: action.payload.data.body.userNo,
            token: action.payload.data.body.token,
          },
        }
      } else {
        // エラー時
        localStorage.removeItem('authInfo.userNo')
        localStorage.removeItem('authInfo.token')
        localStorage.removeItem('authInfo.isLogin')
        return {
          ...state,
          authInfo: {},
          userInfo: {},
          redirectPath: '',
          errorMessage: action.payload.data.body.message,
        }
      }
    case 'AUTH_USER_POST_REQUEST':
      return {
        ...state,
        loadingFlag: true,
      }
    case 'AUTH_USER_POST_OK':
      if (action.payload.data.error === 0) {
        // 正常時
        return {
          ...state,
          userInfo: action.payload.data.body,
          loadingFlag: false,
          isLogin: true,
        }
      } else if (action.payload.data.error === 1) {
        // 認証エラー
        localStorage.removeItem('authInfo.userNo')
        localStorage.removeItem('authInfo.token')
        localStorage.removeItem('authInfo.isLogin')
        return {
          ...state,
          authInfo: {},
          userInfo: {},
          errorMessage: action.payload.data.body.message,
          redirectPath: '/sign_in',
          loadingFlag: false,
          isLogin: false,
        }
      } else {
        // エラー時
        localStorage.removeItem('authInfo.userNo')
        localStorage.removeItem('authInfo.token')
        localStorage.removeItem('authInfo.isLogin')
        return {
          ...state,
          authInfo: {},
          userInfo: {},
          errorMessage: action.payload.data.body.message,
          redirectPath: '',
          loadingFlag: false,
          isLogin: false,
        }
      }
    case 'AUTH_USER_POST_NG':
      localStorage.removeItem('authInfo.userNo')
      localStorage.removeItem('authInfo.token')
      localStorage.removeItem('authInfo.isLogin')
      return {
        ...state,
        authInfo: {},
        userInfo: {},
        errorMessage: 'システムエラーが発生しております。しばらくお待ちください。',
        redirectPath: '',
        loadingFlag: false,
        isLogin: false,
      }
    case 'SIGN_OUT_POST_REQUEST':
      return {
        ...state,
        redirectPath: '/',
        loadingFlag: true,
      }
    case 'SIGN_OUT_POST_OK':
      if (action.payload.data.error === 0) {
        // 正常時
        localStorage.removeItem('authInfo.userNo')
        localStorage.removeItem('authInfo.token')
        localStorage.removeItem('authInfo.isLogin')
        return {
          ...state,
          authInfo: {},
          userInfo: {},
          loadingFlag: false,
          isLogin: false,
        }
      } else {
        // エラー時
        localStorage.removeItem('authInfo.userNo')
        localStorage.removeItem('authInfo.token')
        localStorage.removeItem('authInfo.isLogin')
        return {
          ...state,
          errorMessage: action.payload.data.body.message,
          authInfo: {},
          userInfo: {},
          redirectPath: '',
          loadingFlag: false,
          isLogin: false,
        }
      }
    case 'SIGN_OUT_POST_NG':
      localStorage.removeItem('authInfo.userNo')
      localStorage.removeItem('authInfo.token')
      localStorage.removeItem('authInfo.isLogin')
      return {
        ...state,
        errorMessage: 'システムエラーが発生しております。しばらくお待ちください。',
        authInfo: {},
        userInfo: {},
        redirectPath: '',
        loadingFlag: false,
        isLogin: false,
      }
    default:
      return state
  }
}
