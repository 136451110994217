import React from 'react'
import { connect } from 'react-redux'
import { PcHeader, SpHeader } from '../components/top/Header'
import AuthComponent, {
  authMapStateToProps,
  authMapDispatchToProps,
} from '../components/AuthComponent'

function mapStateToProps(state) {
  return authMapStateToProps(state)
}

function mapDispatchToProps(dispatch) {
  return authMapDispatchToProps(dispatch)
}

class PrivacyPolicy extends AuthComponent {
  componentDidMount() {
    super.componentDidMount()
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
  }

  render() {
    const { authUser } = this.props

    return (
      <>
        <div className="top-page">
          <PcHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
            reservationType=""
          />
          <SpHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
            reservationType=""
          />

          <section>
            <div
              className="top-page-selection"
              Style="margin-top: 100px; padding: 10px;"
            >
              <div className="modal" Style="font-family: poppins;">
                <h3 className="modal-title">個人情報保護方針</h3>
                <div className="modal-content">
                  株式会社Fesbase(以下｢当社｣といいます。当社の所在地及び代表者については
                  <a href="https://www.fesbase.co.jp/">｢会社情報｣</a>
                  をご確認下さい。)は､個人情報(個人情報の保護に関する法律(以下｢個人情報保護法｣といいます。)第2条第1項に定義される個人情報をいいます。以下同じ。)について､個人情報保護に関する法令及びその他の規範を遵守し､かつ国際的な動向にも配慮して自主的なルール及び体制を確立し､以下のとおり個人情報保護方針(｢個人情報の取扱いについて｣の内容も含みます。以下｢本方針｣といいます。)を定めます。
                  <br />
                  <br />
                  1.
                  当社は､本方針を実行するために｢個人情報管理規程｣を定めるとともに､当社の全従業員(一般役職員､パートタイマー､業務委託先等を含みます。)､その他関係者に周知徹底させて本方針を実行してまいります。
                  <br />
                  <br />
                  2.
                  当社は､個人情報の漏えい､滅失又はき損を防止するため､不正アクセス対策､コンピュータウイルス対策など適切な情報セキュリティ対策を講じます。
                  <br />
                  <br />
                  3.
                  当社は､個人情報を取得する場合､その利用目的をできる限り特定した上で､適法かつ公正な手段によって取得いたします。また､その利用目的の達成に必要な範囲内でこれを取り扱います。
                  <br />
                  <br />
                  4.
                  当社は､個人情報の主体である本人が､自己の個人情報について､開示､訂正､使用停止､消去等の権利を有していることを確認し､本人からのこれらの要求に対して本人確認を行ったうえで適切に対応します。
                  <br />
                  <br />
                  5.
                  当社は､個人情報を第三者との間で共同利用したり､個人情報の取扱いを第三者に委託する場合､当該第三者について必要な契約を締結し､その他法令上必要な措置を講じます。
                  <br />
                  <br />
                  <p Style="text-align: right;">最終更新日:令和4年4月7日</p>
                  <p Style="text-align: right;">株式会社Fesbase</p>
                </div>
                <h3 className="modal-title">個人情報の取扱いについて</h3>
                <div className="modal-content">
                  株式会社Fesbase(以下｢当社｣といいます。)は､個人情報の適切な取り扱いが個人情報取扱事業者としての重大な責務であると認識し､個人情報保護方針に基づき､皆様の個人情報を次のとおり取り扱います。
                  <br />
                  <br />
                  <div className="sentence">
                    <h3>1. 個人情報の利用目的について</h3>
                    (1)
                    当社は､取得した個人情報を下記の利用目的の達成に必要な範囲内において利用させていただきます。また､ウェブサイトの他のページや通信販売等におけるアンケートなどで特に利用目的を定めている場合は､それらの利用目的に従い利用します。
                    <br />
                    <br />
                    利用目的は下記の通りです。
                    <br />
                    <br />
                    <p>
                      ① お客様が当社サービスを円滑に利用できるようにするため
                    </p>
                    <p>
                      ② 商品やサービス等に関する情報提供や広告の表示･配信のため
                    </p>
                    <p>
                      ③
                      当社サービス利用に関する統計データを作成し改善につなげるため
                    </p>
                    <p>④ お客様からのお問い合わせに対する対応のため</p>
                    <p>
                      ⑤
                      現在提供している当社サービスまたは今後提供を検討している当社サービスに関するアンケート実施のため
                    </p>
                    <p>
                      ⑥ 今後の当社サービスに関する新企画立案を行い提供するため
                    </p>
                    <p>⑦ キャンペーン等の抽選および賞品や商品発送のため</p>
                    <p>⑧ 商品購入時におけるご請求処理のため</p>
                    <p>
                      ⑨
                      その他当社サービスに関する重要なお知らせ等､必要に応じた連絡を行うため
                    </p>
                    <p>
                      ⑩
                      上記のほか､個人情報をご提供いただく際に当社が提示した利用目的
                    </p>
                    <br />
                    <p>
                      (2)
                      当社は､広告配信事業者､アフィリエイト事業者､DMP事業者等の広告関連の事業者やその他の第三者から､お客様に関連する情報(ウェブサイト閲覧履歴､サービス利用履歴､位置情報(GPS情報を含みます)､OS等の端末情報及び､Cookie
                      ID､広告識別子､IPアドレス､その他のオンライン識別子など)を取得し､取得した情報を当社が既に有しているお客様の個人情報と紐づけて利用する場合があります。
                    </p>
                    <br />
                    <h3>2. 第三者への個人情報の提供について</h3>
                    <p>
                      当社は､法令で定める場合を除き､個人情報を本人の同意なく第三者に提供または開示いたしません。
                    </p>
                    <br />
                    <h3>3. 外国にある第三者への個人情報の提供について</h3>
                    <p>
                      当社は､当社が取得した個人情報を､外国にある第三者に提供することがあります。当該外国は､日本と同等の水準の個人情報保護制度を有していない場合がありますが､当社は､法令上許容される場合や法令に定める方法により本人の同意を取得した場合を除いては､日本の個人情報保護法上求められる措置を実施している外国にある第三者以外には､当社が取得した個人情報を提供いたしません。
                    </p>
                    <br />
                    <h3>4. 個人情報の管理について</h3>
                    <p>
                      当社は､その取り扱う個人情報の漏えい､滅失又はき損の防止その他個人情報の安全管理のために､必要かつ適切な措置を講じています。当社における個人情報の取得体制及び講じている安全管理措置の内容については､｢8.
                      お問い合わせ窓口｣記載の窓口までお問い合わせください。
                    </p>
                    <br />
                    <h3>5. 個人情報の開示､訂正､利用停止等の手続について</h3>
                    <p>
                      当社は､保有する個人情報に関して､本人から利用目的の通知､開示(第三者提供記録の開示を含みます。)､訂正､追加､削除､利用停止､消去または第三者への提供停止を求められた場合は､法令等に従い適切に対応します。
                    </p>
                    <br />
                    <p>
                      これらのご請求をご希望の場合は､ご申請のための手続き書類等をお送りいたしますので､｢8.
                      お問い合わせ窓口｣記載の窓口にご連絡ください。
                    </p>
                    <br />
                    <h3>6. Cookie等の使用について</h3>
                    <p>(1) Cookieについて</p>
                    <p>
                      当社ではCookieやBecon等を用いるなどして、本人が容易に認識できない方法による個人情報の取得は行っておりません。
                    </p>
                    <br />
                    <p>(2) Google Analyticsについて</p>
                    <p>
                      当社は､公式ウェブサイトの利用状況を把握するためにGoogle
                      Analyticsを利用しており､Google
                      AnalyticsはCookieを利用して利用者の情報を収集します。詳細については､Googleのウェブサイトをご覧ください。
                    </p>
                    <br />
                    <p>
                      <a href="https://marketingplatform.google.com/about/analytics/terms/jp/">
                        Google Analytics利用規約 (外部サイト)
                      </a>
                    </p>
                    <br />
                    <p>
                      <a href="https://policies.google.com/privacy?hl=ja">
                        Googleプライバシーポリシー (外部サイト)
                      </a>
                    </p>
                    <br />
                    <h3>7. 本方針の変更</h3>
                    <p>
                      当社は､本方針の全部又は一部を予告なく変更させていただく場合がございます。その際は､本サイトに改訂版を掲載しますので､本サイトに掲載された本方針を定期的にご確認いただくようお願いします。
                    </p>
                    <br />
                    <h3>8. お問い合わせ窓口</h3>
                    <p>
                      ご意見､ご質問､苦情のお申出その他当社が保有する個人情報の取扱いに関するお問い合わせは､下記の窓口までお願いいたします。
                    </p>
                    <br />
                    <p>窓口となる部署:株式会社 Fesbase 個人情報受付窓口</p>
                    <br />
                    <p>〒141-0021東京都品川区上大崎3丁目 5-3-702</p>
                    <br />
                    <p>tel:03-4578-5923</p>
                    <br />
                    <p Style="text-align: right;">最終更新日:令和4年4月7日</p>
                    <p Style="text-align: right;">株式会社Fesbase</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivacyPolicy)
