import React from 'react'
import CompleteIcon from './common/CompleteIcon'
import { MenuIcon, SideMenu } from './menu/SideMenu'
import { Helmet } from 'react-helmet'
import ReactPixel from 'react-facebook-pixel'

const head = {
  title: `Reservation | Chefle - Reservation`,
  description: '',
}
class ReserveComplete extends React.Component {
  componentDidMount() {
    ReactPixel.init('502037183268067')
    ReactPixel.pageView()
    // setTimeout(() => {
    //   this.props.history.push('/')
    // }, 3000)
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>{head.title}</title>
          <meta property="og:title" content={head.title} />
          <meta property="og:description" content={head.description} />
          <meta name="description" content={head.description} />
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className="reserve-complete">
          <div className="main-inner">
            <CompleteIcon />
            <p className="reserve-complete-txt">Thank you for using Chefle!</p>
            <p className="reserve-complete-txt2">
              We are working on your Reservation.
              <br />
              We’ll send you email for farther notice.
            </p>
            <a href="/" className="reserve-complete-return-btn">
              Return to Home
            </a>
          </div>
        </div>
        <SideMenu />
      </div>
    )
  }
}
export default ReserveComplete
