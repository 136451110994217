const initialState = {
  ID: '',
  pass: '',
  errors: {},
  errorMessage: '',
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_SIGN_IN_FORM':
      return {
        ...state,
        ...action.payload,
      }
    case 'SIGN_IN_POST_REQUEST':
      return {
        ...state,
        loadingFlag: true,
      }
    case 'SIGN_IN_POST_OK':
      // エラーメッセージはauthUser.jsで返すようにしているため、こちらでの対応は不要
      return {
        ...state,
        loadingFlag: false,
      }
    case 'SIGN_IN_POST_NG':
      return {
        ...state,
        loadingFlag: false,
        errorMessage:
          'システムエラーが発生しております。しばらくお待ちください。',
      }
    default:
      return state
  }
}
