import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Link } from 'react-router-dom'

class ReminderToSignIn extends React.Component {
  state = {
    open: false,
  }
  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }
  render() {
    return (
      <>
        <a
          href={() => false}
          variant="outlined"
          className={this.props.className}
          onClick={this.handleClickOpen}
          Style="cursor: pointer;"
        >
          {this.props.text}
        </a>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'予約申込は会員登録/ログイン後に行えます。'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" className="">
              <Link to="/sign_up" className={'sign-in-btn sign-in-btn-o'}>
                新規登録
              </Link>
              <Link
                to="/sign_in"
                color="primary"
                className={'sign-in-btn sign-in-btn-w'}
              >
                ログイン
              </Link>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </>
    )
  }
}

export default ReminderToSignIn
