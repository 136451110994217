import React, { PureComponent } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import { DatePicker } from 'material-ui-pickers'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'

const errorMessageStyle = {
  position: 'absolute',
  marginTop: '8px',
  color: 'red',
  fontSize: '0.8rem',
}
class CustomDatePicker extends PureComponent {
  state = {
    selectedTime: '',
  }

  handleDateChange = date => {
    this.props.onUpdate({ date })
  }

  handleChange = e => {
    this.setState({ selectedTime: e.target.value })
    this.props.onUpdate({ time: e.target.value })
  }

  disableDate(date, disableDates) {
    const calenderDate = date.getTime()

    if (disableDates.length === 0) {
      return false
    }

    return disableDates.some(
      d => new Date(d.split('-').join('/')).getTime() === calenderDate
    )
  }

  onMonthChange = date => {
    const restaurantId = this.props.restaurantId
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const partySize = this.props.partySize

    this.props.fetchCalendar({ restaurantId, year, month, partySize })
  }

  getDisableDate = () => {
    const disableDates = []
    if (!this.props.calendar.data) {
      return []
    }
    this.props.calendar.data.forEach(calendar => {
      if (!calendar.isReservable) {
        disableDates.push(calendar.date)
      }
    })

    return disableDates
  }

  getTimes = () => {
    if (!this.props.selectedDate) {
      return []
    }
    const date = this.props.selectedDate
    const y = date.getFullYear()
    const m = ('00' + (date.getMonth() + 1)).slice(-2)
    const d = ('00' + date.getDate()).slice(-2)
    const ymd = y + '-' + m + '-' + d

    const times = []
    this.props.calendar.data.forEach(calendar => {
      if (calendar.date === ymd) {
        calendar.timetables.forEach(timetable => {
          times.push(timetable.time)
        })
      }
    })

    return times
  }

  render() {
    const { selectedDate } = this.props

    return (
      <>
        <li className="reserve-form-date">
          <span style={!!this.props.error1 ? { color: '#f44336' } : {}}>
            日付
          </span>
          <div style={{ textAlign: 'center' }} />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              disablePast
              shouldDisableDate={e =>
                this.disableDate(e, this.getDisableDate())
              }
              value={selectedDate}
              onChange={this.handleDateChange}
              autoOk={true}
              onMonthChange={date => this.onMonthChange(date)}
            />
          </MuiPickersUtilsProvider>
          {this.props.error1 && (
            <div style={errorMessageStyle}>{this.props.error1}</div>
          )}
        </li>
        <li className="reserve-form-time">
          <span style={!!this.props.error2 ? { color: '#f44336' } : {}}>
            時間
          </span>
          <div style={{ textAlign: 'center' }} />
          <FormControl>
            <Select
              value={this.state.selectedTime}
              onChange={e => this.handleChange(e)}
              error={!!this.props.error}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {this.getTimes().map((m, i) => (
                <MenuItem key={'reservableTime-' + i} value={m}>
                  {m}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {this.props.error2 && (
            <div style={errorMessageStyle}>{this.props.error2}</div>
          )}
        </li>
      </>
    )
  }
}
class CustomTimePicker extends PureComponent {
  state = {
    selectedTime: '',
  }

  handleChange = e => {
    this.setState({ selectedTime: e.target.value })
    this.props.onUpdate({ time: e.target.value })
  }
  render() {
    return (
      <>
        <FormControl>
          <Select
            value={this.state.selectedTime}
            onChange={e => this.handleChange(e)}
            error={!!this.props.error}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {this.props.reservableTimes.map((m, i) => (
              <MenuItem key={'reservableTime-' + i} value={m}>
                {m}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {this.props.error && (
          <div style={errorMessageStyle}>{this.props.error}</div>
        )}
      </>
    )
  }
}

export { CustomTimePicker, CustomDatePicker }
