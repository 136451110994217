const initialState = {
  data: {},
}
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_CREDITCARD_REQUEST':
      return {
        ...state,
        loadingFlag: true,
      }
    case 'FETCH_CREDITCARD_OK':
      return {
        ...state,
        data: action.payload.data,
        loadingFlag: false,
      }
    case 'FETCH_CREDITCARD_NG':
      return {
        ...state,
        loadingFlag: false,
      }

    case 'CREDITCARD_POST_REQUEST':
      return {
        ...state,
        loadingFlag: true,
      }
    case 'CREDITCARD_POST_OK':
      return {
        ...state,
        data: action.payload.data,
        loadingFlag: false,
      }
    case 'CREDITCARD_POST_NG':
      return {
        ...state,
        data: {
          error: action.errror,
        },
        loadingFlag: false,
      }

    case 'CREDITCARD_UPDATE_REQUEST':
      return {
        ...state,
        loadingFlag: true,
      }
    case 'CREDITCARD_UPDATE_OK':
      return {
        ...state,
        data: action.payload.data,
        loadingFlag: false,
      }
    case 'CREDITCARD_UPDATE_NG':
      return {
        ...state,
        data: {
          error: action.errror,
        },
        loadingFlag: false,
      }

    case 'CREDITCARD_DELETE_REQUEST':
      return {
        ...state,
        loadingFlag: true,
      }
    case 'CREDITCARD_DELETE_OK':
      return {
        ...state,
        data: action.payload.data,
        loadingFlag: false,
      }
    case 'CREDITCARD_DELETE_NG':
      return {
        ...state,
        data: {
          error: action.errror,
        },
        loadingFlag: false,
      }
    default:
      return state
  }
}
