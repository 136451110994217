import { CALL_API } from '../middlewares/api'
import {
  CREDITCARD_PAYMENT_POST_REQUEST,
  CREDITCARD_PAYMENT_POST_OK,
  CREDITCARD_PAYMENT_POST_NG,
} from './index'

export const postCreditcardPayment = (payload = {}, meta = {}) => ({
  [CALL_API]: {
    types: [
      CREDITCARD_PAYMENT_POST_REQUEST,
      CREDITCARD_PAYMENT_POST_OK,
      CREDITCARD_PAYMENT_POST_NG,
    ],
    endpoint: '/chefle/payment',
    payload: {
      ...payload,
      method: 'POST',
    },
  },
})
