import React from 'react'
import StringHelper from '../../helpers/StringHelper'

function getRateImage(count) {
  let rateDefine = [
    {
      min: 0,
      max: 0.7,
      image: 'star_0half.svg',
    },
    {
      min: 0.8,
      max: 1.2,
      image: 'star_1.svg',
    },
    {
      min: 1.3,
      max: 1.7,
      image: 'star_1half.svg',
    },
    {
      min: 1.8,
      max: 2.2,
      image: 'star_2.svg',
    },
    {
      min: 2.3,
      max: 2.7,
      image: 'star_2half.svg',
    },
    {
      min: 2.8,
      max: 3.2,
      image: 'star_3.svg',
    },
    {
      min: 3.3,
      max: 3.7,
      image: 'star_3half.svg',
    },
    {
      min: 3.8,
      max: 4.2,
      image: 'star_4.svg',
    },
    {
      min: 4.2,
      max: 4.7,
      image: 'star_4half.svg',
    },
    {
      min: 4.8,
      max: 5,
      image: 'star_5.svg',
    },
  ]
  let result = rateDefine.find(item => item.min <= count && item.max >= count)
  return result ? result.image : ''
}

const Review = props => (
  <div className="restaurant-review-summary">
    <h2 className="section-title">レビュー サマリー</h2>
    <div className="restaurant-review-summary-box">
      <p className="restaurant-review-summary-num">
        {props.rating ? Math.round(props.rating * 10) / 10 : ''}
      </p>
      <div className="restaurant-review-summary-evaluation">
        <img
          src={`/assets/img/${getRateImage(
            props.rating ? Math.round(props.rating * 10) / 10 : ''
          )}`}
          alt="レビュースター"
          className="restaurant-review-summary-star"
        />
      </div>
    </div>
    <ul className="restaurant-review-summary-txt-container">
      {props.reviews && props.reviews[0] && (
        <li key={props.reviews[0].time}>
          <div className="restaurant-review-summary-txt-img">
            <img
              src={props.reviews[0].profilePhotoUrl}
              alt="レビュー"
              className="restaurant-review-summary-txt-img"
            />
          </div>
          <p className="restaurant-review-summary-txt">
            {StringHelper.omission(props.reviews[0].text, 300)}
          </p>
        </li>
      )}
    </ul>
    <a
      href={'https://search.google.com/local/reviews?placeid=' + props.placeid}
      className="restaurant-review-summary-more"
      target="_blank"
      rel="noopener noreferrer"
    >
      More Google reviews
    </a>
  </div>
)

export default Review
