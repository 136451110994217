import React from 'react'
import { decode } from 'html-entities'

const StringHelper = {
  getCourse: input => {
    if (!input) return input
    const result = input.split(/\n/)
    return result ? result[0] : null
  },
  parseDescription: input => {
    if (!input) return input
    let result = input.split(/\n/)
    result.shift()
    return result.map((r, i) => (
      <React.Fragment key={i + 'description'}>
        <span>{r}</span>
        <br />
      </React.Fragment>
    ))
  },
  dateParse: date => {
    return (
      date.getFullYear() +
      ',' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + date.getDate()).slice(-2)
    )
  },
  dateForServer: date => {
    return (
      date.getFullYear() +
      '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date.getDate()).slice(-2)
    )
  },
  alternativeDateForServer: (date, diff) => {
    let alternativeDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + diff
    )
    return (
      alternativeDate.getFullYear() +
      '-' +
      ('0' + (alternativeDate.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + alternativeDate.getDate()).slice(-2)
    )
  },
  numberWithCommas: x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
  omission: (x, size) => {
    return x && x.length >= size ? x.substring(0, size) + '...' : x
  },
  nl2br: text => {
    if (!text) return text
    return text.split('\n').map((item, key) => (
      <span key={key}>
        {item}
        <br />
      </span>
    ))
  },
  htmlToText: html => {
    return decode(String(html).replace(/<[^>]+>/g, ''))
  },
}

export default StringHelper
