import React, { PureComponent } from 'react'

class AboutChefle extends PureComponent {
  sideMenuClose() {
    var menuCont = document.getElementById('about-chefle-container')
    var hTag = document.getElementsByTagName('html')
    hTag[0].classList.remove('html-fix')
    menuCont.classList.remove('menu-move-open')
    menuCont.classList.add('menu-move-close')
  }

  render() {
    return (
      <div id="about-chefle-container" className="about-chefle-container">
        <div className="menu-container-inner">
          <div Style="display:flex;">
            <a
              id="about-chefle-close"
              className="menu-close-btn"
              href={() => false}
              onClick={() => this.sideMenuClose()}
            >
              <img src="/assets/img/close_icon_01.svg" alt="" />
            </a>
          </div>
          <div className="top-page-selection-head" Style="padding-top: 20px;">
            <div className="top-page-selection-box" Style="padding: 10px 14px;">
              <div className="top-page-selection-box-img">
                <img
                  src="/assets/img/chefle/about_chefle1_sp.png"
                  alt="chefleについて"
                  Style="width: 220px;"
                />
              </div>
              <div Style="margin-top: 15px; font-weight: bold;">
                厳選した人気飲食店を掲載
              </div>
              <p>
                お食事のカテゴリー / 日程 / 時間 /
                場所の決定権は全てあなたにあります。
                <br />
                コンシェルジュのサポートもご活用ください。
              </p>
            </div>
            <div className="top-page-selection-box" Style="padding: 10px 14px;">
              <div className="top-page-selection-box-img">
                <img
                  src="/assets/img/chefle/about_chefle2_sp.png"
                  alt=""
                  Style="width: 220px;"
                />
              </div>
              <div Style="margin-top: 15px; font-weight: bold;">
                WEBで簡単予約
              </div>
              <p>個別質問フォームの回答まで完了したら、あとは待つのみ。</p>
            </div>
            <div
              className="top-page-selection-box"
              Style="padding: 10px 14px; 20px;"
            >
              <div className="top-page-selection-box-img">
                <img
                  src="/assets/img/chefle/about_chefle3_sp.png"
                  alt="chefleについて３"
                  Style="width: 220px;"
                />
              </div>
              <div Style="margin-top: 15px; font-weight: bold;">
                シェフがご自宅で一流の料理を調理
              </div>
              <p>
                贅沢を味わいましょう。
                <br />
                当日は、日常の疲れを癒し、食があなたを幸せの絶頂へともたらします。
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class AboutChefleIcon extends PureComponent {
  sideMenuOpen() {
    var menuCont = document.getElementById('about-chefle-container')
    var hTag = document.getElementsByTagName('html')
    hTag[0].classList.add('html-fix')
    menuCont.classList.remove('menu-move-close')
    menuCont.classList.add('menu-move-open')
  }

  render() {
    // メニューアイコンの色を判断
    let src = '/assets/img/chefle/about_chefle_sp.png'

    return (
      <a
        href={() => false}
        id="about-chefle-open"
        Style={'cursor: pointer; width:320px;'}
        className="menu-icon"
        onClick={() => this.sideMenuOpen()}
      >
        <img src={src} alt="menu" />
      </a>
    )
  }
}
export { AboutChefle, AboutChefleIcon }
