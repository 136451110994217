import React from 'react'
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import queryString from 'query-string'
import { fetchPickupRestaurantList } from '../../actions/restaurantList'
import { fetchImmediatePickupRestaurantList } from '../../actions/immediateRestaurantList'
import isEqual from 'lodash/isEqual'
import { RestaurantCard } from '../../components/restaurant/RestaurantCard'
import { fetchAreas } from '../../actions/areas'
import { fetchCategories } from '../../actions/categories'
import { getActiveCampaign } from '../../actions/campaign'
import { history } from '../../configureStore'
import cleanDeep from 'clean-deep'
import StringHelper from '../../helpers/StringHelper'
import { Helmet } from 'react-helmet'
import { PcHeader, SpHeader } from '../../components/top/Header'
import AuthComponent, {
  authMapStateToProps,
  authMapDispatchToProps,
} from '../../components/AuthComponent'
import ReactLoading from 'react-loading'

function mapStateToProps(state) {
  return Object.assign(authMapStateToProps(state), {
    restaurantList: state.restaurantList,
    immediateRestaurantList: state.immediateRestaurantList,
    areas: state.areas,
    categories: state.categories,
    campaign: state.campaign,
  })
}

function mapDispatchToProps(dispatch) {
  return Object.assign(authMapDispatchToProps(dispatch), {
    fetch: bindActionCreators(fetchPickupRestaurantList, dispatch),
    fetchImmediatePickupRestaurantList: bindActionCreators(
      fetchImmediatePickupRestaurantList,
      dispatch
    ),
    fetchAreas: bindActionCreators(fetchAreas, dispatch),
    fetchCategories: bindActionCreators(fetchCategories, dispatch),
    getActiveCampaign: bindActionCreators(getActiveCampaign, dispatch),
  })
}

class PickupContainer extends AuthComponent {
  constructor(props) {
    super(props)
    this.state = {
      scrollPosition: 0,
    }
  }

  componentDidMount() {
    super.componentDidMount()

    const urlQuery = queryString.parse(this.props.location.search)
    this.props.fetchAreas()
    this.props.fetchCategories()
    this.props.fetch(Object.assign(urlQuery, this.props.match.params))
    this.props.fetchImmediatePickupRestaurantList(
      Object.assign(urlQuery, this.props.match.params)
    )
    this.props.getActiveCampaign()
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
    if (
      !prevProps.campaign.data.id &&
      this.props.campaign.data.id &&
      !Object.keys(queryString.parse(this.props.location.search)).length
    ) {
      history.push(history.location.pathname + '?campaign=active')
      return
    }

    const urlQuery = Object.assign(
      queryString.parse(this.props.location.search),
      this.props.match.params
    )
    const prevQuery = Object.assign(
      queryString.parse(prevProps.location.search),
      prevProps.match.params
    )
    if (!isEqual(urlQuery, prevQuery)) {
      this.props.fetch(urlQuery)
      this.props.fetchImmediatePickupRestaurantList(urlQuery)

      // this.props.fetch('type=pickup')
    }
  }

  addParamQueryString(param) {
    let query = queryString.parse(this.props.location.search)
    return queryString.stringify(Object.assign(query, param))
  }

  renderPaging = restaurantList => {
    const { currentPage, lastPage } = restaurantList

    let array = []
    let hasLast = false
    let hasFirst = false

    let first = Math.max(currentPage - 1, 1)
    let last = Math.min(first + 3, lastPage)
    for (let i = first; i <= last; i++) {
      array.push({
        no: i,
        current: Number(currentPage) === i ? true : false,
      })
      if (i === 1) {
        hasFirst = true
      }
      if (i === lastPage) {
        hasLast = true
      }
    }
    if (!hasFirst) {
      array.unshift({ no: '...', current: false })
      array.unshift({ no: 1, current: false })
    }
    if (currentPage !== lastPage && hasLast === false) {
      array.push({ no: '...', current: false })
      array.push({ no: lastPage, current: false })
    }
    return array.map(a => (
      <React.Fragment key={a.no + '-page'}>
        {!a.current ? (
          <li>
            <Link
              to={`${history.location.pathname}?${this.addParamQueryString({
                page: a.no,
              })}`}
            >
              {a.no}
            </Link>
          </li>
        ) : (
          <li className="top-page-store-pager-active">
            <a className="top-page-store-pager-active" href={() => false}>
              {a.no}
            </a>
          </li>
        )}
      </React.Fragment>
    ))
  }

  makeDescription = (areaName, categoryName, campaignSelect) => {
    let descriptionWords = []

    if (this.props.campaign.data.description && campaignSelect) {
      return StringHelper.nl2br(this.props.campaign.data.description)
    }

    descriptionWords.push('Reserve now at top')

    if (
      categoryName &&
      categoryName !== 'All Cuisines' &&
      areaName === 'All Areas'
    ) {
      descriptionWords.push(categoryName)
    }

    if (
      areaName &&
      areaName !== 'All Areas' &&
      categoryName === 'All Cuisines'
    ) {
      descriptionWords.push(areaName)
    }

    categoryName &&
    categoryName !== 'All Cuisines' &&
    areaName &&
    areaName !== 'All Areas'
      ? descriptionWords.push(`${categoryName} restaurants in ${areaName},`)
      : descriptionWords.push('restaurants,')

    descriptionWords.push(
      'read reviews, explore menus & photos. Search restaurants by cuisine, price, location, and more.'
    )

    let result = descriptionWords.join(' ')
    return result
  }

  changeWhereToEat = area => {
    let url = this.createUrlWithoutPage(area, this.props.match.params.category)
    history.push(url)
  }

  changeWhatToEat = category => {
    let url = this.createUrlWithoutPage(this.props.match.params.area, category)
    history.push(url)
  }

  createUrlWithoutPage = (area, category) => {
    let url =
      area && category
        ? `/${area}/${category}`
        : area && !category
        ? `/${area}`
        : !area && category
        ? `/cuisine/${category}`
        : '/'

    let query = queryString.parse(history.location.search)
    delete query.page

    return url + '?' + queryString.stringify(query)
  }

  changeFilters = payload => {
    let query = queryString.parse(history.location.search)
    query = Object.assign(query, payload)
    let filterd = cleanDeep(query)
    delete filterd.page
    history.push(
      history.location.pathname + '?' + queryString.stringify(filterd)
    )
  }

  //  人気ランキングソート
  sortPopularity = payload => {
    let query = queryString.parse(history.location.search)
    query = Object.assign(query, payload)
    let filterd = cleanDeep(query)
    delete filterd.page

    history.push(
      history.location.pathname + '?' + queryString.stringify(filterd)
    )
  }

  render() {
    const { restaurantList, authUser, immediateRestaurantList } = this.props
    const campaignSelect = queryString.parse(this.props.location.search)
      .campaign

    const resultString = {
      padding: '20px 10px 10px 10px',
      color: '#707070',
      fontSize: '20px',
    }

    const head = {
      title: `シェフル(chefle) - 有名高級店を、テイクアウト。${
        restaurantList.currentPage > 1
          ? `（${restaurantList.currentPage}ページ目）`
          : ''
      }`,
      description:
        'シェフル（Chefle）では、人気高級店のテイクアウトを事前予約することが可能です。ご自身でお電話することが難しいお仕事中や、家事の合間にネットから簡単にお申込み。ご予約した指定時間に、ご自身でピックアップをお願いします。ご自宅で高級店のお味をお楽しみください。',
      page_url: 'https://chefle.com/pickup',
      image: 'http://chefle.com/assets/img/ogp_pickup.jpeg',
    }

    const pcSlideImages = ['/assets/img/chefle/mainvisual_pickup_pc.png']

    const spSlideImages = ['/assets/img/chefle/mainvisual_pickup_sp.png']

    const spinnerProperties = isMobile
      ? { height: '30%', width: '30%' }
      : { height: '12%', width: '12%' }

    return (
      <>
        <div
          className={
            campaignSelect && this.props.campaign.data.id
              ? 'top-page campaign'
              : 'top-page'
          }
        >
          <Helmet>
            <link rel="canonical" href="https://chefle.com/pickup" />
            <title>{head.title}</title>
            <meta property="og:title" content={head.title} />
            <meta property="og:description" content={head.description} />
            <meta property="og:url" content={head.page_url} />
            <meta name="description" content={head.description} />
            <meta property="og:image" content={head.image} />
          </Helmet>
          <PcHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
            reservationType={'pickup'}
          />
          <SpHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
            reservationType={'pickup'}
          />

          <section>
            <div className="top-page-top">
              <div className="slide-title">
                有名高級店を、
                <br />
                おうちで召し上がれ。
                <p>店頭受取</p>
              </div>

              <div className="slide-sevices pc">
                <Link to="/" Style="position: relative">
                  <span className="chefle-kitchen__banner-header">
                    来店予約サービス
                  </span>
                  <span className="chefle-kitchen__banner-text">
                    予約の取りにくいお店もChefleが代行予約
                  </span>
                  <div className="slide-sevices-item">
                    <img
                      Style="height: 114px; object-fit:cover;"
                      src="/assets/img/chefle/top-small.jpg"
                      alt="来店予約レストラン"
                    />
                    <span Style="background-color: white;">
                      来店予約レストラン一覧へ
                      <img
                        src="/assets/img/chefle/right_arrow_to_service.png"
                        alt=""
                      />
                    </span>
                  </div>
                </Link>
                <Link to="/catering" Style="position: relative">
                  <div className="slide-sevices-item">
                    <img
                      src="/assets/img/chefle/to_chefle.png"
                      alt="在宅レストラン"
                    />
                    <span Style="background-color: white;">
                      在宅レストラン一覧へ
                      <img
                        src="/assets/img/chefle/right_arrow_to_service.png"
                        alt=""
                      />
                    </span>
                  </div>
                </Link>
                <Link to="/pickup" Style="position: relative">
                  <div className="slide-sevices-item">
                    <img
                      src="/assets/img/chefle/to_pickup.png"
                      alt="店頭受取へ"
                    />
                    <span Style="color: white; background-color: #ff5800;">
                      店頭受取レストラン
                      <img
                        src="/assets/img/chefle/down_arrow_to_service.png"
                        alt=""
                      />
                    </span>
                  </div>
                </Link>
                {/* <Link to="/kitchen" Style="position: relative">
                  <span className="chefle-kitchen__banner-header">
                    シェフルキッチン
                  </span>
                  <span className="chefle-kitchen__banner-text">
                    プロが目利きした食材をご自宅にお届け
                  </span>
                  <div className="slide-sevices-item">
                    <img
                      src="/assets/img/chefle/chefle-kitchen-banner.png"
                      alt="キッチンバナー"
                    />
                    <span Style="background-color: white;">
                      お取り寄せ一覧へ
                      <img
                        src="/assets/img/chefle/right_arrow_to_service.png"
                        alt=""
                      />
                    </span>
                  </div>
                </Link> */}
              </div>

              <div className="each-slide pc">
                <div style={{ backgroundImage: `url(${pcSlideImages[0]})` }} />
              </div>
              <div className="each-slide sp">
                <div style={{ backgroundImage: `url(${spSlideImages[0]})` }} />
              </div>
            </div>
          </section>
          <section>
            <div className="top-page-selection">
              <div className="top-page-selection-title-box">
                <div className="top-page-selection-title">
                  <img
                    src="/assets/img/chefle/pickup_logo_o.png"
                    alt="店頭受取ロゴ"
                  />
                  <h1 Style="font-size: 19px; font-weight:bold; margin-left: 10px;color: #5c5c5c;font-family: 'Sawarabi Mincho', sans-serif; line-height: 1.2;">
                    人気高級店の特製弁当をテイクアウトサービス
                  </h1>
                </div>
                <p>About our service</p>
              </div>
              <div className="top-page-selection-head">
                <div Style="line-height: 25px; padding-top: 20px; padding-bottom: 20px; padding-right: 14px; padding-left: 14px;">
                  高級店の店頭受取サービスです。打倒コロナとして、多くの高級店で特別に店頭受取（テイクアウト）メニューも用意されております。ぜひ、お気に入りのお店のお料理をご自宅でお楽しみください。
                </div>
              </div>
            </div>
          </section>
          <section className="top-page-restaurant">
            <div className="top-page-selection">
              {/* <div className="top-page-store-area">
                <h2 Style="font-weight: normal; text-align: center; color: #5c5c5c;">
                  即時予約可能なレストランラインナップ
                </h2>
                {immediateRestaurantList.loadingFlag ? (
                  <React.Fragment>
                    <div className="loading-spinner-text">Now Loading</div>
                    <div className="loading-spinner">
                      <ReactLoading
                        type="spin"
                        color="#ff5800"
                        {...spinnerProperties}
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  <>
                    <ul className="store-card-container">
                      {!!immediateRestaurantList.items.length &&
                        immediateRestaurantList.items.map(r => (
                          <>
                            <RestaurantCard key={r.id} restaurant={r} />
                          </>
                        ))}
                    </ul>

                    {!immediateRestaurantList.items.length && (
                      <div style={resultString}>No results</div>
                    )}
                  </>
                )}
                <ul className="top-page-store-pager">
                  {this.renderPaging(immediateRestaurantList)}
                </ul>
              </div> */}
              <div className="top-page-store-area">
                <h2 Style="font-weight: normal; text-align: center; color: #5c5c5c;">
                  店頭受取ラインナップ
                </h2>
                {restaurantList.loadingFlag ? (
                  <React.Fragment>
                    <div className="loading-spinner-text">Now Loading</div>
                    <div className="loading-spinner">
                      <ReactLoading
                        type="spin"
                        color="#ff5800"
                        {...spinnerProperties}
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  <>
                    <ul className="store-card-container">
                      {!!restaurantList.items.length &&
                        restaurantList.items.map(r => (
                          <>
                            <RestaurantCard key={r.id} restaurant={r} />
                          </>
                        ))}
                    </ul>

                    {!restaurantList.items.length && (
                      <div style={resultString}>No results</div>
                    )}
                  </>
                )}
                <ul className="top-page-store-pager">
                  {this.renderPaging(restaurantList)}
                </ul>
              </div>
              <div className="slide-sevices sp">
                <Link to="/" Style="position: relative">
                  <span className="chefle-kitchen__banner-header">
                    来店予約サービス
                  </span>
                  <span className="chefle-kitchen__banner-text">
                    予約の取りにくいお店もChefleが代行予約
                  </span>
                  <div className="slide-sevices-item">
                    <img
                      Style="height: 114px; object-fit:cover;"
                      src="/assets/img/chefle/top-small.jpg"
                      alt="メニュー"
                    />
                    <span Style="background-color: white;">
                      来店予約レストラン一覧へ
                      <img
                        src="/assets/img/chefle/right_arrow_to_service.png"
                        alt=""
                      />
                    </span>
                  </div>
                </Link>
                <Link to="/catering" Style="position: relative">
                  <div className="slide-sevices-item">
                    <img src="/assets/img/chefle/to_chefle.png" alt="chefle" />
                    <span Style="background-color: white;">
                      在宅レストラン一覧へ
                      <img
                        src="/assets/img/chefle/right_arrow_to_service.png"
                        alt=""
                      />
                    </span>
                  </div>
                </Link>
                <Link to="/pickup" Style="position: relative">
                  <div className="slide-sevices-item">
                    <img
                      src="/assets/img/chefle/to_pickup.png"
                      alt="店頭受取"
                    />
                    <span Style="color: white; background-color: #ff5800;">
                      店頭受取レストラン一覧
                      <img
                        src="/assets/img/chefle/down_arrow_to_service.png"
                        alt=""
                      />
                    </span>
                  </div>
                </Link>
                {/* <Link to="/kitchen" Style="position: relative">
                  <span className="chefle-kitchen__banner-header">
                    シェフルキッチン
                  </span>
                  <span className="chefle-kitchen__banner-text">
                    プロが目利きした食材をご自宅にお届け
                  </span>
                  <div className="slide-sevices-item">
                    <img
                      src="/assets/img/chefle/chefle-kitchen-banner.png"
                      alt="キッチンバナー"
                    />
                    <span Style="background-color: white;">
                      お取り寄せ一覧へ
                      <img
                        src="/assets/img/chefle/right_arrow_to_service.png"
                        alt=""
                      />
                    </span>
                  </div>
                </Link> */}
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PickupContainer)
