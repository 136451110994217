import React, { Component } from 'react'
import StringHeler from '../../helpers/StringHelper'

export default class Menu extends Component {
  render() {
    return (
      <div className="restaurant-menu-area">
        <h2 className="section-title">メニュー内容</h2>
        <ul className="restaurant-menu-list">
          {this.props.menus.map((menu, i) => (
            <li key={menu.id}>
              <h3 className="restaurant-menu-title">
                {menu.isLunch && <img src="/assets/img/lunch.svg" alt="" />}
                {menu.isDinner && <img src="/assets/img/dinner.svg" alt="" />}
                {menu.name}
              </h3>
              <p className="restaurant-menu-course">
                {StringHeler.getCourse(menu.description)}
              </p>
              <p className="restaurant-menu-notes">
                {StringHeler.parseDescription(menu.description)}
              </p>
              <p className="restaurant-menu-price">
                ¥&nbsp;{StringHeler.numberWithCommas(menu.price)}
                &nbsp;/&nbsp;person
              </p>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}
