import { CALL_API } from '../middlewares/api'

import {
  UPDATE_ACCOUNT_FORM,
  UPDATE_ACCOUNT_POST_REQUEST,
  UPDATE_ACCOUNT_OK,
  UPDATE_ACCOUNT_NG,
} from './index'

export const updateAccountForm = (payload, meta) => ({
  type: UPDATE_ACCOUNT_FORM,
  payload,
  meta,
})

export const postUpdateAccount = (payload = {}, meta = {}) => ({
  [CALL_API]: {
    types: [UPDATE_ACCOUNT_POST_REQUEST, UPDATE_ACCOUNT_OK, UPDATE_ACCOUNT_NG],
    endpoint: `/chefle/user/update`,
    payload: {
      ...payload,
      method: 'POST',
    },
  },
})
