import React from 'react'
import InstagramEmbed from 'react-instagram-embed'

const Embed = props => (
  <>
    {props.instagramUrls && props.instagramUrls.length > 0 && (
      <div className="restaurant-photo-area">
        <h2 className="section-title">写真</h2>
        <ul className="restaurant-photos">
          {props.instagramUrls &&
            props.instagramUrls.map((instaUrl, i) => (
              <li key={i} className="restaurant-photos-box">
                <InstagramEmbed
                  url={instaUrl}
                  clientAccessToken={
                    process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN
                  }
                  // maxWidth={320}
                  hideCaption={false}
                  containerTagName="div"
                  protocol=""
                  injectScript
                  onLoading={() => undefined}
                  onSuccess={() => undefined}
                  onAfterRender={() => undefined}
                  onFailure={() => undefined}
                />
              </li>
            ))}
        </ul>
      </div>
    )}
  </>
)

export default Embed
