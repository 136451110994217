import React from 'react'
import CancelationPolicy from '../common/CancelationPolicy'
import PersonalInformation from '../common/PersonalInformation'
import TermsOfService from '../common/TermsOfService'
import SpecifiedCommercialTransactionLaw from '../common/SpecifiedCommercialTransactionLaw'
import FAQ from '../common/FAQ'
import AboutChefle from '../common/AboutChefle'

const CommonFooter = () => (
  <>
    <footer className="common-footer">
      <div className="footer-inner">
        <div className="footer-inner-box">
          <p Style="padding: 10px 0;border-bottom: solid 1px;">About</p>
          <ul>
            <li>
              <img
                src="/assets/img/chefle/footer-arrow-icon.png"
                alt=""
                Style="width: 15px; margin-right: 5px;"
              />

              <AboutChefle
                text={'シェフルについて'}
                btnclassname={'btn-container-item'}
              />
            </li>
            <li>
              <img
                src="/assets/img/chefle/footer-arrow-icon.png"
                alt=""
                Style="width: 15px; margin-right: 5px;"
              />
              <a href="https://www.fesbase.co.jp/" target="blank">
                会社情報
              </a>
            </li>
            {/* <li>
              <img
                src="/assets/img/chefle/footer-arrow-icon.png"
                alt=""
                Style="width: 15px; margin-right: 5px;"
              />
              <a href="/gotoeat" target="blank">
                Go To Eat キャンペーンについて
              </a>
            </li> */}
          </ul>
        </div>
        <div className="footer-inner-box">
          <p Style="padding: 10px 0;border-bottom: solid 1px;">Service</p>
          <ul>
            <li>
              <img
                src="/assets/img/chefle/footer-arrow-icon.png"
                alt=""
                Style="width: 15px; margin-right: 5px;"
              />
              <a href="/">来店予約サービス</a>
            </li>
            <li>
              <img
                src="/assets/img/chefle/footer-arrow-icon.png"
                alt=""
                Style="width: 15px; margin-right: 5px;"
              />
              <a href="/catering">在宅レストランサービス</a>
            </li>
            <li>
              <img
                src="/assets/img/chefle/footer-arrow-icon.png"
                alt=""
                Style="width: 15px; margin-right: 5px;"
              />
              <a href="/pickup">店頭受取サービス</a>
            </li>
            <li>
              <img
                src="/assets/img/chefle/footer-arrow-icon.png"
                alt=""
                Style="width: 15px; margin-right: 5px;"
              />
              <a href="https://jpneazy.com/">JPNEAZY</a>
            </li>
          </ul>
        </div>
        <div className="footer-inner-box">
          <p Style="padding: 10px 0;border-bottom: solid 1px;">Support</p>
          <ul>
            <li>
              <img
                src="/assets/img/chefle/footer-arrow-icon.png"
                alt=""
                Style="width: 15px; margin-right: 5px;"
              />
              <FAQ text={'FAQ'} btnclassname={'btn-container-item'} />
            </li>
            <li>
              <img
                src="/assets/img/chefle/footer-arrow-icon.png"
                alt=""
                Style="width: 15px; margin-right: 5px;"
              />
              <a
                href="https://jpneazy.com/reservation/chat.html"
                target="blank"
              >
                チャットコンシェルジュサービス
              </a>
            </li>
            <li>
              <img
                src="/assets/img/chefle/footer-arrow-icon.png"
                alt=""
                Style="width: 15px; margin-right: 5px;"
              />
              <a href="https://www.fesbase.co.jp#block-yui_3_17_2_12_1504242646453_9315">
                お問い合わせ
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-logo">
        <hr className="sp" Style="width: 100%;" />
        <a href="/">
          <img src="/assets/img/chefle/chefle_logo_white.png" alt="" />
        </a>
        <p>Copyright &copy; Fesbase Inc. All rights reserved</p>
        <span className="footer-company-tel">
          運営会社: 株式会社Fesbase tel: 03-4578-5923
        </span>
      </div>
      　　　　
      <div className="footer-bottom">
        <p>
          <img
            src="/assets/img/chefle/footer-arrow-icon2.png"
            alt=""
            Style="width: 15px; margin-right: 5px;"
            className="sp"
          />
          <TermsOfService
            text={'利用規約'}
            btnclassname={'btn-container-item'}
          />
        </p>
        <p>
          <img
            src="/assets/img/chefle/footer-arrow-icon2.png"
            alt=""
            Style="width: 15px; margin-right: 5px;"
            className="sp"
          />
          <PersonalInformation
            text={'個人情報保護方針'}
            btnclassname={'btn-container-item'}
          />
        </p>
        <p>
          <img
            src="/assets/img/chefle/footer-arrow-icon2.png"
            alt=""
            Style="width: 15px; margin-right: 5px;"
            className="sp"
          />
          <CancelationPolicy
            text={'キャンセルポリシー'}
            btnclassname={'btn-container-item'}
          />
        </p>
        <p>
          <img
            src="/assets/img/chefle/footer-arrow-icon2.png"
            alt=""
            Style="width: 15px; margin-right: 5px;"
            className="sp"
          />
          <SpecifiedCommercialTransactionLaw
            text={'特定商取引法'}
            btnclassname={'btn-container-item'}
          />
        </p>
      </div>
    </footer>
  </>
)

export default CommonFooter
