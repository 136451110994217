import React, { Component } from 'react'
import Modal from 'react-modal'

class TermsOfService extends Component {
  state = {
    modalIsOpen: false,
  }
  openModal() {
    this.setState({ modalIsOpen: true })
  }
  closeModal() {
    this.setState({ modalIsOpen: false })
  }
  changeModalState() {
    this.setState({ modalIsOpen: !this.state.modalIsOpen })
  }
  render() {
    return (
      <>
        <a
          href={() => false}
          onClick={this.openModal.bind(this)}
          className={this.props.btnclassname}
        >
          {this.props.text}
        </a>
        <Modal
          closeTimeoutMS={200}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal.bind(this)}
          style={customStyles}
          contentLabel="CancelationPolicy Modal"
        >
          <div className="modal">
            <h3 className="modal-title">利用規約</h3>
            <div className="modal-content">
              この｢Chefle利用規約｣(以下｢本規約｣といいます)は、株式会社Fesbase
              (以下｢当社｣といいます)がインターネット上で『Chefle』の名称で提供するサービスの利用に関し、ユーザーに遵守していただく事項その他当社とユーザーの権利義務関係を規定するものです。
              <br />
              <br />
              <div className="sentence">
                <h3>第１条 (定　義)</h3>
                本規約において使用される用語の定義は、以下の各号に定めるとおりとします。
                <br />
                ・(1)｢本サイト｣とは、当社が運営するウェブサイトであって、｢
                chefle.com ｣のドメインに属するウェブページ群をいいます。
                <br />
                ・(2)｢本サービス｣とは、当社が本サイト上において｢ Chefle
                ｣の名称で提供するサービスで、第3条に定める内容を有するものをいいます。
                <br />
                ・(3)｢ユーザー｣とは、本サイトを利用するすべての個人または法人をいいます(第5号に定める出店者を除きます)。
                <br />
                ・(4)｢会員｣とは、ユーザーのうち、第5条の規定に従って会員登録を行った者をいいます。
                <br />
                ・(5)｢出店者｣とは、当社と本サービスへの出店に関する契約を締結し、本サービスを通じて販売しようとする商品を本サイトに掲載している事業者をいいます。
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>第２条 (本規約の適用範囲)</h3>
                1.本規約は、本サービスの利用に関する諸条件その他本サービスの利用に関する当社とユーザーとの権利義務関係を定めることを目的とし、当社およびユーザーに適用されます。ユーザーが本サービスを利用するにあたっては、本規約の全文をお読みいただき、これに同意いただく必要があります。なお、ユーザーが本サービスを利用した場合には、本規約の内容について同意したものとみなされます。
                <br />
                2.当社は、別途本規約に付随する個別規約、利用上の注意事項等(以下｢個別規約等｣と総称します)を定めることができるものとします。個別規約等は本規約の一部を構成するものとし、本規約と個別規約等が異なる場合には、個別規約等が優先するものとします。
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>第３条 (本サービス)</h3>
                当社がユーザーに提供する本サービスの具体的な内容は、次の各号に定めるとおりとします。
                <br />
                (1)商品情報の提供 出店者に関する情報(以下｢出店者情報｣といいます)および出店者が販売するケータリング商品、テイクアウト（店頭受取）商品、宅配商品等の食料品(以下｢商品等｣といいます)についての情報(以下｢商品等情報｣といいます)の提供
                <br />
                (2)商品の検索機能の提供 商品ジャンル、価格帯、配達可能地域その他の商品に関する条件を指定することにより、指定した条件に合致する商品等を抽出し、一覧表示するシステムの提供
                <br />
                (3)注文情報の出店者への転送 当社が本サービスを通じて受領したユーザーからの注文情報(当該ユーザーの個人情報を含みます)の出店者への転送
                <br />
                (4)附帯サービスの提供 前各号のサービスに附帯関連するサービス(当社が直接の売主として本サイト上で販売する商品(以下「当社商品」という)に関するサービスを含む)の提供
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>第４条 (ユーザーと出店者との関係等)</h3>
                (1)
                当社は、第3条のサービスを提供するものであり、本サイトに掲載された商品等の販売は、出店者が自己の責任において行っています。ユーザーは直接出店者との間で商品等の売買その他の取引を行い、当社は当該取引に付随・関連して生じた紛争につき一切関知せず、なんらの責任を負いません。
                <br />
                (2)
                本サイトに掲載された出店者情報および商品等情報は、出店者から提供されたものです。したがって、当社は、出店者情報および商品等情報の有用性、目的適合性、完全性、正確性、適法性、最新性、真実性等をなんら保証するものではありません。
                <br />
                (3)
                本サービスの利用に付随・関連してユーザーと出店者その他の第三者との間で紛争が生じた場合、ユーザーは自己の責任と負担においてかかる紛争を解決するものとし、当社はかかる紛争につき一切関知せず、なんらの責任を負いません。
                <br />
                (4)
                前3項の規定にかかわらず、当社商品の取引または、それに付随・関連して生じた紛争については、当社とユーザーとの間で解決するものとします。
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>第５条 (商品等の代金)</h3>
                1.ユーザーが本サービスを通じて購入した商品等の代金は、当社が出店者に代わり徴収します。ユーザーは購入した商品等の代金を、本サイト上でインターネット等を通じてクレジットカードにより支払うものとします。なお、ユーザーの商品等の代金の支払債務は、本条に従って当社に支払った時点で消滅するものとします。
                <br />
                2.当社商品の代金は、当社がユーザーから直接徴収します。また、当社商品の代金の支払方法および支払債務の消滅の時期については、前項の例によります。
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>第６条 (ユーザー情報の取扱い)</h3>
                1.当社は、ユーザーが本サービスを利用するに際して当社に対して提供した情報を当社の個人情報保護方針に定める以下の利用目的等に従って取り扱います。ユーザーは、当社が係る情報を当社の個人情報保護方針に従って取り扱うことについて、あらかじめ同意します。
                <br />
                ・(1)ご注文頂いた当社または提携先の商品の配送、代金の請求、決済、当社からお客様へのお問い合わせ、関連するアフターサービス、その他の取引の遂行
                <br />
                ・(2)電子メール、郵便、FAXなどによる当社または提携先の商品・サービスについての情報提供
                <br />
                ・(3)当社がお客様に有用だと考えるメールマガジン、お知らせ
                <br />
                ・(4)当社または提携先のアンケート、キャンペーンに関連したお客様へ連絡
                <br />
                ・(5)お客様から当社になされるお問い合わせへの対応
                <br />
                ・(6)当社サービスの改善や新規サービス開発に必要なデータの解析や分析
                <br />
                ・(7)事業活動の一環としてのマーケティング、販売促進、商品・サービス企画等のための統計データの作成
                <br />
                ・(8)不正行為の監視
                <br />
                2.当社は、ユーザーから提供されたユーザー情報を、ユーザーが購入した商品の販売に必要な範囲で出店者に対して提供します。ユーザーはかかるユーザー情報の出店者への提供につき、あらかじめ同意します。
                <br />
                3.当社は、ユーザーが当社に提供した情報を、個人を特定できない形での統計的な情報として、当社の裁量で、利用および公開することができるものとし、ユーザーはこれに同意するものとします。
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>第７条 (知的財産権)</h3>
                本サイトに含まれる文章・画像・映像・サイトデザイン・レイアウト・商標・標章その他一切の情報の知的財産権は当社または当社にその利用を許諾した権利者に帰属しています。ユーザーはこれらの情報を当社の事前の許諾なく利用または使用することはできません。
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>第８条 (禁止事項)</h3>
                ユーザーは、本サービスの利用にあたり、次の各号に掲げる行為またはそのおそれのあると当社が判断する行為を行ってはならないものとします。
                <br />
                ・(1)法令または本規約に違反する行為、犯罪行為に関連する行為または公序良俗に反する行為
                <br />
                ・(2)当社または出店者その他の第三者の財産権(知的財産権を含む)、人格権(名誉権、肖像権、プライバシーの権利)その他の権利を侵害し、または信用を毀損する行為
                <br />
                ・(3)第三者への成りすまし、または注文の際に虚偽の情報を申告する行為
                <br />
                ・(4)有害なコンピュータプログラム、メール等を送信し、または書き込む行為
                <br />
                ・(5)本サービスの提供に用いられているサーバその他当社のコンピュータに過度な負荷をかけ、または不正にアクセスする行為
                <br />
                ・(6)本サービスの運営・維持を妨げる行為
                <br />
                ・(7)前各号のほか、当社が不適当と判断する行為
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>第９条 (利用拒絶等)</h3>
                1.当社は、ユーザーが次の各号のいずれかに該当する場合には、当該ユーザーによる本サイトへのアクセスまたは本サービスの利用を拒絶するために必要な措置を講ることができるものとします。この場合、当社は、当該ユーザーに対し、当該措置を講じた理由を開示する義務を負いません。
                <br />
                ・1)本規約のいずれかの規定に違反し、または違反するおそれがあると当社が合理的に判断したとき、もしくは過去に違反したことがあるとき
                <br />
                ・2)当社に提供した情報が虚偽であることが判明した場合
                <br />
                ・3)過去に当該ユーザーと取引を行った出店者から当該ユーザーに関する苦情が頻発したとき
                <br />
                ・4)暴力団、暴力団員、暴力団関係団体、暴力団関係者、その他の反社会的勢力(以下あわせて｢暴力団等｣という)であることが判明したとき、または過去に暴力団等であったことが判明したとき
                <br />
                ・5)本サービスを利用して購入した商品等の代金その他本サービスの利用に関連してユーザーが負担する債務を期日に支払わないとき、または過去に支払わなかったことがあったとき
                <br />
                ・6)支払停止もしくは支払不能となった場合
                <br />
                ・7)破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始もしくはこれらに類する手続の開始の申立てを自ら行い、もしくは第三者からなされた場合
                <br />
                ・8)当社から連絡が取れなくなった場合
                <br />
                ・9)前各号のほか、当社が本サイトへのアクセスまたは本サービスの利用を不適当と判断したとき
                <br />
                2.前項各号のいずれかの事由に該当した場合、ユーザーは、当社に対して負っている債務の一切(出店者に対する債務で当社が回収を代行するものを含みます)について当然に期限の利益を失い、直ちにすべての債務の支払を行わなければなりません。
                <br />
                3.当社は、本条に基づき当社が行った措置によりユーザーに生じた損害について、一切の責任を負いません。
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>第１０条 (本サービスの一時停止)</h3>
                1.当社は、本サービスを常に良好な状態でご利用いただくため、次の各号に掲げる場合には、ユーザーに対する事前の通知その他何らの手続を要することなく、本サービスの全部または一部の提供を一定期間停止することができるものとします。
                <br />
                (1)本サービスの提供に用いられているサーバ、ソフトウェア等の点検、保守、修理、修補、改良等(定期であると緊急であるとを問いません)に必要があるとき
                <br />
                (2)本サービスの提供に用いられているサーバ、ソフトウェア等のトラブル、電源供給の停止、ネットワークトラブル等が生じたとき
                <br />
                (3)システムに負荷が集中した場合等、本サービスの正常な提供に支障が生じると当社が合理的に判断したとき
                <br />
                (4)その他、運用上ないし技術上必要であると当社が判断したとき
                <br />
                2.当社は、当社に故意または重過失がある場合を除き、前項に基づく当社の措置に起因してユーザーに生じた損害について、なんらの責任を負いません。
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>第１１条 (本サービスの変更、廃止)</h3>
                1.当社は、その裁量で本サービスの内容を変更し、または本サービスの全部または一部の提供を廃止することができるものとします。{' '}
                <br />
                2.当社は、前項に基づく当社の措置に起因してユーザーに生じた損害について、一切の責任を負いません。
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>第１２条 (無保証)</h3>
                1.当社は、本サイト上に掲載される全ての情報(商品等に関する情報のみならず、本サイト上に表示される広告、第三者が管理・運営するリンク等、本サイト内に含まれる一切の情報を含みます)の有用性、目的適合性、完全性、正確性、安全性、適法性、最新性、真実性等を保証するものではありません。
                <br />
                2.当社は、本サービスの利用により出店者からユーザーの満足する商品等の提供を受けることができることを保証するものではありません。
                <br />
                3.当社は、本サービスの提供に障害が生じないこと、本サイトの利用に関連して送信される電子メール、ウェブコンテンツ等に、コンピュータウイルス等の有害なものが含まれていないことを保証するものではありません。
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>第１３条 (不可抗力)</h3>
                停電・通信回線の事故・天災等の不可抗力、通信事業者の不履行、インターネット通信回線の不具合、サーバ等のシステム上の不具合または緊急メンテナンスの発生その他甲の責に帰すことのできない事由により、サーバまたはソフトウェアの障害・不具合・誤動作、本サービスの全部または一部の停止等の事態が発生した場合であっても、これに起因してユーザーに生じた損害等につき、当社はなんらの責任を負いません。
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>第１４条 (契約上の地位の譲渡禁止)</h3>
                1.当社は、①本サービスの運営者たる地位、②本契約に基づく自らの契約上の地位、権利または義務、③ユーザー情報その他の本サービスの運営に必要な情報を、事業譲渡その他の事由により第三者に承継させることができるものとし、ユーザーは、かかる承継について、あらかじめ承諾するものとします。
                <br />
                2.ユーザーは、本契約に基づく自らの契約上の地位、権利または義務を、第三者に対して譲渡、移転、担保権設定その他の方法による処分をしてはならず、または、第三者に引き受けさせてはなりません。
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>第１５条 (損害賠償)</h3>
                1.ユーザーが本サービスに利用に関連して当社に損害を与えた場合、当社は、ユーザーに対し、その被った損害の全部(弁護士費用を含みます)の賠償を請求することができるものとします。
                <br />
                2.ユーザーが本サービスの利用に関連して損害を被った場合は、当社に故意または重大な過失がある場合に限り損害賠償責任を負うものとし、その賠償の範囲は、付随的損害、間接損害、特別損害、将来の損害および逸失利益にかかる損害を含まず、直接かつ現実の損害に限るものとします。
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>第１６条 (準拠法)</h3>
                本規約は日本法を準拠法とし、かつ、同法に従い解釈されるものとします。
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>第１７条 (合意管轄)</h3>
                本サービスの利用に関連して当社とユーザーとの間で生じた紛争については、東京地方裁判所をもって第一審の専属的合意管轄裁判所とします。
                <br />
                <br />
                <br />
                制定実施日：2020年5月
              </div>
            </div>
          </div>
        </Modal>
      </>
    )
  }
}

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    'z-index': '1000',
  },
  content: {
    margin: 'auto',
    'max-width': '900px',
    'border-radius': '18px',
    'z-index': '1000',
  },
}

export default TermsOfService
