import React from 'react'
import CompleteIcon from './common/CompleteIcon'
import { MenuIcon, SideMenu } from './menu/SideMenu'
import { Helmet } from 'react-helmet'

const head = {
  title: `Payment Complete | Chefle`,
  description: '',
}

const PaymentComplete = () => (
  <>
    <Helmet>
      <title>{head.title}</title>
      <meta property="og:title" content={head.title} />
      <meta property="og:description" content={head.description} />
      <meta name="description" content={head.description} />
      <meta name="robots" content="noindex" />
    </Helmet>
    <div className="reserve-complete">
      <div className="main-inner">
        <CompleteIcon />
        <p className="reserve-complete-txt">
          Chefleをご利用いただきありがとうございます!
        </p>
        <p className="reserve-complete-txt2">処理が完了しました。</p>
        <a href="/" className="reserve-complete-return-btn">
          ホームへ戻る
        </a>
      </div>
    </div>
    <SideMenu />
  </>
)
export default PaymentComplete
