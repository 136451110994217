import { CALL_API } from '../middlewares/api'
import { CALENDAR_REQUEST, CALENDAR_OK, CALENDAR_NG } from './index'

export const fetchCalendar = (payload = {}) => ({
  [CALL_API]: {
    types: [CALENDAR_REQUEST, CALENDAR_OK, CALENDAR_NG],
    endpoint: `/restaurants/${payload.restaurantId}/calendar?year=${
      payload.year
    }&month=${payload.month}&partySize=${payload.partySize}`,
    payload,
  },
})
