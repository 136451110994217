import {
  UPDATE_DELETE_ACCOUNT_FORM,
  CONFIRM_DELETE_ACCOUNT_FORM,
} from '../actions'
import { history } from '../configureStore'

const validateDeleteAccountForm = deleteAccount => {
  let errors = {}
  !deleteAccount.reason1 &&
    !deleteAccount.reason2 &&
    !deleteAccount.reason3 &&
    !deleteAccount.reason4 &&
    deleteAccount.withdrawal === '' &&
    (errors.withdrawal = '退会理由を選択または記入してください。')

  return errors
}

const update = action => {
  const deleteAccount = action.payload
  if (deleteAccount.errors) {
    let errors = validateDeleteAccountForm(deleteAccount)
    return { ...action, payload: { ...action.payload, errors: errors } }
  }
  return action
}

const confirm = action => {
  const deleteAccount = action.payload
  let errors = validateDeleteAccountForm(deleteAccount)
  if (!!Object.keys(errors).length) {
    return { ...action, payload: { ...action.payload, errors: errors } }
  }
  history.push('/setting/delete/confirm')

  return action
}

export default store => next => action => {
  if (action.type === UPDATE_DELETE_ACCOUNT_FORM) {
    return next(update(action))
  }
  if (action.type === CONFIRM_DELETE_ACCOUNT_FORM) {
    return next(confirm(action))
  }
  return next(action)
}
