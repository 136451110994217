import React from 'react'
import CompleteIcon from '../../components/common/CompleteIcon'
import { Helmet } from 'react-helmet'
import ReactPixel from 'react-facebook-pixel'

const head = {
  title: `Reservation | Chefle - Reservation`,
}
class ResendEmailCompleteContainer extends React.Component {
  componentDidMount() {
    ReactPixel.init('502037183268067')
    ReactPixel.pageView()
  }
  render() {
    return (
      <div className="sign-up">
        <Helmet>
          <title>{head.title}</title>
          <meta property="og:title" content={head.title} />
          <meta property="og:description" content={head.description} />
          <meta name="description" content={head.description} />
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className="reserve-complete">
          <div className="main-inner">
            <CompleteIcon />
            <p className="reserve-complete-txt" />
            <p className="reserve-complete-txt2">
              ご入力されたメールアドレスに
              <br className="sp" />
              パスワード再設定メールを送信しました。
              <br />
              メールに記載の暫定パスワードにてログインしてください。
              <br className="pc" />
              <br />
              <br />
              <br className="sp" />
              【パスワード再設定メールが届かない場合】
              <br className="sp" />
              <br />
              <b>Gmailをご利用のお客様</b>
              <br />
              メールが迷惑メールに振り分けられる場合がございます。受信フォルダの他、迷惑メールフォルダもご確認ください。
              <br />
              <br />
              <b>その他</b>
              <br />
              登録されたメールアドレスが入力間違いの場合がございます。
              <br />
              この場合、お手数ですが、もう
              一度新規登録を行っていただくきますようお願 い申し上げます。
            </p>
            <a href="/" className="reserve-complete-return-btn">
              トップページへ戻る
            </a>
          </div>
        </div>
      </div>
    )
  }
}
export default ResendEmailCompleteContainer
