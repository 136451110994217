import React, { PureComponent } from 'react'
import Modal from 'react-modal'

class WhereToEat extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
    }
  }

  openModal = () => {
    this.setState({ modalIsOpen: true })
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false })
  }

  select = urlKey => {
    this.closeModal()
    this.props.onSelect(urlKey)
  }

  render() {
    return (
      <>
        <div style={{ cursor: 'pointer' }}>
          <p
            id="pc-where-to-eat"
            className="top-page-selection-head-where-eat modal-open"
            onClick={this.openModal.bind(this)}
          >
            <span>{this.props.label}</span>
          </p>
        </div>
        <AreaModal
          state={this.state}
          closeModal={this.closeModal.bind(this)}
          select={this.select}
          areas={this.props.areas}
          urlKey={this.props.urlKey}
        />
      </>
    )
  }
}

class WhereToEatSp extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
    }
  }

  openModal = () => {
    this.setState({ modalIsOpen: true })
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false })
  }

  select = urlKey => {
    this.closeModal()
    this.props.onSelect(urlKey)
  }

  changeModal() {
    this.setState({ modalIsOpen: !this.state.modalIsOpen })
  }

  render() {
    return (
      <>
        <img
          src="/assets/img/area_icon_01.svg"
          alt=""
          className="fixed-footer-left"
          onClick={this.changeModal.bind(this)}
        />
        <AreaModal
          state={this.state}
          closeModal={this.closeModal.bind(this)}
          select={this.select}
          areas={this.props.areas}
          urlKey={this.props.urlKey}
        />
      </>
    )
  }
}

const AreaModal = props => (
  <Modal
    closeTimeoutMS={200}
    isOpen={props.state.modalIsOpen}
    onRequestClose={props.closeModal}
    className="slide-modal-contents"
    style={customStyles}
  >
    <p className="to-eat-modal-head where-to-eat-head">Where to eat</p>
    <ul className="to-eat-modal-list">
      <li>
        <input
          type="radio"
          checked={props.urlKey === ''}
          onClick={() => props.select()}
        />
        <label
          className="radio"
          checked={props.urlKey === ''}
          onClick={() => props.select()}
        >
          全エリア
        </label>
      </li>
      {props.areas.map(area => {
        return (
          <li key={area.id}>
            <div>
              <input
                type="radio"
                checked={props.urlKey === area.urlKey}
                onClick={() => props.select(area.urlKey)}
              />
              <label
                className="radio"
                checked={props.urlKey === area.urlKey}
                onClick={() => props.select(area.urlKey)}
              >
                {area.name}
              </label>
            </div>
          </li>
        )
      })}
    </ul>
  </Modal>
)

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 100,
  },
}

const getAreaName = (areas, urlKey) => {
  if (!urlKey) {
    return '全エリア'
  }
  let area = areas.find(area => area.urlKey === urlKey)
  return !area ? '' : area.name
}

export { WhereToEat, WhereToEatSp, getAreaName }
