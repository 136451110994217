import { CALL_API } from '../middlewares/api'
import {
  FETCH_CREDITCARD_REQUEST,
  FETCH_CREDITCARD_OK,
  FETCH_CREDITCARD_NG,
  CREDITCARD_POST_REQUEST,
  CREDITCARD_POST_OK,
  CREDITCARD_POST_NG,
  CREDITCARD_UPDATE_REQUEST,
  CREDITCARD_UPDATE_OK,
  CREDITCARD_UPDATE_NG,
  CREDITCARD_DELETE_REQUEST,
  CREDITCARD_DELETE_OK,
  CREDITCARD_DELETE_NG,
} from './index'

export const fetchCreditcard = payload => ({
  [CALL_API]: {
    types: [FETCH_CREDITCARD_REQUEST, FETCH_CREDITCARD_OK, FETCH_CREDITCARD_NG],
    endpoint: `/chefle/user/card?user_no=${payload.userNo}&token=${
      payload.token
    }`,
    payload,
  },
})

export const postCreditcard = (payload = {}, meta = {}) => ({
  [CALL_API]: {
    types: [CREDITCARD_POST_REQUEST, CREDITCARD_POST_OK, CREDITCARD_POST_NG],
    endpoint: '/chefle/user/card',
    payload: {
      ...payload,
      method: 'POST',
    },
  },
})

export const updateCreditcard = (payload = {}, meta = {}) => ({
  [CALL_API]: {
    types: [
      CREDITCARD_UPDATE_REQUEST,
      CREDITCARD_UPDATE_OK,
      CREDITCARD_UPDATE_NG,
    ],
    endpoint: '/chefle/user/card',
    payload: {
      ...payload,
      method: 'PUT',
    },
  },
})

export const deleteCreditcard = (payload = {}, meta = {}) => ({
  [CALL_API]: {
    types: [
      CREDITCARD_DELETE_REQUEST,
      CREDITCARD_DELETE_OK,
      CREDITCARD_DELETE_NG,
    ],
    endpoint: '/chefle/user/card',
    payload: {
      ...payload,
      method: 'DELETE',
    },
  },
})
