import { CALL_API } from '../middlewares/api'
import {
  UPDATE_AUTH_USER,
  AUTH_USER_POST_REQUEST,
  AUTH_USER_POST_OK,
  AUTH_USER_POST_NG,
  SIGN_OUT_POST_REQUEST,
  SIGN_OUT_POST_OK,
  SIGN_OUT_POST_NG,
} from './index'

export const getAuthUser = (payload = {}, meta = {}) => ({
  [CALL_API]: {
    types: [AUTH_USER_POST_REQUEST, AUTH_USER_POST_OK, AUTH_USER_POST_NG],
    endpoint: `/chefle/user`,
    payload: {
      ...payload,
      method: 'POST',
    },
  },
})

export const postSignOut = (payload = {}, meta = {}) => ({
  [CALL_API]: {
    types: [SIGN_OUT_POST_REQUEST, SIGN_OUT_POST_OK, SIGN_OUT_POST_NG],
    endpoint: `/chefle/logout`,
    payload: {
      ...payload,
      method: 'POST',
    },
  },
})

export const updateAuthUser = (payload, meta) => ({
  type: UPDATE_AUTH_USER,
  payload,
  meta,
})
