import React, { Component } from 'react'
import Modal from 'react-modal'

class SpecifiedCommercialTransactionLaw extends Component {
  state = {
    modalIsOpen: false,
  }
  openModal() {
    this.setState({ modalIsOpen: true })
  }
  closeModal() {
    this.setState({ modalIsOpen: false })
  }
  changeModalState() {
    this.setState({ modalIsOpen: !this.state.modalIsOpen })
  }
  render() {
    return (
      <>
        <a
          href={() => false}
          onClick={this.openModal.bind(this)}
          className={this.props.btnclassname}
        >
          {this.props.text}
        </a>
        <Modal
          closeTimeoutMS={200}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal.bind(this)}
          style={customStyles}
          contentLabel="CancelationPolicy Modal"
        >
          <div className="modal">
            <h3 className="modal-title">特定商取引法</h3>
            <div className="modal-content">
              <div className="sentence">
                <tr>
                  <th Style="display: flex; justify-content: flex-start;">
                    販売業者：
                  </th>
                  <td>株式会社Fesbase</td>
                </tr>
                <tr>
                  <th Style="display: flex; justify-content: flex-start;">
                    運営責任者：
                  </th>
                  <td>金田 雅人</td>
                </tr>
                <tr>
                  <th Style="display: flex; justify-content: flex-start;">
                    サイト：
                  </th>
                  <td>
                    シェフル chefle <br />
                    <a href="https://chefle.com">https://chefle.com</a>
                  </td>
                </tr>
                <br />
                <tr>
                  <th Style="display: flex; justify-content: flex-start;">
                    所在地：
                  </th>
                  <td>〒141-0021 東京都品川区上大崎3-5-3 朝日目黒BM702</td>
                </tr>
                <tr>
                  <th Style="display: flex; justify-content: flex-start;">
                    商品の名称：
                  </th>
                  <td>サービス利用料課金</td>
                </tr>
                <tr>
                  <th Style="display: flex; justify-content: flex-start;">
                    販売価格：
                  </th>
                  <td>各店舗の各メニューごとに表示</td>
                </tr>
                <tr>
                  <th Style="display: flex; justify-content: flex-start;">
                    支払方法：　
                  </th>
                  <td>クレジットカード</td>
                </tr>
                <tr>
                  <th Style="display: flex; justify-content: flex-start;">
                    支払期限：
                  </th>
                  <td>取引によって異なります</td>
                </tr>
                <br />
                <tr>
                  <th Style="display: flex; justify-content: flex-start;">
                    引き渡し時期：
                  </th>
                  <td>店舗が承諾したお客様の予約申込み日</td>
                </tr>
                <tr>
                  <th Style="display: flex; justify-content: flex-start;">
                    キャンセルポリシー：
                  </th>
                  <td>各店舗ページにて記載</td>
                </tr>
              </div>
            </div>
          </div>
        </Modal>
      </>
    )
  }
}

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    'z-index': '1000',
  },
  content: {
    margin: 'auto',
    'max-width': '900px',
    'border-radius': '18px',
    'z-index': '1000',
  },
}

export default SpecifiedCommercialTransactionLaw
