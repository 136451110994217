import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { PcHeader, SpHeader } from '../../components/top/Header'
import AuthComponent, {
  authMapStateToProps,
  authMapDispatchToProps,
} from '../../components/AuthComponent'
import { isMobile } from 'react-device-detect'
import LazyLoad from 'react-lazyload'
import ChefleKitchenSlider from './ChefleKitchenSlider'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'

function mapStateToProps(state) {
  return authMapStateToProps(state)
}

function mapDispatchToProps(dispatch) {
  return authMapDispatchToProps(dispatch)
}

class ChefleKitchenContainer extends AuthComponent {
  constructor(props) {
    super(props)
    this.state = {
      scrollPosition: 0,
    }
  }

  componentDidMount() {
    super.componentDidMount()
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
  }

  render() {
    const { authUser } = this.props

    const head = {
      title:
        'シェフル(chefle) - 有名高級店の食材を、あなたのご自宅に配送いたします。',
      description:
        '高級店シェフが目利きした旬の食材を、シェフ考案の動画レシピ（YouTubeにて無料公開）・食べ方とともに、豊洲市場より直送でお届けしますので鮮度抜群です。豊洲の仲卸業者の全面協力もあり、高級食材としてはお値段もリーズナブル。もちろん、ギフト配送も行っておりますのでお気軽にお問い合わせくださいませ。',
      page_url: 'https://chefle.com/kitchen',
      image: 'http://chefle.com/assets/img/chefle/chefle-kitchen-ogp.png',
    }

    const pcSlideImages = ['/assets/img/chefle/chefle-kitchen-top-pc.jpg']
    const spSlideImages = ['/assets/img/chefle/chefle-kitchen-top-sp.jpg']

    const CreateFoodContent = src => {
      return (
        <LazyLoad height="100%" offset={150} Style="position: relative;">
          <div className="chefle-kitchen__bg-dark" />
          {src !== 'gift-card' && (
            <img
              className="chefle-kitchen__image-icon"
              src="/assets/img/chefle/chefle-kitchen-shipping-free.jpg"
              alt="配送料無料"
            />
          )}
          <img
            className="chefle-kitchen__image"
            src={`/assets/img/chefle/${src}.jpg`}
            alt="シェフルキッチン"
          />
        </LazyLoad>
      )
    }

    const createRankingCardContent = props => {
      const { rankingClass, textClass, href, src, title, ranking, text } = props
      return (
        <a className="chefle-kitchen__ranking-link" href={href}>
          <Card className="chefle-kitchen__ranking-card">
            <CardActionArea className={rankingClass}>
              <CardMedia
                className="chefle-kitchen__card-media"
                component="img"
                alt={title}
                src={src}
              />
              <CardContent className="chefle-kitchen__ranking-card-content">
                <div
                  className={[
                    'chefle-kitchen__ranking-order font',
                    textClass,
                  ].join(' ')}
                >
                  {ranking}位
                </div>
                <div className="chefle-kitchen__ranking-card-text font">
                  {text}
                </div>
              </CardContent>
            </CardActionArea>
          </Card>
        </a>
      )
    }

    const chefeDescription = {
      yamada: {
        pc:
          '銀座にある鮨處やまだは魅力的な握り寿司15貫を良心的な価格で提供している超人気店。異業種から鮨職人に転職した異色の職人でもある店主の山田裕介さんは、テレビ番組「ジュブチューン」 では超一流鮨職人の審査員として出演するなど、数多く出演しています。',
        sp:
          '銀座にある鮨處やまだは魅力的な握り寿司15貫を良心的な価格で提供している超人気店です。',
      },
      ichimiya: {
        pc:
          'オーナーシェフ一宮宏行さんは神戸出身。北海道洞爺湖ウインザーホテル メインレストランシェフを務めたあと、 銀座にある「炉釜炭焼きステーキ トロワフレー」にて料理長を務める。「神戸牛炉釜炭焼ステーキIDEA銀座」「日本橋ウェスタ」にて修業後、 銀座に「但馬牛倶楽部炉釜炭焼きステーキ 一宮」を開店しました。 自ら競りにも立ち会い、最高の但馬牛を見極めています。',
        sp:
          'オーナーシェフ一宮宏行さんは神戸出身。様々な名店にて修業後、 銀座に「但馬牛倶楽部炉釜炭焼きステーキ 一宮」を開店しました。 自ら競りにも立ち会い、最高の但馬牛を見極めています。',
      },
      monoris: {
        pc:
          '表参道と渋谷の間、グルメの名店が集まっていると話題のエリアに位置するモノリスは、ジビエに定評のあるフレンチ料理のお店です。',
        sp:
          '表参道と渋谷の間、グルメの名店が集まっていると話題のエリアに位置するモノリスは、ジビエに定評のあるフレンチ料理のお店です。',
      },
      yoshitake: {
        pc:
          '店主山岸忍さんは、ホテルオークラの懐石料理店「山里」での修業を経て、2013年に独立されました。清潔感のある店内で、気さくで元気で笑顔の似合う山岸店主が、目の前で揚げる姿は、 まさにエンターテイメントです。銀座の真ん中で最高の天ぷらをお楽しみいただけます。',
        sp:
          '店主山岸忍さんは、ホテルオークラの懐石料理店「山里」での修業を経て、2013年に独立されました。清潔感のある店内で、気さくで元気で笑顔の似合う山岸店主が、目の前で揚げる姿は、 まさにエンターテイメントです。',
      },
      ikkon: {
        pc:
          '紹介会員制として17年、六本木にある「割烹 一献」は、一日5組限定 隠れた名店として、食通の間では有名な割烹料理店です。生産者への感謝を想い、独自性と枠にとらわれない新しい発想をもおもちである、店主鹿渡氏にシェフルキッチンへのご協力を頂きました。',
        sp:
          '紹介会員制として17年、六本木にある「割烹 一献」は、一日5組限定 隠れた名店として、食通の間では有名な割烹料理店です。',
      },
      momonoki: {
        pc:
          'メディアにも出演している店主の小林武志さんの作る、日本人に向けた中国料理を四季折々の最高の食材を使って提供されています。店名「桃の木」の由来は、美味しい料理に沢山の人々が集まって頂けるお店にしたいという店主の小林氏の思いから付けた名前だそうです。',
        sp:
          'メディアにも出演している店主の小林武志さんの作る、日本人に向けた中国料理を四季折々の最高の食材を使って提供されています。',
      },
      tubame: {
        pc:
          '「燕三条の魅力で新潟を熱狂させ、皆を幸せに」をモットーに、燕三条に誇る職人による、洋食器、グラスや箸などを使い、料理を提供されています。食材においても、燕三条イタリア野菜研究会のご協力により、毎週地元燕三条から沢山の野菜が送られ、食材を活かるイタリア料理だと、地元でも評判のお店です。',
        sp:
          '「燕三条の魅力で新潟を熱狂させ、皆を幸せに」をモットーに、燕三条に誇る職人による、洋食器、グラスや箸などを使い、料理を提供されています。',
      },
    }
    console.log()

    return (
      <>
        <div className="top-page">
          <Helmet>
            <link rel="canonical" href="https://chefle.com/kitchen" />
            <title>{head.title}</title>
            <meta property="og:title" content={head.title} />
            <meta property="og:description" content={head.description} />
            <meta property="og:url" content={head.page_url} />
            <meta name="description" content={head.description} />
            <meta property="og:image" content={head.image} />
          </Helmet>
          <PcHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
            reservationType={'chefle-kitchen'}
          />
          <SpHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
            reservationType={'chefle-kitchen'}
          />

          <section>
            <div className="top-page-top">
              <div
                className="slide-title"
                style={{
                  marginTop: '15px',
                  lineHeight: '1.4',
                }}
              >
                高級店の食材を、
                <br />
                あなたのご自宅に
                <p>シェフルキッチン</p>
              </div>
              <div className="each-slide pc">
                <div
                  style={{
                    backgroundImage: `url(${pcSlideImages[0]})`,
                    height: '390px',
                  }}
                />
              </div>
              <div className="each-slide sp">
                <div
                  style={{
                    backgroundImage: `url(${spSlideImages[0]})`,
                    height: '185px',
                  }}
                />
              </div>
            </div>
          </section>
          <section>
            <div className="top-page-selection">
              <div className="top-page-selection-title-box">
                <div className="top-page-selection-title chefle-kitchen__top">
                  <img
                    src="/assets/img/chefle/chefle-kitchen-logo.png"
                    alt="キッチンロゴ"
                  />
                  <h1 className="chefle-kitchen__top-header">
                    人気高級店がお店で使用している
                    <br />
                    高級食材の配送サービス
                  </h1>
                </div>
                <p className="font">About our service</p>
              </div>
              <div className="top-page-selection-head">
                <div className="chefle-kitchen__nousui font">
                  高級店シェフが目利きした旬の食材を、シェフ考案の動画レシピ・食べ方とともに豊洲市場より直送でお届けします。
                  ぜひ、高級店のお料理をあなたのご自宅でお楽しみください。
                </div>
              </div>
              <div className="chefle-kitchen__header">
                <h2 className="font" Style="margin: 10px 0;">
                  商品人気ランキング
                </h2>
              </div>
              <div className="chefle-kitchen__ranking-content">
                {createRankingCardContent({
                  rankingClass: 'chefle-kitchen__ranking-1',
                  textClass: 'chefle-kitchen__ranking-text-1',
                  href: 'https://shop.chefle.com/products/oma_chutoro_otoro',
                  src: '/assets/img/chefle/tyuutoro-ootoro.jpg',
                  title: '大間まぐろ',
                  ranking: '1',
                  text: '天然大間まぐろ(大トロ、中トロ）',
                })}
                {createRankingCardContent({
                  rankingClass: 'chefle-kitchen__ranking-2',
                  textClass: 'chefle-kitchen__ranking-text-2',
                  href: 'https://shop.chefle.com/products/sharkfin',
                  src: '/assets/img/chefle/hukahire-rank.jpg',
                  title: 'フカヒレ',
                  ranking: '2',
                  text: 'フカヒレ姿煮',
                })}
                {!isMobile && (
                  <React.Fragment>
                    {createRankingCardContent({
                      rankingClass: 'chefle-kitchen__ranking-3',
                      textClass: 'chefle-kitchen__ranking-text-3',
                      href: 'https://shop.chefle.com/products/oma_otoro',
                      src: '/assets/img/chefle/oma-otoro2.jpg',
                      title: '天然大間まぐろ(大トロ）',
                      ranking: '3',
                      text: '天然大間まぐろ(大トロ）',
                    })}
                    {createRankingCardContent({
                      rankingClass: '',
                      textClass: '',
                      href: 'https://shop.chefle.com/products/kobeshabushabu',
                      src: '/assets/img/chefle/syabusyabu.jpg',
                      title: '最高ランクしゃぶしゃぶセット',
                      ranking: '4',
                      text: '最高ランクしゃぶしゃぶセット',
                    })}
                  </React.Fragment>
                )}
              </div>
              {/* <a
                className="chefle-kitchen__ranking-peraichi font"
                href="https://peraichi.com/landing_pages/view/chefleranking"
              >
                人気ランキング全体を見る
              </a> */}
              {/* <div className="chefle-kitchen__special">
                <a
                  className="chefle-kitchen__special-link"
                  href="https://peraichi.com/landing_pages/view/cheflespringsale"
                >
                  入学・入社祝い特集
                </a>
              </div> */}
              <div className="chefle-kitchen__header">
                <h2 className="font">レシピ一覧</h2>
              </div>
              <ChefleKitchenSlider />
            </div>
          </section>
          <section className="top-page-restaurant">
            <div className="top-page-selection">
              <div className="chefle-kitchen__header">
                <h2 className="font" Style="margin-top:20px">
                  厳選ご馳走食材
                </h2>
              </div>
              {isMobile && (
                <div>
                  <p className="chefle-kitchen__food-title-sp font">
                    [注文件数順カテゴリー]
                  </p>
                  <div className="chefle-kitchen__foods-list">
                    <p className="chefle-kitchen__food-text-sp font">1. うに</p>
                    <a
                      className="chefle-kitchen__food-button font"
                      href="https://shop.chefle.com/collections/uni"
                    >
                      購入する
                    </a>
                  </div>
                  <div className="chefle-kitchen__foods-list">
                    <p className="chefle-kitchen__food-text-sp font">2. 海老</p>
                    <a
                      className="chefle-kitchen__food-button font"
                      href="https://shop.chefle.com/collections/ebi"
                    >
                      購入する
                    </a>
                  </div>
                  <div className="chefle-kitchen__foods-list">
                    <p className="chefle-kitchen__food-text-sp font">
                      3. 大間まぐろ
                    </p>
                    <a
                      className="chefle-kitchen__food-button font"
                      href="https://shop.chefle.com/collections/omamaguro"
                    >
                      購入する
                    </a>
                  </div>
                  <div className="chefle-kitchen__foods-list">
                    <p className="chefle-kitchen__food-text-sp font">
                      4. フカヒレ
                    </p>
                    <a
                      className="chefle-kitchen__food-button font"
                      href="https://shop.chefle.com/collections/fukahire"
                    >
                      購入する
                    </a>
                  </div>
                  <a
                    className="chefle-kitchen__foods-title-sp font"
                    href="https://shop.chefle.com/collections/all"
                  >
                    すべての商品一覧
                  </a>
                </div>
              )}
              <div className="chefle-kitchen__foods">
                <div className="chefle-kitchen__food">
                  <a
                    className="chefle-kitchen__image-content"
                    href="https://shop.chefle.com/products/limited-sushi-box"
                  >
                    {CreateFoodContent('sushibox')}
                    <h3
                      className="chefle-kitchen__image-text font"
                      Style="width: 250px;"
                    >
                      SUSHI BOX
                    </h3>
                    <div className="chefle-kitchen__food-content">
                      <p className="chefle-kitchen__food-description font">
                        ※逆輸入商品※
                        <br />
                        <br />
                        香港在住者向けに「SUSHIBOX」として寿司ダネセットを輸出中。大人気のため、国内向けにも【限定30セット】販売させて頂きます。
                      </p>
                    </div>
                  </a>
                </div>
                <div className="chefle-kitchen__food">
                  <a
                    className="chefle-kitchen__image-content"
                    href="https://shop.chefle.com/products/gift-card"
                  >
                    {CreateFoodContent('gift-card')}
                    <div className="chefle-kitchen__food-content">
                      <p className="chefle-kitchen__food-description font">
                        シェフルデジタルギフトカードで、高級食材をプレゼントしませんか？
                        <br />
                        お誕生日プレゼント、お中元、お歳暮など。5,000円、10,000円、15,000円、20,000円の中からお好きな金額をお選びいただけます。
                      </p>
                    </div>
                  </a>
                </div>
                <div className="chefle-kitchen__food">
                  <a
                    className="chefle-kitchen__image-content"
                    href="https://shop.chefle.com/collections/rice"
                  >
                    {CreateFoodContent('kosihikari')}
                    <h3 className="chefle-kitchen__image-text font">
                      コシヒカリ
                      <br />
                      <span Style="font-size:30px;">秘境米/塩沢</span>
                    </h3>
                    <div className="chefle-kitchen__food-content">
                      <p className="chefle-kitchen__food-description font">
                        三条市鹿熊集落で育てられた幻の米"秘境米"、そしてTop of
                        コシヒカリと名高い正統派 南魚沼
                        "塩沢産"。新潟コシヒカリの両極をぜひ食べ比べください。
                      </p>
                      <div className="chefle-kitchen__food-text">
                        <p className="chefle-kitchen__food-chefe font">
                          [目利き] 燕三条Bit
                        </p>
                        <p className="chefle-kitchen__food-intermediate font">
                          [販売] 白熊
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="chefle-kitchen__food">
                  <a
                    className="chefle-kitchen__image-content"
                    href="https://shop.chefle.com/products/nigatabeef"
                  >
                    {CreateFoodContent('niigatagyu')}
                    <h3
                      className="chefle-kitchen__image-text font"
                      Style="width: 250px;"
                    >
                      <span Style="font-size: 28px;">別格 雪温貯蔵</span>
                      <br />
                      にいがた和牛
                    </h3>
                    <div className="chefle-kitchen__food-content">
                      <p className="chefle-kitchen__food-description font">
                        八海山の麓「魚沼の里」の雪室で熟成された新潟ブランド和牛"雪温貯蔵"にいがた和牛。最高峰A5ランク・サーロインステーキをお楽しみください。
                      </p>
                      <div className="chefle-kitchen__food-text">
                        <p className="chefle-kitchen__food-chefe font">
                          [目利き] 燕三条Bit
                        </p>
                        <p className="chefle-kitchen__food-intermediate font">
                          [販売] 内山肉店
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="chefle-kitchen__food">
                  <a
                    className="chefle-kitchen__image-content"
                    href="https://shop.chefle.com/products/tenkeiko"
                  >
                    {CreateFoodContent('tenkeiko')}
                    <h3 className="chefle-kitchen__image-text font">
                      天恵菇
                      <br />
                      <span Style="font-size:30px;">(てんけいこ)</span>
                    </h3>
                    <div className="chefle-kitchen__food-content">
                      <p className="chefle-kitchen__food-description font">
                        南魚沼市八色原地区で育てられる希少な椎茸「天恵菇」。育て方が難しく安定供給ができないため地元でも手に入りにくいプレミアム椎茸をご提供。
                      </p>
                      <div className="chefle-kitchen__food-text">
                        <p className="chefle-kitchen__food-chefe font">
                          [目利き] 燕三条Bit
                        </p>
                        <p className="chefle-kitchen__food-intermediate font">
                          [販売] おらしょの野菜
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="chefle-kitchen__food">
                  <a
                    className="chefle-kitchen__image-content"
                    href="https://shop.chefle.com/products/kuroshiitake"
                  >
                    {CreateFoodContent('kuromaitake')}
                    <h3 className="chefle-kitchen__image-text font">黒舞茸</h3>
                    <div className="chefle-kitchen__food-content">
                      <p className="chefle-kitchen__food-description font">
                        舞茸の中でも最高ランクの香り・旨味を持つと言われる黒舞茸。希少性が高く、市場でも、高額で取引されます。
                      </p>
                      <div className="chefle-kitchen__food-text">
                        <p className="chefle-kitchen__food-chefe font">
                          [目利き] 燕三条Bit
                        </p>
                        <p className="chefle-kitchen__food-intermediate font">
                          [販売] おらしょの野菜
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="chefle-kitchen__food">
                  <a
                    className="chefle-kitchen__image-content"
                    href="https://shop.chefle.com/products/fukahire"
                  >
                    {CreateFoodContent('hukahire')}
                    <h3 className="chefle-kitchen__image-text font">
                      フカヒレ
                    </h3>
                    <div className="chefle-kitchen__food-content">
                      <p className="chefle-kitchen__food-description font">
                        中華の高級食材といえばフカヒレ。姿煮が簡単に作れる煮込み用ソース付きです。”桃の木"特別レシピのフカヒレ丼も最高の美味です。非日常のひとときに。
                      </p>
                      <div className="chefle-kitchen__food-text">
                        <p className="chefle-kitchen__food-chefe font">
                          [目利き] 桃の木
                        </p>
                        <p className="chefle-kitchen__food-intermediate font">
                          [販売] 古樹軒
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="chefle-kitchen__food">
                  <a
                    className="chefle-kitchen__image-content"
                    href="https://shop.chefle.com/products/dryhotate"
                  >
                    {CreateFoodContent('kaibashira')}
                    <h3 className="chefle-kitchen__image-text font">
                      干し貝柱
                    </h3>
                    <div className="chefle-kitchen__food-content">
                      <p className="chefle-kitchen__food-description font">
                        一流店"桃の木"の看板メニュー「ホタテチャーハン」がご自宅で作れます。名門古樹軒の干し貝柱は、スープ、あんかけ、煮込みなど何にでも合います。
                      </p>
                      <div className="chefle-kitchen__food-text">
                        <p className="chefle-kitchen__food-chefe font">
                          [目利き] 桃の木
                        </p>
                        <p className="chefle-kitchen__food-intermediate font">
                          [販売] 古樹軒
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="chefle-kitchen__food">
                  <a
                    className="chefle-kitchen__image-content"
                    href="https://shop.chefle.com/products/ikkonset"
                  >
                    {CreateFoodContent('taimeshi')}
                    <h3 className="chefle-kitchen__image-text font">鯛めし</h3>
                    <div className="chefle-kitchen__food-content">
                      <p className="chefle-kitchen__food-description font">
                        お祝い事には”鯛”。下調理した鯛に、オリジナルブレンド米、お出汁も付けてお届けします。高級魚あぶらぼう、高知の新鮮な鯵もついた贅沢セットです。
                      </p>
                      <div className="chefle-kitchen__food-text">
                        <p className="chefle-kitchen__food-chefe font">
                          [目利き] 一献
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="chefle-kitchen__food">
                  <a
                    className="chefle-kitchen__image-content"
                    href="https://shop.chefle.com/products/buriou"
                  >
                    {CreateFoodContent('buri')}
                    <h3 className="chefle-kitchen__image-text font">ブリ</h3>
                    <div className="chefle-kitchen__food-content">
                      <p className="chefle-kitchen__food-description font">
                        日本一ブランドの鹿児島長島町の”鰤王（ぶりおう）”にフレンチ石井シェフのレシピを付けました。フレンチ風にご賞味ください。
                      </p>
                      <div className="chefle-kitchen__food-text">
                        <p className="chefle-kitchen__food-chefe font">
                          [目利き] モノリス
                        </p>
                        <p className="chefle-kitchen__food-intermediate font">
                          [販売] 東町漁業協同組合
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="chefle-kitchen__food">
                  <a
                    className="chefle-kitchen__image-content"
                    href="https://shop.chefle.com/collections/uni"
                  >
                    {CreateFoodContent('uni')}
                    <h3 className="chefle-kitchen__image-text font">うに</h3>

                    <div className="chefle-kitchen__food-content">
                      <p className="chefle-kitchen__food-description font">
                        おひとり様もご自宅で贅沢なお食事を。極上ウニ100g（1-2人前）を木箱でお届けします。
                      </p>
                      <div className="chefle-kitchen__food-text">
                        <p className="chefle-kitchen__food-chefe font">
                          [目利き] 鮨処やまだ
                        </p>
                        <p className="chefle-kitchen__food-intermediate font">
                          [仲卸] 新石商店
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="chefle-kitchen__food">
                  <a
                    className="chefle-kitchen__image-content"
                    href="https://shop.chefle.com/products/kurumaebi"
                  >
                    {CreateFoodContent('kuruma-ebi')}
                    <h3 className="chefle-kitchen__image-text font">車海老</h3>

                    <div className="chefle-kitchen__food-content">
                      <p className="chefle-kitchen__food-description font">
                        活車海老を豊洲市場から直送でお届けします。天ぷらや塩焼きなどで新鮮な車海老をお楽しみください。
                      </p>
                      <div className="chefle-kitchen__food-text">
                        <p className="chefle-kitchen__food-chefe font">
                          [目利き] 天ぷらよしたけ
                        </p>
                        <p className="chefle-kitchen__food-intermediate font">
                          [仲卸] 登美粋
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="chefle-kitchen__food">
                  <a
                    className="chefle-kitchen__image-content"
                    href="https://shop.chefle.com/products/iseebi"
                  >
                    {CreateFoodContent('ise-ebi')}
                    <h3 className="chefle-kitchen__image-text font">
                      伊勢海老
                    </h3>

                    <div className="chefle-kitchen__food-content">
                      <p className="chefle-kitchen__food-description font">
                        活伊勢海老を豊洲市場から直送でお届けします。少し大きめの400gサイズでお届けします。
                      </p>
                      <div className="chefle-kitchen__food-text">
                        <p className="chefle-kitchen__food-chefe font">
                          [目利き] 天ぷらよしたけ
                        </p>
                        <p className="chefle-kitchen__food-intermediate font">
                          [仲卸] 登美粋
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="chefle-kitchen__food">
                  <a
                    className="chefle-kitchen__image-content"
                    href="https://shop.chefle.com/collections/kujira"
                  >
                    {CreateFoodContent('kujira')}
                    <h3 className="chefle-kitchen__image-text font">
                      鯨
                      <br />
                      <span Style="font-size: 22px">(くじら)</span>
                    </h3>

                    <div className="chefle-kitchen__food-content">
                      <p className="chefle-kitchen__food-description font">
                        懐かしくもあり新しくもある鯨肉。豊洲の専門仲卸登美粋さんから新鮮な状態でお届けします。
                      </p>
                      <div className="chefle-kitchen__food-text">
                        <p className="chefle-kitchen__food-chefe font">
                          [目利き]鮨処やまだ
                        </p>
                        <p className="chefle-kitchen__food-intermediate font">
                          [仲卸] 登美粋
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="chefle-kitchen__food">
                  <a
                    className="chefle-kitchen__image-content"
                    href="https://shop.chefle.com/collections/omamaguro"
                  >
                    {CreateFoodContent('maguro')}
                    <h3 className="chefle-kitchen__image-text font">
                      大間
                      <br />
                      まぐろ
                    </h3>

                    <div className="chefle-kitchen__food-content">
                      <p className="chefle-kitchen__food-description font">
                        寿司ネタの王様のまぐろ。そのまぐろの中でさらに王様と言われる大間産まぐろをご家庭でも味わうことができます。
                      </p>
                      <div className="chefle-kitchen__food-text">
                        <p className="chefle-kitchen__food-chefe font">
                          [目利き] 鮨処やまだ
                        </p>
                        <p className="chefle-kitchen__food-intermediate font">
                          [仲卸] 相馬水産
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="chefle-kitchen__food">
                  <a
                    className="chefle-kitchen__image-content"
                    href="https://shop.chefle.com/products/karasumi"
                  >
                    {CreateFoodContent('karasumi')}
                    <h3 className="chefle-kitchen__image-text font">
                      高級珍味
                      <br />
                      からすみ
                    </h3>

                    <div className="chefle-kitchen__food-content">
                      <p className="chefle-kitchen__food-description font">
                        通常はお店の裏メニュー。銀座鮨店主手づくりの限定30本の貴重な最高級からすみをお届けします。
                      </p>
                      <div className="chefle-kitchen__food-text">
                        <p className="chefle-kitchen__food-chefe font">
                          [販売] 鮨処やまだ
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div>
                <div className="chefle-kitchen__header">
                  <h2 className="font">食材目利きシェフご紹介</h2>
                </div>
                <div>
                  <div className="chefle-kitchen__chefe-contents">
                    <div className="chefle-kitchen__chefe-content">
                      <LazyLoad height="100%" offset={150}>
                        <img
                          className="chefle-kitchen__chefe-image"
                          src="/assets/img/chefle/yamada.jpg"
                          alt="山田"
                        />
                      </LazyLoad>
                      <div className="chefle-kitchen__chefe-link">
                        <a
                          href="https://youtu.be/o0s7DIamBos"
                          className="chefle-kitchen__chefe-video"
                          target="blank"
                        >
                          >>紹介PVをみる
                        </a>
                        <a
                          href="https://chefle.com/menu/restaurants/30549"
                          className="chefle-kitchen__chefe-video"
                          target="blank"
                        >
                          >>予約
                        </a>
                      </div>
                    </div>
                    <div>
                      <p className="chefle-kitchen__shop-name font">
                        鮨處やまだ（銀座・鮨）
                      </p>
                      <p className="chefle-kitchen__shop-description font">
                        {isMobile
                          ? chefeDescription.yamada.sp
                          : chefeDescription.yamada.pc}
                      </p>
                    </div>
                  </div>
                  <div className="chefle-kitchen__chefe-contents">
                    <div className="chefle-kitchen__chefe-content">
                      <LazyLoad height="100%" offset={150}>
                        <img
                          className="chefle-kitchen__chefe-image"
                          src="/assets/img/chefle/ichimiya.jpg"
                          alt="一宮"
                        />
                      </LazyLoad>
                      <a
                        href="https://youtu.be/eG2ZVBpSW-0"
                        className="chefle-kitchen__chefe-video"
                        target="blank"
                      >
                        >>紹介PVをみる
                      </a>
                    </div>
                    <div>
                      <p className="chefle-kitchen__shop-name font">
                        一宮（銀座・ステーキ）
                      </p>
                      <p className="chefle-kitchen__shop-description font">
                        {isMobile
                          ? chefeDescription.ichimiya.sp
                          : chefeDescription.ichimiya.pc}
                      </p>
                    </div>
                  </div>
                  <div className="chefle-kitchen__chefe-contents">
                    <div className="chefle-kitchen__chefe-content">
                      <LazyLoad height="100%" offset={150}>
                        <img
                          className="chefle-kitchen__chefe-image"
                          src="/assets/img/chefle/monoris.jpg"
                          alt="モノリス"
                        />
                      </LazyLoad>
                      <div className="chefle-kitchen__chefe-link">
                        <a
                          href="https://youtu.be/TRblXKVw1V4"
                          className="chefle-kitchen__chefe-video"
                          target="blank"
                        >
                          >>紹介PVをみる
                        </a>
                        <a
                          href="https://chefle.com/menu/restaurants/30859"
                          className="chefle-kitchen__chefe-video"
                          target="blank"
                        >
                          >>予約
                        </a>
                      </div>
                    </div>
                    <div>
                      <p className="chefle-kitchen__shop-name font">
                        monoLith モノリス （渋谷・フレンチ）
                      </p>
                      <p className="chefle-kitchen__shop-description font">
                        {isMobile
                          ? chefeDescription.monoris.sp
                          : chefeDescription.monoris.pc}
                      </p>
                    </div>
                  </div>
                  <div className="chefle-kitchen__chefe-contents">
                    <div className="chefle-kitchen__chefe-content">
                      <LazyLoad height="100%" offset={150}>
                        <img
                          className="chefle-kitchen__chefe-image"
                          src="/assets/img/chefle/yoshitake.jpg"
                          alt="よしたけ"
                        />
                      </LazyLoad>
                      <a
                        href="https://youtu.be/KaRn6mO7RWQ"
                        className="chefle-kitchen__chefe-video"
                        target="blank"
                      >
                        >>紹介PVをみる
                      </a>
                    </div>
                    <div>
                      <p className="chefle-kitchen__shop-name font">
                        天ぷら よしたけ（銀座・天ぷら）
                      </p>
                      <p className="chefle-kitchen__shop-description font">
                        {isMobile
                          ? chefeDescription.yoshitake.sp
                          : chefeDescription.yoshitake.pc}
                      </p>
                    </div>
                  </div>
                  <div className="chefle-kitchen__chefe-contents">
                    <div className="chefle-kitchen__chefe-content">
                      <LazyLoad height="100%" offset={150}>
                        <img
                          className="chefle-kitchen__chefe-image"
                          src="/assets/img/chefle/ikkon.jpg"
                          alt="いっこん"
                        />
                      </LazyLoad>
                      <a
                        href="https://youtu.be/I423JK_Sej4"
                        className="chefle-kitchen__chefe-video"
                        target="blank"
                      >
                        >>紹介PVをみる
                      </a>
                    </div>
                    <div>
                      <p className="chefle-kitchen__shop-name font">
                        割烹一献（六本木・割烹）
                      </p>
                      <p className="chefle-kitchen__shop-description font">
                        {isMobile
                          ? chefeDescription.ikkon.sp
                          : chefeDescription.ikkon.pc}
                      </p>
                    </div>
                  </div>
                  <div className="chefle-kitchen__chefe-contents">
                    <div className="chefle-kitchen__chefe-content">
                      <LazyLoad height="100%" offset={150}>
                        <img
                          className="chefle-kitchen__chefe-image"
                          src="/assets/img/chefle/momonoki.jpg"
                          alt="桃の木"
                        />
                      </LazyLoad>
                      <div className="chefle-kitchen__chefe-link">
                        <a
                          href="https://youtu.be/hn1b4rYRmjo"
                          className="chefle-kitchen__chefe-video"
                          target="blank"
                        >
                          >>紹介PVをみる
                        </a>
                        <a
                          href="https://chefle.com/menu/restaurants/30857"
                          className="chefle-kitchen__chefe-video"
                          target="blank"
                        >
                          >>予約
                        </a>
                      </div>
                    </div>
                    <div>
                      <p className="chefle-kitchen__shop-name font">
                        赤坂 桃の木（永田町・桃の木）
                      </p>
                      <p className="chefle-kitchen__shop-description font">
                        {isMobile
                          ? chefeDescription.momonoki.sp
                          : chefeDescription.momonoki.pc}
                      </p>
                    </div>
                  </div>
                  <div className="chefle-kitchen__chefe-contents">
                    <div className="chefle-kitchen__chefe-content">
                      <LazyLoad height="100%" offset={150}>
                        <img
                          className="chefle-kitchen__chefe-image"
                          src="/assets/img/chefle/tubame.jpg"
                          alt="つばめ"
                        />
                      </LazyLoad>
                      <a
                        href="https://youtu.be/rMsg4t3swj0"
                        className="chefle-kitchen__chefe-video"
                        target="blank"
                      >
                        >>紹介PVをみる
                      </a>
                    </div>
                    <div>
                      <p className="chefle-kitchen__shop-name font">
                        燕三条イタリアンBit（新潟 燕三条・イタリアン)
                      </p>
                      <p className="chefle-kitchen__shop-description font">
                        {isMobile
                          ? chefeDescription.tubame.sp
                          : chefeDescription.tubame.pc}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slide-sevices sp">
                <Link to="/" Style="position: relative">
                  <span className="chefle-kitchen__banner-header">
                    来店予約サービス
                  </span>
                  <span className="chefle-kitchen__banner-text">
                    予約の取りにくいお店もChefleが代行予約
                  </span>
                  <div className="slide-sevices-item">
                    <img
                      Style="height: 114px; object-fit:cover;"
                      src="/assets/img/chefle/top-small.jpg"
                      alt="メニューへ"
                    />
                    <span Style="background-color: white;">
                      来店予約レストラン一覧へ
                      <img
                        src="/assets/img/chefle/right_arrow_to_service.png"
                        alt=""
                      />
                    </span>
                  </div>
                </Link>
                <Link to="/catering" Style="position: relative">
                  <div className="slide-sevices-item">
                    <img
                      src="/assets/img/chefle/to_chefle.png"
                      alt="在宅レストラン"
                    />
                    <span Style="background-color: white;">
                      在宅レストラン一覧へ
                      <img
                        src="/assets/img/chefle/right_arrow_to_service.png"
                        alt=""
                      />
                    </span>
                  </div>
                </Link>
                <Link to="/pickup" Style="position: relative">
                  <div className="slide-sevices-item">
                    <img
                      src="/assets/img/chefle/to_pickup.png"
                      alt="店頭受取"
                    />
                    <span Style="background-color: #fff;">
                      店頭受取レストラン一覧へ
                      <img
                        src="/assets/img/chefle/right_arrow_to_service.png"
                        alt=""
                      />
                    </span>
                  </div>
                </Link>
                <Link to="/kitchen" Style="position: relative">
                  <span className="chefle-kitchen__banner-header">
                    シェフルキッチン
                  </span>
                  <span className="chefle-kitchen__banner-text">
                    プロが目利きした食材をご自宅にお届け
                  </span>
                  <div className="slide-sevices-item">
                    <img
                      src="/assets/img/chefle/chefle-kitchen-banner.png"
                      alt="キッチンバナー"
                    />
                    <span Style="color: white; background-color: #ff5800;">
                      お取り寄せ
                      <img
                        src="/assets/img/chefle/down_arrow_to_service.png"
                        alt=""
                      />
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChefleKitchenContainer)
