import React from 'react'
import { connect } from 'react-redux'
import AuthComponent, {
  authMapStateToProps,
  authMapDispatchToProps,
} from '../../../components/AuthComponent'
import { Helmet } from 'react-helmet'

import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router'
import { PcHeader, SpHeader } from '../../../components/top/Header'
import CompleteIcon from '../../../components/common/CompleteIcon'

const styles = theme => ({
  textField: {
    width: '100%',
    fontSize: '16px',
  },
  formControl: {
    minWidth: 100,
  },
})

function mapStateToProps(state) {
  return Object.assign(authMapStateToProps(state), {})
}

function mapDispatchToProps(dispatch) {
  return Object.assign(authMapDispatchToProps(dispatch), {})
}
class PasswordUpdateCompleteContainer extends AuthComponent {
  render() {
    const { authUser } = this.props
    return (
      <>
        <div className="account-setting">
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
          <PcHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
          />
          <SpHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
          />
          <div className="account-setting-contents">
            <div className="reserve-complete">
              <div className="main-inner">
                <CompleteIcon />
                <p className="reserve-complete-txt" />
                <p className="reserve-complete-txt2">
                  パスワードを変更しました。
                </p>
                <a href="/" className="reserve-complete-return-btn">
                  トップページへ戻る
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(PasswordUpdateCompleteContainer))
)
