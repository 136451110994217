import React, { PureComponent } from 'react'
import Modal from 'react-modal'

class WhatToEat extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
    }
  }

  openModal = () => {
    this.setState({ modalIsOpen: true })
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false })
  }

  select = urlKey => {
    this.closeModal()
    this.props.onSelect(urlKey)
  }

  render() {
    return (
      <>
        <div style={{ cursor: 'pointer' }}>
          <p
            id="pc-what-to-eat"
            className="top-page-selection-head-what-eat modal-open"
            onClick={this.openModal.bind(this)}
          >
            <span>{this.props.label}</span>
          </p>
        </div>
        <CategoryModal
          state={this.state}
          closeModal={this.closeModal.bind(this)}
          select={this.select}
          categories={this.props.categories}
          urlKey={this.props.urlKey}
        />
      </>
    )
  }
}

class WhatToEatSp extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
    }
  }

  openModal = () => {
    this.setState({ modalIsOpen: true })
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false })
  }

  select = urlKey => {
    this.closeModal()
    this.props.onSelect(urlKey)
  }

  changeModal() {
    this.setState({ modalIsOpen: !this.state.modalIsOpen })
  }

  render() {
    return (
      <>
        <img
          src="/assets/img/couse_icon_01.svg"
          alt=""
          className="fixed-footer-right"
          onClick={this.changeModal.bind(this)}
        />
        <CategoryModal
          state={this.state}
          closeModal={this.closeModal.bind(this)}
          select={this.select}
          categories={this.props.categories}
          urlKey={this.props.urlKey}
        />
      </>
    )
  }
}

const CategoryModal = props => (
  <Modal
    closeTimeoutMS={200}
    isOpen={props.state.modalIsOpen}
    onRequestClose={props.closeModal}
    className="slide-modal-contents"
    style={customStyles}
  >
    <p className="to-eat-modal-head what-to-eat-head">What to eat</p>
    <ul className="to-eat-modal-list">
      <li>
        <input
          type="radio"
          checked={props.urlKey === ''}
          onClick={() => props.select()}
        />
        <label
          className="radio"
          checked={props.urlKey === ''}
          onClick={() => props.select()}
        >
          全料理ジャンル
        </label>
      </li>
      {props.categories.map(category => {
        return (
          <li key={category.id}>
            <input
              type="radio"
              checked={props.urlKey === category.urlKey}
              onClick={() => props.select(category.urlKey)}
            />
            <label
              className="radio"
              checked={props.urlKey === category.urlKey}
              onClick={() => props.select(category.urlKey)}
            >
              {category.name}
            </label>
          </li>
        )
      })}
    </ul>
  </Modal>
)

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 100,
  },
}

const getCategoryName = (categories, urlKey) => {
  if (!urlKey) {
    return '全料理ジャンル'
  }
  let category = categories.find(category => category.urlKey === urlKey)
  return !category ? '' : category.name
}

export { WhatToEat, WhatToEatSp, getCategoryName }
