import React, { Component } from 'react'
import Modal from 'react-modal'

class FAQ extends Component {
  state = {
    modalIsOpen: false,
  }
  openModal() {
    this.setState({ modalIsOpen: true })
  }
  closeModal() {
    this.setState({ modalIsOpen: false })
  }
  changeModalState() {
    this.setState({ modalIsOpen: !this.state.modalIsOpen })
  }
  render() {
    return (
      <>
        <a
          href={() => false}
          onClick={this.openModal.bind(this)}
          className={this.props.btnclassname}
        >
          {this.props.text}
        </a>
        <Modal
          closeTimeoutMS={200}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal.bind(this)}
          style={customStyles}
          contentLabel="CancelationPolicy Modal"
        >
          <div className="modal">
            <h3 className="modal-title">お申込みについて</h3>
            <div className="modal-content">
              <h2 Style="color: #ff5800;">【在宅レストラン】</h2>
              <div className="sentence">
                <h3>Q1：お申し込みは電話でも可能ですか？</h3>
                <p>
                  現在、お申し込みはインターネットからのみ受付可能です。
                  <br />
                  コンシェルジュによる
                  <a
                    href="https://jpneazy.com/reservation/chat.html"
                    target="blank"
                  >
                    チャットサポート
                  </a>
                  （平日午前10時から午後18時まで）も行っておりますのでお気軽にお問い合わせください。
                </p>
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>Q2：サービス利用の最低条件を教えてください。</h3>
                <p>各シェフ毎に異なります。ページ内詳細をご確認ください。</p>
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>Q3：当日、用意しなければならないものはありますか？</h3>
                <p>
                  ご用意して頂くものは特にございません。
                  <br />
                  キッチン周りの清掃をお願いします。特に、シンクの中には、洗い物が無い状態の徹底をお願い申し上げます。万が一、調理をするにあたり、著しく不衛生であり、調理が出来かねる状況と判断された場合、当日キャンセル扱いとなり、退出させていただく場合がございます。十分にご注意ください。
                  <br />
                  その他、各シェフ毎に、設備要件が異なります。申込みリクエスト送信後、自動メール返信にて、設備要件確認のためアンケートのご回答をお願い申し上げております。その後、成約可能か弊社にて判断させて頂きます。
                  <br />
                  また、必要最低限の調味料（塩・しょうゆ・みそ・みりん・酢）やフライパン、鍋のご用意はある前提でございますので、その点ご了承くださいませ。
                </p>
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>Q4：IHクッキングヒーターでもサービス利用は可能ですか？</h3>
                <p>
                  各シェフ毎に異なります。ページ内詳細をご確認ください。
                  <br />
                  可能な場合も、IH専用の調理器具はご用意いただくこと前提でございます。
                </p>
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>Q5：希望の食材を用意していただくことは可能ですか？</h3>
                <p>
                  確約できませんが、シェフにご希望をお伝えすることは可能です。
                  <br />
                  ※食材の仕入れ状況により、やむなく当日のメニュー内容変更が生じる可能性もございます。ご了承くださいませ。
                </p>
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>Q6：アレルギー対応は可能ですか？</h3>
                <p>
                  はい、可能です。
                  <br />
                  時間の都合上、別の調理器具などを使用することは難しいため、過度のアレルギーの場合は、お断りする可能性がございます。必ず、事前にご確認ください。
                </p>
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>Q7：飲み物の用意はありますか？</h3>
                <p>一切ございません。お飲み物は、各自でご用意くださいませ。</p>
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>Q8：大人数でのパーティー出張は可能ですか？</h3>
                <p>
                  はい、可能な場合もございます。スケジュールの関係上、お早めにご予約頂けますと幸いです。
                </p>
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>Q9：月極での契約は可能でしょうか？</h3>
                <p>
                  有名店所属のシェフのみが所属しておりますため、一切行っておりません。
                </p>
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>Q10：自宅以外でも出張して頂けますか？</h3>
                <p>
                  はい、可能です。但し、キッチン設備が整っている場所に限ります。
                </p>
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>
                  Q11：当日どのような料理を作っていただけるか、事前に確認することは可能ですか？
                </h3>
                <p>
                  食材の仕入れ状況等は、当日まで分かり兼ねますので、全ておまかせコースにて対応させて頂いております。
                </p>
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>Q12：子供向けのコースメニューはありますか？</h3>
                <p>
                  各シェフ毎に異なります。ページ内詳細をご確認ください。
                  <br />
                  また、子供向けコースの対象年齢は6歳以上11歳未満と設定させて頂いております。ご了承くださいませ。
                </p>
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>Q13：時間はどれくらいかかりますか？</h3>
                <p>
                  おおよそ3時間～4時間程度とお考え下さい。
                  <br />
                  当日、申し込み時間にお食事を開始して頂けるよう、事前時間に訪問し、下準備を行います。
                  <br />
                  ※下準備に必要な時間は、ページ内詳細をご確認ください。
                  <br />
                  その後、お料理提供、片付けとなります。
                </p>
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>Q14：場所が変更になった場合、対応は可能ですか？</h3>
                <p>
                  対応エリア内であれば、ご対応することは可能です。メールにて、開催場所変更のご連絡をお願いします。
                  <br />
                  お早めにご連絡いただけますと幸いです。
                </p>
                <br />
                <br />
              </div>
              <h2 Style="color: #ff5800;">【店頭受取】</h2>
              <div className="sentence">
                <h3>Q1：お申し込みは電話でも可能ですか？</h3>
                <p>
                  現在、お申し込みはインターネットからのみ受付可能です。
                  <br />
                  コンシェルジュによるチャットサポート（平日午前10時から午後18時まで）も行っておりますのでお気軽にお問い合わせください。
                </p>
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>Q2：当日のお弁当予約は可能ですか？</h3>
                <p>基本的には、前々日までのご予約をお願いしております。</p>
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>Q3：店頭受取時間の指定はできますか？</h3>
                <p>はい。申し込みページの時間＝店頭での受取時間となります。</p>
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>Q4：店頭支払いに変更できますか？</h3>
                <p> 申し訳ございませんが、全て事前決済となります。</p>
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>
                  Q5：キャンセルや変更の連絡はいつまでにしたらいいですか？
                </h3>
                <p>
                  理由の如何を問わず、予約確定後の日程変更、時間変更、個数変更、キャンセルはいたしかねます。
                </p>
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>Q6：アレルギー対応はできますか？</h3>
                <p>
                  申し訳ございませんが、個別のアレルギー対応はいたしかねます。
                </p>
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>Q6：アレルギー対応はできますか？</h3>
                <p>
                  申し訳ございませんが、個別のアレルギー対応はいたしかねます。
                  <br />
                  アレルギー主要7品目につきましても、チャットサポート（平日午前10時から午後18時まで）にて事前にご確認をお願いします。
                </p>
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>Q7：賞味期限はありますか？</h3>
                <p>
                  店頭受取は、全て、ご注文頂いてからのご用意です。全てのお料理は手作業、防腐剤や添加物は不使用です。当日、お早めにお召し上がり下さい。
                </p>
                <br />
                <br />
              </div>
              <br />
              <br />
              <h2 Style="color: #ff5800;">【来店予約】</h2>
              <div className="sentence">
                <h3>Q1：お申し込みは電話でも可能ですか？</h3>
                <p>
                  現在、お申し込みはインターネットからのみ受付可能です。
                  <br />
                  コンシェルジュによる
                  <a
                    href="https://jpneazy.com/reservation/chat.html"
                    target="blank"
                  >
                    チャットサポート
                  </a>
                  （平日午前10時から午後18時まで）も行っておりますのでお気軽にお問い合わせください。
                </p>
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>Q2：当日の予約は可能ですか？</h3>
                <p>基本的には、前々日までのご予約をお願いしております。</p>
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>Q3：席だけの予約は可能ですか？</h3>
                <p>
                  申し訳ございませんが、コース代を事前決済にて承っており、席のみのご予約は受け付けておりません。
                </p>
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>Q4：店頭支払いに変更できますか？</h3>
                <p>申し訳ございませんが、全て事前決済となります。</p>
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>
                  Q5：キャンセルや変更の連絡はいつまでにしたらいいですか？
                </h3>
                <p>各レストランのキャンセルポリシーをご確認ください。</p>
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>Q6：貸し切りの対応はできますか？</h3>
                <p>
                  在宅レストランとして掲載のある店舗では、ご対応可能な場合もございます。まずは、お問い合わせください。
                </p>
                <br />
                <br />
              </div>
            </div>

            <h3 className="modal-title">問合せ先について</h3>
            <div className="modal-content">
              <div className="sentence">
                <h3>Q1：問合せ先を教えてください。</h3>
                <p>
                  chefle_support@fesbase.co.jp にて随時承っております。
                  <br />
                  その他、
                  <a
                    href="https://jpneazy.com/reservation/chat.html"
                    target="blank"
                  >
                    チャットサポート
                  </a>
                  も行っておりますので、ご活用ください。
                </p>
                <br />
                <br />
              </div>
              <div className="sentence">
                <h3>Q2：営業時間を教えてください。</h3>
                <p>平日午前10時から午後18時までとなっております。</p>
                <br />
                <br />
              </div>
            </div>
          </div>
        </Modal>
      </>
    )
  }
}

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    'z-index': '1000',
  },
  content: {
    margin: 'auto',
    'max-width': '900px',
    'border-radius': '18px',
    'z-index': '1000',
  },
}

export default FAQ
