const initialState = {
  data: {
    category: {},
    area: {},
    images: [],
    instagramUrls: [],
  },
  reservableDate: {
    disableDayOfWeeks: [],
    disableDates: [],
    enableDates: [],
  },
  reservableTimes: [],
  restaulantLoadingFlag: false,
  reservableDateLoadingFlag: false,
  reservableTimesLoadingFlag: false,
  error: false,
}
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'RESTAURANT_REQUEST':
      return {
        ...state,
        loadingFlag: true,
        error: false,
      }
    case 'RESTAURANT_OK':
      return {
        ...state,
        data: action.payload.data,
        loadingFlag: false,
        error: false,
      }
    case 'RESTAURANT_NG':
      return {
        ...state,
        loadingFlag: false,
        error: true,
      }
    case 'RESERVABLE_DATE_REQUEST':
      return {
        ...state,
        reservableDateLoadingFlag: true,
      }
    case 'RESERVABLE_DATE_OK':
      return {
        ...state,
        reservableDate: action.payload.data,
        reservableDateLoadingFlag: false,
      }
    case 'RESERVABLE_DATE_NG':
      return {
        ...state,
        reservableDateLoadingFlag: false,
      }
    case 'RESERVABLE_TIME_REQUEST':
      return {
        ...state,
        reservableTimesLoadingFlag: true,
      }
    case 'RESERVABLE_TIME_OK':
      return {
        ...state,
        reservableTimes: action.payload.data,
        reservableTimesLoadingFlag: false,
      }
    case 'RESERVABLE_TIME_NG':
      return {
        ...state,
        reservableTimesLoadingFlag: false,
      }
    case 'LOCAL_SET_RESEVABLE_TIME':
      return {
        ...state,
        reservableTimes: action.payload,
      }
    default:
      return state
  }
}
