import axios from 'axios'
import ApiOrigin from '../constants/endpoint'

const API_ROOT = ApiOrigin

// api を呼ぶときはこのアクションに統一する
export const CALL_API = 'Call API'

const callApi = (endpoint, payload) => {
  const url = endpoint.indexOf(API_ROOT) === -1 ? API_ROOT + endpoint : endpoint
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'X-Client-Terminal-Id': localStorage.getItem('clientTerminalId'),
    },
    data: {}, //FIXME
    url,
    timeout: 20000,
  }
  payload.method = !!payload.method ? payload.method : 'GET'
  if (payload && payload.method !== 'GET') {
    options.method = payload.method
    options.data = payload.data // 送りたいデータは data の key にくるむ
    options.body = JSON.stringify(payload.body) // API の実装に合わせる
  }

  return axios(options)
    .then(response => {
      return response
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export default store => next => action => {
  const callAPI = action[CALL_API] // 指定したタイプの Action

  if (typeof callAPI === 'undefined') {
    return next(action)
  }

  const { endpoint } = callAPI
  const { types, payload } = callAPI

  const actionWith = data => {
    const finalAction = Object.assign({}, action, data)
    delete finalAction[CALL_API]
    return finalAction
  }
  const [requestType, okType, ngType] = types
  next(actionWith({ type: requestType }))

  return callApi(endpoint, payload).then(
    response =>
      next(actionWith({ payload: { data: response.data }, type: okType })),
    error =>
      next(
        actionWith({ type: ngType, errror: error.message || 'system error' })
      )
  )
}
