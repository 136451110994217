import { UPDATE_PICKUP_FORM, CONFIRM_PICKUP_FORM } from '../actions'
import { history } from '../configureStore'
import {
  validateGuest,
  validatePickupRestaurant,
  validateUsePoint,
} from './logic/validator'
import { save as saveGuest, remove as removeGuest } from './logic/guest'

const validateMenuForm = menuForm => {
  let errors = {}

  !menuForm.date && (errors.date = '日付が必要です')
  !menuForm.time && (errors.time = '時間が必要です')

  let usePointErrors = validateUsePoint(
    menuForm.effectivePoint,
    menuForm.usePoint,
    menuForm.isUsePoint,
    menuForm.totalPrice
  )
  Object.keys(usePointErrors).length && (errors.usePoint = usePointErrors)

  // 予約金額より多いポイントを利用する際はバリデーションエラーが発生することが望ましいが、
  // バリデーションを突破してしまうことがある。
  // メニュー金額よりポイントの方が多ければ使用するポイントを下げる
  // if (
  //   menuForm.totalPrice <= menuForm.usePoint &&
  //   menuForm.usePoint <= menuForm.effectivePoint
  // ) {
  //   menuForm.usePoint = Math.round(menuForm.totalPrice / 100) * 100 - 100
  //   console.log(
  //     menuForm.totalPrice,
  //     menuForm.usePoint,
  //     menuForm.effectivePoint,
  //     1
  //   )
  // }
  // if (
  //   menuForm.totalPrice <= menuForm.usePoint &&
  //   menuForm.effectivePoint <= menuForm.totalPrice
  // ) {
  //   menuForm.usePoint = menuForm.effectivePoint
  //   console.log(menuForm.usePoint, 2)
  // }

  !menuForm.confirmedCancellationPolicy &&
    (errors.confirmedCancellationPolicy =
      'キャンセルポリシーとユーザーガイドのチェックが必要です')

  let guestErrors = validateGuest(menuForm.guest)
  Object.keys(guestErrors).length && (errors.guest = guestErrors)

  let restaurantErroes = validatePickupRestaurant(menuForm.restaurant)
  Object.keys(restaurantErroes).length && (errors.restaurant = restaurantErroes)

  return errors
}

const update = action => {
  const menuForm = action.payload

  if (Object.keys(menuForm.errors).length) {
    let errors = validateMenuForm(menuForm)
    return { ...action, payload: { ...action.payload, errors: errors } }
  }
  return action
}

const confirm = action => {
  const menuForm = action.payload
  let errors = validateMenuForm(menuForm)

  if (!!Object.keys(errors).length) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    return { ...action, payload: { ...action.payload, errors: errors } }
  }

  action.meta && action.meta.isSaveUser
    ? saveGuest(menuForm.guest)
    : removeGuest()

  history.push('/pickup/restaurants/menu-reservation/confirm')
  return action
}

export default store => next => action => {
  if (action.type === UPDATE_PICKUP_FORM) {
    return next(update(action))
  }

  if (action.type === CONFIRM_PICKUP_FORM) {
    return next(confirm(action))
  }
  return next(action)
}
