import React from 'react'

const SnsShareButton = ({ name, address }) => {
  const url = window.location.href
  return (
    <div className="sns__wrapper">
      <a
        href={`https://twitter.com/share?text=${name}%20%0a${address}&url=${url}`}
        target="_blank"
        rel="noopener noreferrer"
        className="sns__twitter-button"
      >
        <img
          src="/assets/img/chefle/twitter-share1.png"
          alt="twitterロゴ"
          className="sns__twitter-logo"
        />
      </a>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
        target="_blank"
        rel="noopener noreferrer"
        className="sns__facebook-button"
      >
        <img
          src="/assets/img/chefle/facebook-share1.png"
          alt="twitterロゴ"
          className="sns__facebook-logo"
        />
      </a>
    </div>
  )
}

export default SnsShareButton
