import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { PcHeader, SpHeader } from '../../components/top/Header'
import AuthComponent, {
  authMapStateToProps,
  authMapDispatchToProps,
} from '../../components/AuthComponent'

function mapStateToProps(state) {
  return authMapStateToProps(state)
}

function mapDispatchToProps(dispatch) {
  return authMapDispatchToProps(dispatch)
}

class Community extends AuthComponent {
  componentDidMount() {
    super.componentDidMount()
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
  }

  render() {
    const { authUser } = this.props

    return (
      <>
        <div className="top-page">
          <PcHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
            reservationType={'community'}
          />
          <SpHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
            reservationType={'community'}
          />
          <section>
            <div
              className="top-page-selection"
              Style="margin-top: 100px; padding: 10px;"
            >
              <p className="community__head">シェフルへようこそ</p>
              <p className="community__text">
                訪日外国人旅行者に人気のあるお店の掲載から弊社サービスは始まりました。
                それは間違いなく、日本国内の皆様にもオススメのお店です。
              </p>
              <p className="community__text">
                新掲載のお店や、新しい企画、たまにオススメの旅の情報など、発信していきたいと思います。
              </p>
              <p className="community__text">
                お好みのアイコンをクリックしてみてください。
              </p>
              <div className="community__sns">
                <div>
                  <a href="https://lin.ee/kuYTXl9">
                    <img
                      className="community__icon"
                      Style="margin-right: 50px;"
                      src="/assets/img/chefle/line.png"
                      alt="line_icon"
                    />
                  </a>
                </div>
                <div>
                  <a href="https://www.facebook.com/Chefle">
                    <img
                      className="community__icon"
                      Style="margin-right: 50px;"
                      src="/assets/img/chefle/facebook.png"
                      alt="facebook_icon"
                    />
                  </a>
                </div>
                <div>
                  <a href="https://www.instagram.com/chefle_official">
                    <img
                      className="community__icon"
                      src="/assets/img/chefle/insta.png"
                      alt="instagram_icon"
                    />
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Community)
