import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { PcHeader, SpHeader } from '../components/top/Header'
import AuthComponent, {
  authMapStateToProps,
  authMapDispatchToProps,
} from '../components/AuthComponent'
import { Link } from 'react-router-dom'

const head = {
  title: `Go To Eat キャンペーンについて`,
  description: '',
}

function mapStateToProps(state) {
  return Object.assign(authMapStateToProps(state), {})
}

function mapDispatchToProps(dispatch) {
  return Object.assign(authMapDispatchToProps(dispatch), {})
}
class AboutGoToEat extends AuthComponent {
  render() {
    const { authUser } = this.props

    return (
      <>
        <div className="top-page goto">
          <Helmet>
            <title>{head.title}</title>
            <meta property="og:title" content={head.title} />
            <meta property="og:description" content={head.description} />
            <meta name="description" content={head.description} />
            <meta name="robots" content="noindex" />
          </Helmet>
          <PcHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
          />
          <SpHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
          />
          <section>
            <div>
              <img
                src="/assets/img/gotoeat/fv-pc.png"
                className="pc"
                Style="vertical-align:bottom; width:100%; padding-top:70px;"
                alt="gotoeat"
              />
            </div>
            <div className="sp" Style="padding-top:50px;">
              <img
                src="/assets/img/gotoeat/fv-sp.png"
                Style="vertical-align:bottom; width:100%;"
                alt="gotoeat"
              />
            </div>
          </section>
          <section>
            <div class="top-page-selection">
              <div className="notice-goto">
                <p className="notification-title">お知らせ</p>
                <p className="notification-txt">
                  感染拡大防止に向けて、対象地域の酒類提供を行う飲食店などに対し、営業時間短縮等の要請が出されました。
                  <br />
                  本キャンペーンは、これらの要請に沿って運用しますので、利用者におかれましてはご留意いただき、要請にご協力をお願いいたします。
                  <br />
                  随時更新されますので、詳細は下記農水省HP「Go
                  ToEat事業」についてのTOPICSをご参照ください。
                </p>
                <a
                  className="notification-maff"
                  href="https://www.maff.go.jp/j/shokusan/gaisyoku/hoseigoto.html"
                >
                  https://www.maff.go.jp/j/shokusan/gaisyoku/hoseigoto.html
                </a>
              </div>
            </div>
          </section>

          <section class="goto-about">
            <div class="top-page-selection">
              <div class="top-page-restaurant-title-box">
                <h3>GO TO EAT　キャンペーンとは</h3>
              </div>
              <div class="thoughts-about-the-chefle">
                シェフル(Chefle)
                “来店予約”に掲載されている全レストラン（コロナ対策対応済み）に予約・来店すると、
                <br />
                来店されるお客様お一人につき1,000円分のGo To
                Eatポイントがもらえる農林水産省が進めるキャンペーンです。
                <br />
                次回予約時に、ポイント分が割引でご予約できます。
                <p>
                  <span>
                    ＊1回のご予約での獲得ポイントは最大10名様分（10,000ポイント）となっています。
                  </span>
                </p>
              </div>
              <div class="top-page-goto-box">
                <div class="top-page-goto-box-title">
                  <p class="top-page-goto-box-title-top">
                    期間中何度でも利用できる
                  </p>
                  <p class="top-page-goto-box-title-middle">
                    Go To Eat　キャンペーン概要
                  </p>
                  <p class="top-page-goto-box-title-bottom">対象期間</p>
                </div>
                <div class="top-page-goto-box-text">
                  <span>・ポイント獲得：</span>
                  <span className="top-page-goto-box-campaign">
                    10月10日予約リクエスト分〜2021年1月31日まで
                  </span>
                  <span>（終了いたしました）</span>
                  <div className="top-page-goto-text-point">
                    *11月17日よりChefleポイントの付与を開始しています。
                  </div>
                  <br class="br-sp" />
                  ・ポイント利用：ポイント獲得後〜
                  <br class="br-sp" />
                  2021年
                  <span class="top-page-goto-campaign-date">
                    3月31日
                    <span class="top-page-goto-campaign-date-end">5月31日</span>
                  </span>
                  予約リクエスト分まで
                </div>
                <div class="top-page-goto-box-box">
                  <div class="top-page-goto-box-box-container">
                    <div class="top-page-goto-box-box-container-check">
                      <img src="/assets/img/gotoeat/checked.png" alt="check" />
                    </div>
                    <div class="top-page-goto-box-box-container-text">
                      <p class="top-page-goto-box-box-container-text-top">
                        ランチ（14:59までご来店）
                      </p>
                      <p class="top-page-goto-box-box-container-text-bottom">
                        お一人様 <span>500</span>ポイント
                      </p>
                    </div>
                  </div>
                  <div class="top-page-goto-box-box-container">
                    <div class="top-page-goto-box-box-container-check">
                      <img src="/assets/img/gotoeat/checked.png" alt="check" />
                    </div>
                    <div class="top-page-goto-box-box-container-text">
                      <p class="top-page-goto-box-box-container-text-top">
                        ディナー（15:00以降のご来店）
                      </p>
                      <p class="top-page-goto-box-box-container-text-bottom">
                        お一人様 <span>1000</span>ポイント
                      </p>
                    </div>
                  </div>
                </div>
                <div class="top-page-goto-box-note">
                  <div>
                    [ポイント有効期限と最大予約期間（来店日）について]
                    <br />
                    ポイント有効期限は2021年5月31日までとなり、予約期間（来店日）の最終日は翌月25日の、2021年6月25日となります。
                    <br />
                    （GoToEatポイントを利用しては、これ以降の予約ができません。）
                  </div>
                  <div Style="margin: 20px 0;">
                    [有効期限後に予約日時を変更する場合]
                    <br />
                    6月25日以降の来店日には変更できません。
                  </div>
                  *1ポイント=1円としてご利用いただけます。 <br class="br-sp" />
                  *100ポイント単位でご利用いただけます。
                  <br />
                  *1予約につき最大10名様分までとなります。
                  <br />
                  *GoToEatキャンペーンを利用する場合は、獲得ポイント以上の飲食が必要となります。
                </div>
              </div>
            </div>
          </section>
          <section class="goto-howto">
            <div class="top-page-selection">
              <div class="top-page-selection-title-box">
                <div class="top-page-selection-title">
                  <h4>Go To Eat キャンペーンご利用方法</h4>
                </div>
              </div>
              <div class="top-page-selection-head" Style="padding-top: 20px">
                <div class="top-page-selection-box">
                  <div class="top-page-selection-box-img">
                    <img src="/assets/img/gotoeat/howto_01.png" alt="howto1" />
                  </div>
                  <h3>
                    <span>STEP 1.</span> レストランを予約
                  </h3>
                  <p>
                    シェフル(Chefle)”来店予約”に掲載されているレストランを予約。(全て料理代は事前決済です)1回のご予約でお一人様最大1000ポイント(合計最大10名様分10,000ポイント)が貯まります。
                  </p>
                </div>
                <div class="top-page-selection-box-img-arrow">
                  <img
                    src="/assets/img/gotoeat/goto-arrow.png"
                    alt=""
                    class="pc"
                  />
                  <img
                    src="/assets/img/gotoeat/goto-arrow-sp.png"
                    alt=""
                    class="sp"
                  />
                </div>
                <div class="top-page-selection-box">
                  <div class="top-page-selection-box-img">
                    <img src="/assets/img/gotoeat/howto_02.png" alt="howto2" />
                  </div>
                  <h3>
                    <span>STEP 2.</span> 来店
                  </h3>
                  <p>
                    シェフル(Chefle)掲載店は、全てコロナ対策を万全に行っているお店です。
                    <br />
                    ご自身の感染予防と周囲の方へのご配慮の徹底もどうぞお願いいたします。
                  </p>
                </div>
                <div class="top-page-selection-box-img-arrow">
                  <img
                    src="/assets/img/gotoeat/goto-arrow.png"
                    alt=""
                    class="pc"
                  />
                  <img
                    src="/assets/img/gotoeat/goto-arrow-sp.png"
                    alt=""
                    class="sp"
                  />
                </div>
                <div class="top-page-selection-box">
                  <div class="top-page-selection-box-img">
                    <img src="/assets/img/gotoeat/howto_03.png" alt="howto3" />
                  </div>
                  <h3>
                    <span>STEP 3.</span> ポイント獲得・利用
                  </h3>
                  <p>
                    来店日の翌日に獲得ポイントが確定します。
                    <br />
                    次回予約時に、ポイントを100ポイント単位でご利用いただけます。
                  </p>
                </div>
              </div>
              <div class="top-page-goto-box-howto">
                <p class="top-page-goto-box-howto-title">ご注意</p>
                <p class="top-page-goto-box-howto-note">
                  <span>・GoToEatポイントの付与は11月17日で終了しました。</span>
                  <span className="top-page-goto-text-description">
                    (Chefleポイントの付与を同日より開始しております。)
                  </span>
                  <br />
                  <span>
                    ・GoToEatキャンペーンを利用する場合は、獲得ポイント以上の飲食が必要となります。
                  </span>
                  <br />
                  <span>
                    ・“店頭受取”はポイント利用のみの対象、”在宅サービス“はポイント獲得・利用の対象外となります。
                  </span>
                  <br />
                  ・新型コロナウイルス感染症の感染拡大状況により、特定のエリアのレストランやご利用者がGo
                  To Eatキャンペーンの対象外となる場合があります。
                  <br />
                  ・Go To
                  Eatキャンペーンは国の給付金を活用した事業です。予算に達した時点でキャンペーンが前倒しで終了する場合があります。
                  <br />
                  ・複数名のご予約の場合も、ご予約者にポイントがまとめて付与されます。
                  <br />
                  ・ポイントの獲得には、シェフル(Chefle）の会員登録が必要となります。
                </p>
              </div>
              <div className="top-page-goto-box-buttons">
                <a className="top-page-goto-box-button-top" href="/">
                  Chefle TOPページへ
                </a>
                <a
                  className="top-page-goto-box-button-agri"
                  href="https://gotoeat.maff.go.jp/"
                >
                  「農水省 Go To Eat キャンペーン」ページへ
                </a>
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutGoToEat)
