import React, { Component } from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'

const errorMessageStyle = {
  position: 'absolute',
  marginTop: '8px',
  color: 'red',
  fontSize: '0.8rem',
}
class MemberSelector extends Component {
  state = {
    open: false,
  }
  createList() {
    let list = []
    for (let i = 1; i < 20; i++) {
      list.push(i)
    }
    return list
  }
  handleOpen = () => this.setState({ open: true })
  handleClose = () => this.setState({ open: false })
  handleChange = event => {
    if (this.props.partySizeType === 'adult') {
      this.props.onUpdate({
        adult: event.target.value,
        date: null,
      })
      this.props.reFetchCalendar(event.target.value, 'adult')
    }
    if (this.props.partySizeType === 'child') {
      this.props.onUpdate({ child: event.target.value, date: null })
      this.props.reFetchCalendar(event.target.value, 'child')
    }
    if (this.props.partySizeType === 'baby') {
      this.props.onUpdate({ baby: event.target.value })
    }
  }

  render() {
    const list = this.createList()
    return (
      <>
        <FormControl>
          <Select
            open={this.state.open}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            value={this.props.menuForm}
            onChange={this.handleChange}
            error={!!this.props.error}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {list.map((m, i) => (
              <MenuItem key={i + 'menu'} value={m}>
                {m}
              </MenuItem>
            ))}
            <MenuItem value={20}>over 20</MenuItem>
          </Select>
        </FormControl>
        {this.props.error && (
          <div style={errorMessageStyle}>{this.props.error}</div>
        )}
      </>
    )
  }
}

export default MemberSelector
